import React from 'react';
import Dashboard from './Dashboard';

export default function TableDefinitionDashboard() {
  return (
    <Dashboard
      dashboardType="TABLE_DEFINITION"
      editable={false}
      defaultOrgaGridJson={gridJson}
      defaultOrgaPanelsJson={panelsJson}
      defaultUserGridJson={null}
      defaultUserPanelsJson={null}
    />
  );
}

const gridJson = {
  lg: [
    {
      w: 12,
      h: 8,
      x: 0,
      y: 0,
      i: 'TableDefinition_54A0-Toez8',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true
    }
  ],
  md: [
    {
      w: 12,
      h: 8,
      x: 0,
      y: 0,
      i: 'TableDefinition_54A0-Toez8',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true
    }
  ],
  sm: [
    {
      w: 6,
      h: 6,
      x: 0,
      y: 0,
      i: 'TableDefinition_54A0-Toez8',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true
    }
  ]
};

const panelsJson = {
  'TableDefinition_54A0-Toez8': {
    i: 'TableDefinition_54A0-Toez8',
    panelType: 'TableDefinition',
    configuration: {
      titleVisible: false,
      closable: false,
      movable: false,
      resizableX: false,
      resizableY: false
    }
  }
};

