import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import  { usePimStyles } from '../../../PimTogoTheme';
import TableDefinitionForm from './TableDefinitionForm';
import ContextTableDefiniton from './ContextTableDefiniton';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const dialog_theme = createTheme({
  components: {
    typography: {
      htmlFontSize: '25px !important',
    },
/*     MuiDialog: {
      styleOverrides: {
        paper: {
          fontSize: '50px !important',
        },
      },
    }, */
  },
});

export const CreateTableDefinition = ({
  openDialogTableDefinition,
  handleCloseDialogTableDefinition,
  initFormTableDefinitionState,
  toCreateTableDefinition,
  initContentTable,
  searchTableDefinitions,
  setTableContent,
  tableContent
  
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();

  useEffect(() => {}, []);

  return (
    <ThemeProvider theme={dialog_theme}>
          <Dialog
      aria-labelledby="create-instance-title"
      open={openDialogTableDefinition}
      onClose={handleCloseDialogTableDefinition}
      sx={{ '& .MuiDialog-paper': { maxWidth: '800px !important' } }} 
      fullWidth 
      transitionDuration={0}
    >
      <DialogTitle
        id="create-instance-title"
        className={classesPim.dialogTitle}
        sx={{  color: 'white' , fontSize: '2rem'  }} 
      >        {toCreateTableDefinition ? t('dashboard.tableDef.createTableDef') : t('dashboard.tableDef.editTableDef') }

      </DialogTitle>
      {/* <TableDefinitionForm
        handleCloseDialogTableDefinition={handleCloseDialogTableDefinition}
        initFormTableDefinitionState={initFormTableDefinitionState}
        toCreateTableDefinition={toCreateTableDefinition}
        searchTableDefinitions={searchTableDefinitions}
        // contentLangSelections={contentLangSelections}
      /> */}

      <ContextTableDefiniton
        handleCloseDialogTableDefinition={handleCloseDialogTableDefinition}
        initFormTableDefinitionState={initFormTableDefinitionState}
        toCreateTableDefinition={toCreateTableDefinition}
        searchTableDefinitions={searchTableDefinitions}
        setTableContent={setTableContent}
        tableContent={tableContent}
      />
    </Dialog>
  </ThemeProvider>

  );
};

export default CreateTableDefinition;
