import React from 'react';
import { useTranslation } from 'react-i18next';

import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  abcEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  input: {
    '&:invalid': {
      border: 'red solid 2px'
    }
  },
  switchMediaTextEditor: {
    float: 'right'
  }
}));

export const ToggleStandardValues = ({
  switchMediaTextEditor,
  setBlockDefaultData,
  resetBlockDefaultData,
  isBlockDefaultData,
  preventResetValue
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const state = !isBlockDefaultData;

  const handleChange = () => {
    if (state) {
      setBlockDefaultData();
    } else {
      resetBlockDefaultData(Boolean(preventResetValue));
    }
  };

  return (
    <Tooltip
      title={
        state
          ? t('instanceEditor.defaultToggleOff')
          : t('instanceEditor.defaultToggleOn')
      }
      arrow
    >
      <Switch
        checked={state}
        onClick={handleChange}
        color="default"
        name="checkedA"
        className={switchMediaTextEditor ? classes.switchMediaTextEditor : null}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Tooltip>
  );
};
