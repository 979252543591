import { createTheme, makeStyles } from '@material-ui/core/styles';
import { deDE, enUS, frFR, itIT } from '@material-ui/core/locale';

let materialUiLang;
switch (document.documentElement.lang) {
  case 'de':
    materialUiLang = deDE;
    break;
  case 'en':
    materialUiLang = enUS;
    break;
  case 'fr':
    materialUiLang = frFR;
    break;
  case 'it':
    materialUiLang = itIT;
    break;

  default:
    materialUiLang = enUS;
    break;
}

const orgaLocked = $('body > .orgaLocked').length > 0;

export const extraLightPimColor = orgaLocked ? '#dbdbdb' : '#ffd8a9';
export const lightPimColor = orgaLocked ? '#dedede' : '#ffc56e';
export const mainPimColor = orgaLocked ? '#b0b0b0' : '#f29528';
export const darkPimColor = orgaLocked ? '#6f6f6f' : '#c66a00';

export const gradientPimColor = orgaLocked
  ? 'linear-gradient(to right, #6f6f6f, #b0b0b0)'
  : 'linear-gradient(to right, #f29528, #fbdba7)';



const theme = createTheme(
  {
    palette: {
      primary: {
        light: '#6b93ff',
        main: '#0066ff',
        dark: '#003dcb',
        contrastText: '#fff'
      },
      secondary: {
        light: lightPimColor,
        main: mainPimColor,
        dark: darkPimColor,
        contrastText: '#fff'
      }
    },
    typography: {
      htmlFontSize: 10
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '1em'
        }
      },
      MuiDialogTitle: {
        root: {
          backgroundColor: '#4d4c47',
          '& .MuiTypography-root': {
            color: 'white'
          }
        }
      },
      MuiDialogContent: {
        root: {
          marginTop: '2rem'
        }
      }
    }
  },
  materialUiLang
);
export default theme;

export const usePimStyles = makeStyles(() => ({
  addAction: {
    color: 'white',
    backgroundColor: '#5cb85c !important',
    borderColor: '#5cb85c !important',
    '&:hover': {
      backgroundColor: '#458c45 !important'
    }
  },
  deleteAction: {
    color: 'white',
    backgroundColor: '#d9534f',
    borderColor: '#d43f3a',
    '&:hover': {
      backgroundColor: '#aa3e3b'
    }
  },
  deleteActionMenuButton: {
    color: '#d43f3a'
  },
  editAction: {
    color: 'white',
    backgroundColor: orgaLocked ? '#dedede !important' : '#f29528 !important',
    borderColor: orgaLocked ? '#dedede !important' : '#ea9026 !important',
    '&:hover': {
      backgroundColor: orgaLocked ? '#b0b0b0 !important' : '#c66a00 !important'
    }
  },
  editActionMenuButton: {
    color: '#000000de'
  },
  dialog: {
    '& .MuiDialog-container > .MuiDialog-paper': {
      borderRadius: '0.6rem',
      boxShadow: '0 0 1em black',
      '& > div > .MuiPaper-root': {
        padding: '0 2rem !important',
        boxShadow: 'none',
        overflow: 'auto',
        maxHeight: '70vh'
      }
    }
  },
  dialogTitle: {
    backgroundColor: '#333 !important',
    position: 'sticky',
    top: '0',
    zIndex: '9',
    '& > *': {
      color: 'white !important'
    }
  },
  dialogBottom: {
    position: 'sticky',
    bottom: '0',
    zIndex: '9',
    marginTop: '1rem'
  },
  gridItem: {
    margin: '8px'
  },
  dialogTableDefinition: {
    '& .MuiDialog-container > .MuiDialog-paper': {
      borderRadius: '0.6rem',
      boxShadow: '0 0 1em black',
      '& > div > .MuiPaper-root': {
        padding: '0 2rem !important',
        boxShadow: 'none',
        overflow: 'auto',
        maxHeight: '70vh',
        maxWidth: '800px !important'
      }
    }
  }
}));
