import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress, Icon } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

export const ReadOnlyFeature = ({
  featureInfo,
  featureValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();
  let valueText = '';
  let valueIcon = null;

  if (featureValue == null || featureValue == undefined) {
    valueText = '';
  } else {
    switch (featureInfo.featureComponent.featureType) {
      case 'FEATURE_TYPE_ALPHANUMERIC_NON_LOCALIZED':
      case 'FEATURE_TYPE_ALPHANUMERIC':
      case 'FEATURE_TYPE_NUMERIC': {
        valueText = featureValue;
        break;
      }
      case 'FEATURE_TYPE_ALPHANUMERIC_SELECTION':
      case 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION': {
        if (typeof featureValue != 'string') {
          const selectedValues = featureValue.selected;
          if (selectedValues) {
            let delimiter = '';
            selectedValues.forEach(function (selectedValue) {
              valueText += delimiter + selectedValue.label;
              delimiter = '|';
            });
          }
        } else {
          valueText = featureValue;
        }

        break;
      }
      case 'FEATURE_TYPE_BOOLEAN': {
        if (featureValue) {
          valueIcon = 'fa fa-check font-grey';
        } else {
          valueIcon = 'fa fa-times font-grey';
        }
        break;
      }
      case 'FEATURE_TYPE_LOCAL_DATE':
        break;
      //TODO: other feature types
    }
  }

  const title = isInTableContext ? null : featureInfo.title;

  const mandatory = featureInfo.mandatory;
  const isMandatoryError = mandatory && !featureValue;
  const isDefaultData = featureInfo.isDefaultData;

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !isDefaultData) {
    helper = t('common.errorMandatory');
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  if (valueIcon) {
    return <Icon className={valueIcon} aria-hidden="true" />;
  }

  return (
    <TextField
      InputProps={{ readOnly: true }}
      label={title}
      InputLabelProps={{
        shrink: false,
        disableAnimation: true,
        className: classesTitle.title
      }}
      disabled={true}
      className="derivedFeature"
      variant={isInTableContext ? 'standard' : 'outlined'}
      value={valueText}
      error={Boolean(error) || (isMandatoryError && !isDefaultData)}
      FormHelperTextProps={{ component: 'div' }}
      helperText={helper}
      onMouseEnter={showError}
    />
  );
};
