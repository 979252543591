export const standardEditor = {
  ROOT: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 5,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        singleData: true,
        childOverride: false
      }
    },
    displayName: 'Container',
    custom: {},
    parent: null,
    hidden: false,
    nodes: ['Sg_yTodtF'],
    linkedNodes: {}
  },
  Sg_yTodtF: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'Sg_yTodtF-1635839552081',
          label: {
            de: 'PRODUKT',
            en: 'PRODUCT',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Product: {
              Text: {
                bulkDataType_texts: true
              },
              Medium: {
                bulkDataType_media: true
              },
              CustomField: {
                bulkDataType_custom_fields: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1649683526681',
          label: {
            de: 'Artikel-Daten',
            en: 'Article data',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              CustomField: {
                bulkDataType_custom_fields_group_ARTICLE_DETAILS: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1635839667385',
          label: {
            de: 'artikel Texte+Medien',
            en: 'article text+Media',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              Text: {
                bulkDataType_texts: true
              },
              Medium: {
                bulkDataType_media: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1635839726761',
          label: {
            de: 'merkmale',
            en: 'features',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              Features: {
                bulkDataType_features: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1635839777835',
          label: {
            de: 'keywords',
            en: 'Keywords',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Product: {
              GeneralField: {
                keyword: true
              }
            },
            Article: {
              GeneralField: {
                keyword: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1635840021548',
          label: {
            de: 'alle Artikel-Daten',
            en: 'all article data ',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              GeneralField: {
                keyword: true
              },
              CustomField: {
                bulkDataType_custom_fields: true
              },
              Features: {
                bulkDataType_features: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1698317677885',
          label: {
            de: 'Golden Record',
            en: 'Golden Record',
            it: 'Golden Record',
            fr: 'Golden Record'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              CustomField: {
                bulkDataType_custom_fields: true
              },
              Features: {
                bulkDataType_features: true
              }
            }
          },
          subscriptionReq: ['GoldenRecord'],
          permissionReq: ['ROLE_GOLDEN_RECORD']
        },
        {
          id: 'Sg_yTodtF-1708423315808',
          label: {
            de: 'Datenbereiche',
            en: 'New tab',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {},
          permissionReq: ['ROLE_ADJUST_PERMISSIONS_DASHBOARD']
        },
        {
          id: 'Sg_yTodtF-1649678150331',
          label: {
            de: 'BMEcat DISCOUNT_GROUP',
            en: 'BMEcat DISCOUNT_GROUP',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_DISCOUNT_GROUP: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1649764352772',
          label: {
            de: 'BMEcaT REACH',
            en: 'BMEcat REACH',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              CustomField: {
                'bulkDataType_custom_fields_group_BMEcat REACH': true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1635840482572',
          label: {
            de: 'bmecat Bestelldaten',
            en: 'BMEcat Order data',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            Article: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_ORDER_DETAILS: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1635840716158',
          label: {
            de: 'BMEcat Preise',
            en: 'BMEcat Prices',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            PRODUCT_PRICE_DETAILS__BMEcat1: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_PRODUCT_PRICE_DETAILS: true
              }
            },
            PRODUCT_PRICE__BMEcat1: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_PRODUCT_PRICE: true
              }
            }
          }
        },
        {
          id: 'Sg_yTodtF-1646812968814',
          label: {
            de: 'BMEcat Verpackung',
            en: 'BMEcat Packaging',
            it: 'Nuovo tab',
            fr: 'Nouvel onglet'
          },
          singleData: true,
          selected: false,
          dataReq: {
            PACKING_UNIT__BMEcat1: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_PACKING_UNIT: true
              }
            }
          }
        }
      ],
      orientation: 'horizontal',
      isMultipleData: false,
      selectedTabIndex: 7,
      dataSource: {
        source: 'Product',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'ROOT',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'Sg_yTodtF-1628248349246': 'nwkWTboPw-',
      'Sg_yTodtF-1628248358977': '0joqe5qRC',
      'Sg_yTodtF-1628248359121': 'iOgTNCco4',
      'Sg_yTodtF-1628248886638': 'Z298HsS-E',
      'Sg_yTodtF-1628251597081': 'F1X-kYYG_',
      'Sg_yTodtF-1628251682188': 'gHGOjKANA',
      'Sg_yTodtF-1628443486621': 'oI22bn0fN',
      'Sg_yTodtF-1628492167929': '3EbYtwmI2',
      'Sg_yTodtF-1631611224109': 'oLFyuY3s8',
      'Sg_yTodtF-1631776620277': 'uzRcZrfrf',
      'Sg_yTodtF-1632296323800': 'wEqGELovc',
      'Sg_yTodtF-1632296653673': 'GKg7moXJV',
      'Sg_yTodtF-1632330725915': 'azwcguaet',
      'Sg_yTodtF-1633416134836': 'KRdsIjh9e',
      'Sg_yTodtF-1633416229710': 'BGoXGF89z',
      'Sg_yTodtF-1633442120334': 'pvL1DYMSF',
      'Sg_yTodtF-1633526674356': 'Uq5Ydh0bt',
      'Sg_yTodtF-1634028791117': 'biYiuJC3z',
      'Sg_yTodtF-1635497029188': 'alJHRjp17',
      'Sg_yTodtF-1635497208757': 'uD0TyyJ3o',
      'Sg_yTodtF-1635497212844': 'jml9n7pHm',
      'Sg_yTodtF-1635497212995': 'kzqI-y6_E',
      'Sg_yTodtF-1635497213136': 'T8ax5Ihkp',
      'Sg_yTodtF-1635497213287': 'hFHQkYhFw',
      'Sg_yTodtF-1635497213431': 'JXdDehr2r',
      'Sg_yTodtF-1635497213706': 'EVvoxBtkM',
      'Sg_yTodtF-1635497213571': '6SwD9MNXO',
      'Sg_yTodtF-1635839552081': 'ks4-CMz9v',
      'Sg_yTodtF-1635839616760': '-S8d5VxV_',
      'Sg_yTodtF-1635839667385': 'TGOQ83b15',
      'Sg_yTodtF-1635839726761': 'SvCsHRgQn',
      'Sg_yTodtF-1635839777835': 'fgq883r_B',
      'Sg_yTodtF-1635840021548': 'rKXBP-AGU',
      'Sg_yTodtF-1635840482572': 'z7ffBUt4u',
      'Sg_yTodtF-1635840716158': 'tHUB8wggY',
      'Sg_yTodtF-1646812968814': 'c80qm7ek4',
      'Sg_yTodtF-1649678150331': 'zTbQ9gQlQ',
      'Sg_yTodtF-1649683526681': 'LyjaXHryN',
      'Sg_yTodtF-1649764352772': 'HrcohlL3r',
      'Sg_yTodtF-1649776399797': 'sfIQruFZx',
      'Sg_yTodtF-1649776560109': '4EKDFmSFP',
      'Sg_yTodtF-1649776584109': 'icY-Zo9pP',
      'Sg_yTodtF-1650453044023': 'mp21cPTyg',
      'Sg_yTodtF-1698049119491': '2vD-G5jD7',
      'Sg_yTodtF-1698317634210': 'HfbzgHLEi',
      'Sg_yTodtF-1698317677885': 'CDDGIEeyt',
      'Sg_yTodtF-1708423315808': 'HXP7bIway'
    }
  },
  'nwkWTboPw-': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: [
      'ZqrMG12r9',
      'f3jfJANSM',
      'kWQTMZVZZ',
      'QOhpS0i6o',
      'gD7No7Xcs',
      'tqKznVHeT',
      '_IldkyJwS',
      'Xq3I3-QDY',
      'ys73thjRt'
    ],
    linkedNodes: {}
  },
  ZqrMG12r9: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: 'ProductName',
      variant: 'formatted'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '0joqe5qRC': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['oiHUyhLHW'],
    linkedNodes: {}
  },
  iOgTNCco4: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['FSBkMPp38'],
    linkedNodes: {}
  },
  FSBkMPp38: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1628248544645',
          header: 'alle allgemeinen Felder',
          singleData: true,
          category: {
            bulkDataType: 'general_fields',
            label: 'alle allgemeinen Felder'
          }
        },
        {
          dataId: 'tableData_1628248544770',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'iOgTNCco4',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1628248540779: 'PuBPhnkhfV',
      tableData_1628248544495: 't32iKkNu_',
      tableData_1628248544645: 'ia_AdwvOsv',
      tableData_1628248544770: 'eu9cO55afO',
      tableData_1628248744965: 'rif88TFPA',
      collapseTableRow: 'Z67_7Hbke',
      tableData_1629289423463: 'm3T8afOK6'
    }
  },
  PuBPhnkhfV: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248540779'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  t32iKkNu_: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248544495'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: ['yOabvXvMI'],
    linkedNodes: {}
  },
  ia_AdwvOsv: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248544645'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: ['ZoQuAGNxQ'],
    linkedNodes: {}
  },
  eu9cO55afO: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248544770'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: ['LTyzZnTqj'],
    linkedNodes: {}
  },
  yOabvXvMI: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_A_Text',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 't32iKkNu_',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  rif88TFPA: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248744965'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: ['H09QxBe3z'],
    linkedNodes: {}
  },
  'Z298HsS-E': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['FdlLz2d3V'],
    linkedNodes: {}
  },
  FdlLz2d3V: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1628248901566',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'Z298HsS-E',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1628248901566: 'B_MJXOiTuq',
      collapseTableRow: 'wkGQcyrUi'
    }
  },
  B_MJXOiTuq: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248901566'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FdlLz2d3V',
    hidden: false,
    nodes: ['rBlW4zBYw'],
    linkedNodes: {}
  },
  rBlW4zBYw: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'B_MJXOiTuq',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  wkGQcyrUi: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'FdlLz2d3V',
    hidden: false,
    nodes: ['n_kRdUtCK'],
    linkedNodes: {}
  },
  n_kRdUtCK: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1628248915801',
          header: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN',
          singleData: true,
          category: {
            customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN',
            label: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN'
          }
        },
        {
          dataId: 'tableData_1628248924496',
          header: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX',
          singleData: true,
          category: {
            customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX',
            label: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX'
          }
        },
        {
          dataId: 'tableData_1628248924640',
          header: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE',
          singleData: true,
          category: {
            customFieldType: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE',
            label: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE'
          }
        }
      ],
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'wkGQcyrUi',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1628248915801: 'V-yCZ81rIG',
      tableData_1628248924496: 'fWFcP8B58',
      tableData_1628248924640: 'aJxsHtNd9D'
    }
  },
  'V-yCZ81rIG': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248915801'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'n_kRdUtCK',
    hidden: false,
    nodes: ['NecpUHQt3'],
    linkedNodes: {}
  },
  NecpUHQt3: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'V-yCZ81rIG',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  fWFcP8B58: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248924496'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'n_kRdUtCK',
    hidden: false,
    nodes: ['TzYzu_oT8'],
    linkedNodes: {}
  },
  aJxsHtNd9D: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628248924640'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'n_kRdUtCK',
    hidden: false,
    nodes: ['GFgj0K3Jx'],
    linkedNodes: {}
  },
  TzYzu_oT8: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'fWFcP8B58',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  GFgj0K3Jx: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'aJxsHtNd9D',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  f3jfJANSM: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: 'ProductImage',
      label: 'Medientyp auswählen...'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'F1X-kYYG_': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['w6OHh3zJm'],
    linkedNodes: {}
  },
  w6OHh3zJm: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1628251616955',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            'UDX.EDXF.PACKING_UNIT': {
              CustomField: {
                'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN': true,
                'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX': true,
                'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE': true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      isShortVerticalTab: true,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'F1X-kYYG_',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1628251616955': 'oXTWVVVFFp'
    }
  },
  oXTWVVVFFp: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'w6OHh3zJm',
    hidden: false,
    nodes: ['EdgenqSXj'],
    linkedNodes: {}
  },
  EdgenqSXj: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1628251633493',
          header: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN',
          singleData: true,
          category: {
            customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN',
            label: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN'
          }
        },
        {
          dataId: 'tableData_1628251639813',
          header: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX',
          singleData: true,
          category: {
            customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX',
            label: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX'
          }
        },
        {
          dataId: 'tableData_1628251640039',
          header: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE',
          singleData: true,
          category: {
            customFieldType: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE',
            label: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE'
          }
        }
      ],
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'oXTWVVVFFp',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1628251633493: 'BGKNT8aE8K',
      tableData_1628251639813: 'XP86LC6zA',
      tableData_1628251640039: '6XNGxql6OM'
    }
  },
  BGKNT8aE8K: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251633493'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'EdgenqSXj',
    hidden: false,
    nodes: ['C0VHu7UuR'],
    linkedNodes: {}
  },
  C0VHu7UuR: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MIN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'BGKNT8aE8K',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  XP86LC6zA: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251639813'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'EdgenqSXj',
    hidden: false,
    nodes: ['ROQexhKZu'],
    linkedNodes: {}
  },
  '6XNGxql6OM': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'UDX.EDXF.PACKING_UNIT',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251640039'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'EdgenqSXj',
    hidden: false,
    nodes: ['uqzBUIEJ-'],
    linkedNodes: {}
  },
  ROQexhKZu: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UDX.EDXF.PACKING_UNIT-QUANTITY_MAX',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'XP86LC6zA',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'uqzBUIEJ-': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UDX.EDXF.PACKING_UNIT-PACKING_UNIT_CODE',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '6XNGxql6OM',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  gHGOjKANA: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['mSPL9NwOj'],
    linkedNodes: {}
  },
  mSPL9NwOj: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1628251695291',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            PRODUCT_PRICE_DETAILS: {
              CustomField: {
                'PRODUCT_PRICE_DETAILS-DATETIMEvalid_start_date': true,
                'PRODUCT_PRICE_DETAILS-DATETIMEvalid_end_date': true,
                'PRODUCT_PRICE_DETAILS-DAILY_PRICE': true
              }
            },
            PRODUCT_PRICE: {
              CustomField: {
                'PRODUCT_PRICE-TERRITORY': true,
                'PRODUCT_PRICE-price_type': true,
                'PRODUCT_PRICE-LOWER_BOUND': true,
                'PRODUCT_PRICE-PRICE_AMOUNT': true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      isShortVerticalTab: true,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'gHGOjKANA',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1628251695291': 'NNevGx6KAl'
    }
  },
  NNevGx6KAl: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'mSPL9NwOj',
    hidden: false,
    nodes: ['XRy2z7Pc4'],
    linkedNodes: {}
  },
  XRy2z7Pc4: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1628251701836',
          header: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_start_date',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_start_date',
            label: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_start_date'
          }
        },
        {
          dataId: 'tableData_1628251713441',
          header: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_end_date',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_end_date',
            label: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_end_date'
          }
        },
        {
          dataId: 'tableData_1628251713740',
          header: 'PRODUCT_PRICE_DETAILS-DAILY_PRICE',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE_DETAILS-DAILY_PRICE',
            label: 'PRODUCT_PRICE_DETAILS-DAILY_PRICE'
          }
        }
      ],
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'NNevGx6KAl',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1628251701836: 'G32ye8FaIy',
      tableData_1628251713441: 'FYrfsxTi9',
      tableData_1628251713740: '6ZY5Sh8Gi6',
      collapseTableRow: 'OnJvvn3wV'
    }
  },
  G32ye8FaIy: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251701836'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'XRy2z7Pc4',
    hidden: false,
    nodes: ['n2VM0CMjx'],
    linkedNodes: {}
  },
  n2VM0CMjx: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_start_date',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'G32ye8FaIy',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  FYrfsxTi9: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251713441'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'XRy2z7Pc4',
    hidden: false,
    nodes: ['wVG8TDvHv'],
    linkedNodes: {}
  },
  '6ZY5Sh8Gi6': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251713740'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'XRy2z7Pc4',
    hidden: false,
    nodes: ['KIv9GSFLV'],
    linkedNodes: {}
  },
  wVG8TDvHv: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE_DETAILS-DATETIMEvalid_end_date',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'FYrfsxTi9',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  KIv9GSFLV: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE_DETAILS-DAILY_PRICE',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '6ZY5Sh8Gi6',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  OnJvvn3wV: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'XRy2z7Pc4',
    hidden: false,
    nodes: ['_9bdAOrCy'],
    linkedNodes: {}
  },
  _9bdAOrCy: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1628251810511',
          header: 'PRODUCT_PRICE-TERRITORY',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE-TERRITORY',
            label: 'PRODUCT_PRICE-TERRITORY'
          }
        },
        {
          dataId: 'tableData_1628251821431',
          header: 'PRODUCT_PRICE-price_type',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE-price_type',
            label: 'PRODUCT_PRICE-price_type'
          }
        },
        {
          dataId: 'tableData_1628251821574',
          header: 'PRODUCT_PRICE-LOWER_BOUND',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE-LOWER_BOUND',
            label: 'PRODUCT_PRICE-LOWER_BOUND'
          }
        },
        {
          dataId: 'tableData_1628251821722',
          header: 'PRODUCT_PRICE-PRICE_AMOUNT',
          singleData: true,
          category: {
            customFieldType: 'PRODUCT_PRICE-PRICE_AMOUNT',
            label: 'PRODUCT_PRICE-PRICE_AMOUNT'
          }
        },
        {
          dataId: 'tableData_1628251821874',
          header: 'Neue Spalte',
          singleData: true
        }
      ],
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'OnJvvn3wV',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1628251810511: 'wvHr51LEiR',
      tableData_1628251821431: 'RGqh6-0qF',
      tableData_1628251821574: 'Xj7NQqGb24',
      tableData_1628251821722: 'kPeogbWI6g',
      tableData_1628251821874: 'io9BOPXP7a'
    }
  },
  wvHr51LEiR: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251810511'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '_9bdAOrCy',
    hidden: false,
    nodes: ['N98pznu-X'],
    linkedNodes: {}
  },
  'N98pznu-X': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE-TERRITORY',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'wvHr51LEiR',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'RGqh6-0qF': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251821431'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '_9bdAOrCy',
    hidden: false,
    nodes: ['Gk-FiWrKE'],
    linkedNodes: {}
  },
  Xj7NQqGb24: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251821574'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '_9bdAOrCy',
    hidden: false,
    nodes: ['8teLK2Dgl'],
    linkedNodes: {}
  },
  kPeogbWI6g: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251821722'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '_9bdAOrCy',
    hidden: false,
    nodes: ['VlL4FzVIL'],
    linkedNodes: {}
  },
  io9BOPXP7a: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1628251821874'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '_9bdAOrCy',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'Gk-FiWrKE': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE-price_type',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'RGqh6-0qF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '8teLK2Dgl': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE-LOWER_BOUND',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'Xj7NQqGb24',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  VlL4FzVIL: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_PRICE-PRICE_AMOUNT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'kPeogbWI6g',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  kWQTMZVZZ: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_P_Text',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  QOhpS0i6o: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_P_Text_Translate',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  _IldkyJwS: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_Select',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'Xq3I3-QDY': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_P_MultiSelect',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  gD7No7Xcs: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_P_Number',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  tqKznVHeT: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_RANGE_NUMBER',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  ys73thjRt: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_P_Date',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nwkWTboPw-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  oI22bn0fN: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['MaWeYGnW0', 'NePP3YPwZ', 'zwTFrHobL', 'pqlDjSkQG'],
    linkedNodes: {}
  },
  pqlDjSkQG: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'oI22bn0fN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '3EbYtwmI2': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['mgUGsCYO2'],
    linkedNodes: {}
  },
  mgUGsCYO2: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1628492186593',
          label: 'Art. Nr.',
          singleData: true
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      isShortVerticalTab: true,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: '3EbYtwmI2',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1628492186593': 'b163oqcLBN'
    }
  },
  b163oqcLBN: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'mgUGsCYO2',
    hidden: false,
    nodes: [
      '_32ik-bzM',
      'szB8NYg3o',
      'Y0PCVqc49',
      'uiO1ic8Sc',
      'skmi5QvDS',
      'HkWXSsG2j',
      '4G8e5uJs1',
      '7wD3OtzR4',
      '0jDeMTMHH',
      'E7YSZ5qW6'
    ],
    linkedNodes: {}
  },
  szB8NYg3o: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Y0PCVqc49: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'ean',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  uiO1ic8Sc: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'originCountry',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  skmi5QvDS: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  HkWXSsG2j: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'NO_CU_PER_OU',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '4G8e5uJs1': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CONTENT_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '7wD3OtzR4': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MIN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '0jDeMTMHH': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_INTERVAL',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  E7YSZ5qW6: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRICE_QUANTITY',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Z67_7Hbke: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: ['WF8ADmKz_', 'e895PUVh6'],
    linkedNodes: {}
  },
  H09QxBe3z: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_A_Range',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'rif88TFPA',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '_32ik-bzM': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'b163oqcLBN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  oiHUyhLHW: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1629107672963',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1629107679659',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: '0joqe5qRC',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1629107672963: 'IZwYBrI5pR',
      tableData_1629107679659: 'IKlP_Nzlp'
    }
  },
  IZwYBrI5pR: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1629107672963'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'oiHUyhLHW',
    hidden: false,
    nodes: ['KAYUk6Np-'],
    linkedNodes: {}
  },
  IKlP_Nzlp: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1629107679659'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'oiHUyhLHW',
    hidden: false,
    nodes: ['BGolCx1_h'],
    linkedNodes: {}
  },
  'KAYUk6Np-': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'IZwYBrI5pR',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  BGolCx1_h: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'IKlP_Nzlp',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  m3T8afOK6: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1629289423463'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'FSBkMPp38',
    hidden: false,
    nodes: ['aLDeei6Ff'],
    linkedNodes: {}
  },
  aLDeei6Ff: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_A_BOOLEAN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'm3T8afOK6',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  MaWeYGnW0: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Product',
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'oI22bn0fN',
    hidden: false,
    nodes: ['OiWjLVOYx'],
    linkedNodes: {}
  },
  OiWjLVOYx: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'MaWeYGnW0',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  NePP3YPwZ: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'oI22bn0fN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '-jCjMGAzD': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'zwTFrHobL',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  FhgGMrhn9: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'general_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'zwTFrHobL',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  oLFyuY3s8: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['HGRTiri_E'],
    linkedNodes: {}
  },
  HGRTiri_E: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1631611246007',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1631611250469',
          header: 'ORDER_UNIT',
          singleData: true,
          category: {
            customFieldType: 'ORDER_UNIT',
            label: 'ORDER_UNIT'
          }
        },
        {
          dataId: 'tableData_1631611523092',
          header: 'NO_CU_PER_OU',
          singleData: true,
          category: {
            customFieldType: 'NO_CU_PER_OU',
            label: 'NO_CU_PER_OU'
          }
        },
        {
          dataId: 'tableData_1631611531065',
          header: 'CONTENT_UNIT',
          singleData: true,
          category: {
            customFieldType: 'CONTENT_UNIT',
            label: 'CONTENT_UNIT'
          }
        },
        {
          dataId: 'tableData_1631611531641',
          header: 'QUANTITY_MIN',
          singleData: true,
          category: {
            customFieldType: 'QUANTITY_MIN',
            label: 'QUANTITY_MIN'
          }
        },
        {
          dataId: 'tableData_1631611531832',
          header: 'QUANTITY_INTERVAL',
          singleData: true,
          category: {
            customFieldType: 'QUANTITY_INTERVAL',
            label: 'QUANTITY_INTERVAL'
          }
        },
        {
          dataId: 'tableData_1631611532079',
          header: 'PRICE_QUANTITY',
          singleData: true,
          category: {
            customFieldType: 'PRICE_QUANTITY',
            label: 'PRICE_QUANTITY'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'oLFyuY3s8',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1631611246007: 'oXBKdaTiJ6',
      tableData_1631611250469: '2nS52rpkQ',
      tableData_1631611523092: 'sHv77Zi9B',
      tableData_1631611531065: 'Q2xbwLa5B',
      tableData_1631611531641: 'Z0qABmITIQ',
      tableData_1631611531832: 'egj8wwwomC',
      tableData_1631611532079: 'e-o-kFTnG'
    }
  },
  oXBKdaTiJ6: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611246007'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['WNLZdUWuv'],
    linkedNodes: {}
  },
  '2nS52rpkQ': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611250469'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['KNeb9X1DK'],
    linkedNodes: {}
  },
  WNLZdUWuv: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'oXBKdaTiJ6',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  KNeb9X1DK: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2nS52rpkQ',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  sHv77Zi9B: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611523092'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['plz6XIOH7'],
    linkedNodes: {}
  },
  plz6XIOH7: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'NO_CU_PER_OU',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'sHv77Zi9B',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Q2xbwLa5B: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611531065'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['dx80BuUNB'],
    linkedNodes: {}
  },
  Z0qABmITIQ: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611531641'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['M3tgMt7lM'],
    linkedNodes: {}
  },
  egj8wwwomC: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611531832'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['tjgL5jhl-'],
    linkedNodes: {}
  },
  'e-o-kFTnG': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1631611532079'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'HGRTiri_E',
    hidden: false,
    nodes: ['fYC0x04QO'],
    linkedNodes: {}
  },
  dx80BuUNB: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CONTENT_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'Q2xbwLa5B',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  M3tgMt7lM: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MIN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'Z0qABmITIQ',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'tjgL5jhl-': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_INTERVAL',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'egj8wwwomC',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  fYC0x04QO: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRICE_QUANTITY',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'e-o-kFTnG',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  uzRcZrfrf: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['HEgs4d77B'],
    linkedNodes: {}
  },
  HEgs4d77B: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1631776634258',
          label: 'Art. Nr.',
          singleData: true
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'uzRcZrfrf',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1631776634258': 'c1TMIo4a0Y'
    }
  },
  c1TMIo4a0Y: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'HEgs4d77B',
    hidden: false,
    nodes: ['vC8HRK34q', 'OUYsjnd03', 'xY8cf5BX2'],
    linkedNodes: {}
  },
  vC8HRK34q: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'ean',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'c1TMIo4a0Y',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  OUYsjnd03: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'originCountry',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'c1TMIo4a0Y',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  xY8cf5BX2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'c1TMIo4a0Y',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  LTyzZnTqj: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'eu9cO55afO',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  WF8ADmKz_: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'Z67_7Hbke',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  wEqGELovc: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['syeGklzOE', 'akTRpmsSA', 'omACpezrz', 'lcjyoposd'],
    linkedNodes: {}
  },
  akTRpmsSA: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'wEqGELovc',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  lcjyoposd: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'wEqGELovc',
    hidden: false,
    nodes: ['RXOqAyroT'],
    linkedNodes: {}
  },
  RXOqAyroT: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1632296354393',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1632296358802',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'lcjyoposd',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1632296354393: 'I5pJP3_OkU',
      tableData_1632296358802: '3rLBBskeu',
      collapseTableRow: 'E3x9VUn4U'
    }
  },
  I5pJP3_OkU: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1632296354393'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'RXOqAyroT',
    hidden: false,
    nodes: ['Afjl5m_jZ'],
    linkedNodes: {}
  },
  '3rLBBskeu': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1632296358802'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'RXOqAyroT',
    hidden: false,
    nodes: ['yHkBGplXn'],
    linkedNodes: {}
  },
  Afjl5m_jZ: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'I5pJP3_OkU',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  yHkBGplXn: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '3rLBBskeu',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  ZoQuAGNxQ: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'general_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ia_AdwvOsv',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  e895PUVh6: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'Z67_7Hbke',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  GKg7moXJV: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['REP2V30kG'],
    linkedNodes: {}
  },
  REP2V30kG: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1632296670848',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            Article: {
              Text: {
                bulkDataType_texts: true
              },
              Medium: {
                bulkDataType_media: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'GKg7moXJV',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1632296670848': 'DqDyicH2Mf'
    }
  },
  DqDyicH2Mf: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'REP2V30kG',
    hidden: false,
    nodes: ['SxAWwLTYE', 'tSNoDF_yK'],
    linkedNodes: {}
  },
  rizlA61nS: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'SxAWwLTYE',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  tSNoDF_yK: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'DqDyicH2Mf',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  E3x9VUn4U: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'RXOqAyroT',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  syeGklzOE: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: 'Produkt)',
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'wEqGELovc',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  omACpezrz: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: 'Artikel',
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'wEqGELovc',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  azwcguaet: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['rsyima3YI'],
    linkedNodes: {}
  },
  SxAWwLTYE: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'DqDyicH2Mf',
    hidden: false,
    nodes: ['rizlA61nS'],
    linkedNodes: {}
  },
  rsyima3YI: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1633416042911',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'azwcguaet',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1633416042911': '2kAl6Y0lYo'
    }
  },
  '2kAl6Y0lYo': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'rsyima3YI',
    hidden: false,
    nodes: [
      'UGD9V4NP9',
      '8gDDbjifz',
      'tps9SzV1D',
      'L7vr-iLRZ',
      'ragDEUxqn',
      'adlfFWrnH'
    ],
    linkedNodes: {}
  },
  UGD9V4NP9: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2kAl6Y0lYo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '8gDDbjifz': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'NO_CU_PER_OU',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2kAl6Y0lYo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  tps9SzV1D: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CONTENT_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2kAl6Y0lYo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'L7vr-iLRZ': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MIN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2kAl6Y0lYo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  ragDEUxqn: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_INTERVAL',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2kAl6Y0lYo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  adlfFWrnH: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRICE_QUANTITY',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '2kAl6Y0lYo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  KRdsIjh9e: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['B0Pea0WOW'],
    linkedNodes: {}
  },
  B0Pea0WOW: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1633416149873',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'KRdsIjh9e',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1633416149873': 'iALr5mVOfb'
    }
  },
  iALr5mVOfb: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'B0Pea0WOW',
    hidden: false,
    nodes: ['5q3_--vKM', 'R9zrzUfco'],
    linkedNodes: {}
  },
  '5q3_--vKM': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'general_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'iALr5mVOfb',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  R9zrzUfco: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'iALr5mVOfb',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  BGoXGF89z: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  pvL1DYMSF: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['KD7pRqdMJ', 'WG6KHEHIh', 'V2EAOlST1'],
    linkedNodes: {}
  },
  KD7pRqdMJ: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: {
        de: 'PRODUKT:',
        en: 'PRODUCT:',
        it: 'Titolo',
        fr: 'Titre'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'pvL1DYMSF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  WG6KHEHIh: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'pvL1DYMSF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  V2EAOlST1: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'pvL1DYMSF',
    hidden: false,
    nodes: ['LZveimX-W', 'D0k4qGAQ4'],
    linkedNodes: {}
  },
  'LZveimX-W': {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: {
        de: 'ARTIKEL:',
        en: 'ARTICLE:',
        it: 'Titolo',
        fr: 'Titre'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'V2EAOlST1',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  D0k4qGAQ4: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1633442218358',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1633442225754',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'V2EAOlST1',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1633442218358: 'ATvvPzkd-a',
      tableData_1633442225754: 'jWlMcJINi'
    }
  },
  'ATvvPzkd-a': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633442218358'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'D0k4qGAQ4',
    hidden: false,
    nodes: ['HCSTOaht0'],
    linkedNodes: {}
  },
  HCSTOaht0: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ATvvPzkd-a',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  jWlMcJINi: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633442225754'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'D0k4qGAQ4',
    hidden: false,
    nodes: ['l-N0X7j-c'],
    linkedNodes: {}
  },
  'l-N0X7j-c': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'jWlMcJINi',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  zwTFrHobL: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Product',
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'oI22bn0fN',
    hidden: false,
    nodes: ['-jCjMGAzD', 'FhgGMrhn9'],
    linkedNodes: {}
  },
  Uq5Ydh0bt: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['LvOE1TIWx', '3sTMPa1Jq', 'YOQG1AMLC'],
    linkedNodes: {}
  },
  LvOE1TIWx: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1633526686423',
          header: 'alle allgemeinen Felder',
          singleData: true,
          category: {
            bulkDataType: 'general_fields',
            label: 'alle allgemeinen Felder'
          }
        },
        {
          dataId: 'tableData_1633526700117',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        },
        {
          dataId: 'tableData_1633526701993',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        },
        {
          dataId: 'tableData_1633526797295',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1633526798225',
          header: 'EAN',
          singleData: true,
          category: {
            generalFieldType: 'ean',
            label: 'EAN'
          }
        },
        {
          dataId: 'tableData_1633526799147',
          header: 'Ursprungsland',
          singleData: true,
          category: {
            generalFieldType: 'originCountry',
            label: 'Ursprungsland'
          }
        },
        {
          dataId: 'tableData_1633527000615',
          header: 'CF_A_Text (BMEcat 2005)',
          singleData: true,
          category: {
            customFieldType: 'CF_A_Text',
            label: 'CF_A_Text (BMEcat 2005)'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'Uq5Ydh0bt',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1633526686423: 'jtV0XFmf4g',
      tableData_1633526700117: 'qdRcwsKJZ',
      tableData_1633526701993: 'WT3qLPkgp',
      tableData_1633526797295: 'RLXcN8_2l',
      tableData_1633526798225: '4nbfvekh1',
      tableData_1633526799147: 'uQP1kXEtr',
      tableData_1633527000615: 'c1HC1ya2-'
    }
  },
  jtV0XFmf4g: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633526686423'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['JFGIVh1x8'],
    linkedNodes: {}
  },
  JFGIVh1x8: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Select field type...',
      bulkDataType: 'general_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'jtV0XFmf4g',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  qdRcwsKJZ: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633526700117'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['CMHWyA0YE'],
    linkedNodes: {}
  },
  WT3qLPkgp: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633526701993'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['CWgrbN8cC'],
    linkedNodes: {}
  },
  CMHWyA0YE: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Select field type...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'qdRcwsKJZ',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  CWgrbN8cC: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Select field type...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'WT3qLPkgp',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  RLXcN8_2l: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633526797295'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['dvehcBzik'],
    linkedNodes: {}
  },
  '4nbfvekh1': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633526798225'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['pE_o8Gypb'],
    linkedNodes: {}
  },
  uQP1kXEtr: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633526799147'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['bgmpG4j_s'],
    linkedNodes: {}
  },
  dvehcBzik: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Select field type...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'RLXcN8_2l',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  pE_o8Gypb: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'ean',
      label: 'Select field type...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '4nbfvekh1',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bgmpG4j_s: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'originCountry',
      label: 'Select field type...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'uQP1kXEtr',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'c1HC1ya2-': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1633527000615'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'LvOE1TIWx',
    hidden: false,
    nodes: ['XcwIlermx'],
    linkedNodes: {}
  },
  XcwIlermx: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CF_A_Text',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'c1HC1ya2-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '3sTMPa1Jq': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1634021645583',
          header: 'ORDER_UNIT',
          singleData: true,
          category: {
            customFieldType: 'ORDER_UNIT',
            label: 'ORDER_UNIT'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'Uq5Ydh0bt',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1634021645583: 'ILY3FB_7ZA'
    }
  },
  ILY3FB_7ZA: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1634021645583'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '3sTMPa1Jq',
    hidden: false,
    nodes: ['bFZ3ldJxj'],
    linkedNodes: {}
  },
  bFZ3ldJxj: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ILY3FB_7ZA',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  YOQG1AMLC: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1634021659699',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'Uq5Ydh0bt',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1634021659699': 'w2OARtNrka'
    }
  },
  w2OARtNrka: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'YOQG1AMLC',
    hidden: false,
    nodes: ['Wu4ut62Yq'],
    linkedNodes: {}
  },
  Wu4ut62Yq: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'w2OARtNrka',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  biYiuJC3z: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['5sr7cSklb'],
    linkedNodes: {}
  },
  '5sr7cSklb': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1634028807640',
          header: 'alle allgemeinen Felder',
          singleData: true,
          category: {
            bulkDataType: 'general_fields',
            label: 'alle allgemeinen Felder'
          }
        },
        {
          dataId: 'tableData_1634028816925',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        },
        {
          dataId: 'tableData_1634028833208',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        },
        {
          dataId: 'tableData_1634028817503',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'biYiuJC3z',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1634028807640: '4QgKC-2Wvl',
      tableData_1634028816925: 'lHivxTW6s',
      tableData_1634028817503: 'AGzfkwAJB',
      tableData_1634028833208: '4-V6KNQfL',
      collapseTableRow: 'turRwguyA'
    }
  },
  '4QgKC-2Wvl': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1634028807640'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '5sr7cSklb',
    hidden: false,
    nodes: ['Ga1yU9M-F'],
    linkedNodes: {}
  },
  'Ga1yU9M-F': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'general_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '4QgKC-2Wvl',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  lHivxTW6s: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1634028816925'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '5sr7cSklb',
    hidden: false,
    nodes: ['kZ_uvvAId'],
    linkedNodes: {}
  },
  AGzfkwAJB: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1634028817503'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '5sr7cSklb',
    hidden: false,
    nodes: ['S8dTWBopY'],
    linkedNodes: {}
  },
  kZ_uvvAId: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'lHivxTW6s',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  S8dTWBopY: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'AGzfkwAJB',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '4-V6KNQfL': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1634028833208'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '5sr7cSklb',
    hidden: false,
    nodes: ['CVS99bZ7L'],
    linkedNodes: {}
  },
  CVS99bZ7L: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '4-V6KNQfL',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  turRwguyA: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: '5sr7cSklb',
    hidden: false,
    nodes: ['gsN3vhjvu', 'FNhBIFAAP'],
    linkedNodes: {}
  },
  gsN3vhjvu: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'turRwguyA',
    hidden: false,
    nodes: ['sAJCIJfra'],
    linkedNodes: {}
  },
  sAJCIJfra: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'gsN3vhjvu',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  rEJ2AXUnH: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'FNhBIFAAP',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  FNhBIFAAP: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'turRwguyA',
    hidden: false,
    nodes: ['rEJ2AXUnH'],
    linkedNodes: {}
  },
  alJHRjp17: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['vAJyVOSvm', 'F6yY_0WoM', 'UGocH9_Fg'],
    linkedNodes: {}
  },
  vAJyVOSvm: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Product',
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'alJHRjp17',
    hidden: false,
    nodes: ['PxpN51pXt'],
    linkedNodes: {}
  },
  PxpN51pXt: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'vAJyVOSvm',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  F6yY_0WoM: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'alJHRjp17',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  UGocH9_Fg: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Product',
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'alJHRjp17',
    hidden: false,
    nodes: ['VKmeUHDtT'],
    linkedNodes: {}
  },
  VKmeUHDtT: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'UGocH9_Fg',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  uD0TyyJ3o: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['voiOI21lg'],
    linkedNodes: {}
  },
  voiOI21lg: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'uD0TyyJ3o',
    hidden: false,
    nodes: ['uVopeWMLz'],
    linkedNodes: {}
  },
  uVopeWMLz: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1635497268345',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            Article: {
              Text: {
                bulkDataType_texts: true
              },
              Medium: {
                bulkDataType_media: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'voiOI21lg',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1635497268345': 'e47uwB52Y4'
    }
  },
  e47uwB52Y4: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'uVopeWMLz',
    hidden: false,
    nodes: ['QaXmZ9_4h', 'qLQGeb8Dt'],
    linkedNodes: {}
  },
  QaXmZ9_4h: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'e47uwB52Y4',
    hidden: false,
    nodes: ['2u92QbPUd'],
    linkedNodes: {}
  },
  '2u92QbPUd': {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'QaXmZ9_4h',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  qLQGeb8Dt: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'e47uwB52Y4',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  jml9n7pHm: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['W0yW1KH-N'],
    linkedNodes: {}
  },
  'W0yW1KH-N': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'jml9n7pHm',
    hidden: false,
    nodes: ['6Ncq0XJ5N'],
    linkedNodes: {}
  },
  '6Ncq0XJ5N': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635497390613',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635497396380',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'W0yW1KH-N',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635497390613: '61Oxm5QT10',
      tableData_1635497396380: '3jJtpv2eO'
    }
  },
  '61Oxm5QT10': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497390613'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '6Ncq0XJ5N',
    hidden: false,
    nodes: ['IN9fy83uH'],
    linkedNodes: {}
  },
  IN9fy83uH: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '61Oxm5QT10',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '3jJtpv2eO': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497396380'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '6Ncq0XJ5N',
    hidden: false,
    nodes: ['yzVq4IOPC'],
    linkedNodes: {}
  },
  yzVq4IOPC: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '3jJtpv2eO',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'kzqI-y6_E': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['d-hvus74A'],
    linkedNodes: {}
  },
  'd-hvus74A': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'kzqI-y6_E',
    hidden: false,
    nodes: ['UaPZa6t2V'],
    linkedNodes: {}
  },
  UaPZa6t2V: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635497491902',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635497500785',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        },
        {
          dataId: 'tableData_1635497501497',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        },
        {
          dataId: 'tableData_1635497502480',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'd-hvus74A',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635497491902: 'IRot30XQ1J',
      tableData_1635497500785: '5QDZgKfqJ',
      tableData_1635497501497: 'WZLj8Zu4B',
      tableData_1635497502480: 'sufL9OQ2a'
    }
  },
  IRot30XQ1J: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497491902'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'UaPZa6t2V',
    hidden: false,
    nodes: ['02KR6rJGU'],
    linkedNodes: {}
  },
  '02KR6rJGU': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'IRot30XQ1J',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '5QDZgKfqJ': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497500785'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'UaPZa6t2V',
    hidden: false,
    nodes: ['2EE7dieo0'],
    linkedNodes: {}
  },
  WZLj8Zu4B: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497501497'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'UaPZa6t2V',
    hidden: false,
    nodes: ['XNSBgETsj'],
    linkedNodes: {}
  },
  sufL9OQ2a: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497502480'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'UaPZa6t2V',
    hidden: false,
    nodes: ['dhDlUFdIn'],
    linkedNodes: {}
  },
  '2EE7dieo0': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '5QDZgKfqJ',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  XNSBgETsj: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'WZLj8Zu4B',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  dhDlUFdIn: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'sufL9OQ2a',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  T8ax5Ihkp: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['Ac5gdPuf7'],
    linkedNodes: {}
  },
  Ac5gdPuf7: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'T8ax5Ihkp',
    hidden: false,
    nodes: ['cUOowXlAh'],
    linkedNodes: {}
  },
  cUOowXlAh: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635497637574',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635497647557',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'Ac5gdPuf7',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635497637574: 'LCTJWdTbzO',
      tableData_1635497647557: 'uaQsAp6Lj'
    }
  },
  LCTJWdTbzO: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497637574'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'cUOowXlAh',
    hidden: false,
    nodes: ['Ic1XRUkgU'],
    linkedNodes: {}
  },
  Ic1XRUkgU: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'LCTJWdTbzO',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  uaQsAp6Lj: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497647557'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'cUOowXlAh',
    hidden: false,
    nodes: ['hGkFM36lD'],
    linkedNodes: {}
  },
  hGkFM36lD: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'uaQsAp6Lj',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  hFHQkYhFw: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['wETvU87zD'],
    linkedNodes: {}
  },
  wETvU87zD: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'hFHQkYhFw',
    hidden: false,
    nodes: ['Jf1dDerej'],
    linkedNodes: {}
  },
  Jf1dDerej: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635497720646',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635497727063',
          header: 'ORDER_UNIT',
          singleData: true,
          category: {
            customFieldType: 'ORDER_UNIT',
            label: 'ORDER_UNIT'
          }
        },
        {
          dataId: 'tableData_1635497727207',
          header: 'NO_CU_PER_OU',
          singleData: true,
          category: {
            customFieldType: 'NO_CU_PER_OU',
            label: 'NO_CU_PER_OU'
          }
        },
        {
          dataId: 'tableData_1635497727356',
          header: 'CONTENT_UNIT',
          singleData: true,
          category: {
            customFieldType: 'CONTENT_UNIT',
            label: 'CONTENT_UNIT'
          }
        },
        {
          dataId: 'tableData_1635497727493',
          header: 'QUANTITY_MIN',
          singleData: true,
          category: {
            customFieldType: 'QUANTITY_MIN',
            label: 'QUANTITY_MIN'
          }
        },
        {
          dataId: 'tableData_1635497727632',
          header: 'QUANTITY_INTERVAL',
          singleData: true,
          category: {
            customFieldType: 'QUANTITY_INTERVAL',
            label: 'QUANTITY_INTERVAL'
          }
        },
        {
          dataId: 'tableData_1635497758001',
          header: 'PRICE_QUANTITY',
          singleData: true,
          category: {
            customFieldType: 'PRICE_QUANTITY',
            label: 'PRICE_QUANTITY'
          }
        },
        {
          dataId: 'tableData_1635497758208',
          header: 'Steuersatz',
          singleData: true,
          category: {
            customFieldType: 'TAX',
            label: 'Steuersatz'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'wETvU87zD',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635497720646: 'GwdKFMB8M5',
      tableData_1635497727063: 'LBbDW4WNL',
      tableData_1635497727207: 'EeEhUYSEJR',
      tableData_1635497727356: 'olNCvWtmPQ',
      tableData_1635497727493: 'K76KWrtqZy',
      tableData_1635497727632: 'lvi_QqshHG',
      tableData_1635497758001: 'ypo-szev7',
      tableData_1635497758208: 'stM4UiFE4w'
    }
  },
  GwdKFMB8M5: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497720646'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['SMUyjdC_T'],
    linkedNodes: {}
  },
  SMUyjdC_T: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'GwdKFMB8M5',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  LBbDW4WNL: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497727063'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['6asacXQDf'],
    linkedNodes: {}
  },
  EeEhUYSEJR: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497727207'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['iIVX4JHOS'],
    linkedNodes: {}
  },
  olNCvWtmPQ: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497727356'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['HnReUUvlD'],
    linkedNodes: {}
  },
  K76KWrtqZy: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497727493'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['BFXZMYs3m'],
    linkedNodes: {}
  },
  lvi_QqshHG: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497727632'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['3Rsqc-T33'],
    linkedNodes: {}
  },
  '6asacXQDf': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'LBbDW4WNL',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  iIVX4JHOS: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'NO_CU_PER_OU',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'EeEhUYSEJR',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  HnReUUvlD: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CONTENT_UNIT',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'olNCvWtmPQ',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  BFXZMYs3m: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MIN',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'K76KWrtqZy',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '3Rsqc-T33': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_INTERVAL',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'lvi_QqshHG',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'ypo-szev7': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497758001'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['yASYK0zd7'],
    linkedNodes: {}
  },
  stM4UiFE4w: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497758208'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Jf1dDerej',
    hidden: false,
    nodes: ['f36QnSE6w'],
    linkedNodes: {}
  },
  yASYK0zd7: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRICE_QUANTITY',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ypo-szev7',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  f36QnSE6w: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'TAX',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'stM4UiFE4w',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  JXdDehr2r: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['YReH5o23z'],
    linkedNodes: {}
  },
  YReH5o23z: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'JXdDehr2r',
    hidden: false,
    nodes: ['WqwoFka2x'],
    linkedNodes: {}
  },
  WqwoFka2x: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1635497805866',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            BMEcat_PRODUCT_PRICE_DETAILS: {
              CustomField: {
                bulkDataType_custom_fields: true
              }
            },
            BMEcat_PRODUCT_PRICE: {
              CustomField: {
                bulkDataType_custom_fields: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'YReH5o23z',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1635497805866': '_dzUhG0KgK'
    }
  },
  _dzUhG0KgK: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'BMEcat_PRODUCT_PRICE_DETAILS',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'WqwoFka2x',
    hidden: false,
    nodes: ['u4Cy3sOnN'],
    linkedNodes: {}
  },
  u4Cy3sOnN: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635497815091',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        }
      ],
      dataSource: {
        source: 'BMEcat_PRODUCT_PRICE_DETAILS',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: '_dzUhG0KgK',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635497815091: 'nGGWTmXQwA',
      collapseTableRow: 'PD-SSkSZ5'
    }
  },
  nGGWTmXQwA: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'BMEcat_PRODUCT_PRICE_DETAILS',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497815091'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'u4Cy3sOnN',
    hidden: false,
    nodes: ['6mSlw5ml_'],
    linkedNodes: {}
  },
  '6mSlw5ml_': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'nGGWTmXQwA',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'PD-SSkSZ5': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'BMEcat_PRODUCT_PRICE',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'u4Cy3sOnN',
    hidden: false,
    nodes: ['-PIpo5ZTR'],
    linkedNodes: {}
  },
  '-PIpo5ZTR': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635497830001',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        }
      ],
      dataSource: {
        source: 'BMEcat_PRODUCT_PRICE',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'PD-SSkSZ5',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635497830001: 'jBcMIuZVmG'
    }
  },
  jBcMIuZVmG: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'BMEcat_PRODUCT_PRICE',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635497830001'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '-PIpo5ZTR',
    hidden: false,
    nodes: ['wodpYxQ39'],
    linkedNodes: {}
  },
  wodpYxQ39: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'jBcMIuZVmG',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  EVvoxBtkM: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '6SwD9MNXO': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'ks4-CMz9v': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['c_Knn__Bx', 's9aqXZrdb', 'Rqws1XG6s'],
    linkedNodes: {}
  },
  c_Knn__Bx: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Product',
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'ks4-CMz9v',
    hidden: false,
    nodes: ['uQLRWm2ke'],
    linkedNodes: {}
  },
  uQLRWm2ke: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'c_Knn__Bx',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  s9aqXZrdb: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'ks4-CMz9v',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '-S8d5VxV_': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['a0Toy8v5q'],
    linkedNodes: {}
  },
  a0Toy8v5q: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: '-S8d5VxV_',
    hidden: false,
    nodes: ['uxBbi1amN'],
    linkedNodes: {}
  },
  uxBbi1amN: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635839633985',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635839640941UPC__BMEcat1',
          header: {
            text: 'UPC (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'UPC__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941Buyer_XID_buyer_specific__BMEcat1',
          header: {
            text: 'BUYER_XID (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'Buyer_XID_buyer_specific__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941MANUFACTURER_XID__BMEcat1',
          header: {
            text: 'MANUFACTURER_XID (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'MANUFACTURER_XID__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941MANUFACTURER_NAME__BMEcat1',
          header: {
            text: 'MANUFACTURER_NAME (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'MANUFACTURER_NAME__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941MANUFACTURER_TYPE_DESCR__BMEcat1',
          header: {
            text: 'MANUFACTURER_TYPE_DESCR (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'MANUFACTURER_TYPE_DESCR__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941DELIVERY_TIME__BMEcat1',
          header: {
            text: 'DELIVERY_TIME (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'DELIVERY_TIME__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941SEGMENT__BMEcat1',
          header: {
            text: 'SEGMENT (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'SEGMENT__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941PRODUCT_TYPE__BMEcat1',
          header: {
            text: 'PRODUCT_TYPE (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'PRODUCT_TYPE__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941CONTENT_UNIT__BMEcat4',
          header: {
            text: 'CONTENT_UNIT (ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'CONTENT_UNIT__BMEcat4'
          }
        },
        {
          dataId: 'tableData_1635839640941ORDER_UNIT__BMEcat4',
          header: {
            text: 'ORDER_UNIT (ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'ORDER_UNIT__BMEcat4'
          }
        },
        {
          dataId: 'tableData_1635839640941NO_CU_PER_OU__BMEcat2',
          header: {
            text: 'NO_CU_PER_OU (ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'NO_CU_PER_OU__BMEcat2'
          }
        },
        {
          dataId: 'tableData_1635839640941PRICE_QUANTITY__BMEcat2',
          header: {
            text: 'PRICE_QUANTITY (ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'PRICE_QUANTITY__BMEcat2'
          }
        },
        {
          dataId: 'tableData_1635839640941QUANTITY_MIN__BMEcat3',
          header: {
            text: 'QUANTITY_MIN (ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'QUANTITY_MIN__BMEcat3'
          }
        },
        {
          dataId: 'tableData_1635839640941QUANTITY_INTERVAL__BMEcat3',
          header: {
            text: 'QUANTITY_INTERVAL (ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'QUANTITY_INTERVAL__BMEcat3'
          }
        },
        {
          dataId: 'tableData_1635839640941CONTENT_UNIT__BMEcat2',
          header: {
            text: 'CONTENT_UNIT (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'CONTENT_UNIT__BMEcat2'
          }
        },
        {
          dataId: 'tableData_1635839640941ORDER_UNIT__BMEcat2',
          header: {
            text: 'ORDER_UNIT (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'ORDER_UNIT__BMEcat2'
          }
        },
        {
          dataId: 'tableData_1635839640941NO_CU_PER_OU__BMEcat1',
          header: {
            text: 'NO_CU_PER_OU (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'NO_CU_PER_OU__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941PRICE_QUANTITY__BMEcat1',
          header: {
            text: 'PRICE_QUANTITY (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'PRICE_QUANTITY__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941QUANTITY_MIN__BMEcat2',
          header: {
            text: 'QUANTITY_MIN (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'QUANTITY_MIN__BMEcat2'
          }
        },
        {
          dataId: 'tableData_1635839640941QUANTITY_INTERVAL__BMEcat2',
          header: {
            text: 'QUANTITY_INTERVAL (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'QUANTITY_INTERVAL__BMEcat2'
          }
        },
        {
          dataId: 'tableData_1635839640941QUANTITY_MAX__BMEcat1',
          header: {
            text: 'QUANTITY_MAX (2005)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'QUANTITY_MAX__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941EAN__BMEcat1',
          header: {
            text: 'EAN (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'EAN__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941GTIN__BMEcat1',
          header: {
            text: 'GTIN (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'GTIN__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941MANUFACTURER_PID__BMEcat1',
          header: {
            text: 'MANUFACTURER_PID (2005, ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'MANUFACTURER_PID__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941BRAND_NAME__BMEcat1',
          header: {
            text: 'BRAND_NAME ( ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'BRAND_NAME__BMEcat1'
          }
        },
        {
          dataId: 'tableData_1635839640941TENDER_TEXT__BMEcat1',
          header: {
            text: 'TENDER_TEXT ( ETIM 5)',
            textAddon: null,
            missingContentLang: null,
            typeName: null,
            deactivated: false,
            editorTextContent: false
          },
          bulkData: {
            type: 'CustomField',
            contentIdentifier: 'TENDER_TEXT__BMEcat1'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'a0Toy8v5q',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635839633985: 'TOBPaxPKQH',
      tableData_1635839640941: 'ymjo0r50j',
      collapseTableRow: 'BFgKx4w2P',
      tableData_1635839640941UPC__BMEcat1: 'LL9olP-kQn',
      tableData_1635839640941Buyer_XID_buyer_specific__BMEcat1: 'k7ygsW_tfv',
      tableData_1635839640941MANUFACTURER_XID__BMEcat1: 'GpJqr2N8jk',
      tableData_1635839640941MANUFACTURER_NAME__BMEcat1: 'JLejf6vzwY',
      tableData_1635839640941MANUFACTURER_TYPE_DESCR__BMEcat1: 'HIXW2zG-Qd',
      tableData_1635839640941DELIVERY_TIME__BMEcat1: 'pC7hpdR3qX',
      tableData_1635839640941SEGMENT__BMEcat1: 'Mo5PvgXfsb',
      tableData_1635839640941PRODUCT_TYPE__BMEcat1: 'BIzPbXWL8i',
      tableData_1635839640941CONTENT_UNIT__BMEcat4: 'hmOkQoJyZ_',
      tableData_1635839640941ORDER_UNIT__BMEcat4: 'bR46E-OIa3',
      tableData_1635839640941NO_CU_PER_OU__BMEcat2: '8gdk_P04kp',
      tableData_1635839640941PRICE_QUANTITY__BMEcat2: 'Qh3tLlv7tY',
      tableData_1635839640941QUANTITY_MIN__BMEcat3: 'QTGuPtMuhx',
      tableData_1635839640941QUANTITY_INTERVAL__BMEcat3: 'QkE9iHjTzuO',
      tableData_1635839640941CONTENT_UNIT__BMEcat2: 'd7ycxl7fXFP',
      tableData_1635839640941ORDER_UNIT__BMEcat2: 'gNqJBJkuuzw',
      tableData_1635839640941NO_CU_PER_OU__BMEcat1: '-V0MHQ6doqh',
      tableData_1635839640941PRICE_QUANTITY__BMEcat1: 'Cd90sgsq48F',
      tableData_1635839640941QUANTITY_MIN__BMEcat2: 'dc-WQHPJK_x',
      tableData_1635839640941QUANTITY_INTERVAL__BMEcat2: 'TtlqrBfJFr2',
      tableData_1635839640941QUANTITY_MAX__BMEcat1: 'QQwn6hhAv5I',
      tableData_1635839640941EAN__BMEcat1: 'SxNsQoB8hTx',
      tableData_1635839640941GTIN__BMEcat1: 'EXN4JVEKrcU',
      tableData_1635839640941MANUFACTURER_PID__BMEcat1: 'VowTjMadAw7',
      tableData_1635839640941BRAND_NAME__BMEcat1: 'wC-w5OsiCYJ',
      tableData_1635839640941TENDER_TEXT__BMEcat1: 'xQc695m9vTR'
    }
  },
  TOBPaxPKQH: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839633985'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: ['SWuykLzfc'],
    linkedNodes: {}
  },
  SWuykLzfc: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'TOBPaxPKQH',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  ymjo0r50j: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: ['o2hSgnj-4'],
    linkedNodes: {}
  },
  'o2hSgnj-4': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ymjo0r50j',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  TGOQ83b15: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['U9JvNg_4E'],
    linkedNodes: {}
  },
  U9JvNg_4E: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'TGOQ83b15',
    hidden: false,
    nodes: ['04qUpvVcX'],
    linkedNodes: {}
  },
  '04qUpvVcX': {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1635839691926',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            Article: {
              Text: {
                bulkDataType_texts: true
              },
              Medium: {
                bulkDataType_media: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'U9JvNg_4E',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1635839691926': 'L9kPCxj2a8'
    }
  },
  L9kPCxj2a8: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: '04qUpvVcX',
    hidden: false,
    nodes: ['uVvvDiz-9', '944ZiQa-a'],
    linkedNodes: {}
  },
  'uVvvDiz-9': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'L9kPCxj2a8',
    hidden: false,
    nodes: ['C6JmxPLVj'],
    linkedNodes: {}
  },
  C6JmxPLVj: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'uVvvDiz-9',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '944ZiQa-a': {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'L9kPCxj2a8',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  SvCsHRgQn: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['pbcBp-ByC'],
    linkedNodes: {}
  },
  'pbcBp-ByC': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'SvCsHRgQn',
    hidden: false,
    nodes: ['Urw1srbva'],
    linkedNodes: {}
  },
  Urw1srbva: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635839747638',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635839753138',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'pbcBp-ByC',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635839747638: 'C4_bNtjAkk',
      tableData_1635839753138: 'Gt0B99WhD'
    }
  },
  C4_bNtjAkk: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839747638'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Urw1srbva',
    hidden: false,
    nodes: ['cwMtS-70B'],
    linkedNodes: {}
  },
  'cwMtS-70B': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'C4_bNtjAkk',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Gt0B99WhD: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839753138'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Urw1srbva',
    hidden: false,
    nodes: ['2XcRfyeAa'],
    linkedNodes: {}
  },
  '2XcRfyeAa': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'Gt0B99WhD',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  fgq883r_B: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['UitkoMD8L', '55gw5ZLyS', 'pUc37bMm-'],
    linkedNodes: {}
  },
  '55gw5ZLyS': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'fgq883r_B',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  UitkoMD8L: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: {
        de: 'Produkt',
        en: 'Headline',
        it: 'Titolo',
        fr: 'Titre'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'fgq883r_B',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'pUc37bMm-': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'fgq883r_B',
    hidden: false,
    nodes: ['KMtOu32IZ', 'CuWuz8S8R'],
    linkedNodes: {}
  },
  KMtOu32IZ: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: {
        de: 'Artikel',
        en: 'Headline',
        it: 'Titolo',
        fr: 'Titre'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'pUc37bMm-',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  CuWuz8S8R: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635839987692',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635839995188',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'pUc37bMm-',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635839987692: 'I3ho14CS3S',
      tableData_1635839995188: 'DZdDnHhvu'
    }
  },
  I3ho14CS3S: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839987692'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'CuWuz8S8R',
    hidden: false,
    nodes: ['jUwv7rUdN'],
    linkedNodes: {}
  },
  jUwv7rUdN: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'I3ho14CS3S',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  DZdDnHhvu: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839995188'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'CuWuz8S8R',
    hidden: false,
    nodes: ['ZfW6XKJi6'],
    linkedNodes: {}
  },
  ZfW6XKJi6: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'DZdDnHhvu',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'rKXBP-AGU': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['tDdDaHAR_'],
    linkedNodes: {}
  },
  tDdDaHAR_: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'rKXBP-AGU',
    hidden: false,
    nodes: ['1yPYqL4Qz'],
    linkedNodes: {}
  },
  '1yPYqL4Qz': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635840039296',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1635840045758',
          header: 'Keywords (Artikel)',
          singleData: true,
          category: {
            generalFieldType: 'keyword',
            label: 'Keywords (Artikel)'
          }
        },
        {
          dataId: 'tableData_1635840046508',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        },
        {
          dataId: 'tableData_1635840048593',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'tDdDaHAR_',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635840039296: 'ci12pj9SJX',
      tableData_1635840045758: 'oeD96K-wD',
      tableData_1635840046508: '0KpRsSTPT',
      tableData_1635840048593: '3t4rBy-av'
    }
  },
  ci12pj9SJX: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635840039296'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '1yPYqL4Qz',
    hidden: false,
    nodes: ['EALJH3RkF'],
    linkedNodes: {}
  },
  EALJH3RkF: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ci12pj9SJX',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'oeD96K-wD': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635840045758'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '1yPYqL4Qz',
    hidden: false,
    nodes: ['FdBx1MLU3'],
    linkedNodes: {}
  },
  '0KpRsSTPT': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635840046508'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '1yPYqL4Qz',
    hidden: false,
    nodes: ['f-d7hxPQp'],
    linkedNodes: {}
  },
  '3t4rBy-av': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635840048593'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '1yPYqL4Qz',
    hidden: false,
    nodes: ['SRIJDgNq3'],
    linkedNodes: {}
  },
  FdBx1MLU3: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'keyword',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'oeD96K-wD',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'f-d7hxPQp': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '0KpRsSTPT',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  SRIJDgNq3: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '3t4rBy-av',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  z7ffBUt4u: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['B-VqtpBrN'],
    linkedNodes: {}
  },
  'B-VqtpBrN': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'z7ffBUt4u',
    hidden: false,
    nodes: ['8dlQz1GzJ'],
    linkedNodes: {}
  },
  tHUB8wggY: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['P-1IQwv5J'],
    linkedNodes: {}
  },
  'P-1IQwv5J': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'tHUB8wggY',
    hidden: false,
    nodes: ['2pwNyk8Jm'],
    linkedNodes: {}
  },
  '2pwNyk8Jm': {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1635840734323',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            PRODUCT_PRICE_DETAILS__BMEcat1: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_PRODUCT_PRICE_DETAILS: true
              }
            },
            PRODUCT_PRICE__BMEcat1: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_PRODUCT_PRICE: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'P-1IQwv5J',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1635840734323': 'LsgxGvwAAI'
    }
  },
  LsgxGvwAAI: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS__BMEcat1',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: '2pwNyk8Jm',
    hidden: false,
    nodes: ['1YCmE9MyV'],
    linkedNodes: {}
  },
  BFgKx4w2P: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: ['CI3PSMINb', 'Pt4H6Kpnq'],
    linkedNodes: {}
  },
  CI3PSMINb: {
    type: {
      resolvedName: 'TextEditorSelector'
    },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'BFgKx4w2P',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Pt4H6Kpnq: {
    type: {
      resolvedName: 'MediaEditorSelector'
    },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'BFgKx4w2P',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '8dlQz1GzJ': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1646812559229',
          header: 'alle Felder "BMEcat_ORDER_DETAILS"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat_ORDER_DETAILS',
            label: 'alle Felder "BMEcat_ORDER_DETAILS"'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'B-VqtpBrN',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1646812559229: '8OgS3xmwAw'
    }
  },
  '8OgS3xmwAw': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1646812559229'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '8dlQz1GzJ',
    hidden: false,
    nodes: ['svGNS0nRx'],
    linkedNodes: {}
  },
  svGNS0nRx: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat_ORDER_DETAILS'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '8OgS3xmwAw',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '1YCmE9MyV': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1646812587322',
          header: 'alle Felder "BMEcat_PRODUCT_PRICE_DETAILS"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat_PRODUCT_PRICE_DETAILS',
            label: 'alle Felder "BMEcat_PRODUCT_PRICE_DETAILS"'
          }
        }
      ],
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS__BMEcat1',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'LsgxGvwAAI',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1646812587322: 'odmOfeC5VA',
      collapseTableRow: 'lL07W1El9'
    }
  },
  odmOfeC5VA: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE_DETAILS__BMEcat1',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1646812587322'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '1YCmE9MyV',
    hidden: false,
    nodes: ['ZmbBVxvxA'],
    linkedNodes: {}
  },
  ZmbBVxvxA: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat_PRODUCT_PRICE_DETAILS'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'odmOfeC5VA',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  lL07W1El9: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE__BMEcat1',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: '1YCmE9MyV',
    hidden: false,
    nodes: ['-7UYN3TxU'],
    linkedNodes: {}
  },
  '-7UYN3TxU': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1646812627970',
          header: 'alle Felder "BMEcat_PRODUCT_PRICE"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat_PRODUCT_PRICE',
            label: 'alle Felder "BMEcat_PRODUCT_PRICE"'
          }
        }
      ],
      dataSource: {
        source: 'PRODUCT_PRICE__BMEcat1',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'lL07W1El9',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1646812627970: 'LOTNXTNzMK'
    }
  },
  LOTNXTNzMK: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PRODUCT_PRICE__BMEcat1',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1646812627970'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: '-7UYN3TxU',
    hidden: false,
    nodes: ['wGa7Pz1dI'],
    linkedNodes: {}
  },
  wGa7Pz1dI: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat_PRODUCT_PRICE'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'LOTNXTNzMK',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  c80qm7ek4: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['kdFJ0yUUs'],
    linkedNodes: {}
  },
  kdFJ0yUUs: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1646812984946',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            PACKING_UNIT__BMEcat1: {
              CustomField: {
                bulkDataType_custom_fields_group_BMEcat_PACKING_UNIT: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: 'c80qm7ek4',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1646812984946': 'CfIxEiGtll'
    }
  },
  CfIxEiGtll: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PACKING_UNIT__BMEcat1',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'kdFJ0yUUs',
    hidden: false,
    nodes: ['kO0IpquFb'],
    linkedNodes: {}
  },
  kO0IpquFb: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1646813080060',
          header: 'alle Felder "BMEcat_PACKING_UNIT"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat_PACKING_UNIT',
            label: 'alle Felder "BMEcat_PACKING_UNIT"'
          }
        }
      ],
      dataSource: {
        source: 'PACKING_UNIT__BMEcat1',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'CfIxEiGtll',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1646813080060: 'SYaf8mxhRH'
    }
  },
  SYaf8mxhRH: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'PACKING_UNIT__BMEcat1',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1646813080060'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'kO0IpquFb',
    hidden: false,
    nodes: ['iBw9_UJmh'],
    linkedNodes: {}
  },
  iBw9_UJmh: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat_PACKING_UNIT'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'SYaf8mxhRH',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Rqws1XG6s: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'Product',
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'ks4-CMz9v',
    hidden: false,
    nodes: ['9Z64b_pjE'],
    linkedNodes: {}
  },
  '9Z64b_pjE': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'Rqws1XG6s',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941UPC__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UPC__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941Buyer_XID_buyer_specific__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'Buyer_XID_buyer_specific__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941MANUFACTURER_XID__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'MANUFACTURER_XID__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941MANUFACTURER_NAME__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'MANUFACTURER_NAME__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941MANUFACTURER_TYPE_DESCR__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'MANUFACTURER_TYPE_DESCR__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941DELIVERY_TIME__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'DELIVERY_TIME__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941SEGMENT__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'SEGMENT__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941PRODUCT_TYPE__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRODUCT_TYPE__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941CONTENT_UNIT__BMEcat4: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CONTENT_UNIT__BMEcat4',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941ORDER_UNIT__BMEcat4: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT__BMEcat4',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941NO_CU_PER_OU__BMEcat2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'NO_CU_PER_OU__BMEcat2',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941PRICE_QUANTITY__BMEcat2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRICE_QUANTITY__BMEcat2',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941QUANTITY_MIN__BMEcat3: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MIN__BMEcat3',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941QUANTITY_INTERVAL__BMEcat3: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_INTERVAL__BMEcat3',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941CONTENT_UNIT__BMEcat2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'CONTENT_UNIT__BMEcat2',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941ORDER_UNIT__BMEcat2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'ORDER_UNIT__BMEcat2',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941NO_CU_PER_OU__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'NO_CU_PER_OU__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941PRICE_QUANTITY__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'PRICE_QUANTITY__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941QUANTITY_MIN__BMEcat2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MIN__BMEcat2',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941QUANTITY_INTERVAL__BMEcat2: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_INTERVAL__BMEcat2',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941QUANTITY_MAX__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'QUANTITY_MAX__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941EAN__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'EAN__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941GTIN__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'GTIN__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941MANUFACTURER_PID__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'MANUFACTURER_PID__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941BRAND_NAME__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'BRAND_NAME__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  bulkData_tableData_1635839640941TENDER_TEXT__BMEcat1: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'TENDER_TEXT__BMEcat1',
      generalFieldType: ''
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  zTbQ9gQlQ: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['EMw9Yktzf'],
    linkedNodes: {}
  },
  LyjaXHryN: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['yXsLRd6Oq'],
    linkedNodes: {}
  },
  yXsLRd6Oq: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1649683900717',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1649837442102',
          header: 'alle Felder "ARTICLE_DETAILS"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_ARTICLE_DETAILS',
            label: 'alle Felder "ARTICLE_DETAILS"'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'LyjaXHryN',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1649683900717: 'eLx75AxORG',
      tableData_1649837442102: 'AVhZcI_WA'
    }
  },
  eLx75AxORG: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649683900717'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'yXsLRd6Oq',
    hidden: false,
    nodes: ['bRgh8RKyo'],
    linkedNodes: {}
  },
  bRgh8RKyo: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'eLx75AxORG',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  HrcohlL3r: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['TWiheV-eL'],
    linkedNodes: {}
  },
  sfIQruFZx: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['zbkrzi3Bw'],
    linkedNodes: {}
  },
  zbkrzi3Bw: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'sfIQruFZx',
    hidden: false,
    nodes: ['A_DzMo8C0'],
    linkedNodes: {}
  },
  A_DzMo8C0: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1649776428290',
          header: 'Erste Spalte',
          singleData: true
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'zbkrzi3Bw',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1649776428290: 'coCTNAX6tO',
      collapseTableRow: 'y8HKqjKcu'
    }
  },
  coCTNAX6tO: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649776428290'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'A_DzMo8C0',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  y8HKqjKcu: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'A_DzMo8C0',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '4EKDFmSFP': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'icY-Zo9pP': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['LwmuZdAn5'],
    linkedNodes: {}
  },
  LwmuZdAn5: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'icY-Zo9pP',
    hidden: false,
    nodes: ['Q7eJhMv8-'],
    linkedNodes: {}
  },
  'Q7eJhMv8-': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1649776604459',
          header: 'alle Felder "BMEcat_DISCOUNT_GROUP"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat_DISCOUNT_GROUP',
            label: 'alle Felder "BMEcat_DISCOUNT_GROUP"'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'LwmuZdAn5',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1649776604459: 'QV_80DVZrq'
    }
  },
  QV_80DVZrq: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649776604459'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'Q7eJhMv8-',
    hidden: false,
    nodes: ['31l61Nj8O'],
    linkedNodes: {}
  },
  '31l61Nj8O': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat_DISCOUNT_GROUP'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'QV_80DVZrq',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  EMw9Yktzf: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1649776947153',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1649776952129',
          header: 'alle Felder "BMEcat_DISCOUNT_GROUP"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat_DISCOUNT_GROUP',
            label: 'alle Felder "BMEcat_DISCOUNT_GROUP"'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'zTbQ9gQlQ',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1649776947153: '3xXmZDOBzE',
      tableData_1649776952129: '9_lKygukE'
    }
  },
  '3xXmZDOBzE': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649776947153'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'EMw9Yktzf',
    hidden: false,
    nodes: ['1VqMlakLa'],
    linkedNodes: {}
  },
  '1VqMlakLa': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '3xXmZDOBzE',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '9_lKygukE': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649776952129'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'EMw9Yktzf',
    hidden: false,
    nodes: ['1o6nQmvTu'],
    linkedNodes: {}
  },
  '1o6nQmvTu': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat_DISCOUNT_GROUP'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '9_lKygukE',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'TWiheV-eL': {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1649776988114',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1649776995568',
          header: 'alle Felder "BMEcat REACH"',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields_group_BMEcat REACH',
            label: 'alle Felder "BMEcat REACH"'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'HrcohlL3r',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1649776988114: 'ZMTRvgJG-5',
      tableData_1649776995568: 'NQTK3i1Zx'
    }
  },
  'ZMTRvgJG-5': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649776988114'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'TWiheV-eL',
    hidden: false,
    nodes: ['-XHjHxapn'],
    linkedNodes: {}
  },
  '-XHjHxapn': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'ZMTRvgJG-5',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  NQTK3i1Zx: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649776995568'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'TWiheV-eL',
    hidden: false,
    nodes: ['j2mBJvIr_'],
    linkedNodes: {}
  },
  j2mBJvIr_: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_BMEcat REACH'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'NQTK3i1Zx',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  AVhZcI_WA: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1649837442102'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'yXsLRd6Oq',
    hidden: false,
    nodes: ['nUfzZv32T'],
    linkedNodes: {}
  },
  nUfzZv32T: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields_group_ARTICLE_DETAILS'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'AVhZcI_WA',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  mp21cPTyg: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['fKTeOAT8E'],
    linkedNodes: {}
  },
  fKTeOAT8E: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1650453062976',
          header: 'Art. Nr.',
          singleData: true,
          category: {
            generalFieldType: 'articleNumber',
            label: 'Art. Nr.'
          }
        },
        {
          dataId: 'tableData_1650453069599',
          header: 'EAN (2005, ETIM 5)',
          singleData: true,
          category: {
            customFieldType: 'EAN__BMEcat1',
            label: 'EAN (2005, ETIM 5)'
          }
        },
        {
          dataId: 'tableData_1650453081551',
          header: 'GTIN (2005, ETIM 5)',
          singleData: true,
          category: {
            customFieldType: 'GTIN__BMEcat1',
            label: 'GTIN (2005, ETIM 5)'
          }
        },
        {
          dataId: 'tableData_1650453082639',
          header: 'UPC (2005, ETIM 5)',
          singleData: true,
          category: {
            customFieldType: 'UPC__BMEcat1',
            label: 'UPC (2005, ETIM 5)'
          }
        },
        {
          dataId: 'tableData_1650453178678',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'mp21cPTyg',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1650453062976: 'jJr4aoRumB',
      tableData_1650453069599: 'CdMjawzgo',
      tableData_1650453081551: 'FmoQrqVdw',
      tableData_1650453082639: '6zQdx48E1',
      tableData_1650453178678: 'CNTF_gUEq'
    }
  },
  jJr4aoRumB: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1650453062976'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'fKTeOAT8E',
    hidden: false,
    nodes: ['KbQ_6FX6u'],
    linkedNodes: {}
  },
  KbQ_6FX6u: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'jJr4aoRumB',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  CdMjawzgo: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1650453069599'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'fKTeOAT8E',
    hidden: false,
    nodes: ['zq4Fby06e'],
    linkedNodes: {}
  },
  zq4Fby06e: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'EAN__BMEcat1',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'CdMjawzgo',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  FmoQrqVdw: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1650453081551'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'fKTeOAT8E',
    hidden: false,
    nodes: ['AT0FTf1Er'],
    linkedNodes: {}
  },
  '6zQdx48E1': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1650453082639'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'fKTeOAT8E',
    hidden: false,
    nodes: ['hId_MiBYL'],
    linkedNodes: {}
  },
  AT0FTf1Er: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'GTIN__BMEcat1',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'FmoQrqVdw',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  hId_MiBYL: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: 'UPC__BMEcat1',
      generalFieldType: '',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: '6zQdx48E1',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  CNTF_gUEq: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1650453178678'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'fKTeOAT8E',
    hidden: false,
    nodes: ['4BAaajW3j'],
    linkedNodes: {}
  },
  '4BAaajW3j': {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'CNTF_gUEq',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'LL9olP-kQn': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941UPC__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  k7ygsW_tfv: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941Buyer_XID_buyer_specific__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  GpJqr2N8jk: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941MANUFACTURER_XID__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  JLejf6vzwY: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941MANUFACTURER_NAME__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'HIXW2zG-Qd': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941MANUFACTURER_TYPE_DESCR__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  pC7hpdR3qX: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941DELIVERY_TIME__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Mo5PvgXfsb: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941SEGMENT__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  BIzPbXWL8i: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941PRODUCT_TYPE__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  hmOkQoJyZ_: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941CONTENT_UNIT__BMEcat4'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'bR46E-OIa3': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941ORDER_UNIT__BMEcat4'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '8gdk_P04kp': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941NO_CU_PER_OU__BMEcat2'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Qh3tLlv7tY: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941PRICE_QUANTITY__BMEcat2'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  QTGuPtMuhx: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941QUANTITY_MIN__BMEcat3'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  QkE9iHjTzuO: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941QUANTITY_INTERVAL__BMEcat3'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  d7ycxl7fXFP: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941CONTENT_UNIT__BMEcat2'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  gNqJBJkuuzw: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941ORDER_UNIT__BMEcat2'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '-V0MHQ6doqh': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941NO_CU_PER_OU__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  Cd90sgsq48F: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941PRICE_QUANTITY__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'dc-WQHPJK_x': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941QUANTITY_MIN__BMEcat2'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  TtlqrBfJFr2: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941QUANTITY_INTERVAL__BMEcat2'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  QQwn6hhAv5I: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941QUANTITY_MAX__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  SxNsQoB8hTx: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941EAN__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  EXN4JVEKrcU: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941GTIN__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  VowTjMadAw7: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941MANUFACTURER_PID__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'wC-w5OsiCYJ': {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941BRAND_NAME__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  xQc695m9vTR: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1635839640941TENDER_TEXT__BMEcat1'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'uxBbi1amN',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '2vD-G5jD7': {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'ArticleGoldenRecordItem',
        singleData: false,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  HfbzgHLEi: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['OhzucNR_d'],
    linkedNodes: {}
  },
  OhzucNR_d: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'ArticleGoldenRecordItem',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'HfbzgHLEi',
    hidden: false,
    nodes: ['YJC4uySE5'],
    linkedNodes: {}
  },
  YJC4uySE5: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1698317651672',
          header: 'Erste Spalte',
          singleData: true
        }
      ],
      dataSource: {
        source: 'ArticleGoldenRecordItem',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'OhzucNR_d',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1698317651672: 'vRdLErpiHF'
    }
  },
  vRdLErpiHF: {
    type: {
      resolvedName: 'TableCellContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'ArticleGoldenRecordItem',
        singleData: true,
        childOverride: true
      },
      dataId: 'tableData_1698317651672'
    },
    displayName: 'CellCanvas',
    custom: {},
    parent: 'YJC4uySE5',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  CDDGIEeyt: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['wxOcrmaJ9'],
    linkedNodes: {}
  },
  wxOcrmaJ9: {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'ArticleGoldenRecordItem',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'CDDGIEeyt',
    hidden: false,
    nodes: ['wxOcrmaJ91yPYqL4Qz'],
    linkedNodes: {}
  },
  wxOcrmaJ91yPYqL4Qz: {
    type: {
      resolvedName: 'Table'
    },
    isCanvas: false,
    props: {
      orientation: 'horizontal',
      isMultipleData: true,
      columns: [
        {
          dataId: 'tableData_1635840046508',
          header: 'alle eigenen Felder',
          singleData: true,
          category: {
            bulkDataType: 'custom_fields',
            label: 'alle eigenen Felder'
          }
        },
        {
          dataId: 'tableData_1635840048593',
          header: 'alle Merkmale',
          singleData: true,
          category: {
            bulkDataType: 'features',
            label: 'alle Merkmale'
          }
        }
      ],
      dataSource: {
        source: 'ArticleGoldenRecordItem',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Table',
    custom: {},
    parent: 'wxOcrmaJ9',
    hidden: false,
    nodes: [],
    linkedNodes: {
      tableData_1635840046508: '0KpRsSTPT',
      tableData_1635840048593: '3t4rBy-av'
    }
  },
  HXP7bIway: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Product',
        childOverride: false
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'Sg_yTodtF',
    hidden: false,
    nodes: ['emlhIRBg6', 'KzOxp4iZM', '6pS2sLPLU'],
    linkedNodes: {}
  },
  KzOxp4iZM: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      generalFieldType: 'articleDataZone',
      label: 'Datenbereich'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'HXP7bIway',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  '6pS2sLPLU': {
    type: {
      resolvedName: 'Container'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: true
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'HXP7bIway',
    hidden: false,
    nodes: ['rHg8jg2va', 'TfBeYZAWe'],
    linkedNodes: {}
  },
  TfBeYZAWe: {
    type: {
      resolvedName: 'Tabs'
    },
    isCanvas: false,
    props: {
      tabs: [
        {
          id: 'articleNumber-1708425268251',
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true,
          dataReq: {
            Article: {
              GeneralField: {
                keyword: true
              }
            }
          }
        }
      ],
      orientation: 'vertical',
      isMultipleData: true,
      selectedTabIndex: 0,
      dataSource: {
        source: 'Article',
        singleData: false,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Tabs',
    custom: {},
    parent: '6pS2sLPLU',
    hidden: false,
    nodes: [],
    linkedNodes: {
      'articleNumber-1708425268251': 'RWLd44lLWx'
    }
  },
  RWLd44lLWx: {
    type: {
      resolvedName: 'TabContent'
    },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      containerFlexColumns: '1',
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'TabCanvas',
    custom: {},
    parent: 'TfBeYZAWe',
    hidden: false,
    nodes: ['B5_TmKQXo'],
    linkedNodes: {}
  },
  B5_TmKQXo: {
    type: {
      resolvedName: 'FieldEditorSelector'
    },
    isCanvas: false,
    props: {
      generalFieldType: 'articleDataZone',
      label: 'Datenbereich'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'RWLd44lLWx',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  emlhIRBg6: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: {
        de: 'Produkt',
        en: 'Product',
        it: 'Prodotto',
        fr: 'Produit'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'HXP7bIway',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  rHg8jg2va: {
    type: {
      resolvedName: 'Title'
    },
    isCanvas: false,
    props: {
      text: {
        de: 'Artikel',
        en: 'Article',
        it: 'Articolo',
        fr: 'Article'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: '6pS2sLPLU',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  }
};
