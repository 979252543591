import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogTitle, Button, Icon } from '@material-ui/core';

import { createArticleView } from './createArticleView';
import { createCustomInstanceView } from './createCustomInstanceView';
import { useDataManagement } from '../DataManagementContext';
import { useCreateInstance } from './CreateInstanceContext';
import { InstanceTabEditorReader } from '../../tabeditor/reader/InstanceTabEditorReader';
import { getFeatureKey } from '../ArticleFeatureEditor';
import { usePimStyles } from 'src/jsx/PimTogoTheme';
import Tooltip from '@material-ui/core/Tooltip';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';

export const CREATE_INSTANCE_ID = 'CREATE_INSTANCE';

export const CreateInstance = ({
  isCreateInstanceOpen,
  parentInstanceEditor,
  instanceType,
  articleId,
  closeCreateInstance
}) => {
  const {
    getContentLang,
    getVariableContentDefs,
    getCreationFeatureDefs,
    setCreationFeatureDefs,
    getPtdId
  } = useDataManagement();
  const {
    getCreationParameters,
    getCreationData,
    resetCreation,
    getRegExErrorList,
    resetRegExErrorList
  } = useCreateInstance();
  const { t } = useTranslation();
  const classes = usePimStyles();

  const [createInstanceData, setCreateInstanceData] = useState(null);
  const ptdId = getPtdId();
  const contentLang = getContentLang();
  const variableContentDefs = getVariableContentDefs(instanceType);
  const instanceTitel = variableContentDefs?.instanceTitel;
  const regExErrorList = getRegExErrorList();
  //const [articleId, setArticleId] = useState (doubleInstanceID);
  let createInstanceView;
  let startingRootNodeId;
  switch (instanceType) {
    case 'Article':
      createInstanceView = createArticleView;
      startingRootNodeId = 'CREATE_ARTICLE_ROOT';
      break;
    case 'Product':
      //TODO: tbd
      break;
    //Custom Instance:
    default:
      createInstanceView = createCustomInstanceView;
      startingRootNodeId = 'CREATE_CUSTOM_INSTANCE_ROOT';
      break;
  }

  useEffect(() => {
    resetRegExErrorList();
    ajaxGet('instanceEditor/createInstance', {
      contentLanguage: contentLang,
      id: parentInstanceEditor.instanceId,
      articleId: articleId,
      instanceTypeParent: parentInstanceEditor.instanceType,
      instanceTypeTarget: instanceType,
      ptdId: ptdId
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        let createData = null;
        if (response.childrenInstanceModels) {
          createData = response.childrenInstanceModels[0];
        } else if (response.articlesModel) {
          createData = response.articlesModel.articleModels[0];
          setCreationFeatureDefs(response.articlesModel.features);
        }

        delete createData.generalFields.articleNumber;
        setCreateInstanceData(createData);
      }
    });
  }, [articleId]);

  function saveCreateInstance() {
    let creationParameters = getCreationParameters();
    creationParameters = { ...creationParameters };
    const creationData = getCreationData();

    const missingGeneralFields = variableContentDefs.generalFieldDefs.filter(
      (def) => def.mandatory && !(def.value in creationParameters)
    );
    const missingTexts = variableContentDefs.textDefs.filter(
      (def) =>
        def.mandatory &&
        !(def.value in creationData.textValues) &&
        !createInstanceData.textEditorsMap[def.value].defaultDataAwareInfo
          .defaultData
    );
    const missingCustomFields = variableContentDefs.customFieldDefs.filter(
      (def) =>
        def.mandatory &&
        !(def.value in creationData.featureValues) &&
        !createInstanceData.customFields[def.value].defaultDataAwareInfo
          .defaultData
    );

    let missing = [missingGeneralFields, missingTexts, missingCustomFields];

    let url;
    switch (instanceType) {
      case 'Article': {
        url = 'dataManagement/createArticle';
        creationParameters.productId = parentInstanceEditor.instanceId;

        const featureDefs = getCreationFeatureDefs();
        const missingFeatures = featureDefs.filter(
          (def) =>
            def.mandatory &&
            !(getFeatureKey(def) in creationData.featureValues) &&
            !createInstanceData.features[getFeatureKey(def)]
              .defaultDataAwareInfo.defaultData
        );
        missing.push(missingFeatures);

        break;
      }
      case 'Product':
        url = 'dataManagement/createProduct';
        break;
      //Custom Instance:
      default:
        url = 'dataManagement/createCustomInstance';
        creationParameters.subType = instanceType;
        creationParameters.containerInstanceId =
          parentInstanceEditor.instanceId;
        creationParameters.ptdId = ptdId;
        if (
          ['Article', 'Product'].includes(parentInstanceEditor.instanceType)
        ) {
          creationParameters.containerInstanceClassName =
            parentInstanceEditor.instanceType;
        } else {
          creationParameters.containerInstanceClassName = 'CustomInstance';
        }
        break;
    }

    for (let missingValues of missing) {
      if (missingValues.length) {
        // let missingValueId = missingValues[0].value;
        // const missingValueComponent = TOGO.creationMandatory[missingValueId];
        // if (missingValueComponent) {
        //   missingValueComponent.current.scrollIntoView({ behavior: 'smooth' });
        // }

        TOGO.Util.notifyResponse(t('common.errorMandatory'), true);
        return;
      }
    }

    creationParameters.contentLanguage = contentLang;
    ajaxPost(url, creationParameters, creationData).then((ajaxData) => {
      let response = ajaxData.response;
      TOGO.Util.notifyResponse(response.message, !response.success);

      if (!ajaxData.error && response) {
        let createdInstance;
        switch (instanceType) {
          case 'Article': {
            createdInstance = response.createArticle;
            break;
          }
          case 'Product':
            break;

          default:
            //Custom Instance:
            createdInstance = response.createCustomInstance;
            break;
        }

        resetCreation();
        resetRegExErrorList();

        closeCreateInstance(createdInstance);
      }
    });
  }

  const handleClose = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
    closeDialog();
  };

  function closeDialog() {
    resetRegExErrorList();

    closeCreateInstance(null);
  }

  return (
    <Dialog
      aria-labelledby="create-instance-title"
      open={isCreateInstanceOpen}
      onClose={handleClose}
      className={classes.dialog}
      transitionDuration={0}
      id="createInstanceDialog"
      PaperProps={{ className: 'reactInstanceEditorWrap' }}
    >
      <DialogTitle id="create-instance-title" className={classes.dialogTitle}>
        {t('instanceEditor.createInstance', { instanceTitle: instanceTitel })}
      </DialogTitle>
      <InstanceTabEditorReader
        instanceType={instanceType}
        instanceId={CREATE_INSTANCE_ID}
        loadedInstanceData={createInstanceData}
        startingRootNodeId={startingRootNodeId}
        loadedNodesContent={createInstanceView}
        loadedContentLang={getContentLang()}
      />

      {regExErrorList.length ? (
        <div style={{ display: 'inline-flex' }}>
          <Tooltip title={t('instanceEditor.regExErrorDefault')} arrow>
            <span style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Button
                startIcon={<Icon className="fa fa-plus" />}
                onClick={saveCreateInstance}
                className={`${classes.addAction} ${classes.dialogBottom}`}
                style={{ width: '50%' }}
                disabled
              >
                {t('common.create')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('instanceEditor.regExErrorDefault')} arrow>
            <span style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Button
                onClick={closeDialog}
                className={`${classes.deleteAction} ${classes.dialogBottom}`}
                style={{ width: '50%', marginLeft: '1rem' }}
                disabled
              >
                {t('common.abort')}
              </Button>
            </span>
          </Tooltip>
        </div>
      ) : (
        <div style={{ display: 'inline-flex' }}>
          <Button
            startIcon={<Icon className="fa fa-plus" />}
            onClick={saveCreateInstance}
            className={`${classes.addAction} ${classes.dialogBottom}`}
            style={{ width: '50%' }}
          >
            {t('common.create')}
          </Button>
          <Button
            onClick={closeDialog}
            className={`${classes.deleteAction} ${classes.dialogBottom}`}
            style={{ width: '50%', marginLeft: '1rem' }}
          >
            {t('common.abort')}
          </Button>
        </div>
      )}
    </Dialog>
  );
};
