import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const { t } = useTranslation();

const headCells_CLASSIFICATION_GROUP = [
	{
		id: 'Aktionen',
		numeric: false,
		disablePadding: false,
		label: t('manageOrganization.organization.actions')
	},
	{ id: 'classification', numeric: false, disablePadding: false, label:  t('classificationGroup.classification') },
	{
		id: 'id',
		numeric: false,
		disablePadding: false,
		label:  t('classificationGroup.id') 
	}
];
	return (
		<>
				<TableHead>
					<TableRow>
						{headCells_CLASSIFICATION_GROUP.map((headCell, index) => (
							<TableCell
								key={index}
								align={headCell.numeric ? 'right' : 'left'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
		</>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	onRequestSort: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTableCell-head': {
			backgroundColor: '#a9a9a975',
			fontSize: '1.5rem',
			fontWeight: 'bold'
		},
		'& .MuiTableCell-body': {
			fontSize: '1.5rem'
		},
		width: '100%',
		'& .MuiSvgIcon-root': { fontSize: '3rem !important' }
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750,
		'& .MuiTableCell-root': {
			borderLeft: '1px solid rgba(224, 224, 224, 1)'
		}
	},
	deleteBtn: {
		color: 'rgb(237 14 14 / 89%) !important'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}));


export default function EnhancedTableClassificationGroup({
	classificationGroups,
	setInitFormClassificationGroupState,
	setClassificationGroups,
	searchClassificationGroups,
	setToCreateClassificationGroup,
	setContentLangSelections,
	handleClickOpenDialogClassificationGroup
}) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('company');

	const { t } = useTranslation();

	const confirmDeleteClassificationGroup = (id) => {
		TOGO.popupManager.confirmZebraDialog(
			t('classificationGroup.deleteButtonConfirm'),
			deleteClassificationGroup,
			id
		);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const deleteClassificationGroup = (id) => {
		var pojo = new Object();
		pojo.classificationGroupId = id;
		ajaxPost('classificationGroupsManagement/delete', pojo).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				setClassificationGroups(classificationGroups.filter((org) => org.id !== id));
				TOGO.Util.notifyResponse(ajaxData.response.message, false);
				searchClassificationGroups();
			}
		});
	};

	const copieClassificationGroup = (id) => {
		getClassificationGroup(id, true);
	};

	const getClassificationGroup = (id, bool) => {
		ajaxGet('classificationGroupsManagement/edit', {
			classificationGroupId: id
		}).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				console.log(ajaxData.response);
				var res = ajaxData.response.classificationGroupForm;
				if (res != null && res != undefined) {
					var classificationGroupForm = {
						id: id,
						classificationIdentifier: res?.classificationIdentifier || '',
						classificationGroupName: res?.classificationGroupName || '',
						localClassification: res?.localClassification || ''
					};

					setInitFormClassificationGroupState(classificationGroupForm);
					setToCreateClassificationGroup(bool);
					handleClickOpenDialogClassificationGroup();
				}
			}
		});
	};
	const editClassificationGroup = (id) => {
		var contentLang = t('language.code');
		ajaxGet('classificationGroupsManagement/edit', {
			classificationGroupId: id,
			contentLang:contentLang
		}).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				var res = ajaxData.response.model.classificationGroupForm;
				var contentLangSelections = ajaxData.response.model.contentLangSelections;

				if (res != null && res != undefined) {
					var classificationGroupForm = {
						id: id,
						language:contentLang,
						classificationIdentifier: res?.classificationIdentifier || '',
						classificationGroupName: res?.classificationGroupName || '',
						localClassification: res?.localClassification || ''
					};
					setContentLangSelections(contentLangSelections);
					setInitFormClassificationGroupState(classificationGroupForm);
					setToCreateClassificationGroup(false);
					handleClickOpenDialogClassificationGroup();
				}
			}
		});
	};


	function naturalSorter(as, bs) {
		var a,
			b,
			a1,
			b1,
			i = 0,
			n,
			L,
			rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
		if (as === bs) return 0;
		if (as == '' || as == null) return 1;
		a = as.toLowerCase().match(rx);
		if (bs == '' || bs == null) return -1;
		b = bs.toLowerCase().match(rx);
		L = a.length;
		while (i < L) {
			if (!b[i]) return 1;
			(a1 = a[i]), (b1 = b[i++]);
			if (a1 !== b1) {
				n = a1 - b1;
				if (!isNaN(n)) return n;
				return a1 > b1 ? 1 : -1;
			}
		}
		return b[i] ? -1 : 0;
	}
	function sortAlphaNum(a, b) {
		var reA = /[^a-zA-Z]/g;
		var reN = /[^0-9]/g;
		var aA = a.replace(reA, '');
		var bA = b.replace(reA, '');
		if (aA === bA) {
			var aN = parseInt(a.replace(reN, ''), 10);
			var bN = parseInt(b.replace(reN, ''), 10);
			return aN === bN ? 0 : aN > bN ? 1 : -1;
		} else {
			return aA > bA ? 1 : -1;
		}
	}

	function descendingComparator(a, b, orderBy) {
		return naturalSorter(
			a['organizationForm'][orderBy],
			b['organizationForm'][orderBy]
		);
	}

	function getComparator(order, orderBy) {
		console.log(orderBy);
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	// This method is created for cross-browser compatibility, if you don't
	// need to support IE11, you can use Array.prototype.sort() directly
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	return (
		<div className={classes.root}>
			{/* <ConfirmDialog
	 handelCloseConfirmDialog={handelCloseConfirmDialog} openConfirmDialog={openConfirmDialog} deleteOrganization={deleteOrganization}/> */}
			{classificationGroups.length > 0 ? (
				<Paper className={classes.paper}>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							aria-label="simple table"
						>
							<EnhancedTableHead
								classes={classes}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
							/>
								<TableBody>
									{classificationGroups.map((row, index) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={index}>
												<TableCell align="center">
													<Grid container >
														<Grid item xs={6}>
														{row.localClassification &&
																<Tooltip
																	title={
																		<Typography fontSize={5}>{t('common.edit')}</Typography>}>
																	<IconButton
																		onClick={() => {
																			editClassificationGroup(row.id);
																		}}
																	>
																		<EditIcon />
																	</IconButton>
																</Tooltip> }
														</Grid>
														<Grid item>
														{row.groupName !='STANDARD' && <Tooltip
														title={
															<Typography fontSize={5}>{t('classificationGroup.deleteButton')}</Typography>
														}
													>
														<IconButton
															onClick={() => {
																confirmDeleteClassificationGroup(row.id);
															}}
														>
															<DeleteIcon className={classes.deleteBtn} />
														</IconButton>
													</Tooltip>}
														</Grid>
													</Grid>
												</TableCell>
												<TableCell align="left">
													{row.groupName}
												</TableCell>
												<TableCell align="left">
													{row.classificationIdentifier}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
						</Table>
					</TableContainer>{' '}
				</Paper>
			) : (
				<Typography
					paragraph={true}
					noWrap={false}
					fontWeight={'bold'}
					align="center"
					fontSize={20}
				>
					{t('common.noSearchResults')}
				</Typography>
			)}
		</div>
	);
}

