import i18n from '../../../../i18n';

import { makeStyles } from '@material-ui/core/styles';

export const JODIT_CONFIG = {
  enter: 'div',
  buttons: [
    'undo',
    'redo',
    '|',
    'ul',
    {
      icon: 'ol',
      command: 'insertOrderedList',
      tags: ['ol'],
      tooltip: 'Insert Ordered List',
      list: {
        default: 'Default',
        'lower-alpha': 'Lower Alpha',
        'upper-alpha': 'Upper Alpha'
      },
      exec:
        'function(e,t,n){var r=n.control,o="button"+r.command,a=r.args&&r.args[0]||i.dataBind(e,o);i.dataBind(e,o,a),e.execCommand(r.command,!1,a)}'
    },
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'superscript',
    'subscript',
    '|',
    'brush'
  ],
  beautyHTML: true,
  beautifyHTMLCDNUrlsJS: ['frameworks/beautify-1.13.0/beautify.min.js'],
  sourceEditorCDNUrlsJS: ['frameworks/ace-1.4.12/ace.js'],
  colorPickerDefaultTab: 'color',
  height: '6.25rem',
  minHeight: 0,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  allowTabNavigation: false,
  processPasteFromWord: true,
  processPasteHTML: true,
  defaultActionOnPaste: 'insert_as_html',
  defaultActionOnPasteFromWord: 'insert_clear_html',
  cleanHTML: {
    denyTags: {
      a: {
        href: false
      },
      table: false,
      tr: false,
      td: false
    },
    replaceNBSP: true
  },
  controls: {
    ol: {
      icon: 'ol',
      command: 'insertOrderedList',
      tags: ['ol'],
      tooltip: 'Insert Ordered List',
      list: {
        default: 'Default',
        'lower-alpha': 'Lower Alpha',
        'upper-alpha': 'Upper Alpha'
      },
      exec:
        'function(e,t,n){var r=n.control,o="button"+r.command,a=r.args&&r.args[0]||i.dataBind(e,o);i.dataBind(e,o,a),e.execCommand(r.command,!1,a)}'
    }
  },
  placeholder: i18n.t('instanceEditor.textEditor.placeholder'),
  showCharsCounter: false,
  showPlaceholder: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbar: false,
  showXPathInStatusbar: false,
  readonly: true,
  autofocus: false
};

export const useStyles = makeStyles(() => ({
  borderWrap: {
    '& > .jodit-container': {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    }
  },
  defaultData: {
    '& .jodit-workplace': {
      backgroundColor: '#fafad2'
    }
  }
}));
