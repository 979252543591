import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

import { useField, useFormikContext } from 'formik';
import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  OutlinedInput
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%'
  },
  menuPaper: {
    maxHeight: 100
  },
  indeterminateColor: {
    color: '#5cb85c'
  },
  allZonesCheckbox: {
    marginRight: '1rem'
  }
}));

export const useTitleStyles = makeStyles(() => ({
  titleSelect: {
    top: '-2rem',
    left: '-0.6rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  }
}));

const USER_DATAZONES_KEY = 'userDataZones';
const USER_DATAZONES_DEFAULT_KEY = 'userDataZonesDefault';

function UserDataZonesSelect({ dataZones }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setFieldValue } = useFormikContext();
  const [fieldSelectedDataZones] = useField(USER_DATAZONES_KEY);
  const [fieldUserDataZonesDefault] = useField(USER_DATAZONES_DEFAULT_KEY);

  const classesTitle = useTitleStyles();

  const [userDataZonesDefault, setUserDataZonesDefault] = useState(
    fieldUserDataZonesDefault.value
  );
  const [selected, setSelected] = useState(
    fieldSelectedDataZones.value ? fieldSelectedDataZones.value : []
  );

  const userDataZonesRequiredError =
    !userDataZonesDefault && (!selected || selected.length < 1);

  function selectDataZones(event) {
    const newSelectedValueList = event.target.value;

    setSelected(newSelectedValueList);
    setFieldValue(USER_DATAZONES_KEY, newSelectedValueList);
  }

  function toggleDataZonesDefault(event) {
    const checked = event.target.checked;
    setUserDataZonesDefault(checked);
    setFieldValue(USER_DATAZONES_DEFAULT_KEY, checked);

    if (checked) {
      const newSelectedValueList = [];
      setSelected(newSelectedValueList);
      setFieldValue(USER_DATAZONES_KEY, newSelectedValueList);
    }
  }

  return (
    <FormGroup row>
      <Grid direction="row" container spacing={1}>
        <Grid container item sm={6}>
          <FormControl
            variant="filled"
            fullWidth={true}
            disabled={Boolean(userDataZonesDefault)}
            error={userDataZonesRequiredError}
          >
            <InputLabel
              id="UserDataZonesSelect-label"
              disableAnimation
              shrink={false}
              className={classesTitle.titleSelect}
            >
              {t('dashboard.articleDataZones.name')}
            </InputLabel>
            <Select
              name={USER_DATAZONES_KEY}
              labelId="UserDataZonesSelect-label"
              multiple
              value={selected}
              onChange={(event) => {
                selectDataZones(event, false);
              }}
              displayEmpty={true}
              error={userDataZonesRequiredError}
              input={
                <OutlinedInput
                  notched={false}
                  label={t('dashboard.articleDataZones.name')}
                />
              }
              renderValue={(selectedToRender) => {
                if (userDataZonesDefault) {
                  return 'all';
                  // return t('managUsers.register.allDataZones');
                }

                const selectedLength =
                  selectedToRender != null ? selectedToRender.length : 0;
                return t('managUsers.register.dataZonesSelection', {
                  count: selectedLength
                });
              }}
            >
              {!userDataZonesDefault &&
                dataZones.length &&
                dataZones.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(zone.id) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={zone.label} />
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText component="div">
              {userDataZonesRequiredError ? (
                t('managUsers.register.dataZoneRequired')
              ) : (
                <span>&#8203;</span>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid container item sm={6}>
          <FormControlLabel
            className={classes.allZonesCheckbox}
            control={
              <Checkbox
                checked={userDataZonesDefault}
                onChange={toggleDataZonesDefault}
                name={USER_DATAZONES_DEFAULT_KEY}
              />
            }
            label={t('managUsers.register.allDataZones')}
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
}

export default UserDataZonesSelect;
