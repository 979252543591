import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PaginationClassificationGroup from './PaginationClassificationGroup';
import ajaxGet from 'src/services/ajaxGet';
import theme, { usePimStyles } from '../PimTogoTheme';
// import PersonAddAltIcon from '@material-ui/icons/PersonAddAlt';
import PersonAddAltIcon from '@material-ui/icons/PersonAdd';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CreateClassificationGroup from './CreateClassificationGroup';
import EnhancedTableClassificationGroup from './EnhancedTableClassificationGroup';


const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));

const INITIAL_FORM_STATE_CLASSIFICATION_GROUP = {
  id:'',
  classificationIdentifier : '',
  classificationGroupName: '',
};

export default function ClassificationGroup() {
  const classes = useStyles();
  const classesPim = usePimStyles();
  const { t } = useTranslation();

  const [searchExpression, setSearchExpression] = useState('');
  const [classificationGroups, setClassificationGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(50);
  const [paginationstate, setPaginationstate] = useState({});
  const [openDialogClassificationGroup, setOpenDialogClassificationGroup] = useState(false);
  const [toCreateClassificationGroup, setToCreateClassificationGroup] = useState(true);
  const [classificationList, setClassificationList] = useState({});
  const [contentLangSelections, setContentLangSelections] = useState({});

  const [productTypeDefinition , setProductTypeDefinition] = useState(false);

  const [initFormClassificationGroupState, setInitFormClassificationGroupState] = useState(
    INITIAL_FORM_STATE_CLASSIFICATION_GROUP
  );

  const handelEventSearchClassificationGroup = () => {
    setCurrentPage(1);
    setElementsPerPage(50);
    searchClassificationGroups();
  };

  const searchClassificationGroups = (showFirstUserPage) => {
    let targetPage = currentPage;
    if (showFirstUserPage) {
      targetPage = 1;
    }

   ajaxGet('classificationGroupsManagement/search', {
      searchExpression: searchExpression,
      currentPage: targetPage,
      elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setClassificationGroups(response.groupModels);
        setPaginationstate(response.pagination);
        setProductTypeDefinition(response.featureIndividualCLC)
      }
    });
  };

  useEffect(() => {
    searchClassificationGroups();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickOpenDialogClassificationGroup = () => {
    setOpenDialogClassificationGroup(true);
  };

  const addUserhandel = () => {
    ajaxGet('classificationGroupsManagement/getExtenClassificationList', {
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        let classificationsCollection = response.model.classificationsCollection;
        setClassificationList(classificationsCollection);
      }
    });
    setOpenDialogClassificationGroup(true);
    const classificationGroupForm = {
      id:'',
      classificationIdentifier : '',
      classificationGroupName: ''
    };

    setInitFormClassificationGroupState(classificationGroupForm);
  };

  const handleCloseDialogClassificationGroup = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
      setOpenDialogClassificationGroup(false);
      setToCreateClassificationGroup(true);
      setInitFormClassificationGroupState(INITIAL_FORM_STATE_CLASSIFICATION_GROUP);
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCurrentPage(1);
      setElementsPerPage(50);
      searchClassificationGroups();
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid className={classes.root}>
          <form>
            <TextField
              label={t('manageOrganization.organization.search')}
              margin="normal"
              id="user-name-search"
              fullWidth
              value={searchExpression}
              onChange={handleChange}
              onKeyDown={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handelEventSearchClassificationGroup()}
                    position="start"
                  >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Grid>
        <Grid className={classes.root}>
          {/* {true && (
            <PaginationClassificationGroup
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
            />
          )} */}
        </Grid>
        {'   '}
        <Grid className={classes.root}>
          <Button
            variant="outlined"
            onClick={() => {
              setToCreateClassificationGroup(true);
              addUserhandel();
            }}
            startIcon={<PersonAddAltIcon />}
            className={`${classesPim.addAction} ${classes.submitButton}`}
          >
            <Typography fontSize={5}>{t('classificationGroup.createTitle')} </Typography>
          </Button>

          <CreateClassificationGroup
            openDialogClassificationGroup={openDialogClassificationGroup}
            handleCloseDialogClassificationGroup={handleCloseDialogClassificationGroup}
            initFormClassificationGroupState={initFormClassificationGroupState}
            toCreateClassificationGroup={toCreateClassificationGroup}
            searchClassificationGroups={searchClassificationGroups}
            classificationList={classificationList}
            contentLangSelections={contentLangSelections}
            productTypeDefinition={productTypeDefinition}
          />

          <EnhancedTableClassificationGroup
            classificationGroups={classificationGroups}
            setInitFormClassificationGroupState={setInitFormClassificationGroupState}
            setContentLangSelections={setContentLangSelections}
            setClassificationGroups={setClassificationGroups}
            setToCreateClassificationGroup={setToCreateClassificationGroup}
            handleClickOpenDialogClassificationGroup={handleClickOpenDialogClassificationGroup}
            searchClassificationGroups={searchClassificationGroups}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
}
