import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

const useStyles = makeStyles(() => ({
  booleanEditorInTable: {
    width: '100%',
    '& .MuiSelect-select': {
      width: '100% !important',
      paddingRight: '0'
    },
    '& .MuiInput-root': {
      width: '3rem !important'
    }
  },
  irrelevantBooleanInTable: {
    width: '100%',
    '& .MuiSelect-select': {
      width: '100% !important',
      paddingRight: '0'
    },
    '& .MuiInput-root': {
      width: '15rem !important'
    }
  }
}));

const NULL_VALUE = 'nullValue';
const IRRELEVANT_VALUE = 'irrelevantValue';

export const getBoolPayloadData = (value) => ({
  booleanValue: value
});

export const Bool = ({
  featureInfo,
  value,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classes = useStyles();
  const classesTitle = useTitleStyles();
  let title;

  const irrelevantValue = featureInfo.featureComponent?.irrelevantFeatureValue;
  const irrelevantFeaturePossible = Boolean(
    featureInfo.featureComponent?.irrelevantFeatureValuePossible
  );

  let booleanValue;

  if (value === null) {
    booleanValue = NULL_VALUE;
  } else if (value === IRRELEVANT_VALUE) {
    booleanValue = IRRELEVANT_VALUE;
  } else {
    booleanValue = value;
  }
  const mandatory = featureInfo.mandatory;
  const isMandatoryError =
    !irrelevantValue && mandatory && booleanValue == NULL_VALUE;

  const hasDefaultData = featureInfo.hasDefaultData;

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  }

  const formControlProps = {};
  const selectProps = {};

  if (isInTableContext) {
    title = null;
    if (booleanValue === IRRELEVANT_VALUE) {
      formControlProps.className = classes.irrelevantBooleanInTable;
    } else {
      formControlProps.className = classes.booleanEditorInTable;
    }

    selectProps.IconComponent = 'span';
  } else {
    title = isInTableContext ? null : featureInfo.title;
    formControlProps.variant = 'outlined';
  }

  useEffect(() => {
    if (irrelevantValue) {
      setValueState(IRRELEVANT_VALUE);
    }
  }, []);

  function saveValue(event) {
    if (loading) return;

    let newValue = event.target.value;

    if (newValue === IRRELEVANT_VALUE) {
      setValueState(IRRELEVANT_VALUE);
      const payload = getBoolPayloadData(null);
      payload.irrelevantFeatureValue = true;
      saveChangedValue(IRRELEVANT_VALUE, payload);
      return;
    }

    if (newValue === NULL_VALUE) {
      newValue = null;
    }
    setValueState(newValue);
    saveChangedValue(newValue, getBoolPayloadData(newValue));
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <FormControl
      {...formControlProps}
      error={Boolean(error) || (isMandatoryError && !hasDefaultData)}
      size="small"
      onMouseEnter={showError}
    >
      {!isInTableContext && (
        <InputLabel
          shrink={false}
          className={classesTitle.titleSelect}
          id={'input-label-' + featureInfo.featureIdentifier}
        >
          {title}
        </InputLabel>
      )}
      <Select
        size="small"
        value={booleanValue}
        onChange={(event) => saveValue(event)}
        labelId={'input-label-' + featureInfo.featureIdentifier}
        className={classesTitle.booleanInputStyle}
        {...selectProps}
      >
        {irrelevantFeaturePossible && (
          <MenuItem value={IRRELEVANT_VALUE}>
            <span>{t('instanceEditor.irrelevant')}</span>
          </MenuItem>
        )}
        <MenuItem value={true}>
          <Icon className="fa fa-check font-green" aria-hidden="true" />
        </MenuItem>
        <MenuItem value={false}>
          <Icon className="fa fa-times font-red" aria-hidden="true" />
        </MenuItem>
        {(!mandatory || hasDefaultData || booleanValue === NULL_VALUE) && (
          <MenuItem value={NULL_VALUE}>
            <span>&nbsp;</span>
          </MenuItem>
        )}
      </Select>
      <FormHelperText component="div">{helper}</FormHelperText>
    </FormControl>
  );
};
