function ajaxPut(url, params, json, formData, returnCall) {
  const ajaxData = {
    response: null,
    error: null
  };

  let paramString = '';
  if (params) {
    for (let param in params) {
      let paramVal = params[param];
      if (paramVal === null) {
        delete params[param];
      }
    }

    paramString = '?';

    for (const [key, value] of Object.entries(params)) {
      paramString += key + '=' + encodeURIComponent(value) + '&';
    }

    if (paramString.length == 1) {
      paramString = '';
    } else {
      paramString = paramString.substring(0, paramString.length - 1);
    }
  }

  const ajaxParams = { method: 'put' };

  if (json) {
    ajaxParams.url = url + paramString;
    ajaxParams.data = JSON.stringify(json);
    ajaxParams.contentType = 'application/json';
  } else if (formData) {
    ajaxParams.data = formData;
    ajaxParams.contentType = false;
    ajaxParams.processData = false;
    ajaxParams.url = url + paramString;
  } else {
    ajaxParams.url = url + paramString;
  }

  let call;
  if (returnCall) {
    call = $.ajax(ajaxParams);
  } else {
    call = $.ajax(ajaxParams)
      .done(function (response) {
        if (response.success) {
          ajaxData.response = response;
        } else {
          TOGO.Util.notify('', response.message, 5000, true);
          ajaxData.error = response.message;
        }
      })
      .fail(function (jqxhr, textStatus, errorThrown) {
        let errorMsg = TOGO.callbacks.defaultAjaxErrorHandler(
          jqxhr,
          textStatus,
          errorThrown
        );
        ajaxData.error = errorMsg;
        ajaxData.httpRequestResponseText = jqxhr;

        if (jqxhr.status === 423) {
          ajaxData.instanceLocked = true;
        }
      });
  }

  if (returnCall) {
    return call;
  }

  const returnAjaxData = () => {
    return ajaxData;
  };

  return call.then(returnAjaxData, returnAjaxData);
}

export default ajaxPut;
