import { Jodit } from 'jodit';
import { useTranslation } from 'react-i18next';

import { useDataManagement } from '../DataManagementContext';
import { getFeatureKey } from '../ArticleFeatureEditor';

export const VARIABLE_TYPE = {
  VARIABLE_CONTENT: 'variable-content',
  VARIABLE_CONTENT_UNIT: 'variable-content-unit',
  VARIABLE_CONTENT_LABEL: 'variable-content-label'
};

export const VARIABLE_CONTENT_SEL =
  VARIABLE_TYPE.VARIABLE_CONTENT +
  ', ' +
  VARIABLE_TYPE.VARIABLE_CONTENT_LABEL +
  ', ' +
  VARIABLE_TYPE.VARIABLE_CONTENT_UNIT;

export const DATA_CONTENT = {
  CUSTOM_FIELD: 'custom-field',
  FEATURE: 'feature',
  CLASSIFICATION: 'classification',
  CLASSIFICATION_SYSTEM: 'classification-system'
};
export const DATA_PREFIX = 'data-';
export const DATA_CATEGORY = {
  CUSTOM_FIELD: DATA_PREFIX + DATA_CONTENT.CUSTOM_FIELD,
  FEATURE: DATA_PREFIX + DATA_CONTENT.FEATURE,
  CLASSIFICATION: DATA_PREFIX + DATA_CONTENT.CLASSIFICATION,
  CLASSIFICATION_SYSTEM: DATA_PREFIX + DATA_CONTENT.CLASSIFICATION_SYSTEM
};

export function useConfigJoditForVariables({
  joditConfig,
  textType,
  instanceEditor
}) {
  const { t } = useTranslation();
  const { getVariableContentDefs, getFeatureDefs } = useDataManagement();

  const containerId = instanceEditor.instanceId;
  const textEditorData = instanceEditor.textEditor[textType];
  const contentAutomation = Boolean(
    textEditorData?.defaultDataAwareInfo?.subscribedContentAutomation
  );

  if (!contentAutomation) {
    return joditConfig;
  }

  const buttonLabels = {
    buttonMenuLabel: t('instanceEditor.textVariable.title'),
    addTextVariable: t('instanceEditor.textVariable.buttonAdd'),
    editTextVariable: t('instanceEditor.textVariable.buttonEdit'),
    deleteTextVariable: t('instanceEditor.textVariable.buttonDelete')
  };

  const textVariableButtonListName = 'textVariableButtonList';
  const textVariableButtonList = joditConfig.buttons.find(
    (button) =>
      typeof button === 'object' && button.name == textVariableButtonListName
  );
  var textVariableButtonListIndex = joditConfig.buttons.indexOf(
    textVariableButtonList
  );
  if (textVariableButtonListIndex !== -1) {
    joditConfig.buttons.splice(textVariableButtonListIndex, 1);
  }
  joditConfig.buttons.push({
    name: textVariableButtonListName,
    text: buttonLabels.buttonMenuLabel,
    component: 'select',
    list: {
      addTextVariable: {
        buttonFunction: addTextVariable
      },
      editTextVariable: {
        buttonFunction: editTextVariable
      },
      deleteTextVariable: {
        buttonFunction: deleteTextVariable
      }
    },
    childTemplate: (editor, key) => {
      const buttonLabel = buttonLabels[key];
      return `<span>${buttonLabel}</span>`;
    },
    exec: function (editor, t, { control }) {
      if (!control.args) {
        return false;
      }
      const buttonFunction = control.args[1].buttonFunction;
      if (buttonFunction) {
        buttonFunction(editor);
      }
    }
  });

  const prepareSelectedVariableButtonName = 'prepareSelectedVariableAsButton';
  const prepareSelectedVariableButton = joditConfig.buttons.find(
    (button) =>
      typeof button === 'object' &&
      button.name == prepareSelectedVariableButtonName
  );
  var prepareSelectedVariableButtonIndex = joditConfig.buttons.indexOf(
    prepareSelectedVariableButton
  );
  if (prepareSelectedVariableButtonIndex !== -1) {
    joditConfig.buttons.splice(prepareSelectedVariableButtonIndex, 1);
  }
  joditConfig.buttons.push({
    name: prepareSelectedVariableButtonName,
    text: 'prepare selected variable as Button',
    exec: prepareSelectedVariableAsButton
  });

  function prepareSelectedVariableAsButton(editor) {
    let selection = editor.selection;

    let $selectedVariable = TOGO.Components.$selectedTextVariable;
    const $spacerStart = $('<span class="tempContentSpacer">&nbsp;</span>');
    const $spacerEnd = $('<span class="tempContentSpacer">&nbsp;</span>');
    $selectedVariable.before($spacerStart);
    $selectedVariable.after($spacerEnd);

    let selectionRange = document.createRange();
    selectionRange.setStart($spacerStart[0], 0);
    selectionRange.setEnd($spacerEnd[0], 0);
    selection.selectRange(selectionRange);
  }

  function addTextVariable(editor) {
    chooseTextVariableContent(editor, null, {
      dataCategory: DATA_CATEGORY.CUSTOM_FIELD,
      customField: null,
      feature: null,
      classification: null,
      classificationSystem: null,
      tagName: null
    });
  }

  function editTextVariable(editor) {
    const $selectedVariable = TOGO.Components.$selectedTextVariable;
    if (!$selectedVariable || !$selectedVariable.length) return;

    let dataCategory = null;
    const tagName = $selectedVariable.prop('tagName').toLowerCase();
    const customField =
      $selectedVariable.attr(DATA_CATEGORY.CUSTOM_FIELD) || null;
    const feature = $selectedVariable.attr(DATA_CATEGORY.FEATURE) || null;
    const classification =
      $selectedVariable.attr(DATA_CATEGORY.CLASSIFICATION) || null;
    const classificationSystem =
      $selectedVariable.attr(DATA_CATEGORY.CLASSIFICATION_SYSTEM) || null;

    if (customField) {
      dataCategory = DATA_CATEGORY.CUSTOM_FIELD;
    } else if (feature) {
      dataCategory = DATA_CATEGORY.FEATURE;
    }

    chooseTextVariableContent(editor, $selectedVariable, {
      dataCategory: dataCategory,
      customField: customField,
      feature: feature,
      classification: classification,
      classificationSystem: classificationSystem,
      tagName: tagName
    });
  }

  function deleteTextVariable() {
    if (!contentAutomation) return;

    const $selectedVariable = TOGO.Components.$selectedTextVariable;
    if (!$selectedVariable || !$selectedVariable.length) return;

    if ($selectedVariable) {
      $selectedVariable.remove();
    }
  }

  function chooseTextVariableContent(
    editor,
    $selectedTextVariable,
    variableSetup
  ) {
    if (!contentAutomation) return;

    const parentClass = textEditorData?.parentObjectClass;
    const variableContentDefs = getVariableContentDefs(parentClass);
    if (!variableContentDefs) return;

    const isPtdDefaultDataForArticle = containerId.endsWith(
      'PtdDefaultDataForArticle'
    );
    const customFields = variableContentDefs.customFieldDefs;
    const featureDefs =
      parentClass === 'Article' || isPtdDefaultDataForArticle
        ? getFeatureDefs()
        : null;

    TOGO.popupManager.joditDialogOpen = true;
    const dialog = new Jodit.modules.Dialog();

    const $dialogContent = $('<div></div>');
    const $wrap = $('<div></div>', {
      id: 'textVariableDialog',
      class: 'joditDialog'
    });

    const $dataCategoryWrap = $('<div></div>', {
      class: 'textVariableSelectWrap textVariableSelectDataCategoryWrap'
    });
    const $dataCategoryTitle = $('<h4></h4>', {
      text: t('instanceEditor.textVariable.dataCategory')
    });
    $dataCategoryWrap.append($dataCategoryTitle);
    const $dataCategorySelect = $('<select></select>', {
      id: 'textVariableSelectDataCategory',
      class: 'selectpicker'
    });
    $dataCategorySelect.attr('title', '-');
    const $customFieldsOption = $('<option></option>', {
      value: DATA_CATEGORY.CUSTOM_FIELD,
      text: t('data.customFields')
    });
    if (DATA_CATEGORY.CUSTOM_FIELD === variableSetup.dataCategory) {
      $customFieldsOption.attr('selected', 'selected');
    }
    $dataCategorySelect.append($customFieldsOption);
    $dataCategoryWrap.append($dataCategorySelect);
    if (featureDefs?.length) {
      const $featuresOption = $('<option></option>', {
        value: DATA_CATEGORY.FEATURE,
        text: t('data.features')
      });
      if (DATA_CATEGORY.FEATURE === variableSetup.dataCategory) {
        $featuresOption.attr('selected', 'selected');
      }
      $dataCategorySelect.append($featuresOption);
    } else {
      $dataCategoryWrap.addClass('hidden');
    }
    $wrap.append($dataCategoryWrap);

    var dataCategoryPossible = false;

    if (customFields?.length) {
      const $customFieldsWrap = $('<div></div>', {
        class: 'textVariableSelectWrap textVariableSelectCustomFieldWrap hidden'
      });
      if (variableSetup.dataCategory === DATA_CATEGORY.CUSTOM_FIELD) {
        $customFieldsWrap.removeClass('hidden');
      }
      const $customFieldsTitle = $('<h4></h4>', {
        text: t('data.customFields')
      });
      $customFieldsWrap.append($customFieldsTitle);
      const $customFieldSelect = $('<select></select>', {
        id: 'textVariableSelectCustomField',
        class: 'selectpicker'
      });
      $customFieldSelect.attr('title', '-');
      customFields.forEach((customField) => {
        const $customFieldOption = $('<option></option>', {
          value: customField.value,
          text: customField.label
        });
        if (customField.value === variableSetup.customField) {
          $customFieldOption.attr('selected', 'selected');
        }
        $customFieldSelect.append($customFieldOption);

        dataCategoryPossible = true;
      });
      $customFieldsWrap.append($customFieldSelect);
      $wrap.append($customFieldsWrap);
    }

    if (featureDefs?.length) {
      const $featuresWrap = $('<div></div>', {
        class: 'textVariableSelectWrap textVariableSelectFeatureWrap hidden'
      });
      if (variableSetup.dataCategory === DATA_CATEGORY.FEATURE) {
        $featuresWrap.removeClass('hidden');
      }
      const $featuresTitle = $('<h4></h4>', {
        text: t('data.features')
      });
      $featuresWrap.append($featuresTitle);
      const $featuresSelect = $('<select></select>', {
        id: 'textVariableSelectFeature',
        class: 'selectpicker'
      });
      $featuresSelect.attr('title', '-');
      featureDefs.forEach((featureDef) => {
        const $featureOption = $('<option></option>', {
          value: featureDef.featureIdentifier,
          text: featureDef.featureHeader.text
        })
          .attr(
            DATA_CATEGORY.CLASSIFICATION_SYSTEM,
            featureDef.classificationIdentifier
          )
          .attr(DATA_CATEGORY.CLASSIFICATION, featureDef.classIdentifier);
        if (featureDef.featureIdentifier === variableSetup.feature) {
          $featureOption.attr('selected', 'selected');
        }
        $featuresSelect.append($featureOption);

        dataCategoryPossible = true;
      });
      $featuresWrap.append($featuresSelect);
      $wrap.append($featuresWrap);
    }

    if (!dataCategoryPossible) {
      TOGO.Util.notifyResponse(
        t('instanceEditor.textVariable.dataCategoryNotPossible'),
        true
      );

      TOGO.popupManager.joditDialogOpen = false;
      return;
    }

    const $tagNameWrap = $('<div></div>', {
      class: 'joditDialogSelectWrap textVariableSelectTagNameWrap'
    });
    const $tagNameTitle = $('<h4></h4>', {
      text: t('instanceEditor.textVariable.tagName')
    });
    $tagNameWrap.append($tagNameTitle);
    const $tagNameSelect = $('<select></select>', {
      id: 'textVariableSelectTagName',
      class: 'selectpicker'
    });
    $tagNameSelect.attr('title', '-');

    const $tagNameLabelOption = $('<option></option>', {
      value: VARIABLE_TYPE.VARIABLE_CONTENT_LABEL,
      text: t('instanceEditor.textVariable.label')
    });
    if (VARIABLE_TYPE.VARIABLE_CONTENT_LABEL === variableSetup.tagName) {
      $tagNameLabelOption.attr('selected', 'selected');
    }
    $tagNameSelect.append($tagNameLabelOption);

    const $tagNameValueOption = $('<option></option>', {
      value: VARIABLE_TYPE.VARIABLE_CONTENT,
      text: t('instanceEditor.textVariable.value')
    });
    if (VARIABLE_TYPE.VARIABLE_CONTENT === variableSetup.tagName) {
      $tagNameValueOption.attr('selected', 'selected');
    }
    $tagNameSelect.append($tagNameValueOption);

    const $tagNameUnitOption = $('<option></option>', {
      value: VARIABLE_TYPE.VARIABLE_CONTENT_UNIT,
      text: t('instanceEditor.textVariable.unit')
    });
    if (VARIABLE_TYPE.VARIABLE_CONTENT_UNIT === variableSetup.tagName) {
      $tagNameUnitOption.attr('selected', 'selected');
    }
    $tagNameSelect.append($tagNameUnitOption);
    $tagNameWrap.append($tagNameSelect);
    $wrap.append($tagNameWrap);

    const $acceptButtonWrap = $('<div></div>', {
      class: 'textVariableAcceptButtonWrap joditDialogAcceptButtonWrap'
    });
    const $acceptButton = $('<button></button>', {
      id: 'textVariableAcceptButton',
      class: 'btn btn-primary joditDialogAcceptButton'
    });
    $acceptButton.text('OK');
    $acceptButtonWrap.append($acceptButton);
    $wrap.append($acceptButtonWrap);

    $dialogContent.append($wrap);
    const contentAsHtml = editor.create.fromHTML($dialogContent.html());

    if ($selectedTextVariable) {
      dialog.setHeader(t('instanceEditor.textVariable.buttonEdit'));
    } else {
      dialog.setHeader(t('instanceEditor.textVariable.buttonAdd'));
    }

    dialog.setContent(contentAsHtml);
    dialog.destroyAfterClose = true;

    dialog.open();

    $(dialog.dialog)
      .find('.jodit-dialog__header-toolbar')
      .on('click', function () {
        TOGO.popupManager.joditDialogOpen = false;
      });

    const $textVariableDialog = $('#textVariableDialog');
    TOGO.initializer.initialize($textVariableDialog);

    $textVariableDialog
      .find('#textVariableSelectDataCategory')
      .on('change', function () {
        const $select = $(this);
        const dataCategory = $select.val();
        variableSetup.dataCategory = dataCategory;

        const $selectCustomFieldWrap = $textVariableDialog.find(
          '.textVariableSelectCustomFieldWrap'
        );
        const $selectFeatureWrap = $textVariableDialog.find(
          '.textVariableSelectFeatureWrap'
        );

        if (dataCategory === DATA_CATEGORY.CUSTOM_FIELD) {
          $selectCustomFieldWrap.removeClass('hidden');
          $selectFeatureWrap.addClass('hidden');
        } else if (dataCategory === DATA_CATEGORY.FEATURE) {
          $selectFeatureWrap.removeClass('hidden');
          $selectCustomFieldWrap.addClass('hidden');
        }

        $selectCustomFieldWrap.find('select').selectpicker('val', '');
        $selectFeatureWrap.find('select').selectpicker('val', '');
        variableSetup.customField = null;
        variableSetup.feature = null;
      });

    $textVariableDialog
      .find('#textVariableSelectCustomField')
      .on('change', function () {
        const $select = $(this);
        variableSetup.customField = $select.val();
        variableSetup.feature = null;
      });

    $textVariableDialog
      .find('#textVariableSelectFeature')
      .on('change', function () {
        const $select = $(this);
        variableSetup.feature = $select.val();
        variableSetup.customField = null;
      });

    $textVariableDialog
      .find('#textVariableAcceptButton')
      .on('click', function () {
        const $tagNameSelection = $textVariableDialog.find(
          '#textVariableSelectTagName'
        );
        const $selectedTagName = $tagNameSelection.find('option:selected');
        variableSetup.tagName = $tagNameSelection.val();
        if (!variableSetup.tagName) {
          return false;
        }

        const $createTextVariable = $('<' + variableSetup.tagName + '/>');

        let $selectedData;
        let metaTitle;
        if (variableSetup.customField) {
          $selectedData = $textVariableDialog
            .find('#textVariableSelectCustomField')
            .find('option:selected');
          metaTitle = t('data.customField') + ': ';

          $createTextVariable.attr(
            'data-custom-field',
            variableSetup.customField
          );
        } else if (variableSetup.feature) {
          $selectedData = $textVariableDialog
            .find('#textVariableSelectFeature')
            .find('option:selected');
          metaTitle = t('data.feature') + ': ';

          $createTextVariable
            .attr('data-feature', variableSetup.feature)
            .attr(
              DATA_CATEGORY.CLASSIFICATION,
              $selectedData.attr(DATA_CATEGORY.CLASSIFICATION)
            )
            .attr(
              DATA_CATEGORY.CLASSIFICATION_SYSTEM,
              $selectedData.attr(DATA_CATEGORY.CLASSIFICATION_SYSTEM)
            );
        } else {
          return false;
        }

        if ($selectedTextVariable) {
          $selectedTextVariable.remove();
        }

        metaTitle += $selectedData.text();
        metaTitle += '/';
        metaTitle += $selectedTagName.text();

        $createTextVariable.attr('data-contentlabel', metaTitle);

        const $contentMeta = $('<span></span>', {
          class: 'variableContentMeta',
          text: metaTitle
        });
        $contentMeta.attr('contenteditable', 'false');
        $createTextVariable.append($contentMeta);

        const $wrapVariable = $('<span></span>');
        $wrapVariable.append($createTextVariable);

        let textVariableString = $wrapVariable.html();
        editor.s.insertHTML(textVariableString);

        TOGO.popupManager.joditDialogOpen = false;
        dialog.close();

        $('.indicateVariableSelection').removeClass(
          'indicateVariableSelection'
        );
      });
  }

  return joditConfig;
}

export function useResolveTextVariables({ textType, instanceEditor, text }) {
  const {
    getCustomFieldState,
    getFeatureState,
    getVariableContentDefs,
    getFeatureDefs
  } = useDataManagement();

  let textHasVariables = false;
  let $textResolvedVariables = $('');
  if (text) {
    $textResolvedVariables = $(text);
  }
  let $textVariables = $textResolvedVariables.find(VARIABLE_CONTENT_SEL);
  if (!$textVariables.length) {
    return { $text: $textResolvedVariables, textHasVariables };
  }

  const containerId = instanceEditor.instanceId;
  const textEditorData = instanceEditor.textEditor[textType];

  const parentClass = textEditorData?.parentObjectClass;
  const isPtdDefaultDataForArticle = containerId.endsWith(
    'PtdDefaultDataForArticle'
  );
  const variableContentDefs = getVariableContentDefs(parentClass);
  const customFieldDefs = variableContentDefs?.customFieldDefs;
  const featureDefs =
    parentClass === 'Article' || isPtdDefaultDataForArticle
      ? getFeatureDefs()
      : null;

  $textVariables.each(function () {
    textHasVariables = true;
    let $textVariable = $(this);
    let variableContentString = null;
    let customFieldType = $textVariable.attr(DATA_CATEGORY.CUSTOM_FIELD);
    let featureId = $textVariable.attr(DATA_CATEGORY.FEATURE);
    let featureIdentifier = null;
    if (featureId) {
      featureIdentifier = {
        classificationIdentifier: $textVariable.attr(
          DATA_CATEGORY.CLASSIFICATION_SYSTEM
        ),
        classIdentifier: $textVariable.attr(DATA_CATEGORY.CLASSIFICATION),
        featureIdentifier: featureId
      };
    }

    if ($textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT)) {
      let variableValue = null;

      if (customFieldType) {
        variableValue = getCustomFieldState(containerId, customFieldType);
      } else if (featureId) {
        const featureKey = getFeatureKey(featureIdentifier);
        variableValue = getFeatureState(containerId, featureKey);
      }

      variableContentString = getVariableValueforText(variableValue);
    } else if (
      $textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_LABEL) ||
      $textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_UNIT)
    ) {
      let targetDef = null;
      if (customFieldType && customFieldDefs) {
        targetDef = customFieldDefs.find(
          (customFieldDef) => customFieldDef.value === customFieldType
        );
      } else if (featureId && featureDefs) {
        targetDef = featureDefs.find(
          (featureDef) => featureDef.featureIdentifier === featureId
        );
      }
      if ($textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_LABEL)) {
        if (customFieldType && targetDef) {
          variableContentString = targetDef.label;
        } else if (featureId && targetDef) {
          variableContentString = targetDef.featureHeader.text;
        }
      } else if ($textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_UNIT)) {
        if (customFieldType && targetDef) {
          variableContentString = ''; // Units not yet implemented in custom fields
        } else if (featureId && targetDef) {
          variableContentString = targetDef.featureUnit;
        }
      }
    }
    if (variableContentString) {
      $textVariable.attr('data-resolvedvariablecontent', variableContentString);
    }

    if (!$textVariable.find('.variableContentMeta').length) {
      const metaTitle = $textVariable.data('contentlabel');
      const $contentMeta = $('<span></span>', {
        class: 'variableContentMeta',
        text: metaTitle
      });
      $contentMeta.attr('contenteditable', 'false');
      $textVariable.append($contentMeta);
    }

    let isTextVariableLastElement = $textVariable.is(':last-child');
    if (isTextVariableLastElement) {
      const $spacer = $('<span class="variableContentSpacer">&nbsp;</span>');
      $textVariable.parent().append($spacer);
    }
  });

  function getVariableValueforText(variableValue) {
    if (variableValue == null || variableValue == undefined) {
      return '';
    }

    let variableContentString = '';
    if (typeof variableValue === 'object') {
      if (variableValue.selected) {
        let delimiter = '';
        variableValue.selected.forEach((selectedOption) => {
          variableContentString += delimiter + selectedOption.label;
          delimiter = '|';
        });
      } else if (
        variableValue.year &&
        variableValue.month &&
        variableValue.day
      ) {
        var date = new Date(
          variableValue.year,
          variableValue.month - 1,
          variableValue.day
        );
        variableContentString += date.toLocaleDateString(
          document.documentElement.lang
        );
      } else if (variableValue.lowerValue && variableValue.upperValue) {
        variableContentString +=
          getVariableValueforText(variableValue.lowerValue) +
          ' - ' +
          getVariableValueforText(variableValue.upperValue);
      }
    } else {
      variableContentString += variableValue;
    }

    $('.selectedTextVariable').removeClass('selectedTextVariable');
    return variableContentString;
  }

  return { $textResolvedVariables, textHasVariables };
}

export function configPlaceholderForVariables({ joditConfig, text }) {
  if (text.indexOf('<variable-content') == -1) {
    joditConfig.showPlaceholder = true;
  }

  return joditConfig;
}

export function textVariablesShowEditorEventCommand(
  selectedWrapperId,
  contenteditable
) {
  if (!selectedWrapperId) return;

  let $wrapper = $('#' + selectedWrapperId);
  let $textVariableMeta = $wrapper.find('.variableContentMeta');
  $textVariableMeta.attr('contenteditable', contenteditable);
}

export function textVariablesShowEditor(selectedWrapperId) {
  if (!selectedWrapperId) return;

  let $wrapper = $('#' + selectedWrapperId);

  // select variable to edit or delete it:
  $wrapper.off().on('click', '.variableContentMeta', function () {
    //  VARIABLE_CONTENT_SEL
    $('.selectedTextVariable').removeClass('selectedTextVariable');
    $('body').addClass('joditSelectedTextVariable');

    const $thisSelectedTextVariable = $(this).parent();
    $thisSelectedTextVariable.addClass('selectedTextVariable');
    TOGO.Components.$selectedTextVariable = $thisSelectedTextVariable;

    $wrapper
      .find('.jodit-toolbar-button_textVariableButtonList')
      .addClass('indicateVariableSelection');

    let $tempContentSpacer = $wrapper.find('.tempContentSpacer');
    if ($tempContentSpacer.length) {
      let $tempContentSpacerContent = $tempContentSpacer.children();
      if ($tempContentSpacerContent.length) {
        $tempContentSpacer.after($tempContentSpacerContent);
      }
      $tempContentSpacer.remove();
    }

    const $prepareSelectedVariableButton = $wrapper.find(
      '.jodit-toolbar-button_prepareSelectedVariableAsButton > .jodit-toolbar-button__button'
    );
    $prepareSelectedVariableButton.click();
  });

  $wrapper.on('click', '.jodit-wysiwyg', function (event) {
    let $clickedElement = $(event.target);
    if ($clickedElement.is('.variableContentMeta')) {
      return false;
    }
    TOGO.Components.$selectedTextVariable = null;
    $('.selectedTextVariable').removeClass('selectedTextVariable');
    $('body').removeClass('joditSelectedTextVariable');
    $wrapper
      .find('.jodit-toolbar-button_textVariableButtonList')
      .removeClass('indicateVariableSelection');

    let $tempContentSpacer = $wrapper.find('.tempContentSpacer');
    if ($tempContentSpacer.length) {
      let $tempContentSpacerContent = $tempContentSpacer.children();
      if ($tempContentSpacerContent.length) {
        $tempContentSpacer.after($tempContentSpacerContent);
      }
      $tempContentSpacer.remove();
    }
  });
}

export function textVariablesOnChangeText($newTextValue) {
  $('.selectedTextVariable').removeClass('selectedTextVariable');
  $('.indicateVariableSelection').removeClass('indicateVariableSelection');

  let $variableContentSpacer = $newTextValue.find('.variableContentSpacer');
  if ($variableContentSpacer.length) {
    if ($variableContentSpacer.text().trim().length) {
      $variableContentSpacer.removeClass('variableContentSpacer');
    } else if ($variableContentSpacer.is(':last-child')) {
      $variableContentSpacer.remove();
    }
  }
}
