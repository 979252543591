import React, { useState, useEffect  } from 'react';
import {
  IconButton,

  Typography,
  TextField,
  Button
  
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PaginationTableDefinition from './PaginationTableDefinition';
import ajaxGet from 'src/services/ajaxGet';
import theme, { usePimStyles } from '../../../PimTogoTheme';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CreateTableDefinition from './CreateTableDefinition';
import EnhancedTableDefinition from './EnhancedTableDefinition';
import i18next from 'i18next';
import {MenuItem,Tooltip} from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';



const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));


const useStyles_tab = makeStyles((theme) => ({
	root: {
		'& .MuiTableCell-head': {
			backgroundColor: '#a9a9a975 !important',
			fontSize: '1.5rem !important',
			fontWeight: 'bold !important'
		},
		'& .MuiTableCell-body': {
			fontSize: '1.5rem'
		},
		width: '100%',
		'& .MuiSvgIcon-root': { fontSize: '3rem !important' }
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750,
		'& .MuiTableCell-root': {
			borderLeft: '1px solid rgba(224, 224, 224, 1)'
		}
	},
	deleteBtn: {
		color: 'rgb(237 14 14 / 89%) !important'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}));


const  cell_1 = {
  content: "1_0",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:1,
  column:0
}
const  cell_2 = {
  content: "1_1",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:1,
  column:1
}
const  cell_3 = {
  content: "2_0",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:2,
  column:0

}
const  cell_4 = {
  content: "2_1",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:2,
  column:1
}
const  cell_1_ = {
  content: "3_0",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:3,
  column:0
}
const  cell_2_ = {
  content: "3_1",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:3,
  column:1
}
const  cell_3_ = {
  content: "4_0",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:4,
  column:0
}
const  cell_4_ = {
  content: "4_1",
  width: 100,
  height: 50,
  paraStyle: "P2",
  charStyle: "C1",
  colour: "none",
  borderStyle: "B3",
  verticalAlignment: "middle",
  horizontalAlignment: "left",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  colspan: 1,
  rowspan: 1,
  row:4,
  column:1
}

const INITIAL_FORM_STATE_TABLE_DEFINITION = {
      id:'',
      tableDefinitionName: '',
      size:'',
      unit:'',
      alignment:'',
      order:''
};

const INIT_TABLE_CONTENT  = {
  columns:['Column 1', 'Column 2'],
  rows:[
    { id: 0, data: [cell_1,cell_2],},
    { id: 1, data: [cell_3,cell_4]},
    { id: 2, data: [cell_1_,cell_2_],},
    { id: 3, data: [cell_3_,cell_4_]},
]
}

export const PANELTYPE = 'TableDefinition';
export const TABLE_DEF_I18KEY = 'dashboard.tableDef.namePanel';

const LIST_TYPES = {
  FAVORITES: 'favorites',
  RECENTS: 'recents'
};

export function getTableDefinitionLabel({panelItem, togoData, listType}) {
  if (!listType) {
    listType = panelItem.configuration.selectedListType;
    if (!listType) return PANELTYPE;
  }

  if (listType == LIST_TYPES.FAVORITES) {
    return i18next.t('dashboard.tableDef.favorites');
  }
  if (listType == LIST_TYPES.RECENTS) {
    return i18next.t('dashboard.tableDef.recents');
  }

  return listType;
}

export function TableDefinitionPanelConfig({
  triggerConfigSave,
  saveConfig,
  togoData,
  panelItem,
  setPanelTitle
}) {
  const { t } = useTranslation();

  const [selectedListType, setSelectedListType] = useState('');

  useEffect(() => {
    setSelectedListType(panelItem.configuration.selectedListType || '');
  }, []);

  useEffect(() => {
    if (triggerConfigSave) {
      saveListType();
    }
  }, [triggerConfigSave]);

  function saveListType() {
    const changedPanel = {
      ...panelItem,
      configuration: {
        ...panelItem.configuration,
        selectedListType: selectedListType
      }
    };
    saveConfig({ changedPanel });

    const title = getTableDefinitionLabel({panelItem, togoData, listType: selectedListType });
    setPanelTitle(title);
  }

  function changeListType(event) {
    const newListType = event.target.value;
    setSelectedListType(newListType);
  }

  return (
    <TextField
      id="tableDefConfigSelect"
      select
      variant="outlined"
      fullWidth={true}
      label={t('common.type')}
      value={selectedListType}
      onChange={changeListType}
    >
      {Object.values(LIST_TYPES).map((listType) => (
        <MenuItem key={listType} value={listType}>
           {getTableDefinitionLabel({ panelItem, togoData, listType })}
        </MenuItem>
      ))}
    </TextField>
  );
}

const TYPE_LABEL_CODE = {
  Product: 'data.product',
  ProductTypeDefinition: 'data.ptd',
  ProductStructureNode: 'data.productStructure',
  Catalog: 'data.catalog',
  Shop: 'data.shop',
};


export  function TableDefinitionPanelContent({ panelItem, contentSize }) {
  const classesPim = usePimStyles();
  const { t } = useTranslation();
  const classes = useStyles();
  const classes_tab = useStyles_tab();


  

  const size = contentSize - 37 || null;

  const configuration = panelItem.configuration;
  let timeStampLocale = 'en-GB';
  const userLang = document.documentElement.lang || 'en';
  switch (userLang) {
    case 'de':
      timeStampLocale = 'de-DE';
      break;
    case 'fr':
      timeStampLocale = 'fr-FR';
      break;
    case 'it':
      timeStampLocale = 'it-IT';
      break;
  }
  const [searchExpression, setSearchExpression] = useState('');
  const [tableDefinitions, setTableDefinitions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(5);
  const [paginationstate, setPaginationstate] = useState({});
  const [openDialogTableDefinition, setOpenDialogTableDefinition] = useState(false);
  const [toCreateTableDefinition, setToCreateTableDefinition] = useState(true);
  // const [contentLangSelections, setContentLangSelections] = useState({});
 // const [columns, setColumns] = useState(['Spalte 1', 'Spalte 2']);



const [tableContent, setTableContent] = useState({});
  const [initFormTableDefinitionState, setInitFormTableDefinitionState] = useState(
    INITIAL_FORM_STATE_TABLE_DEFINITION
  );  
  const handelEventSearchTableDefinition = () => {
    setCurrentPage(1);
    setElementsPerPage(5);
    searchTableDefinitions();
  };

  const searchTableDefinitions = (showFirstUserPage) => {

    let targetPage = currentPage;
    if (showFirstUserPage) {
      targetPage = 1;
    }

   ajaxGet('tableDefinitionSearch', {
      searchExpression: searchExpression,
      currentPage: targetPage,
      elementsPerPage: elementsPerPage,
      language:	t('language.code')

    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setTableDefinitions(response.tableDefinitionList);
        setPaginationstate(response.pagination);
      }
    });
  };

  useEffect(() => {
    searchTableDefinitions();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickOpenDialogTableDefinition = () => {
    setOpenDialogTableDefinition(true);
  };


  const addTabDefHandel = () => {
    setOpenDialogTableDefinition(true);
    const TableDefinitionForm = {
      id:'',
      name: '',
      size:'',
      unit:'',
      alignment:'',
      order:''
    };

    setInitFormTableDefinitionState(TableDefinitionForm);
  };

  const handleCloseDialogTableDefinition = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
      setOpenDialogTableDefinition(false);
      setToCreateTableDefinition(true);
      setInitFormTableDefinitionState(INITIAL_FORM_STATE_TABLE_DEFINITION);
      setTableContent(INIT_TABLE_CONTENT);
    
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCurrentPage(1);
      setElementsPerPage(50);
      searchTableDefinitions();
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid className={classes.root}>
          <form>
            <TextField
              label={t('manageOrganization.organization.search')}
              margin="normal"
              id="user-name-search"
              fullWidth
              value={searchExpression}
              onChange={handleChange}
              onKeyDown={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handelEventSearchTableDefinition()}
                    position="start"
                  >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Grid>
        <Grid className={classes.root}>
          {tableDefinitions.length != 0 &&   (
            <PaginationTableDefinition
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
            />
          )}
        </Grid>
        {'   '}
        <Grid className={classes.root}>
        <Tooltip title={t('dashboard.tableDef.createTableDef')}>
        <IconButton aria-label="add" size='medium'  variant="outlined"
            onClick={() => {
              setToCreateTableDefinition(true);
              setTableContent(INIT_TABLE_CONTENT)
              addTabDefHandel();
            }}>
        <PostAddIcon/>
      </IconButton>
        </Tooltip>
          <CreateTableDefinition
            openDialogTableDefinition={openDialogTableDefinition}
            handleCloseDialogTableDefinition={handleCloseDialogTableDefinition}
            initFormTableDefinitionState={initFormTableDefinitionState}
             initContentTable={INIT_TABLE_CONTENT} 
            toCreateTableDefinition={toCreateTableDefinition}
            searchTableDefinitions={searchTableDefinitions}
            setTableContent={setTableContent}
            tableContent={tableContent}
            // contentLangSelections={contentLangSelections}
          />

          <EnhancedTableDefinition
            tableDefinitions={tableDefinitions}
            setInitFormTableDefinitionState={setInitFormTableDefinitionState}
            setTableDefinitions={setTableDefinitions}
            setToCreateTableDefinition={setToCreateTableDefinition}
            handleClickOpenDialogTableDefinition={handleClickOpenDialogTableDefinition}
            searchTableDefinitions={searchTableDefinitions}
            setTableContent={setTableContent}
            tableContent={tableContent}
            classes_tab={classes_tab}
            // setContentLangSelections={setContentLangSelections}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
}
