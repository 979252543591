import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';
import { useTranslation } from 'react-i18next';



function EnhancedTableHead(props) {

  const { t } = useTranslation();

const headCells = [
  {
    id: 'Aktionen',
    numeric: false,
    disablePadding: false,
    label: t('manageOrganization.organization.actions')
  },
  {
    id: 'Benutzername',
    numeric: false,
    disablePadding: false,
    label:  t('managUsers.register.username')
  },
  { id: 'Vorname', numeric: false, disablePadding: false, label: t('managUsers.register.firstName') },

  { id: 'Name', numeric: false, disablePadding: false, label:  t('managUsers.register.lastName') },
  {
    id: 'Organization',
    numeric: false,
    disablePadding: false,
    label: t('managUsers.register.organization')
  },
  { id: 'EMail', numeric: false, disablePadding: false, label: t('managUsers.register.email') }
];

const headCellsOrg = [
  {
    id: 'Aktionen',
    numeric: false,
    disablePadding: false,
    label: t('manageOrganization.organization.actions')
  },
  {
    id: 'company',
    numeric: false,
    disablePadding: false,
    label:  t('manageOrganization.organization.name') 
  },
  { id: 'email', numeric: false, disablePadding: false, label:  t('manageOrganization.organization.email')  },
  { id: 'city', numeric: false, disablePadding: false, label:  t('manageOrganization.organization.city')  },
  { id: 'country', numeric: false, disablePadding: false, label: t('manageOrganization.organization.country') }
];
  return (
    <>
      {props.isOrganization ? (
        <TableHead>
          <TableRow>
            {headCellsOrg.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                // sortDirection={orderBy === headCell.id ? order : false}
              >
                {/* <TableSortLabel
			  active={orderBy === headCell.id}
			  direction={orderBy === headCell.id ? order : 'asc'}
				onClick={createSortHandler(headCell.id)}
			>
			  {orderBy === headCell.id ? (
				<Box component="span" className={classes.visuallyHidden}>
				  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
				</Box>
			  ) : null}
			</TableSortLabel> */}
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      ) : (
        <TableHead>
          <TableRow>
            {headCells.map((headCell, index) => (
              <TableCell
                key={index}
                align={headCell.numeric ? 'right' : 'left'}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head': {
      backgroundColor: '#a9a9a975',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    '& .MuiTableCell-body': {
      fontSize: '1.5rem'
    },
    width: '100%',
    '& .MuiSvgIcon-root': { fontSize: '3rem !important' }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  deleteBtn: {
    color: 'rgb(237 14 14 / 89%) !important'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));
//setOpenDialogUser(true);

export default function EnhancedTable({
  rows,
  isOrganization,
  handleClickOpen,
  setIsLocked,
  setInitFormState,
  setToCreateOrga,
  setOrganizations,
  searchOrganizations,
  setUsers,
  searchUsers,
  setOrgaList,
  setInitFormUserState,
  setRoleInfos,
  setArticleDataZones,
  setToCreateUser,
  handleClickOpenDialogUser,
  editSubscribableClassifications
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('company');
  const { t } = useTranslation();

  const confirmDeleteOrganization = (id) => {
    TOGO.popupManager.confirmZebraDialog(
       t('manageOrganization.organization.deleteButtonConfirm'),
      deleteOrganization,
      id
    );
  };

  const confirmDeleteUser = (id) => {
    TOGO.popupManager.confirmZebraDialog(
      t('managUsers.register.deleteButtonConfirm'),
      deleteUser,
      id
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const deleteOrganization = (id) => {
    var pojo = new Object();
    pojo.orgaId = id;
    ajaxPost('organizationManagement/delete', pojo).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setOrganizations(rows.filter((org) => org.id !== id));
        TOGO.Util.notifyResponse(ajaxData.response.message, false);
        searchOrganizations();
      }
    });
  };

  const editOrganization = (id) => {
    ajaxGet('organizationManagement/edit', {
      orgaId: id
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        var res = ajaxData.response.organizationForm;
        if (res != null && res != undefined) {
          var orgaform = {
            id: id,
            company: res?.company || '',
            companyAffix: res?.companyAffix || '',
            taxnumber: res?.taxnumber || '',
            pdfNameBuilder: res?.pdfNameBuilder || '',
            department: res?.department || '',
            street: res?.street || '',
            streetNumber: res?.streetNumber || '',
            postalCode: res?.postalCode || '',
            city: res?.city || '',
            country: res?.country || '',
            phone: res?.phone || '',
            fax: res?.fax || '',
            email: res?.email || '',
            website: res?.website || '',
            organisationState: res?.organisationState || ''
          };
          setInitFormState(orgaform);
          setToCreateOrga(false);
          setIsLocked(ajaxData.response.locked);
          handleClickOpen();
        }
      }
    });
  };

  const getUserInfo = (id, bool) => {
    ajaxGet('listusers/edit_', {
      userToEditId: id
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {

        var res = ajaxData.response.manageUserForm;
        if (res != null && res != undefined) {
          var userform = {
            id: id,
            username: res?.username || '',
            password: '',
            passwordRepeat: '',
            userLocale: res?.userLocale || '',
            userTimezone: res?.userTimezone || '',
            salutation: res?.salutation || '',
            title: res?.title || '',
            company: res?.company || '',
            department: res?.department || '',
            firstName: res?.firstName || '',
            lastName: res?.lastName || '',
            street: res?.street || '',
            streetNumber: res?.streetNumber || '',
            postalCode: res?.postalCode || '',
            city: res?.city || '',
            country: res?.country || '',
            phone: res?.phone || '',
            mobile: res?.mobile || '',
            fax: res?.fax || '',
            email: res?.email || '',
            website: res?.website || '',
            organization: res?.organization || '',
            permissions: res.permissions || [],
            userDataZones: res.userDataZones || [],
            userDataZonesDefault: typeof res.userDataZonesDefault == "boolean" ? res.userDataZonesDefault : true,
          };
          setInitFormUserState(userform);
          setToCreateUser(bool);
          setOrgaList(ajaxData.response.organizationList);
          setRoleInfos(ajaxData.response.roleInfos);

          const dataZones  = ajaxData.response.articleDataZones;
          if (dataZones != null && dataZones.length != 0) {
            dataZones.sort((a, b) =>
              a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
            );            
            setArticleDataZones(dataZones);
          }

          handleClickOpenDialogUser();
        }
      }
    });
  };
  const editUser = (id) => {
    getUserInfo(id, false);
  };

  const copieUser = (id) => {
    getUserInfo(id, true);
  };
  const deleteUser = (id) => {
    var pojo = new Object();
    pojo.id = id;
    ajaxPost('listusers/delete_', {}, pojo).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setUsers(rows.filter((user) => user.id !== id));
        TOGO.Util.notifyResponse(ajaxData.response.message, false);
        searchUsers();
      }
    });
  };

  return (
    <div className={classes.root}>
      {/* <ConfirmDialog
	 handelCloseConfirmDialog={handelCloseConfirmDialog} openConfirmDialog={openConfirmDialog} deleteOrganization={deleteOrganization}/> */}
      {rows.length > 0 ? (
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="simple table"
            >
              <EnhancedTableHead
                classes={classes}
                isOrganization={isOrganization}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {!isOrganization ? (
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{t('managUsers.register.editTitle')}</Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                editUser(row.id);
                              }}
                            >
                              <EditIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography fontSize={5}>
                                {t('managUsers.register.copieUser')}
                              </Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                copieUser(row.id);
                              }}
                            >
                              <FileCopyIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{t('managUsers.register.deleteButton')}
                              </Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                confirmDeleteUser(row.id);
                              }}
                            >
                              <DeleteIcon
                                fontSize="large"
                                className={classes.deleteBtn}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">
                          {row.firstname}
                        </TableCell>
                        <TableCell align="left">
                          {row.surname}
                        </TableCell>
                        <TableCell align="left">
                          {row.organization}
                        </TableCell>
                        <TableCell align="left">
                          {row.email}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{t('common.edit')}</Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                editOrganization(row.id);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography fontSize={5}>
                                {t('manageOrganization.subscribableOrgaClassifications')}
                              </Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                editSubscribableClassifications(row.id);
                              }}
                            >
                              <PlaylistAddCheckIcon />
                            </IconButton>
                          </Tooltip>                          
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{t('common.delete')}</Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                confirmDeleteOrganization(row.id);
                              }}
                            >
                              <DeleteIcon className={classes.deleteBtn} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {/* <TableCell align="left">{row.organizationForm.usename}</TableCell> */}
                        <TableCell align="left">
                          {row.organizationForm.company}
                        </TableCell>
                        <TableCell align="left">
                          {row.organizationForm.email}
                        </TableCell>
                        <TableCell align="left">
                          {row.organizationForm.city}
                        </TableCell>
                        <TableCell align="left">
                          {row.organizationForm.country}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>{' '}
        </Paper>
      ) : (
        <Typography
          paragraph={true}
          noWrap={false}
          fontWeight={'bold'}
          align="center"
          fontSize={20}
        >
          {t('common.noSearchResults')}
        </Typography>
      )}
    </div>
  );
}

