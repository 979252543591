import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import theme from '../PimTogoTheme';

import Organizations from './Organizations';
import SubscribableClassifications from './SubscribableClassifications';

const useStyles = makeStyles(() => ({
  tab: {
    color: 'black !important'
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
      height: 5
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      opacity: 1,
      fontWeight: 'bold'
    }
  }
}));

export default function OrganizationManagement() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  // forceResizing needed for: MuiTabs-indicator positioning issue https://github.com/mui/material-ui/issues/9337
  const [forceResizing, setForceResizing] = useState(1);

  const manageGlobal =
    $('#react-OrganizationManagement').attr('data-globalmanagement') == 'true';

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [forceResizing]);

  const changeTab = (_, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <ThemeProvider theme={theme}>
      {manageGlobal ? (
        <Box>
          <Tabs
            value={tabIndex}
            onChange={changeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.tabs}
          >
            <Tab
              label={t('manageOrganization.overview.organizations')}
              {...a11yProps(0)}
              className={classes.tab}
            />
            <Tab
              label={t(
                'manageOrganization.overview.subscribableClassifications'
              )}
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Tabs>
          <TabPanel curTabIndex={tabIndex} tabPanelIndex={0}>
            <Organizations manageGlobal={manageGlobal} setForceResizing={setForceResizing} />
          </TabPanel>
          <TabPanel curTabIndex={tabIndex} tabPanelIndex={1}>
            <SubscribableClassifications setForceResizing={setForceResizing} />
          </TabPanel>
        </Box>
      ) : (
        <Organizations manageGlobal={manageGlobal} />
      )}
    </ThemeProvider>
  );
}

function TabPanel(props) {
  const { children, curTabIndex, tabPanelIndex, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={curTabIndex !== tabPanelIndex}
      id={`manage-organization-tabpanel-${tabPanelIndex}`}
      aria-labelledby={`manage-organization-tab-${tabPanelIndex}`}
      {...other}
    >
      {curTabIndex === tabPanelIndex && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  curTabIndex: PropTypes.any.isRequired,
  tabPanelIndex: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `manage-organization-tab-${index}`,
    'aria-controls': `manage-organization-tabpanel-${index}`
  };
}
