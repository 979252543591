import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import TableDefinitionDashboard from './TableDefinitionDashboard';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}



const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
      height: 5,
      top: 43
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      opacity: 1,
      fontWeight: 'bold'
    }
  },
  tabsNoShadow: {
    boxShadow:
      '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important'
  }
});

export default function  DataSheetTabs  ()  {
  const classes = useStyles();

  useEffect(() => {
    let contentLang = $('#contentLangSelectElement').val() || null;
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab
          label="Table-Definition"
          {...a11yProps(0)}
          style={{ color: 'black' }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
       <TableDefinitionDashboard></TableDefinitionDashboard>
      </TabPanel>
    </Paper>
  );
}


