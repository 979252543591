import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

const data = [
  {
    "Interne User": "user1@example.com",
    "Kundenadressen": "customer1@example.com",
    "Export": "user1",
    "Export-Format": "BMEcat",
    "Export-Rhythmus": "daily"
  },
  {
    "Interne User": "user2@example.com",
    "Kundenadressen": "customer2@example.com",
    "Export": "user2",
    "Export-Format": "XLSX",
    "Export-Rhythmus": "weekly"
  },
  {
    "Interne User": "user3@example.com",
    "Kundenadressen": "customer3@example.com",
    "Export": "user3",
    "Export-Format": "PDF",
    "Export-Rhythmus": "monthly"
  },
  {
    "Interne User": "user4@example.com",
    "Kundenadressen": "customer4@example.com",
    "Export": "user4",
    "Export-Format": "BMEcat",
    "Export-Rhythmus": "one time"
  },
  {
    "Interne User": "user5@example.com",
    "Kundenadressen": "customer5@example.com",
    "Export": "user5",
    "Export-Format": "XLSX",
    "Export-Rhythmus": "yearly"
  },
  {
    "Interne User": "user6@example.com",
    "Kundenadressen": "customer6@example.com",
    "Export": "user6",
    "Export-Format": "PDF",
    "Export-Rhythmus": "daily"
  },
  {
    "Interne User": "user7@example.com",
    "Kundenadressen": "customer7@example.com",
    "Export": "user7",
    "Export-Format": "BMEcat",
    "Export-Rhythmus": "weekly"
  },
  {
    "Interne User": "user8@example.com",
    "Kundenadressen": "customer8@example.com",
    "Export": "user8",
    "Export-Format": "XLSX",
    "Export-Rhythmus": "monthly"
  },
  {
    "Interne User": "user9@example.com",
    "Kundenadressen": "customer9@example.com",
    "Export": "user9",
    "Export-Format": "PDF",
    "Export-Rhythmus": "yearly"
  },
  {
    "Interne User": "user10@example.com",
    "Kundenadressen": "customer10@example.com",
    "Export": "user10",
    "Export-Format": "BMEcat",
    "Export-Rhythmus": "one time"
  }
];


function ExportTable({
    panelItem,
    columns,
    rows,
    contentSize,
    listEdit,
    CreateRowDialog,
    saveCreateRow,
    EditRowDialog,
    deleteRow
  }) {


  return (
    <TableContainer component={Paper}>
      <Table aria-label="export data table">
        <TableHead>
          <TableRow>
            <TableCell  sx={{ fontWeight: 'bold', fontSize:'14px' }}>Interne User</TableCell>
            <TableCell  sx={{ fontWeight: 'bold',fontSize:'14px' }}>Kundenadressen</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize:'14px' }}>Export</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize:'14px' }}>Export-Format</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize:'14px' }}>Export-Rhythmus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row["Interne User"]}</TableCell>
              <TableCell>{row["Kundenadressen"]}</TableCell>
              <TableCell>{row["Export"]}</TableCell>
              <TableCell>{row["Export-Format"]}</TableCell>
              <TableCell>{row["Export-Rhythmus"]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ExportTable;
