import React, { useState, useRef, useEffect } from 'react';

import { Jodit } from 'jodit';
import { useTranslation } from 'react-i18next';

import { useDataManagement } from '../DataManagementContext';
import { getFeatureKey } from '../ArticleFeatureEditor';
import {
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  ImageList,
  ImageListItem,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip
} from '@material-ui/core';

import ajaxGet from 'src/services/ajaxGet';
import { Pagination } from '@material-ui/lab';

const ANCHOR_TAG = 'a';

const TAG_ATTR = {
  CLASS_NAME: 'data-class-name',
  MEDIUM_ID: 'data-medium-id',
  MEDIUM_TYPE: 'data-medium-type',
  MEDIUM_FILENAME: 'data-filename',
  SRC: 'src',
  TITLE: 'title'
};
const ATTR_CLASSNAME_VALUE = 'Medium';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginBottom: '2rem'
  },
  formControl: {
    marginRight: '2rem',
    minWidth: 120
  },
  imageListRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  imageList: {
    width: '92rem',
    height: '37rem'
  },
  imageListItem: {
    '& > .MuiImageListItem-item': {
      overflow: 'visible !important'
    }
  },
  imageListCard: {
    overflow: 'visible !important'
  },
  media: {
    height: 180
  },

  foundImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: 'unset',
    height: 'unset',
    transform: 'unset'
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
}));

export const MediaSelector = ({
  joditConfig,
  setConfig,
  textType,
  instanceEditor
}) => {
  const { t } = useTranslation();
  const { getVariableContentDefs } = useDataManagement();
  const classes = useStyles();

  const anchoredMediumInfoRef = useRef(null);
  const [anchoredMediumEvent, setAnchoredMediumEvent] = useState(false);

  const [foundImages, setFoundImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const elementsPerPage = 10;

  const textEditorData = instanceEditor.textEditor[textType];
  const parentClass = textEditorData?.parentObjectClass;
  const variableContentDefs = getVariableContentDefs(parentClass);
  const firstMediumType = variableContentDefs?.mediumDefs?.[0].value;
  const [mediumType, setMediumType] = useState(firstMediumType || '');

  let headerTitle = t('instanceEditor.anchoredMedia.title') + ' ';
  if (anchoredMediumInfoRef?.current?.mediumId) {
    headerTitle += t('instanceEditor.anchoredMedia.buttonEdit');
  } else {
    headerTitle += t('instanceEditor.anchoredMedia.buttonAdd');
  }

  useEffect(() => {
    configJoditForAnchoredMedia();
  }, []);

  useEffect(() => {
    if (!mediumType) {
      return;
    }

    ajaxGet('dataManagement/reactSearchLinkedInstances', {
      linkedInstanceType: mediumType,
      containerInstanceId: instanceEditor.instanceId,
      containerInstanceClassName: instanceEditor.instanceType,
      linkedInstanceCategory: 'Medium',
      contentLanguage: instanceEditor.contentLang,
      searchTerm: searchTerm,
      currentPage: currentPage,
      elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        const resultImages = response.resultDisplayItems.filter(
          (result) => !result.error
        );
        setFoundImages(resultImages);
        setTotalPages(response.pagination.countPages);
      }
    });
  }, [mediumType, searchTerm, currentPage]);

  function configJoditForAnchoredMedia() {
    const addAnchoredMediaButtonName = 'addAnchoredMedia';
    const addAnchoredMediaButton = joditConfig.buttons.find(
      (button) =>
        typeof button === 'object' && button.name == addAnchoredMediaButtonName
    );
    var addAnchoredMediaButtonIndex = joditConfig.buttons.indexOf(
      addAnchoredMediaButton
    );
    if (addAnchoredMediaButtonIndex !== -1) {
      joditConfig.buttons.splice(addAnchoredMediaButtonIndex, 1);
    }

    joditConfig.buttons.push({
      name: addAnchoredMediaButtonName,
      text:
        t('instanceEditor.anchoredMedia.title') +
        ' ' +
        t('instanceEditor.anchoredMedia.buttonAdd'),
      exec: addAnchoredMedium
    });

    const insertMediumButtonName = 'insertMediumButton';
    const insertMediumButton = joditConfig.buttons.find(
      (button) =>
        typeof button === 'object' && button.name == insertMediumButtonName
    );
    var insertMediumButtonIndex = joditConfig.buttons.indexOf(
      insertMediumButton
    );
    if (insertMediumButtonIndex !== -1) {
      joditConfig.buttons.splice(insertMediumButtonIndex, 1);
    }
    joditConfig.buttons.push({
      name: insertMediumButtonName,
      text: 'insert Medium Button',
      exec: insertMedium
    });

    function insertMedium(editor) {
      if (!anchoredMediumInfoRef?.current) {
        return;
      }
      const $selectedAnchoredMedium = TOGO.Components.$selectedAnchoredMedium;
      const anchoredMediumItem = anchoredMediumInfoRef.current.imageItem;
      const mediumType = anchoredMediumInfoRef.current.mediumType;

      const $createAnchoredMedium = $('<img/>');
      $createAnchoredMedium.attr(TAG_ATTR.CLASS_NAME, ATTR_CLASSNAME_VALUE);
      $createAnchoredMedium.attr(TAG_ATTR.MEDIUM_ID, anchoredMediumItem.id);
      $createAnchoredMedium.attr(TAG_ATTR.MEDIUM_TYPE, mediumType);
      $createAnchoredMedium.attr(
        TAG_ATTR.MEDIUM_FILENAME,
        anchoredMediumItem.title
      );
      $createAnchoredMedium.attr(TAG_ATTR.TITLE, anchoredMediumItem.title);
      $createAnchoredMedium.attr(
        TAG_ATTR.SRC,
        anchoredMediumItem.itemPicture.thumbnailUri
      );

      const $wrapVariable = $('<span></span>');
      $wrapVariable.append($createAnchoredMedium);

      if ($selectedAnchoredMedium) {
        $selectedAnchoredMedium.remove();
      }

      let anchoredMediumString = $wrapVariable.html();
      editor.s.insertHTML(anchoredMediumString);

      $('.indicateVariableSelection').removeClass('indicateVariableSelection');
    }

    function addAnchoredMedium(editor) {
      TOGO.popupManager.joditDialogOpen = true;
      const dialog = new Jodit.modules.Dialog();
      const $dialogContent = $('<div></div>');
      const contentAsHtml = editor.create.fromHTML($dialogContent.html());

      dialog.setHeader(t('instanceEditor.anchoredMedia.buttonAdd'));
      dialog.setContent(contentAsHtml);
      dialog.destroyAfterClose = true;
      dialog.open();

      anchoredMediumInfoRef.current = {
        mediumType: null
      };

      setAnchoredMediumEvent(true);

      dialog.close();
    }

    setConfig(joditConfig);
  }

  function closeSelector() {
    setAnchoredMediumEvent(false);
  }

  function exitDialog() {
    if (anchoredMediumInfoRef.current.imageItem != null) {
      const $insertMediumButton = $(
        '.textEditor.editMode .jodit-toolbar-button_insertMediumButton > .jodit-toolbar-button__button'
      );
      $insertMediumButton.click();
    }

    TOGO.popupManager.joditDialogOpen = false;
  }

  function selectMedium(imageItem) {
    anchoredMediumInfoRef.current = { mediumType: mediumType, imageItem };

    setAnchoredMediumEvent(false);
  }

  function changeMediumType(event) {
    const newMediumType = event.target.value;
    setMediumType(newMediumType);
    anchoredMediumInfoRef.current = { mediumType: newMediumType };

    setCurrentPage(1);
  }

  function changePage(_, newPage) {
    setCurrentPage(newPage);
  }

  function changeSearchTerm(event) {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  }

  if (!variableContentDefs) return null;

  return (
    <Dialog
      fullWidth={false}
      maxWidth={false}
      open={Boolean(anchoredMediumEvent)}
      onClose={closeSelector}
      aria-labelledby="anchoredMediumSelectorTitle"
      TransitionProps={{ onExited: exitDialog }}
    >
      <DialogTitle id="anchoredMediumSelectorTitle">{headerTitle}</DialogTitle>
      <DialogContent>
        <div className={classes.searchInput}>
          <TextField
            className={classes.formControl}
            variant="outlined"
            value={mediumType}
            onChange={changeMediumType}
            select
            label={t('instanceEditor.anchoredMedia.mediumType')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fa fa-folder-open-o inputIconVisible" />
                </InputAdornment>
              )
            }}
          >
            {variableContentDefs.mediumDefs.map((mediumDef) => (
              <MenuItem value={mediumDef.value} key={mediumDef.value}>
                {mediumDef.contentLabel?.text || mediumDef.value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="anchoredMediaTextSearch"
            label={t('instanceEditor.anchoredMedia.searchPlaceholder')}
            value={searchTerm}
            onChange={changeSearchTerm}
            variant="outlined"
            disabled={!mediumType}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fa fa-search inputIconVisible" />
                </InputAdornment>
              )
            }}
          />
        </div>
        <ImageList rowHeight={180} cols={5} className={classes.imageList}>
          {foundImages.map((imageItem) => (
            <ImageListItem
              className={classes.imageListItem}
              key={imageItem.id}
              onClick={() => {
                selectMedium(imageItem);
              }}
            >
              <Tooltip title={imageItem.title}>
                <Card variant="outlined" className={classes.imageListCard}>
                  <CardActionArea className={classes.media}>
                    {/* {imageItem.itemPicture.isMissingFile ? (
                      <span className="fa-stack fa-lg">
                        <i className="fa fa-camera fa-stack-1x"></i>
                        <i className="fa fa-ban fa-stack-2x text-danger"></i>
                      </span>
                    ) : (
                      <img
                        className={classes.foundImage}
                        src={imageItem.itemPicture.sourcePNGUri}
                        alt={imageItem.title}
                      />
                    )} */}
                    <img
                      className={classes.foundImage}
                      src={imageItem.itemPicture.sourcePNGUri}
                      alt={imageItem.title}
                    />
                  </CardActionArea>
                </Card>
              </Tooltip>
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Pagination
          disabled={totalPages == 1}
          count={totalPages}
          page={currentPage}
          onChange={changePage}
          color="primary"
          variant="outlined"
        />
        <Button onClick={closeSelector} color="primary">
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function useResolveAnchoredMedia({ textType, instanceEditor, text }) {
  const {
    getCustomFieldState,
    getFeatureState,
    getVariableContentDefs,
    getFeatureDefs
  } = useDataManagement();

  let textHasVariables = false;
  let $textResolvedVariables = $('');
  if (text) {
    $textResolvedVariables = $(text);
  }
  let $textVariables = $textResolvedVariables.find(ANCHOR_TAG);
  if (!$textVariables.length) {
    return { $text: $textResolvedVariables, textHasVariables };
  }

  const containerId = instanceEditor.instanceId;
  const textEditorData = instanceEditor.textEditor[textType];

  const parentClass = textEditorData?.parentObjectClass;
  const isPtdDefaultDataForArticle = containerId.endsWith(
    'PtdDefaultDataForArticle'
  );
  const variableContentDefs = getVariableContentDefs(parentClass);
  const customFieldDefs = variableContentDefs?.customFieldDefs;
  const featureDefs =
    parentClass === 'Article' || isPtdDefaultDataForArticle
      ? getFeatureDefs()
      : null;

  $textVariables.each(function () {
    textHasVariables = true;
    let $textVariable = $(this);
    let variableContentString = null;
    let customFieldType = $textVariable.attr(TAG_ATTR.CUSTOM_FIELD);
    let featureId = $textVariable.attr(TAG_ATTR.FEATURE);
    let featureIdentifier = null;
    if (featureId) {
      featureIdentifier = {
        classificationIdentifier: $textVariable.attr(
          TAG_ATTR.CLASSIFICATION_SYSTEM
        ),
        classIdentifier: $textVariable.attr(TAG_ATTR.CLASSIFICATION),
        featureIdentifier: featureId
      };
    }

    if ($textVariable.is(ANCHOR_TAG)) {
      let variableValue = null;

      if (customFieldType) {
        variableValue = getCustomFieldState(containerId, customFieldType);
      } else if (featureId) {
        const featureKey = getFeatureKey(featureIdentifier);
        variableValue = getFeatureState(containerId, featureKey);
      }

      variableContentString = getVariableValueforText(variableValue);
    } else if ($textVariable.is(ANCHOR_TAG.blub)) {
      let targetDef = null;
      if (customFieldType && customFieldDefs) {
        targetDef = customFieldDefs.find(
          (customFieldDef) => customFieldDef.value === customFieldType
        );
      } else if (featureId && featureDefs) {
        targetDef = featureDefs.find(
          (featureDef) => featureDef.featureIdentifier === featureId
        );
      }
      if ($textVariable.is(ANCHOR_TAG.blub)) {
        if (customFieldType && targetDef) {
          variableContentString = targetDef.label;
        } else if (featureId && targetDef) {
          variableContentString = targetDef.featureHeader.text;
        }
      } else if ($textVariable.is(ANCHOR_TAG.blun)) {
        if (customFieldType && targetDef) {
          variableContentString = ''; // Units not yet implemented in custom fields
        } else if (featureId && targetDef) {
          variableContentString = targetDef.featureUnit;
        }
      }
    }
    if (variableContentString) {
      $textVariable.attr('data-resolvedvariablecontent', variableContentString);
    }

    if (!$textVariable.find('.variableContentMeta').length) {
      const metaTitle = $textVariable.data('contentlabel');
      const $contentMeta = $('<span></span>', {
        class: 'variableContentMeta',
        text: metaTitle
      });
      $contentMeta.attr('contenteditable', 'false');
      $textVariable.append($contentMeta);
    }

    let isTextVariableLastElement = $textVariable.is(':last-child');
    if (isTextVariableLastElement) {
      const $spacer = $('<span class="variableContentSpacer">&nbsp;</span>');
      $textVariable.parent().append($spacer);
    }
  });

  function getVariableValueforText(variableValue) {
    if (variableValue == null || variableValue == undefined) {
      return '';
    }

    let variableContentString = '';
    if (typeof variableValue === 'object') {
      if (variableValue.selected) {
        let delimiter = '';
        variableValue.selected.forEach((selectedOption) => {
          variableContentString += delimiter + selectedOption.label;
          delimiter = '|';
        });
      } else if (
        variableValue.year &&
        variableValue.month &&
        variableValue.day
      ) {
        var date = new Date(
          variableValue.year,
          variableValue.month - 1,
          variableValue.day
        );
        variableContentString += date.toLocaleDateString(
          document.documentElement.lang
        );
      } else if (variableValue.lowerValue && variableValue.upperValue) {
        variableContentString +=
          getVariableValueforText(variableValue.lowerValue) +
          ' - ' +
          getVariableValueforText(variableValue.upperValue);
      }
    } else {
      variableContentString += variableValue;
    }

    return variableContentString;
  }

  return { $textResolvedVariables, textHasVariables };
}

export function configPlaceholderForVariables({ joditConfig, text }) {
  if (text.indexOf('<variable-content') == -1) {
    joditConfig.showPlaceholder = true;
  }

  return joditConfig;
}
