import React, { useState, useEffect } from 'react';

import { useDataManagement } from './DataManagementContext';
import { useCreateInstance } from './create-instances/CreateInstanceContext';
import { FeatureComponent } from './featurecomponents/FeatureComponent';
import { ajaxPostRetry } from 'src/services/ajaxRetry';
import { makeStyles } from '@material-ui/core/styles';
import { readFeatureValueForInstance } from '../tabeditor/reader/InstanceTabEditorReader';
import ajaxGet from 'src/services/ajaxGet';

const useStyles = makeStyles(() => ({
  defaultData: {
    '& .defaultColor': {
      backgroundColor: '#fafad2',
      display: 'inline-flex',
      borderRadius: '5px'
    }
  },
  blockDefaultData: {
    '& .defaultColor': {
      display: 'inline-flex'
    }
  },
  showSwitch: {
    display: 'inline-flex'
  }
}));

export function getFeatureKey(feature) {
  return (
    feature.classificationIdentifier +
    '__' +
    feature.classIdentifier +
    '__' +
    feature.featureIdentifier
  );
}

export const ArticleFeatureEditor = ({ featureKey, instanceEditor }) => {
  const {
    getFeatureState,
    setFeatureState,
    isDefaultData,
    deleteDefaultData,
    setDefaultData,
    setCustomFieldCommonSelectionOptions,
    setBlockDefaultData,
    deleteBlockDefaultData,
    isBlockDefaultData
  } = useDataManagement();
  const classes = useStyles();
  const {
    isCreatingInstance,
    setCreationDataFeature,
    getCreationData,
    isDuplicateInstance
  } = useCreateInstance();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  let waitingForApiCall = false;

  const featureEditorData = instanceEditor.featureEditor[featureKey] || {
    empty: true
  };

  if (featureEditorData) {
    featureEditorData.title = featureEditorData?.name;
    featureEditorData.title += featureEditorData.unit
      ? ' (' + featureEditorData.unit + ')'
      : '';

    if (instanceEditor.readOnly) {
      featureEditorData.readOnlyFeature = true;
    }
  }

  const classificationIdentifier = featureEditorData?.classificationIdentifier;
  const classIdentifier = featureEditorData?.classIdentifier;
  const featureIdentifier = featureEditorData?.featureIdentifier;
  const instanceId = instanceEditor.instanceId;
  const featureType = featureEditorData?.featureComponent?.featureType;
  const value = getFeatureState(instanceId, featureKey);
  const creatingInstance = isCreatingInstance();
  const duplicateInstance = isDuplicateInstance();
  const defaultDataObject = {
    id: featureKey,
    type: 'feature',
    instanceId: instanceId
  };

  const subscribedContentAutomation =
    featureEditorData?.defaultDataAwareInfo?.subscribedContentAutomation;
  const blockDefault = isBlockDefaultData(defaultDataObject);
  const isDefaultDataPTD = isDefaultData(defaultDataObject);
  const hasDefaultData = Boolean(
    featureEditorData?.defaultDataAwareInfo?.defaultDataAvailable
  );
  featureEditorData.isDefaultData = isDefaultDataPTD;
  featureEditorData.hasDefaultData = hasDefaultData;
  featureEditorData.isCreateInstance = creatingInstance;

  const featurePayload = {
    classificationIdentifier: classificationIdentifier,
    classIdentifier: classIdentifier,
    featureIdentifier: featureIdentifier
  };
  if (featureEditorData?.forPtdDefaultData) {
    let targetId = instanceEditor.instanceId;
    targetId = targetId.replace('PtdDefaultDataForArticle', '');
    featurePayload.defaultDataPtdId = targetId;
  } else {
    featurePayload.articleId = instanceEditor.instanceId;
    featurePayload.productId = instanceEditor.containerId;
  }

  const url = 'dataManagement/saveClassificationFeatureValue';

  useEffect(() => {
    if (creatingInstance && !isDefaultDataPTD && !duplicateInstance) {
      if (
        [
          'FEATURE_TYPE_ALPHANUMERIC_SELECTION',
          'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION'
        ].includes(featureType)
      ) {
        setFeatureState(instanceId, featureKey, { ...value, selected: [] });
      } else {
        setFeatureState(instanceId, featureKey, null);
      }
    }

    if (creatingInstance) {
      if (featureEditorData?.featureComponent?.irrelevantFeatureValue) {
        setCreationDataFeature(featureKey, { irrelevantFeatureValue: true });
      }

      if (duplicateInstance) {
        const creationData = getCreationData();
        if (creationData?.duplicateFromInstanceId) {
          const duplicateFromInstanceId = creationData.duplicateFromInstanceId;
          const featureValueOriginal = getFeatureState(
            duplicateFromInstanceId,
            featureKey
          );
          setFeatureState(instanceId, featureKey, featureValueOriginal);
        }
      }
    }
  }, []);

  function setValueState(newValue) {
    setFeatureState(instanceId, featureKey, newValue);
  }

  function saveChangedValue(newValue, valuePayload, responseCallback) {
    if (newValue === null) {
      if (hasDefaultData) setDefaultData(defaultDataObject);
      resetArticleFeatureValue();
    } else {
      if (hasDefaultData) {
        resetBlockDefaultDataFeature(true);
        deleteDefaultData(defaultDataObject);
      }
      saveArticleFeatureValue(valuePayload, newValue, responseCallback);
    }
  }

  function saveArticleFeatureValue(valuePayload, newValue, responseCallback) {
    const payload = {
      ...featurePayload,
      ...valuePayload
    };

    startApiCall();
    ajaxPostRetry({
      url: url,
      params: {
        contentLanguage: instanceEditor.contentLang,
        featureType: featureType
      },
      json: payload
    }).then((ajaxData) => {
      endApiCall();

      setError(ajaxData.error);
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));

      if (ajaxData.error) return;

      let otherFeaturesToRefresh = ajaxData.response.articleModel?.features;
      if (otherFeaturesToRefresh) {
        Object.keys(otherFeaturesToRefresh).map(function (key) {
          var featureComponent = otherFeaturesToRefresh[key].featureComponent;
          var value = featureComponent.stringValue;
          if (value == null) {
            value = '';
          }
          if (value || value == '') {
            setFeatureState(instanceId, key, value);
          }
        });
      }

      if (responseCallback) {
        responseCallback(ajaxData.response);
        return;
      }
      setFeatureState(instanceId, featureKey, newValue);
    });
  }

  function resetArticleFeatureValue() {
    startApiCall();
    ajaxPostRetry({
      url: 'dataManagement/resetClassificationFeature',
      params: { contentLanguage: instanceEditor.contentLang },
      json: featurePayload
    }).then((ajaxData) => {
      endApiCall();
      setError(ajaxData.error);
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;

      let otherFeaturesToRefresh = ajaxData.response.articleModel.features;
      let featureModel = ajaxData.response.featureModel;

      if (featureModel) {
        let value = readFeatureValueForInstance(
          featureModel.featureComponent,
          featureEditorData,
          setCustomFieldCommonSelectionOptions
        );
        setDefaultData(defaultDataObject);
        setFeatureState(instanceId, featureKey, value);
      } else {
        setFeatureState(instanceId, featureKey, null);
      }

      Object.keys(otherFeaturesToRefresh).map(function (key) {
        var featureComponent = otherFeaturesToRefresh[key].featureComponent;
        var value =
          featureComponent.stringValue != null
            ? featureComponent.stringValue
            : '';
        if (value || value == '') {
          setFeatureState(instanceId, key, value);
        }
      });
    });
  }

  function resetDefaultFeature() {
    let featureIdentifier = featureEditorData.featureIdentifier;
    let classificationIdentifier = featureEditorData.classificationIdentifier;
    let classIdentifier = featureEditorData.classIdentifier;
    let contentLanguage = instanceEditor.contentLang;
    let ptdId = featureEditorData.defaultDataAwareInfo.defaultDataId;
    let ptdObjectClass =
      featureEditorData.defaultDataAwareInfo.defaultDataObjectClass;

    ajaxGet('dataManagement/getDefaultFeaturePtd', {
      featureIdentifier: featureIdentifier,
      classificationIdentifier: classificationIdentifier,
      classIdentifier: classIdentifier,
      contentLanguage: contentLanguage,
      ptdId: ptdId,
      ptdObjectClass: ptdObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
      let featureModel = ajaxData.response.featureModel;

      if (featureModel) {
        let value = readFeatureValueForInstance(
          featureModel.featureComponent,
          featureEditorData,
          setCustomFieldCommonSelectionOptions
        );
        setDefaultData(defaultDataObject);
        setFeatureState(instanceId, featureKey, value);
      }
      setCreationDataFeature(featureKey, null);
    });
  }

  function setBlockDefaultDataFeature() {
    let contentIdentifier = featureIdentifier;
    let type = defaultDataObject.type;
    let parentId = instanceEditor.instanceId;
    let parentObjectClass = instanceEditor.instanceType;
    let classificationId = classificationIdentifier;
    let classificationClassId = classIdentifier;
    startApiCall();
    ajaxPostRetry({
      url: 'dataManagement/postSetBlockDefaultData',
      params: {
        contentIdentifier: contentIdentifier,
        type: type,
        parentId: parentId,
        parentObjectClass: parentObjectClass,
        classifcationId: classificationId,
        classificationClassId: classificationClassId
      }
    }).then((ajaxData) => {
      endApiCall();
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    setBlockDefaultData(defaultDataObject);
    deleteDefaultData(defaultDataObject);
    setFeatureState(instanceId, featureKey, null);
    if (
      featureEditorData.classificationIdentifier ===
        '__local_Classification__' &&
      !featureEditorData.formula
    ) {
      refreshDerivedFeaturesAfterBlockDefaultData();
    }
  }

  function refreshDerivedFeaturesAfterBlockDefaultData() {
    let featureIdentifier = featureEditorData.featureIdentifier;
    let classIdentifier = featureEditorData.classIdentifier;
    let classificationIdentifier = featureEditorData.classificationIdentifier;
    let contentLanguage = instanceEditor.contentLang;
    let articleId = instanceEditor.instanceId;

    ajaxGet('dataManagement/refreshDerivedFeaturesAfterBlockDefaultData', {
      featureIdentifier: featureIdentifier,
      classIdentifier: classIdentifier,
      classificationIdentifier: classificationIdentifier,
      contentLanguage: contentLanguage,
      articleId: articleId
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;

      let featuresModel = ajaxData.response.articleModel.features;

      Object.keys(featuresModel).map(function (key) {
        var featureComponent = featuresModel[key].featureComponent;
        var value =
          featureComponent.stringValue != null
            ? featureComponent.stringValue
            : '';
        if (value || value == '') {
          setFeatureState(instanceId, key, value);
        }
      });
    });
  }

  function resetBlockDefaultDataFeature(withOutReset) {
    if (!subscribedContentAutomation) return;

    let contentIdentifier = featureIdentifier;
    let type = defaultDataObject.type;
    let parentId = instanceEditor.instanceId;
    let parentObjectClass = instanceEditor.instanceType;
    let classificationId = classificationIdentifier;
    let classificationClassId = classIdentifier;
    startApiCall();
    ajaxPostRetry({
      url: 'dataManagement/postResetBlockDefaultData',
      params: {
        contentIdentifier: contentIdentifier,
        type: type,
        parentId: parentId,
        parentObjectClass: parentObjectClass,
        classifcationId: classificationId,
        classificationClassId: classificationClassId
      }
    }).then((ajaxData) => {
      endApiCall();
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;

      deleteBlockDefaultData(defaultDataObject);
      if (!withOutReset) {
        resetArticleFeatureValue();
      }
    });
  }

  function startApiCall() {
    waitingForApiCall = true;
    setTimeout(() => {
      if (waitingForApiCall) {
        setLoading(true);
      }
    }, 2000);
  }

  function endApiCall() {
    waitingForApiCall = false;
    setLoading(false);
  }

  function saveCreateValue(newValue, valuePayload) {
    if ((newValue === null || newValue === '') && hasDefaultData) {
      resetDefaultFeature();
    } else {
      deleteDefaultData(defaultDataObject);
      setCreationDataFeature(featureKey, valuePayload);
      setFeatureState(instanceId, featureKey, newValue);
    }
  }

  if (!featureEditorData || featureEditorData.empty) {
    return null;
  }
  return (
    <div
      className={`instanceEditorComponent feature-editor ${
        isDefaultDataPTD ? classes.defaultData : null
      } ${blockDefault ? classes.blockDefaultData : null} ${
        isDefaultDataPTD || blockDefault ? classes.showSwitch : null
      }`}
    >
      <FeatureComponent
        featureInfo={featureEditorData}
        value={value}
        setValueState={setValueState}
        saveChangedValue={creatingInstance ? saveCreateValue : saveChangedValue}
        loading={loading}
        error={error}
        setBlockDefaultData={setBlockDefaultDataFeature}
        resetBlockDefaultData={resetBlockDefaultDataFeature}
        isBlockDefaultData={blockDefault}
      />
    </div>
  );
};
