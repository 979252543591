import React, { useState, useEffect, useMemo, createRef, useRef } from "react";
import { useTheme } from "@mui/material/styles";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";


import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { MdPictureAsPdf } from 'react-icons/md';

import ajaxGet from '../../services/ajaxGet.js';

const ClassificationSplitButton = ({
  anchorRef,
  productID,
  buttonGroupStyle,
  dropDownIconStyle,
  classificationGroups,
  pdf__URL,
  contentLanguage,
  buttonToolTipTitle
}) => {

    const [open, setOpen] = React.useState(false);
    const [targetClassificationGroup, setTargetClassificationGroup] = useState("");

    const handleClick = (e) => {
      e.stopPropagation();
      window.open(pdf__URL + '&contentLanguage=' + contentLanguage ) ;
      };
    
      const handleMenuItemClick = (event, classificationIdentifier) => {

        event.stopPropagation();

        setTargetClassificationGroup(classificationIdentifier)
        setOpen(false);
        localStorage.setItem('classificationGroupStorage', classificationIdentifier);
        ajaxGet('listusers/setClassificationGroup', {
          classificationGroupId:classificationIdentifier
        }).then((ajaxData) => {
          if (!ajaxData.error && ajaxData.response) {
            console.log(ajaxData.response.resultInfo);
          }
        });

      };
    
      const handleToggle = (e) => {
        e.stopPropagation();
        setOpen((prevOpen) => !prevOpen);

        ajaxGet('dataManagement/getClassificationGroup', {}).then((ajaxData) => {
          if (!ajaxData.error && ajaxData.response) {
            console.log(ajaxData.response.resultInfo);
            const  storedClassificationIdentifier = ajaxData.response.resultInfo;
            const lastClassificationGroup = classificationGroups.find(
              (c) => c.classificationIdentifier === storedClassificationIdentifier
            );
            if (lastClassificationGroup) {
              setTargetClassificationGroup(storedClassificationIdentifier);
            }
            else {
              const allClassificationGroup = classificationGroups.find(
                (c) => c.classificationIdentifier === "all"
              );
              if (allClassificationGroup) {
                setTargetClassificationGroup(allClassificationGroup.classificationIdentifier);
              }             
            }
                      
          }
        });
      };

      const handleClose = (event) => {
        event.stopPropagation();
        setOpen(false);
      };

      useEffect(() => {
 
      }, [targetClassificationGroup]);


  return (
    <>
      <ButtonGroup
        variant="contained"
        key={productID}
        ref={(element) => (anchorRef.current[productID] = element)}
        aria-label="split button"
        sx={{
          minHeight: "5.1rem !important"
        }}
      >
        <Tooltip
          title={
            <Typography variant="h5">{buttonToolTipTitle}</Typography>
          }
        >
          <Button onClick={handleClick} className={buttonGroupStyle}>
            <MdPictureAsPdf size={20} style={{ fill: "whitesmoke" }} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title={<Typography variant="h5">Klassifizierung</Typography>}>
          <Button
            className={dropDownIconStyle}
            sx={{
              marginTop: 0,
              padding:'6px 1px !important'
            }}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Popper
        open={open}
        sx={{
          zIndex: 1000,
          fontFamily:'"Roboto", sans-serif !important',
          fontSize:'14px !important',
          color: '#333 !important'
        }}
        anchorEl={ anchorRef.current[productID] }
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">

                <ListSubheader 
                  sx={{
                    fontFamily: '"Roboto", sans-serif !important',
                    fontSize: "15px !important",
                  }}
                >
                  Klassifizierung</ListSubheader>
                  {classificationGroups.map((classificationGroup) => (
                    <MenuItem
                      key={classificationGroup.classificationIdentifier}
                      sx={{
                        fontFamily: '"Roboto", sans-serif !important',
                        fontSize: "14px !important",
                      }}
                      selected={classificationGroup.classificationIdentifier === targetClassificationGroup}
                      onClick={(event) => handleMenuItemClick(event, classificationGroup.classificationIdentifier)}
                    >
                      {classificationGroup.groupName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ClassificationSplitButton;