import React, { createContext, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
//import { CREATE_INSTANCE_ID } from './create-instances/CreateInstance';

const DataManagementContext = createContext(null);

const DataManagementProvider = ({
  readOnlyProvided,
  ptdId,
  classificationGroup,
  children
}) => {
  const [readOnly, setReadOnly] = useState(readOnlyProvided);
  const [contentLang, setContentLang] = useState(null);
  const [instanceContext, setInstanceContext] = useImmer({});
  const [texts, setTexts] = useImmer({});
  const [globalKeywords, setGlobalKeywords] = useState(null);
  const [
    customFieldCommonSelectionOptions,
    setCustomFieldCommonSelectionOptions
  ] = useImmer({});

  const [multipleInstances, setMultipleInstances] = useImmer({});
  const [variableContentDefs, setVariableContentDefs] = useImmer({});
  const [featureDefs, setFeatureDefs] = useState([]);
  const [creationFeatureDefs, setCreationFeatureDefs] = useState([]);
  const [articleDataZones, setArticleDataZones] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);

  const [isDefaultData, setIsDefaultData] = useState([]);
  const [isBlockDefaultData, setIsBlockDefaultData] = useState([]);
  const [isLoadingAddMedia, setIsLoadingAddMedia] = useState(false);

  return (
    <DataManagementContext.Provider
      value={[
        readOnly,
        setReadOnly,
        contentLang,
        setContentLang,
        instanceContext,
        setInstanceContext,
        texts,
        setTexts,
        globalKeywords,
        setGlobalKeywords,
        customFieldCommonSelectionOptions,
        setCustomFieldCommonSelectionOptions,
        multipleInstances,
        setMultipleInstances,
        variableContentDefs,
        setVariableContentDefs,
        featureDefs,
        setFeatureDefs,
        creationFeatureDefs,
        setCreationFeatureDefs,
        articleDataZones,
        setArticleDataZones,
        subscriptionInfo,
        setSubscriptionInfo,
        userPermissions,
        setUserPermissions,
        isDefaultData,
        setIsDefaultData,
        isBlockDefaultData,
        setIsBlockDefaultData,
        isLoadingAddMedia,
        setIsLoadingAddMedia,
        ptdId,
        classificationGroup
      ]}
    >
      {children}
    </DataManagementContext.Provider>
  );
};

const useDataManagement = () => {
  const [
    readOnly,
    setReadOnly,
    contentLang,
    setContentLang,
    instanceContext,
    setInstanceContext,
    texts,
    setTexts,
    globalKeywords,
    setGlobalKeywords,
    customFieldCommonSelectionOptions,
    setCustomFieldCommonSelectionOptions,
    multipleInstances,
    setMultipleInstances,
    variableContentDefs,
    setVariableContentDefs,
    featureDefs,
    setFeatureDefs,
    creationFeatureDefs,
    setCreationFeatureDefs,
    articleDataZones,
    setArticleDataZones,
    subscriptionInfo,
    setSubscriptionInfo,
    userPermissions,
    setUserPermissions,
    isDefaultData,
    setIsDefaultData,
    isBlockDefaultData,
    setIsBlockDefaultData,
    isLoadingAddMedia,
    setIsLoadingAddMedia,
    ptdId,
    classificationGroup
  ] = useContext(DataManagementContext);

  function getInstanceState(instanceDbId, dataCategory) {
    if (!instanceDbId) {
      throw new Error(
        "Error: Instance Database Id missing. Can't get " +
          dataCategory +
          ' state'
      );
    }

    const instanceState = instanceContext[instanceDbId];
    if (!instanceState) {
      throw new Error(
        "Error: Instance not found. Can't get " + dataCategory + ' state'
      );
    }

    return instanceState;
  }

  function handleGetLoadedInstance(instanceDbId) {
    if (!instanceDbId) return undefined;
    return instanceContext[instanceDbId];
  }
  function handleSetLoadedInstance(instanceDbId, instanceValue) {
    if (!instanceDbId || instanceValue == null) return;
    // if (instanceDbId in instanceContext && instanceDbId != CREATE_INSTANCE_ID) {
    //   return;
    // }

    setInstanceContext((draft) => {
      draft[instanceDbId] = instanceValue;
    });
  }

  function handleGetCustomFieldState(instanceDbId, fieldType) {
    const instanceState = getInstanceState(instanceDbId, 'custom field');
    return instanceState.customFields[fieldType];
  }
  function handleSetCustomFieldState(instanceDbId, fieldType, newValue) {
    if (!instanceDbId) return;

    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.customFields[fieldType] = newValue;
    });
  }
  function handleSetCustomFieldStateRange(instanceDbId, fieldType, rangeValue) {
    if (!instanceDbId) return;

    if (rangeValue.lowerValue && rangeValue.upperValue) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.customFields[fieldType] = {
          lowerValue: rangeValue.lowerValue,
          upperValue: rangeValue.upperValue
        };
      });
    } else if (rangeValue.lowerValue) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.customFields[fieldType].lowerValue = rangeValue.lowerValue;
      });
    } else if (rangeValue.upperValue) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.customFields[fieldType].upperValue = rangeValue.upperValue;
      });
    }
  }

  function handleGetFeatureState(instanceDbId, featureKey) {
    const instanceState = getInstanceState(instanceDbId, 'feature');
    return instanceState.features[featureKey];
  }
  function handleSetFeatureState(instanceDbId, featureKey, value) {
    if (!instanceDbId) return;

    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.features[featureKey] = value;
    });
  }
  function handleSetFeatureStateRange(instanceDbId, featureKey, rangeValue) {
    if (!instanceDbId) return;

    if (rangeValue.lowerValue && rangeValue.upperValue) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.features[featureKey] = {
          lowerValue: rangeValue.lowerValue,
          upperValue: rangeValue.upperValue
        };
      });
    } else if (rangeValue.lowerValue) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.features[featureKey].lowerValue = rangeValue.lowerValue;
      });
    } else if (rangeValue.upperValue) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.features[featureKey].upperValue = rangeValue.upperValue;
      });
    }
  }

  function handleGetGeneralFieldState(instanceDbId, generalFieldType) {
    const instanceState = getInstanceState(instanceDbId, 'general field');
    return instanceState.generalFields[generalFieldType];
  }
  function handleSetGeneralFieldState(instanceDbId, generalFieldType, value) {
    if (!instanceDbId) return;

    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.generalFields[generalFieldType] = value;
    });
  }

  function handleGetMediaState(instanceDbId, mediaType) {
    const instanceState = getInstanceState(instanceDbId, 'media');
    return instanceState.media[mediaType];
  }
  function handleSetMediaState(instanceDbId, mediaType, mediaList) {
    if (!instanceDbId) return;

    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.media[mediaType] = mediaList;
    });
  }

  function handleGetSelectedKeywordsState(instanceDbId) {
    const instanceState = getInstanceState(instanceDbId, 'selected keywords');
    return instanceState.selectedKeywords;
  }
  function handleSetSelectedKeywordsState(instanceDbId, nextSelectedKeywords) {
    if (!instanceDbId) return;
    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.selectedKeywords = nextSelectedKeywords;
    });
  }
  function handleGetKeywordOptionsState(instanceDbId) {
    const instanceState = getInstanceState(instanceDbId, 'selected keywords');
    return instanceState.keywordOptions;
  }
  function handleSetKeywordOptionsState(instanceDbId, nextKeywordOptions) {
    if (!instanceDbId) return;
    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.keywordOptions = nextKeywordOptions;
    });
  }
  function handleAddCustomKeywordOptionsState(instanceDbId, newKeywordOption) {
    if (!instanceDbId || !newKeywordOption.addedSelectionOption) return;

    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.keywordOptions.push(newKeywordOption);
      sortOptions(instance.keywordOptions);
    });
  }
  function handleSetGlobalsToKeywordOptionsState(
    instanceDbId,
    newKeywordOptions
  ) {
    if (
      !instanceDbId ||
      newKeywordOptions.find((keyword) => keyword.addedSelectionOption)
    )
      return;

    setInstanceContext((draft) => {
      const instance = draft[instanceDbId];
      instance.keywordOptions = instance.keywordOptions.filter(
        (keyword) => keyword.addedSelectionOption
      );
      instance.keywordOptions.push(...newKeywordOptions);
      sortOptions(instance.keywordOptions);
    });
  }

  function handleGetCustomFieldCommonSelectionOptions(customFieldType) {
    if (!customFieldType) return undefined;
    return customFieldCommonSelectionOptions[customFieldType];
  }
  function handleSetCustomFieldCommonSelectionOptions(
    customFieldType,
    nextOptions
  ) {
    if (!customFieldType) return;
    sortOptions(nextOptions);

    setCustomFieldCommonSelectionOptions((draft) => {
      draft[customFieldType] = nextOptions;
    });
  }

  const sortOptions = (options) => {
    options.sort((a, b) => {
      if (a.addedSelectionOption === b.addedSelectionOption) {
        if (a.label) {
          return a.label.localeCompare(b.label);
        } else {
          return b.label ? -1 : 1;
        }
      }
      return a.addedSelectionOption ? 1 : -1;
    });
  };

  function handleGetGlobalKeywords() {
    return globalKeywords;
  }
  function handleSetGlobalKeywords(newGlobalKeywords) {
    setGlobalKeywords(newGlobalKeywords);
  }
  function handleAddGlobalKeyword(newGlobalKeyword) {
    setGlobalKeywords(globalKeywords.concat(newGlobalKeyword));
  }

  function handleGetTextState(textDbId) {
    if (!textDbId) return undefined;
    return texts[textDbId];
  }
  function handleSetTextState(textDbId, text) {
    if (text == null) {
      setTexts((draft) => {
        delete draft[textDbId];
      });
    } else {
      setTexts((draft) => {
        draft[textDbId] = text;
      });
    }
  }
  function handleSetLoadedTextState(textDbId, text) {
    if (!textDbId || textDbId in texts) return;
    handleSetTextState(textDbId, text);
  }

  function handleGetResolvedVariableTextState(instanceDbId, textType) {
    const instanceState = getInstanceState(
      instanceDbId,
      'resolved text with variables'
    );
    return instanceState.resolvedVariableTexts[textType];
  }
  function handleSetResolvedVariableTextState(instanceDbId, textType, text) {
    if (!instanceDbId) return;

    if (text) {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.resolvedVariableTexts[textType] = text;
      });
    } else {
      setInstanceContext((draft) => {
        const instance = draft[instanceDbId];
        instance.resolvedVariableTexts[textType] = text;
        delete instance.resolvedVariableTexts[textType];
      });
    }
  }

  function handleGetMultipleInstances(instanceType) {
    if (!instanceType) {
      throw new Error(
        "Error: Instance Type missing. Can't get multipleInstances state"
      );
    }

    return multipleInstances[instanceType];
  }

  function handleSetMultipleStances(instanceType, multipleInstances) {
    if (!instanceType || !multipleInstances) return;

    setMultipleInstances((draft) => {
      draft[instanceType] = multipleInstances;
    });
  }

  function handleGetVariableContentDefs(instanceType) {
    if (!instanceType) {
      throw new Error(
        "Error: Instance Type missing. Can't get VariableContentDefs state"
      );
    }

    return variableContentDefs[instanceType];
  }
  function handleSetVariableContentDefs(instanceType, contentDef) {
    if (!instanceType || !contentDef) return;

    setVariableContentDefs((draft) => {
      draft[instanceType] = contentDef;
    });
  }

  function handleGetFeatureDefs() {
    return featureDefs;
  }
  function handleSetFeatureDefs(newFeatureDefs) {
    if (!newFeatureDefs) return;

    setFeatureDefs(newFeatureDefs);
  }

  function handleGetCreationFeatureDefs() {
    return creationFeatureDefs;
  }
  function handleSetCreationFeatureDefs(newCreationFeatureDefs) {
    if (!newCreationFeatureDefs) return;

    setCreationFeatureDefs(newCreationFeatureDefs);
  }

  function handleGetIsDefaultDataList() {
    return isDefaultData;
  }

  function handleSetIsDefaultData(dataObject) {
    var elementPos = isDefaultData
      .map(function (x) {
        return x.id + x.type + x.instanceId;
      })
      .indexOf(dataObject.id + dataObject.type + dataObject.instanceId);

    if (elementPos == -1) {
      isDefaultData.push(dataObject);
      setIsDefaultData(isDefaultData);
    }
  }

  function handleGetIsDefaultData(dataObject) {
    var elementPos = isDefaultData
      .map(function (x) {
        return x.id + x.type + x.instanceId;
      })
      .indexOf(dataObject.id + dataObject.type + dataObject.instanceId);

    return elementPos >= 0 ? true : false;
  }

  function handleDeleteDefaultData(dataObject) {
    var elementPos = isDefaultData
      .map(function (x) {
        return x.id + x.type + x.instanceId;
      })
      .indexOf(dataObject.id + dataObject.type + dataObject.instanceId);
    if (elementPos >= 0) {
      isDefaultData.splice(elementPos, 1);
      setIsDefaultData(isDefaultData);
    }
  }

  function handleSetIsBlockDefaultData(dataObject) {
    var elementPos = isBlockDefaultData
      .map(function (x) {
        return x.id + x.type + x.instanceId;
      })
      .indexOf(dataObject.id + dataObject.type + dataObject.instanceId);

    if (elementPos == -1) {
      isBlockDefaultData.push(dataObject);
      setIsBlockDefaultData(isBlockDefaultData);
    }
  }

  function handleGetIsBlockDefaultData(dataObject) {
    var elementPos = isBlockDefaultData
      .map(function (x) {
        return x.id + x.type + x.instanceId;
      })
      .indexOf(dataObject.id + dataObject.type + dataObject.instanceId);

    return elementPos >= 0 ? true : false;
  }

  function handleDeleteBlockDefaultData(dataObject) {
    var elementPos = isBlockDefaultData
      .map(function (x) {
        return x.id + x.type + x.instanceId;
      })
      .indexOf(dataObject.id + dataObject.type + dataObject.instanceId);
    if (elementPos >= 0) {
      isBlockDefaultData.splice(elementPos, 1);
      setIsBlockDefaultData(isBlockDefaultData);
    }
  }

  function handleIsLoadingAddMedia() {
    return isLoadingAddMedia;
  }

  function handleSetIsLoadingAddMedia(value) {
    setIsLoadingAddMedia(value);
  }

  function handleGetPtdId() {
    return ptdId;
  }

  function handleGetClassificationGroup() {
    return classificationGroup;
  }

  return {
    isReadOnly: () => readOnly,
    setReadOnly: setReadOnly,
    getContentLang: () => contentLang,
    setContentLang: setContentLang,
    getLoadedInstance: handleGetLoadedInstance,
    setLoadedInstance: handleSetLoadedInstance,
    getCustomFieldState: handleGetCustomFieldState,
    setCustomFieldState: handleSetCustomFieldState,
    setCustomFieldStateRange: handleSetCustomFieldStateRange,
    getFeatureState: handleGetFeatureState,
    setFeatureState: handleSetFeatureState,
    setFeatureStateRange: handleSetFeatureStateRange,
    getGeneralFieldState: handleGetGeneralFieldState,
    setGeneralFieldState: handleSetGeneralFieldState,
    getMediaState: handleGetMediaState,
    setMediaState: handleSetMediaState,
    getTextState: handleGetTextState,
    setTextState: handleSetTextState,
    setLoadedTextState: handleSetLoadedTextState,
    getResolvedVariableTextState: handleGetResolvedVariableTextState,
    setResolvedVariableTextState: handleSetResolvedVariableTextState,
    getSelectedKeywordsState: handleGetSelectedKeywordsState,
    setSelectedKeywordsState: handleSetSelectedKeywordsState,
    getKeywordOptionsState: handleGetKeywordOptionsState,
    setKeywordOptionsState: handleSetKeywordOptionsState,
    addCustomKeywordOptionsState: handleAddCustomKeywordOptionsState,
    getCustomFieldCommonSelectionOptions: handleGetCustomFieldCommonSelectionOptions,
    setCustomFieldCommonSelectionOptions: handleSetCustomFieldCommonSelectionOptions,
    setGlobalsToKeywordOptionsState: handleSetGlobalsToKeywordOptionsState,
    getGlobalKeywords: handleGetGlobalKeywords,
    setGlobalKeywords: handleSetGlobalKeywords,
    addGlobalKeyword: handleAddGlobalKeyword,
    getMultipleInstances: handleGetMultipleInstances,
    setMultipleStances: handleSetMultipleStances,
    getVariableContentDefs: handleGetVariableContentDefs,
    setVariableContentDefs: handleSetVariableContentDefs,
    getFeatureDefs: handleGetFeatureDefs,
    setFeatureDefs: handleSetFeatureDefs,
    getCreationFeatureDefs: handleGetCreationFeatureDefs,
    setCreationFeatureDefs: handleSetCreationFeatureDefs,
    getDefaultDataList: handleGetIsDefaultDataList,
    setDefaultData: handleSetIsDefaultData,
    isDefaultData: handleGetIsDefaultData,
    deleteDefaultData: handleDeleteDefaultData,
    setBlockDefaultData: handleSetIsBlockDefaultData,
    isBlockDefaultData: handleGetIsBlockDefaultData,
    deleteBlockDefaultData: handleDeleteBlockDefaultData,
    getIsLoadingAddMedia: handleIsLoadingAddMedia,
    setIsLoadingAddMedia: handleSetIsLoadingAddMedia,
    getPtdId: handleGetPtdId,
    getClassificationGroup: handleGetClassificationGroup,
    articleDataZones: articleDataZones,
    setArticleDataZones: setArticleDataZones,
    subscriptionInfo: subscriptionInfo,
    setSubscriptionInfo: setSubscriptionInfo,
    userPermissions: userPermissions,
    setUserPermissions: setUserPermissions
  };
};

export { DataManagementProvider, useDataManagement };
