import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { FilePicker } from 'react-file-picker';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { useExport } from './ExportContext';
import { useBMEcatStyles } from './BMEcatExportOverview';
import ajaxGet from '../../services/ajaxGet.js';
import ajaxPost from '../../services/ajaxPost.js';

export const ChooseArticle = () => {
  const {
    setProductStructure,
    getProductStructure,
    setCatalogId,
    getCatalogId,
    setShopId,
    getShopId,
    setCtxId,
    getFileName,
    setFileName,
    getArticleColumn,
    setArticleColumn,
    getColumns,
    setColumns,
    getArticleChoice,
    setArticleChoice,
    setLoadingBmeCat
  } = useExport();
  const { t } = useTranslation();
  const classes = useBMEcatStyles();

  const [articleSelectCatalog2go, setArticleSelectCatalog2go] = useState(false);
  const [articleSelectShop2go, setArticleSelectShop2go] = useState(false);
  const [articleSelectProductStructure, setArticleSelectProductStructure] =
    useState(false);

  const [articleSelectInfo, setArticleSelectInfo] = useImmer(null);
  let productStructure = getProductStructure();
  let shop = getShopId();
  let catalog = getCatalogId();
  let fileName = getFileName();
  let articleColumn = getArticleColumn();
  let columns = getColumns();
  let text = getArticleChoice();

  useEffect(() => {
    let contentLang = $('#contentLangSelectElement').val() || null;
    ajaxGet('getArticleSelectInfo', { contentLanguage: contentLang }).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          let articleSelectInfo = {};
          articleSelectInfo.shops = ajaxData.response.shops;
          articleSelectInfo.publications = ajaxData.response.publications;
          articleSelectInfo.productStructureNodes =
            ajaxData.response.productStructureNodes;

          setArticleSelectCatalog2go(
            ajaxData.response.catalogToGoSubscribed &&
              Boolean(
                articleSelectInfo.publications &&
                  articleSelectInfo.publications.length
              )
          );
          setArticleSelectShop2go(
            ajaxData.response.shopToGoSubscribed &&
              Boolean(articleSelectInfo.shops && articleSelectInfo.shops.length)
          );
          setArticleSelectProductStructure(
            Boolean(
              articleSelectInfo.productStructureNodes &&
                articleSelectInfo.productStructureNodes.length
            )
          );

          setArticleSelectInfo(articleSelectInfo);
        }
        setLoadingBmeCat(false);
      }
    );
  }, []);

  const handleChange = (event) => {
    let value = event.target.value;
    if (value === 0) {
      setProductStructure('');
      setCatalogId('');
      setShopId('');
      setFileName('');
      setArticleColumn('');
    }
    setArticleChoice(value);
  };

  const handleChangeColumn = (event) => {
    setArticleColumn(event.target.value);
    setProductStructure('');
    setCatalogId('');
    setShopId('');
  };

  const handleChangeProductStructure = (event) => {
    setProductStructure(event.target.value);
    setCatalogId('');
    setShopId('');
    setFileName('');
    setArticleColumn('');
  };

  const handleChangeShop = (event) => {
    setShopId(event.target.value);
    setProductStructure('');
    setCatalogId('');
    setFileName('');
    setArticleColumn('');
  };

  const handleChangePublication = (event) => {
    setCatalogId(event.target.value);
    setProductStructure('');
    setShopId('');
    setFileName('');
    setArticleColumn('');
  };

  const handleUpload = (file) => {
    let contentLang = $('#contentLangSelectElement').val() || null;
    var fileFormData = new FormData();
    fileFormData.append('file', file);
    fileFormData.append('contentLanguage', contentLang);
    setLoadingBmeCat(true);
    setArticleColumn('');
    ajaxPost('export/uploadFile', {}, null, fileFormData).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      setLoadingBmeCat(false);
      if (ajaxData.error) return;
      setFileName(ajaxData.response.fileName);
      setColumns(ajaxData.response.map);
      setCtxId(ajaxData.response.ctxId);
    });
  };

  return (
    <div>
      <Grid container spaceing={3}>
        <Grid item xs={12}>
          <h2>{t('bmeCatExport.chooseArticle.title')}</h2>
        </Grid>
        <Grid item xs={12}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={text}
            onChange={handleChange}
            className={classes.bmeCatSelect}
          >
            <MenuItem value={0}>
              {t('bmeCatExport.chooseArticle.allArticles')}
            </MenuItem>
            <MenuItem value={1}>
              {t('bmeCatExport.chooseArticle.excelList')}
            </MenuItem>
            {articleSelectProductStructure && (
              <MenuItem value={2}>
                {t('bmeCatExport.chooseArticle.productStructure')}
              </MenuItem>
            )}
            {articleSelectCatalog2go && (
              <MenuItem value={3}>
                {t('bmeCatExport.chooseArticle.catalog')}
              </MenuItem>
            )}
            {articleSelectShop2go && (
              <MenuItem value={4}>
                {t('bmeCatExport.chooseArticle.shop')}
              </MenuItem>
            )}
          </Select>
        </Grid>
      </Grid>
      {text === 1 ? (
        <div style={{ marginTop: '2rem' }}>
          <Grid container spaceing={3}>
            <Grid item xs={6}>
              <FilePicker
                extensions={['xlsx']}
                onChange={(FileObject) => handleUpload(FileObject)}
              >
                <button>
                  <i className="fa fa-table" aria-hidden="true"></i>
                  <span style={{ margin: '0 1rem' }}>Datei auswählen...</span>
                </button>
              </FilePicker>
              {fileName ? <p>Dateiname der Vorlage: {fileName}</p> : ''}
              {columns ? (
                <div style={{ margin: '2rem 0' }}>
                  <InputLabel id="articlenumber-select-label">
                    Art-Nr. - Spalte auswählen:{' '}
                  </InputLabel>

                  <Select
                    labelId="articlenumber-select-label"
                    id="test"
                    value={articleColumn}
                    onChange={handleChangeColumn}
                    className={classes.bmeCatSelect}
                  >
                    <MenuItem value={''}>-</MenuItem>
                    {columns.map((content) => (
                      <MenuItem
                        key={content.key + content.value}
                        value={content.key}
                      >
                        {content.value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
      {text === 2 && articleSelectInfo ? (
        <div style={{ marginTop: '2rem' }}>
          <Grid container spaceing={3}>
            <Grid item xs={6}>
              <InputLabel id="product-structure-select-label">
                Produktstruktur:{' '}
              </InputLabel>

              <Select
                labelId="product-structure-select-label"
                id="test"
                value={productStructure}
                onChange={handleChangeProductStructure}
                className={classes.bmeCatSelect}
              >
                <MenuItem value={''}>{' - '}</MenuItem>
                {articleSelectInfo.productStructureNodes.map((content) => (
                  <MenuItem
                    key={content.nodeIdentifier + content.nodeName}
                    value={content.id}
                  >
                    {content.nodeName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
      {text === 3 && articleSelectInfo ? (
        <div style={{ marginTop: '2rem' }}>
          <Grid container spaceing={3}>
            <Grid item xs={6}>
              <InputLabel id="catalog-select-label">Katalog: </InputLabel>

              <Select
                labelId="catalog-select-label"
                id="test"
                value={catalog}
                onChange={handleChangePublication}
                className={classes.bmeCatSelect}
              >
                <MenuItem value={''}>-</MenuItem>
                {articleSelectInfo.publications.map((content) => (
                  <MenuItem key={content.id + content.name} value={content.id}>
                    {content.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
      {text === 4 && articleSelectInfo ? (
        <div style={{ marginTop: '2rem' }}>
          <Grid container spaceing={3}>
            <Grid item xs={6}>
              <InputLabel id="shop-select-label">Shop: </InputLabel>

              <Select
                labelId="shop-select-label"
                id="test"
                value={shop}
                onChange={handleChangeShop}
                className={classes.bmeCatSelect}
              >
                <MenuItem value={''}>-</MenuItem>
                {articleSelectInfo.shops.map((content) => (
                  <MenuItem key={content.id + content.name} value={content.id}>
                    {content.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
