import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Paper,
  Button as MuiButton,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

import theme from '../PimTogoTheme';
import ajaxGet from 'src/services/ajaxGet';

import { DataManagementProvider } from '../articledata/datamanagement/DataManagementContext';
import { InstanceTabEditorReader } from '../articledata/tabeditor/reader/InstanceTabEditorReader';
import {
  CLASSIFICATION_GROUP_ALL,
  LOCAL_STORAGE_CLASSIFICATION_GROUP
} from '../articledata/datamanagement/InstanceEditor';
import { ptdDefaultDataForProductEditor } from './ptdDefaultDataForProductEditor';
import { ptdDefaultDataForArticleEditor } from './ptdDefaultDataForArticleEditor';
import { useStylesTabs } from '../articledata/tabeditor/tools/Tabs';
import PtdDefaultDataCustomInstance from './PtdDefaultDataCustomInstance';

const classNameReplacementProduct = 'PtdDefaultDataForProduct';
const classNameReplacementArticle = 'PtdDefaultDataForArticle';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  head: {
    backgroundColor: '#eaeaea',
    fontWeight: 'bold'
  },
  headCell: {
    fontWeight: 'bold !important'
  },
  ptdDefaultDataTabsWrap: {
    background: 'white',
    color: 'black',
    boxShadow: 'none',
    borderBottom: '#ffd8a9 solid 2px',
    borderRadius: 4
  },
  ptdDefaultDataTabContent: {
    '& > .MuiBox-root > div > .containerDisplayPaper': {
      boxShadow: 'none'
    }
  },
  ptdDefaultDataCustomInstanceAccordionWrap: {
    margin: '5px 0',
    backgroundImage: 'linear-gradient(to right, #f29528, #fbdba7)'
  },
  ptdDefaultDataCustomInstanceAccordion: {
    background: 'white',
    display: 'flow-root',
    padding: '20px',
    '& > div > .containerDisplayPaper': {
      boxShadow: 'none'
    }
  },
  classification: {
    marginLeft: '1rem'
  },
  classificationTitle: {
    marginLeft: '1rem',
    color: '#636363',
    fontSize: '1.5rem'
  }
});

export default function PtdDefaultDataOverview() {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesTabs = useStylesTabs();

  const [productTypeDefs, setProductTypeDefs] = useState([]);
  const [classificationGroups, setClassificationGroups] = useState([]);
  const [targetClassificationGroup, setTargetClassificationGroup] =
    useState('');

  const [hideOverview, setHideOverview] = useState(false);
  const [activePtd, setActivePtd] = useState(null);
  const [activePtdLabel, setActivePtdLabel] = useState(null);

  const [instanceDefs, setInstanceDefs] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [customInstanceExpanded, setCustomInstanceExpanded] = useState(false);

  useEffect(() => {
    ajaxGet('getPTDsForDefaultData').then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let productTypeDefinitions = ajaxData.response.productTypeDefinitions;
        productTypeDefinitions.sort((a, b) => {
          if (a.classificationLabel === b.classificationLabel) {
            return a.name.text.localeCompare(b.name.text);
          }
          return a.classificationLabel.localeCompare(b.classificationLabel);
        });
        setProductTypeDefs(productTypeDefinitions);
      }
    });

    ajaxGet('tabEditor/allCustomInstanceDef').then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setInstanceDefs(ajaxData.response.variableContentData.instanceDefs);
      }
    });
  }, []);

  useEffect(() => {
    if (!activePtd) return;

    ajaxGet('dataManagement/classificationGroupModels', {
      contentLanguage: $('#contentLangSelectElement').val() || null,
      ptdId: activePtd
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setClassificationGroups(response.classificationGroupModels);

        if (response.classificationGroupModels.length == 1) {
          const onlySelectionGroup = response.classificationGroupModels[0];
          setTargetClassificationGroup(
            onlySelectionGroup.classificationIdentifier
          );
          return;
        }

        const storedClassificationGroup = localStorage.getItem(
          LOCAL_STORAGE_CLASSIFICATION_GROUP
        );
        const lastClassificationGroup = response.classificationGroupModels.find(
          (c) => c.classificationIdentifier === storedClassificationGroup
        );
        if (lastClassificationGroup) {
          setTargetClassificationGroup(
            lastClassificationGroup.classificationIdentifier
          );
        } else {
          setTargetClassificationGroup(CLASSIFICATION_GROUP_ALL);
        }
      }
    });
  }, [activePtd]);

  const changeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  const expandCustomInstance = (customInstanceIndex, isExpanded) => {
    setCustomInstanceExpanded(isExpanded ? customInstanceIndex : false);
  };

  const selectPTD = (ptdId, ptdName, ptdClassificationName) => {
    setActivePtd(ptdId);
    setActivePtdLabel(ptdName + ' (' + ptdClassificationName + ')');
    setHideOverview(true);
  };

  function selectClassificationGroup(event) {
    const val = event.target.value;
    if (val) {
      setTargetClassificationGroup(val);
      localStorage.setItem(LOCAL_STORAGE_CLASSIFICATION_GROUP, val);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        style={{ display: hideOverview ? 'none' : null }}
      >
        <Table
          className={classes.table}
          aria-label="PtdDefaultDataOverview table"
        >
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={classes.headCell}>
                {t('productType.name')}
              </TableCell>
              <TableCell className={classes.headCell}>
                {t('productType.classification')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productTypeDefs &&
              productTypeDefs.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <MuiButton
                      onClick={() =>
                        selectPTD(
                          row.id,
                          row.name.text,
                          row.classificationLabel
                        )
                      }
                    >
                      <Icon className="fa fa-pencil fa-2x" />
                    </MuiButton>
                  </TableCell>
                  <TableCell>{row.name.text}</TableCell>
                  <TableCell>{row.classificationLabel}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {activePtd && (
        <>
          <h3 style={{ marginLeft: '1rem' }}>{activePtdLabel}</h3>
          <div>
            <span className={classes.classificationTitle}>
              {t('productType.classification')}:
            </span>
            <Select
              id="ptd-defaultvalues-classification-group-select"
              value={targetClassificationGroup}
              onChange={selectClassificationGroup}
              className={classes.classification}
            >
              {classificationGroups.map((classificationGroup) => (
                <MenuItem
                  key={classificationGroup.classificationIdentifier}
                  value={classificationGroup.classificationIdentifier}
                >
                  {classificationGroup.groupName ||
                    classificationGroup.classificationIdentifier}
                </MenuItem>
              ))}
            </Select>
          </div>
          <DataManagementProvider
            readOnlyProvided={false}
            classificationGroup={targetClassificationGroup}
          >
            <AppBar
              position="static"
              className={classes.ptdDefaultDataTabsWrap}
            >
              <Tabs
                className={classesTabs.tabs}
                value={tabValue}
                onChange={changeTab}
                aria-label="productTypeDefinition-DefaultDataTab"
              >
                <Tab label={t('data.product')} {...a11yProps(0)} />
                <Tab label={t('data.article')} {...a11yProps(1)} />
                <Tab label={t('data.customInstance')} {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel
              className={`reactInstanceEditorWrap ${classes.ptdDefaultDataTabContent}`}
              value={tabValue}
              index={0}
            >
              <InstanceTabEditorReader
                readOnly={false}
                instanceType={classNameReplacementProduct}
                instanceId={activePtd + classNameReplacementProduct}
                startingRootNodeId={'ROOT'}
                loadedNodesContent={ptdDefaultDataForProductEditor}
                loadedContentLang={$('#contentLangSelectElement').val() || null}
              />
            </TabPanel>
            <TabPanel
              className={`reactInstanceEditorWrap ${classes.ptdDefaultDataTabContent}`}
              value={tabValue}
              index={1}
            >
              <InstanceTabEditorReader
                readOnly={false}
                instanceType={classNameReplacementArticle}
                instanceId={activePtd + classNameReplacementArticle}
                startingRootNodeId={'ROOT'}
                loadedNodesContent={ptdDefaultDataForArticleEditor}
                loadedContentLang={$('#contentLangSelectElement').val() || null}
              />
            </TabPanel>
            <TabPanel
              className={`reactInstanceEditorWrap ${classes.ptdDefaultDataTabContent}`}
              value={tabValue}
              index={2}
            >
              {instanceDefs &&
                instanceDefs.map((instanceDef, index) => (
                  <Accordion
                    key={instanceDef.value}
                    expanded={customInstanceExpanded === index}
                    onChange={(_, isExpanded) =>
                      expandCustomInstance(index, isExpanded)
                    }
                    className={
                      classes.ptdDefaultDataCustomInstanceAccordionWrap
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      }
                      aria-controls={
                        'controls-ptd-defaultdata-' + instanceDef.value + index
                      }
                      id={'ptd-defaultdata-' + instanceDef.value + index}
                    >
                      <Typography className={classes.heading}>
                        {instanceDef.label}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.ptdDefaultDataCustomInstanceAccordion}
                    >
                      <PtdDefaultDataCustomInstance
                        customInstanceType={instanceDef.value}
                        ptdId={activePtd}
                        isCustomInstanceExpanded={
                          customInstanceExpanded === index
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
            </TabPanel>
          </DataManagementProvider>
        </>
      )}
    </ThemeProvider>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function removeMandatorySetup(ptdModelDefaultData) {
  for (let textType in ptdModelDefaultData.textEditorsMap) {
    if (!textType) continue;
    ptdModelDefaultData.textEditorsMap[textType].required = false;
  }

  for (let customFieldType in ptdModelDefaultData.customFields) {
    ptdModelDefaultData.customFields[customFieldType].mandatory = false;
  }

  for (let featureKey in ptdModelDefaultData.features) {
    ptdModelDefaultData.features[featureKey].mandatory = false;
  }
}
