import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle
} from '@material-ui/core';

import  { usePimStyles } from '../PimTogoTheme';
import OutputChannelForm from '../outputChannel/OutputChannelForm';


export const CreateOutputChannel = ({
  openDialogOutputChannel,
  handleCloseDialogOutputChannel,
  initFormOutputChannelState,
  toCreateOutputChannel,
  contentLangSelections,
  searchOutputChannels,
  
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();

  useEffect(() => {}, []);

  return (
    <Dialog
      aria-labelledby="create-instance-title"
      open={openDialogOutputChannel}
      onClose={handleCloseDialogOutputChannel}
      className={classesPim.dialog}
      transitionDuration={0}
    >
      <DialogTitle
        id="create-instance-title"
        className={classesPim.dialogTitle}
      >        {toCreateOutputChannel ? t('outputChannel.createTitle') : t('outputChannel.editTitle') }

      </DialogTitle>
      <OutputChannelForm
        handleCloseDialogOutputChannel={handleCloseDialogOutputChannel}
        initFormOutputChannelState={initFormOutputChannelState}
        toCreateOutputChannel={toCreateOutputChannel}
        searchOutputChannels={searchOutputChannels}
        contentLangSelections={contentLangSelections}
      />
    </Dialog>
  );
};

export default CreateOutputChannel;
