import React from 'react';
import ReactDOM from 'react-dom';

export default function renderReactComponentInTarget(
  reactComponent,
  targetDiv,
  loadEvent,
  preventRenderInitial
) {
  if (!loadEvent) {
    loadEvent = 'togo.navigationloadpage';
  }

  const renderReactHere = () => {
    const $target = $(targetDiv);
    if ($target.length) {
      ReactDOM.render(React.createElement(reactComponent), $target[0]);
    }
  };

  if (!preventRenderInitial) {
    renderReactHere();
  }

  TOGO.viewStack.getContent().on(loadEvent, renderReactHere);
}
