import React, { useState, useEffect } from 'react';

import { useImmer } from 'use-immer';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useDataManagement } from '../../datamanagement/DataManagementContext';
import { MultipleInstanceTable } from './MultipleInstanceTable';
import { MultipleInstanceTab } from './MultipleInstanceTab';
import { CLASSIFICATION_GROUP_ALL } from '../../datamanagement/InstanceEditor';

import ajaxGet from 'src/services/ajaxGet';

export const MultipleInstance = ({
  parentNode,
  parentNodeTypeName,
  nodesContent,
  parentInstanceEditor,
  targetInstanceType
}) => {
  const {
    setMultipleStances,
    setVariableContentDefs,
    setFeatureDefs,
    getPtdId,
    getClassificationGroup
  } = useDataManagement();
  const [multipleInstanceData, setMultipleInstanceData] = useImmer({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  let classificationGroup = getClassificationGroup();
  if (classificationGroup === CLASSIFICATION_GROUP_ALL) {
    classificationGroup = null;
  }

  useEffect(() => {
    const ptdId = getPtdId();

    ajaxGet('dataManagement/childrenInstances', {
      id: parentInstanceEditor.instanceId,
      instanceTypeParent: parentInstanceEditor.instanceType,
      instanceTypeTarget: targetInstanceType,
      ptdId: ptdId,
      classificationGroup: classificationGroup,
      contentLanguage: parentInstanceEditor.contentLang
    }).then((ajaxData) => {
      setError(ajaxData.error);

      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setVariableContentDefs(
          targetInstanceType,
          response.variableContentDefs
        );

        const newMultipleInstanceData = { instanceType: targetInstanceType };
        if (response.childrenInstanceModels) {
          newMultipleInstanceData.childrenInstances =
            response.childrenInstanceModels;
        } else if (response.articlesModel) {
          newMultipleInstanceData.childrenInstances =
            response.articlesModel.articleModels;

          setFeatureDefs(response.articlesModel.features);
        } else {
          setError('Error');
        }

        setMultipleInstanceData(null);
        setMultipleInstanceData(newMultipleInstanceData);

        setMultipleStances(
          targetInstanceType,
          newMultipleInstanceData.childrenInstances.map(
            (instance) => instance.id
          )
        );
      }

      setLoading(false);
    });
  }, [classificationGroup]);

  if (!multipleInstanceData) {
    return null;
  } else if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return <LinearProgress color="secondary" />;
  } else {
    switch (parentNodeTypeName) {
      case 'Tabs': {
        if (!multipleInstanceData.childrenInstances.length) {
          return null;
        }
        return (
          <MultipleInstanceTab
            multipleInstanceData={multipleInstanceData}
            parentNode={parentNode}
            parentInstanceEditor={parentInstanceEditor}
            nodesContent={nodesContent}
          ></MultipleInstanceTab>
        );
      }
      case 'Table': {
        return (
          <MultipleInstanceTable
            multipleInstanceData={multipleInstanceData}
            instanceType={targetInstanceType}
            parentNode={parentNode}
            parentInstanceEditor={parentInstanceEditor}
            nodesContent={nodesContent}
          ></MultipleInstanceTable>
        );
      }
      default:
        return null;
    }
  }
};
