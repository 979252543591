import React, { useState, useEffect  } from 'react';
import {
  IconButton,

  Typography,
  TextField,
  Button
  
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PaginationOutputChannel from './PaginationOutputChannel';
import ajaxGet from 'src/services/ajaxGet';
import theme, { usePimStyles } from '../PimTogoTheme';
import PersonAddAltIcon from '@material-ui/icons/PersonAdd';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CreateOutputChannel from './CreateOutputChannel';
import EnhancedTableOutputChannel from './EnhancedTableOutputChannel';


const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));

const INITIAL_FORM_STATE_CLASSIFICATION_GROUP = {
  id:'',
  classificationIdentifier : '',
  classificationGroupName: '',
};

export default function OutputChannel() {
  const classes = useStyles();
  const classesPim = usePimStyles();
  const { t } = useTranslation();

  const [searchExpression, setSearchExpression] = useState('');
  const [outputChannels, setOutputChannels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(5);
  const [paginationstate, setPaginationstate] = useState({});
  const [openDialogOutputChannel, setOpenDialogOutputChannel] = useState(false);
  const [toCreateOutputChannel, setToCreateOutputChannel] = useState(true);
  const [contentLangSelections, setContentLangSelections] = useState({});


  const [initFormOutputChannelState, setInitFormOutputChannelState] = useState(
    INITIAL_FORM_STATE_CLASSIFICATION_GROUP
  );

  const handelEventSearchOutputChannel = () => {
    setCurrentPage(1);
    setElementsPerPage(50);
    searchOutputChannels();
  };

  const searchOutputChannels = (showFirstUserPage) => {

    let targetPage = currentPage;
    if (showFirstUserPage) {
      targetPage = 1;
    }

   ajaxGet('outputchannelsManagement/search', {
      searchExpression: searchExpression,
      currentPage: targetPage,
      elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setOutputChannels(response.articleDataOutputChannelList);
        setPaginationstate(response.pagination);
      }
    });
  };

  useEffect(() => {
    searchOutputChannels();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickOpenDialogOutputChannel = () => {
    setOpenDialogOutputChannel(true);
  };

  const addUserhandel = () => {
    setOpenDialogOutputChannel(true);
    const outputChannelForm = {
      id:'',
      classificationGroupName: ''
    };

    setInitFormOutputChannelState(outputChannelForm);
  };

  const handleCloseDialogOutputChannel = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
      setOpenDialogOutputChannel(false);
      setToCreateOutputChannel(true);
      setInitFormOutputChannelState(INITIAL_FORM_STATE_CLASSIFICATION_GROUP);
    
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCurrentPage(1);
      setElementsPerPage(50);
      searchOutputChannels();
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid className={classes.root}>
          <form>
            <TextField
              label={t('manageOrganization.organization.search')}
              margin="normal"
              id="user-name-search"
              fullWidth
              value={searchExpression}
              onChange={handleChange}
              onKeyDown={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handelEventSearchOutputChannel()}
                    position="start"
                  >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Grid>
        <Grid className={classes.root}>
          {false && (
            <PaginationOutputChannel
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
            />
          )}
        </Grid>
        {'   '}
        <Grid className={classes.root}>
          <Button
            variant="outlined"
            onClick={() => {
              setToCreateOutputChannel(true);
              addUserhandel();
            }}
            startIcon={<PersonAddAltIcon />}
            className={`${classesPim.addAction} ${classes.submitButton}`}
          >
            <Typography fontSize={5}>{t('outputChannel.createTitle')} </Typography>
          </Button>

          <CreateOutputChannel
            openDialogOutputChannel={openDialogOutputChannel}
            handleCloseDialogOutputChannel={handleCloseDialogOutputChannel}
            initFormOutputChannelState={initFormOutputChannelState}
            toCreateOutputChannel={toCreateOutputChannel}
            searchOutputChannels={searchOutputChannels}
            contentLangSelections={contentLangSelections}
          />

          <EnhancedTableOutputChannel
            outputChannels={outputChannels}
            setInitFormOutputChannelState={setInitFormOutputChannelState}
            setOutputChannels={setOutputChannels}
            setToCreateOutputChannel={setToCreateOutputChannel}
            handleClickOpenDialogOutputChannel={handleClickOpenDialogOutputChannel}
            searchOutputChannels={searchOutputChannels}
            setContentLangSelections={setContentLangSelections}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
}
