import { createTheme, makeStyles } from '@material-ui/core/styles';
import { deDE, enUS, frFR, itIT } from '@material-ui/core/locale';

let materialUiLang;
switch (document.documentElement.lang) {
  case 'de':
    materialUiLang = deDE;
    break;
  case 'en':
    materialUiLang = enUS;
    break;
  case 'fr':
    materialUiLang = frFR;
    break;
  case 'it':
    materialUiLang = itIT;
    break;

  default:
    materialUiLang = enUS;
    break;
}

const theme = createTheme(
  {
    palette: {
      primary: {
        light: '#6b93ff',
        main: '#0066ff',
        dark: '#003dcb',
        contrastText: '#fff'
      },
      secondary: {
        light: '#ffca47',
        main: '#ff9900',
        dark: '#c66a00',
        contrastText: '#fff'
      }
    },
    typography: {
      htmlFontSize: 10
    }
  },
);
export default theme;

export const usePimStyles = makeStyles(() => ({
  addAction: {
    color: 'white',
    backgroundColor: '#5cb85c !important',
    borderColor: '#5cb85c',

    '&:hover': {
      backgroundColor: '#458c45 !important'
    }
  },
  deleteAction: {
    color: 'white',
    backgroundColor: '#d9534f',
    borderColor: '#d43f3a',
    '&:hover': {
      backgroundColor: '#aa3e3b'
    }
  },
  dialog: {
    '& .MuiDialog-container > .MuiDialog-paper': {
      borderRadius: '0.6rem',
      boxShadow: '0 0 1em black',
      '& > div > .MuiPaper-root': {
        padding: '0 2rem !important',
        boxShadow: 'none',
        overflow: 'auto',
        maxHeight: '70vh'
      }
    }
  },
  dialogTitle: {
    backgroundColor: '#4d4c47',
    position: 'sticky',
    top: '0',
    zIndex: '9',
    '& > *': {
      color: 'white !important',
      fontSize: '2.5rem'
    }
  },
  dialogBottom: {
    position: 'sticky',
    bottom: '0',
    zIndex: '9',
    marginTop: '1rem'
  },
  gridItem: {
    margin: '8px'
  },
  body:{
    backgroundImage: 'url(../../../images/Blur.jpg)',
    backgroundRepeat: 'no-repeat',
    opacity: '5px'
  }
  , card :{
  padding: '10px !important',
	boxShadow: '1px 1px 5px black !important',
	borderColor: 'rgba(236, 232, 232, 0.733) !important',
	borderRadius: '5px !important',
  maxHeight:'5.8rem !important'
  },
  titleItem :{
	fontWight:'800 !important',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
},
imageItem :{
	width: '100px !important',
	height: '106px !important',
	/* border: 1px solid black ; */
	borderRadius:'25% !important',
	position: 'relative',
	left: '-27px'

},
itemList:{
  // width: '80% !important'
 }
 , overviewListing: {
	fontSize: '1.2rem',
	position: 'relative',
},
paginationContent: {
	clear:' both',
	position: 'relative'
},paginationPageTurner:{
  display: 'inline-block',
  width: '100%',
  position: 'relative',
/*     margin-right: 2rem; */
  verticalAlign: 'top',
}
}));


// body {
// 	background-image: url(../../../images/Blur.jpg);
// 	background-repeat: no-repeat;
// 	opacity: 5px;

// }

// .card {
// 	padding: 10px !important;
// 	box-shadow: 1px 1px 5px black !important;
// 	border-color: rgba(236, 232, 232, 0.733) !important;
// 	border-radius: 35px !important;
// }
// .item-list{
// 	width: 80% !important;
// }


// .title-item{
// 	font-weight: 800 !important;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;

// }
// *{
// 	box-sizing: content-box !important;
// }

// .image-item{
// 	width: 100px !important;
// 	height: 106px !important;
// 	/* border: 1px solid black ; */
// 	border-radius:25% !important;
// 	position: relative;
// 	left: -27px;

// }

// .card-container{
// 	background-image: url(../../../images/Blur.jpg);

// }

// .mat-list .mat-list-item.mat-3-line{
// 	display: block !important; 
// 	height: 48px !important;
// }
