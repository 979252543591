import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'src/i18n';

import { useDataManagement } from '../DataManagementContext';

import { OptionsEditor, AddOptionSetupChoice } from '../OptionsEditor';

const IRRELEVANT_VALUE = 'irrelevantValue';

export const getSelectionPayloadData = (isMultiSelection, value) => {
  let selectionPayload;
  if (isMultiSelection) {
    selectionPayload = { multiSelection: value };
    if (value?.selected?.length) {
      const multiSelection = value.selected;
      selectionPayload = { multiSelection: multiSelection };
    } else {
      return null;
    }
  } else {
    if (value?.selected?.length) {
      const singleSelection = value.selected[0];
      selectionPayload = { singleSelection: singleSelection };
    } else {
      return null;
    }
  }
  return selectionPayload;
};

export const Selection = ({
  featureInfo,
  value,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const IRRELEVANT_OPTION = {
    selectionValue: IRRELEVANT_VALUE,
    addToValueList: false,
    addedSelectionOption: false,
    label: t('instanceEditor.irrelevant')
  };

  const {
    getCustomFieldCommonSelectionOptions,
    setCustomFieldCommonSelectionOptions
  } = useDataManagement();

  const [standardOptions, setStandardOptions] = useState([]);
  const [blurValue, setBlurValue] = useState([]);

  const isClassificationFeature = Boolean(featureInfo.classificationIdentifier);

  const isMultiSelection = featureInfo.featureComponent.multiSelection;

  const addOptionMsg = i18n.t('instanceEditor.selection.addOption');
  const addCommonOptionMsg = i18n.t('instanceEditor.selection.commonOptions');
  const addCustomOptionMsg = i18n.t('instanceEditor.selection.customOptions');
  const customKeywordsGroupLabel = i18n.t(
    'instanceEditor.selection.customOptions'
  );

  const hasDefaultData = featureInfo.hasDefaultData;
  let contentIdentifier;
  let addOptionSetup = null;

  let options = [];
  let initialStandardOptions;

  if (isClassificationFeature) {
    contentIdentifier = featureInfo.headerCode;

    if (featureInfo.featureComponent.isAddableOption) {
      addOptionSetup = AddOptionSetupChoice.asCustomValue;
    }

    initialStandardOptions = value ? value.standardOptions : [];
  }
  // customField:
  else {
    contentIdentifier = featureInfo.featureIdentifier;

    if (featureInfo.featureComponent.isAddableOption) {
      addOptionSetup = featureInfo.featureComponent.addValueList;
    }

    if (featureInfo.customFieldNotCommonOptions) {
      initialStandardOptions = value ? value.standardOptions : [];
    } else {
      initialStandardOptions =
        getCustomFieldCommonSelectionOptions(contentIdentifier);
    }
  }

  let irrelevantValue = featureInfo.featureComponent?.irrelevantFeatureValue;
  const irrelevantFeaturePossible = Boolean(
    featureInfo.featureComponent?.irrelevantFeatureValuePossible
  );

  useEffect(() => {
    if (irrelevantFeaturePossible) {
      const initOpsWithIrrevant = JSON.parse(
        JSON.stringify(initialStandardOptions)
      );
      initOpsWithIrrevant.unshift(IRRELEVANT_OPTION);

      setStandardOptions(initOpsWithIrrevant);

      if (irrelevantValue) {
        const selectedIrrelevantState = {
          selected: [IRRELEVANT_OPTION],
          customOptions: [],
          standardOptions: initOpsWithIrrevant
        };

        setValueState(selectedIrrelevantState);
        setBlurValue([IRRELEVANT_OPTION]);
      }
    } else {
      setStandardOptions(initialStandardOptions);
    }
  }, []);

  options = options.concat(standardOptions);
  options = value ? options.concat(value.customOptions) : options;

  const selected = value ? value.selected : [];

  function saveSelection(newSelection, optionToAdd) {
    if (optionToAdd) {
      newSelection.push(optionToAdd);
    }

    let payloadNewSelection = newSelection;
    let selectionPayload = {};
    if (newSelection && newSelection.length) {
      let difference = newSelection.filter((x) => !blurValue.includes(x));

      if (
        difference.some(
          (element) => element.selectionValue === IRRELEVANT_VALUE
        )
      ) {
        newSelection = [IRRELEVANT_OPTION];
        selectionPayload.irrelevantFeatureValue = true;
        irrelevantValue = true;
        payloadNewSelection = [];
      } else {
        newSelection = newSelection.filter(
          (element) => element.selectionValue != IRRELEVANT_VALUE
        );
        payloadNewSelection = newSelection;
      }
    }

    if (isMultiSelection) {
      selectionPayload.multiSelection = payloadNewSelection;
    } else {
      const singleSelection = newSelection.length ? newSelection[0] : null;
      selectionPayload.singleSelection = singleSelection;
    }

    let changedValue;
    if (!newSelection.length) {
      if (featureInfo.mandatory && !hasDefaultData) return;
      changedValue = null;
    } else {
      const selectionState = { selected: newSelection };
      selectionState.customOptions = newSelection.filter(
        (option) => option.addedSelectionOption
      );
      if (isClassificationFeature) {
        selectionState.standardOptions = standardOptions;
      }

      changedValue = selectionState;
    }

    saveChangedValue(
      changedValue,
      selectionPayload,
      setSelectionsStateAfterSaving
    );

    let serverResponse = null;

    if (changedValue) {
      setBlurValue(changedValue.selected);
    } else {
      setBlurValue([]);
    }

    function getNewSelectionState() {
      if (optionToAdd) {
        optionToAdd.label = optionToAdd.selectionValue;

        if (!isClassificationFeature && optionToAdd.addToValueList) {
          optionToAdd.selectionValue =
            serverResponse?.id || optionToAdd.selectionValue;
          optionToAdd.addToValueList = false;
          optionToAdd.addedSelectionOption = false;

          setCustomFieldCommonSelectionOptions(
            contentIdentifier,
            standardOptions.concat(optionToAdd)
          );
        }
      }

      const newSelectionState = { selected: newSelection };
      newSelectionState.customOptions = newSelection.filter(
        (option) => option.addedSelectionOption
      );
      if (isClassificationFeature) {
        newSelectionState.standardOptions = standardOptions;
      }

      return newSelectionState;
    }

    function setSelectionsStateAfterSaving(response) {
      serverResponse = response;
      const newSelectionStateAfterSaving = getNewSelectionState();
      setValueState(newSelectionStateAfterSaving);
    }
  }

  return (
    <OptionsEditor
      options={options}
      selectedOptions={selected}
      saveChanges={saveSelection}
      title={featureInfo.title}
      isMultiSelection={isMultiSelection}
      mandatory={featureInfo.mandatory && !hasDefaultData}
      addOptionSetup={addOptionSetup}
      addOptionMsg={addOptionMsg}
      addCommonOptionsMsg={addCommonOptionMsg}
      addCustomOptionMsg={addCustomOptionMsg}
      customOptionsGroupLabel={customKeywordsGroupLabel}
      irrelevantValue={irrelevantValue}
      loading={loading}
      error={error}
    />
  );
};
