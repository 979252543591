import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs as MuiTabs, Tab as MuiTab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useStylesTabs, TabsWrap, OrientationValues } from '../tools/Tabs';

export const useTabsContentStyles = makeStyles(() => ({
  root: {
    '& .instanceEditorComponent': {
      margin: '2rem 1rem'
    }
  },
  two: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 0 100%'
    },
    '& > .instanceEditorComponent': {
      flex: '1 0 48% !important'
    }
  }
}));

export const TabPanelDisplay = ({
  tabId,
  selectedTabIndex,
  index,
  containerFlexColumns,
  children
}) => {
  const classes = useTabsContentStyles();
  return (
    <div
      className="TabPanel"
      role="tabpanel"
      hidden={selectedTabIndex !== index}
      id={`tabpanel-${tabId}-${index}`}
      aria-labelledby={`tab-${tabId}-${index}`}
    >
      {selectedTabIndex === index && (
        <Box
          p={3}
          className={`childrenTabPanel ${classes.root} ${
            containerFlexColumns === '2' ? classes.two : null
          }`}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
TabPanelDisplay.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  selectedTabIndex: PropTypes.any.isRequired,
  containerFlexColumns: PropTypes.any
};

function a11yProps(tabId, index) {
  return {
    id: `tab-${tabId}-${index}`,
    'aria-controls': `tabpanel-${tabId}-${index}`
  };
}

export const TabsDisplay = ({ tabs, orientation, isShortVerticalTab }) => {
  const classes = useStylesTabs();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const isVertical = orientation === OrientationValues.vertical;

  useEffect(() => {

    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has('articleId')) {
      const indexTargetArticleTab = tabs.findIndex((tab) => {
        const dataReqArticle = tab.dataReq?.Article;
        if (dataReqArticle) {
          return (
            Object.keys(tab.dataReq).length === 1 &&
            Boolean(dataReqArticle.CustomField?.bulkDataType_custom_fields) &&
            Boolean(dataReqArticle.Features?.bulkDataType_features) &&
            Boolean(dataReqArticle.GeneralField?.keyword)
          );
        }
      });

      if (indexTargetArticleTab != -1) {
        setSelectedTabIndex(indexTargetArticleTab);
      }
    }
  }, []);

  function selectTab(_e, newValue) {
    setSelectedTabIndex(newValue);
  }

  return (
    <div className={isVertical ? classes.verticalFlexWrap : null}>
      <TabsWrap orientation={orientation}>
        <MuiTabs
          value={selectedTabIndex}
          onChange={selectTab}
          orientation={orientation}
          variant="scrollable"
          scrollButtons="on"
          className={`${isVertical ? classes.tabsVertical : classes.tabs} ${
            isShortVerticalTab ? classes.shortVerticalTab : null
          }`}
          classes={
            isVertical
              ? {
                  flexContainerVertical: classes.flexContainerVertical
                }
              : null
          }
        >
          {tabs.map((tab, index) => (
            <MuiTab
              key={tab.id}
              {...a11yProps(tab.id, index)}
              label={
                <p
                  style={{ fontSize: '1.5rem' }}
                  className={isVertical ? classes.tabTitleVertical : null}
                >
                  {tab.label[document.documentElement.lang] ||
                    tab.label.noLangLabel}
                </p>
              }
            />
          ))}
        </MuiTabs>
      </TabsWrap>
      <div className="tabsContentWrap" style={{ flex: 1 }}>
        {tabs.map((tab, index) => (
          <TabPanelDisplay
            key={tab.id}
            tabId={tab.id}
            selectedTabIndex={selectedTabIndex}
            index={index}
            orientation={orientation}
          >
            {tab.content}
          </TabPanelDisplay>
        ))}
      </div>
    </div>
  );
};
TabsDisplay.propTypes = {
  tabs: PropTypes.any
};
