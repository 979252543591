import React, { useState, useEffect } from 'react';
import ajaxPost from 'src/services/ajaxPost';
import {
  IconButton,
  Button,
  Typography
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import PaginationComponent from '../user/PaginationComponent';
import ajaxGet from 'src/services/ajaxGet';
import theme, { usePimStyles } from '../PimTogoTheme';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useTitleStyles } from '../articledata/datamanagement/featurecomponents/FeatureComponent';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));

export default function Organizations() {
  const { t } = useTranslation();
  const classesPim = usePimStyles();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [classificationName, setClassificationName] = useState('');
  const classesTitle = useTitleStyles();
  
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'black',
      background: '#ebddbad1',
      border: '0.5px solid',
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: '#625c5652'
      }
    }
  });

  function onClassificationNameChange(event) {
    setClassificationName(event.target.value);
  }
  
  function handleExportPopup() {
	ajaxPost('exportClassification', { classificationName: classificationName }).then((ajaxData) => {
        let response = ajaxData.response;
        TOGO.Util.notifyResponse(response.message, !response.success);

        if (!ajaxData.error && response) {
			let url = ajaxData.response?.url;

			TOGO.popupManager.confirmZebraDialog(
				t('manageClassificator.classificator.exportFinished'), 
				openClassificator);
				
			function openClassificator(){
				window.open(url);
  			}
  
        } else {
			let msg = ajaxData.response?.message || ajaxData.error;
			TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
		}
      });
  }

  function verifyClassificationName(classificationName) {
    if (classificationName!=null && classificationName.length>0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

 return (
	<div className={classes.root}>
        <div>
          <Grid item>
              <TextField
                id="outlined-basic"
                label= {t('manageClassificator.classificator.classificationName')}
                margin="normal"
                type="classificationName"
                InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                variant={'outlined'}
                fullWidth
				value={classificationName}
				onChange={onClassificationNameChange}
				onInput={() => {
					verifyClassificationName(classificationName);
				}}
              />
          </Grid>
		</div>
          
		<div>
			<ThemeProvider theme={theme}>
			<>
				<Grid item>
					<Button
						variant="contained"
						onClick={() => {
						handleExportPopup()
						}}
						disabled={disabled}
						className={`${classesPim.addAction} ${classes.submitButton}`}
					>
					<Typography fontSize={5}>{t('manageClassificator.classificator.createTitle')}</Typography>
					</Button>
				</Grid>
			</>
			</ThemeProvider>
		</div>
	</div>
  );
}