import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles
} from '@material-ui/core';

import { lightPimColor } from '../PimTogoTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2rem 0'
  },
  panelConfigAccordionSummary: {
    backgroundColor: lightPimColor
  },
  panelConfigAccordionDetail: {
    paddingTop: '2rem'
  },
  panelBaseConfig: {
    display: 'flex'
  },
  panelBaseConfigFormControl: {
    margin: theme.spacing(3)
  }
}));

export function PanelBaseConfig({
  PanelConfig,
  configI18Key,
  triggerConfigSave,
  saveConfigAll,
  panelItem,
  orgaData,
  setPanelTitle
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [configExpanded, setConfigExpanded] = useState('panelConfig');

  const [titleVisible, setTitleVisible] = useState(true);
  const [closable, setClosable] = useState(true);
  const [movable, setMovable] = useState(true);
  const [resizableX, setResizableX] = useState(true);
  const [resizableY, setResizableY] = useState(true);

  useEffect(() => {
    setTitleVisible(panelItem.configuration.titleVisible);
    setClosable(panelItem.configuration.closable);
    setMovable(panelItem.configuration.movable);
    setResizableX(panelItem.configuration.resizableX);
    setResizableY(panelItem.configuration.resizableY);
  }, []);

  function saveConfig({ changedPanel, keepConfigDialogOpen }) {
    changedPanel.configuration = {
      ...changedPanel.configuration,
      titleVisible: titleVisible,
      closable: closable,
      movable: movable,
      resizableX: resizableX,
      resizableY: resizableY
    };

    saveConfigAll({ changedPanel, keepConfigDialogOpen });
  }

  function changeActiveConfig(isExpanded, configType) {
    setConfigExpanded(isExpanded ? configType : false);
  }

  function toggleBaseConfigCheckbox(event) {
    const checked = event.target.checked;

    switch (event.target.name) {
      case 'titleVisible':
        setTitleVisible(checked);
        break;
      case 'closable':
        setClosable(checked);
        break;
      case 'movable':
        setMovable(checked);
        break;
      case 'resizableX':
        setResizableX(checked);
        break;
      case 'resizableY':
        setResizableY(checked);
        break;
    }
  }

  return (
    <div className={classes.root}>
      <Accordion
        expanded={configExpanded === 'panelConfig'}
        onChange={(_, isExpanded) => {
          changeActiveConfig(isExpanded, 'panelConfig');
        }}
      >
        <AccordionSummary
          className={classes.panelConfigAccordionSummary}
          expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
          aria-controls="dashboard-panel-accordion-config"
          id="dashboard-panel-accordion-configheader"
        >
          {t(configI18Key)}
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.panelConfigAccordionDetail}>
            <PanelConfig
              triggerConfigSave={triggerConfigSave}
              saveConfig={saveConfig}
              orgaData={orgaData}
              panelItem={panelItem}
              setPanelTitle={setPanelTitle}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={configExpanded === 'baseConfig'}
        onChange={(_, isExpanded) => {
          changeActiveConfig(isExpanded, 'baseConfig');
        }}
      >
        <AccordionSummary
          className={classes.panelConfigAccordionSummary}
          expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
          aria-controls="dashboard-panel-accordion-baseconfig"
          id="dashboard-panel-accordion-baseconfigheader"
        >
          {t('dashboard.panel.panelBaseConfig')}
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }}>
          <div className={classes.panelConfigAccordionDetail}>
            <div className={classes.panelBaseConfig}>
              <FormControl
                component="fieldset"
                className={classes.panelBaseConfigFormControl}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={titleVisible}
                        onChange={toggleBaseConfigCheckbox}
                        name="titleVisible"
                      />
                    }
                    label={t('dashboard.panel.titleVisible')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={closable}
                        onChange={toggleBaseConfigCheckbox}
                        name="closable"
                      />
                    }
                    label={t('dashboard.panel.closable')}
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                component="fieldset"
                className={classes.panelBaseConfigFormControl}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={movable}
                        onChange={toggleBaseConfigCheckbox}
                        name="movable"
                      />
                    }
                    label={t('dashboard.panel.movable')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resizableX}
                        onChange={toggleBaseConfigCheckbox}
                        name="resizableX"
                      />
                    }
                    label={t('dashboard.panel.resizableX')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resizableY}
                        onChange={toggleBaseConfigCheckbox}
                        name="resizableY"
                      />
                    }
                    label={t('dashboard.panel.resizableY')}
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
