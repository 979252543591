import React, { useState  } from 'react';

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {  TextField, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function SearchProductInput() {

  const { t } = useTranslation();
  const [searchExpression, setSearchExpression] = useState('');

  const handelEventSearchOutputChannel = () => {
    searchProduct();
  };
  
  window.processData = function(data) {
    // do something with your data like
    console.log("MainComponent received data:", data);
}

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  const searchProduct = () => {
    window.processData("myDataString");
  };
  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      searchProduct();
    }
  };

  return (
    <form>
    <TextField
      label={t('manageOrganization.organization.search')}
      margin="normal"
      
      fullWidth
      value={searchExpression}
      onChange={handleChange}
      onKeyDown={handleKeypress}
      InputProps={{
        endAdornment: (
          <InputAdornment
            // onClick={() => handelEventSearchOutputChannel()}
            position="start"
            id="product_search"
          >
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  </form>
  );
}
