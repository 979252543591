import React, { useContext, useState } from 'react';

import i18n from 'src/i18n';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField, LinearProgress } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import EllipsisToolTip from 'ellipsis-tooltip-react-chan';

import { TableDataContext } from '../tabeditor/tools/Table';
import { useTitleStyles } from './featurecomponents/FeatureComponent';
import { VariableSizeList } from 'react-window';
import ListSubheader from '@material-ui/core/ListSubheader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const defaultFilterOptions = createFilterOptions();
const LISTBOX_PADDING = 0; // px

const OuterElementContext = React.createContext({});

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  },
  singleSelection: {
    '& > .MuiAutocomplete-inputRoot > .MuiAutocomplete-input ': {
       width: '18rem !important'
     }
  },
  optionCheckbox: {
    marginRight: 8
  },
  optionLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const OuterElementType = React.forwardRef(function OuterElementType(
  props,
  ref
) {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export const AddOptionSetupChoice = {
  asCustomValue: 'ADD_VALUE_LIST_NEVER',
  toCommonValueList: 'ADD_VALUE_LIST_ALWAYS',
  question: 'ADD_VALUE_LIST_QUESTION'
};

export const OptionsEditor = ({
  options,
  selectedOptions,
  saveChanges,
  title,
  isMultiSelection,
  mandatory,
  addOptionSetup,
  addOptionMsg,
  addCommonOptionsMsg,
  addCustomOptionMsg,
  customOptionsGroupLabel,
  irrelevantValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  title = isInTableContext ? null : title;

  const isMandatoryError =
    !irrelevantValue &&
    mandatory &&
    (!selectedOptions || !selectedOptions.length);

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError) {
    helper = t('common.errorMandatory');
  }

  if (!isMultiSelection) {
    //single selection with selected:
    if (selectedOptions && selectedOptions.length) {
      selectedOptions = selectedOptions[0];
    }
    //empty single selection:
    else {
      selectedOptions = null;
    }
  }

  function selectOptions(_, changedSelectedOptions) {
    if (loading) return;

    if (!isMultiSelection) {
      changedSelectedOptions = changedSelectedOptions
        ? [changedSelectedOptions]
        : [];
    }

    let optionToAdd = null;
    const addOptionIndex = changedSelectedOptions.findIndex(
      (option) => option.addOption === true
    );
    if (addOptionIndex != -1) {
      changedSelectedOptions.splice(addOptionIndex, 1);

      optionToAdd = {
        selectionValue: inputValue,
        addedSelectionOption: true
      };

      if (addOptionSetup === AddOptionSetupChoice.asCustomValue) {
        saveAndAddNewToCustomOptions();
      } else if (addOptionSetup === AddOptionSetupChoice.toCommonValueList) {
        saveAndAddNewToCommonValueList();
      } else if (addOptionSetup === AddOptionSetupChoice.question) {
        TOGO.popupManager.chooseDialog({
          chooseMsg: addOptionMsg,
          firstOptionText: addCommonOptionsMsg,
          firstOptionCallback: saveAndAddNewToCommonValueList,
          secondOptionText: addCustomOptionMsg,
          secondOptionCallback: saveAndAddNewToCustomOptions
        });
      }
    } else {
      saveChanges(changedSelectedOptions);
      setInputValue('');
    }

    function saveAndAddNewToCustomOptions() {
      optionToAdd.addToValueList = false;
      saveChanges(changedSelectedOptions, optionToAdd);
      setInputValue('');
    }

    function saveAndAddNewToCommonValueList() {
      optionToAdd.addToValueList = true;
      saveChanges(changedSelectedOptions, optionToAdd);
      setInputValue('');
    }
  }

  function getLabel(targetValue) {
    const targetOption = options.find(
      (option) => option.selectionValue === targetValue
    );
    return targetOption?.label;
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <Autocomplete
      className={
        isMultiSelection
          ? `options-editor multi-options-editor ${classes.listbox}`
          : `options-editor ${classes.listbox}`
      }
      multiple={isMultiSelection}
      value={selectedOptions}
      onChange={selectOptions}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      // disableListWrap
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          InputLabelProps={{
            shrink: false,
            disableAnimation: true,
            className: classesTitle.titleSelect
          }}
          className={classes.singleSelection}
          variant={isInTableContext ? 'standard' : 'outlined'}
          error={Boolean(error) || isMandatoryError}
          FormHelperTextProps={{ component: 'div' }}
          helperText={helper}
          onMouseEnter={showError}
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
      disableClearable={mandatory}
      options={options}
      getOptionLabel={(option) => {
        if (option.addOption) {
          return '';
        } else {
          return option.label;
        }
      }}
      groupBy={(option) => {
        if (option.addedSelectionOption && !option.addToValueList) {
          return customOptionsGroupLabel;
        }
        return null;
      }}
      getOptionSelected={(option, valueItem) =>
        option.selectionValue === valueItem.selectionValue
      }
      renderTags={(selection) =>
        selection.map((selectedOption, index) => (
          <span
            key={selectedOption.selectionValue + index}
            className="selected-tags"
          >
            {index == 1 && <span style={{ margin: '0 2px' }}>|</span>}
            <EllipsisToolTip insecure={false} effect="float">
              {getLabel(selectedOption.selectionValue)}
            </EllipsisToolTip>
          </span>
        ))
      }
      filterOptions={(options, state) => {
        const results = defaultFilterOptions(options, state);
        if (addOptionSetup && results.length === 0) {
          return [optionToAddInput];
        }
        return results;
      }}
      noOptionsText={t('common.noResults')}
      renderOption={(option, { selected }) => {
        if (option.addOption) {
          return option.button;
        } else {
          return (
            <>
              <Checkbox className={classes.optionCheckbox} checked={selected} />
              <span title={option.label} className={classes.optionLabel}>
                {option.label}
              </span>
            </>
          );
        }
      }}
      size="small"
      limitTags={2}
      fullWidth={false}
      style={{ width: 'auto' }}
      autoHighlight
      clearOnBlur={true}
      disableCloseOnSelect
    ></Autocomplete>
  );
};

const optionToAddInput = {
  addOption: true,
  button: (
    <button
      className="btn btn-success iconButton"
      type="button"
      style={{ width: '100%' }}
    >
      <i className="fa fa-plus" aria-hidden="true"></i>
      <span>{i18n.t('common.add')}</span>
    </button>
  )
};

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node
};

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
}
const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children
];
