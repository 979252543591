import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const SelectWrapper = ({
  name,
  options,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = evt => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange
  };


  if (name != "language" ){

    if (meta && meta.touched && meta.error) {
      configSelect.error = true;
      configSelect.helperText = meta.error;
    }

    return (
      <TextField {...configSelect}>
       {options =! undefined && Object.entries(options).map((item, pos) => {
          return (
            <MenuItem key={pos} value={item[0]+ "|" + item[1]}>
              {item[1]}
            </MenuItem>
          );
        })}
      </TextField>
    );
  
  } else {
    return (
      <TextField {...configSelect}>
       {options =! undefined && options.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
          );
        })}
      </TextField>
    );
    
  }

};

export default SelectWrapper;
