import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';

export const PANELTYPE = 'SubscriptionQuota';
export const SUB_QUOTA_I18KEY = 'dashboard.subscription.subQuota';

export function getSubQuotaTitle({ orgaData, panelItem, selectedQuota }) {
  let title = PANELTYPE;

  if (!selectedQuota) {
    selectedQuota = panelItem.configuration.selectedQuota;
  }

  if (selectedQuota && orgaData.subscriptionInfo) {
    const selectedQuotaItem = orgaData.subscriptionInfo.subscriptionQuotaFeatureList.find(
      (quota) => quota.name == selectedQuota
    );
    title = selectedQuotaItem.label;
  }

  return title;
}

export function SubQuotaPanelConfig({
  triggerConfigSave,
  saveConfig,
  orgaData,
  panelItem,
  setPanelTitle
}) {
  const { t } = useTranslation();

  const [selectedQuota, setSelectedQuota] = useState('');

  let quotas = [];
  if (orgaData.subscriptionInfo) {
    quotas = orgaData.subscriptionInfo.subscriptionQuotaFeatureList;
  }

  useEffect(() => {
    setSelectedQuota(panelItem.configuration.selectedQuota || '');
  }, []);

  useEffect(() => {
    if (triggerConfigSave) {
      saveQuota();
    }
  }, [triggerConfigSave]);

  const saveQuota = () => {
    const changedPanel = {
      ...panelItem,
      configuration: {
        ...panelItem.configuration,
        selectedQuota: selectedQuota
      }
    };
    saveConfig({ changedPanel });

    const title = getSubQuotaTitle({ panelItem, orgaData, selectedQuota });
    setPanelTitle(title);
  };

  function changeQuota(event) {
    const newQuota = event.target.value;
    setSelectedQuota(newQuota);
  }

  return (
    <TextField
      id="subQuotaConfigSelect"
      select
      variant="outlined"
      fullWidth={true}
      label={t('common.type')}
      value={selectedQuota}
      onChange={changeQuota}
    >
      {quotas.map((quota) => (
        <MenuItem key={quota.name} value={quota.name}>
          {quota.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

const useStylesQuotaContent = makeStyles(() => ({
  circular: {
    marginTop: '1.5rem',
    position: 'relative'
  },
  circularBottom: {
    color: '#dddddd'
  },
  circularTop: {
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  },
  percentage: {
    top: '1.5rem'
  }
}));

function getNumberLocalText(number) {
  if (number == null) {
    return null;
  }
  const formatNumber = +parseFloat(number).toFixed(2);
  const numberLabel = Number(formatNumber).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: false
  });
  return numberLabel;
}

export function SubQuotaPanelContent({ orgaData, panelItem, contentSize }) {
  const { t } = useTranslation();
  const classes = useStylesQuotaContent();

  const size = contentSize || null;
  let circularSize = null;
  let textSize = 1.2;
  if (size) {
    circularSize = size - 42;
    textSize = size / 75;
  }

  let quota = null;
  const configuration = panelItem.configuration;

  if (orgaData.subscriptionInfo && configuration) {
    quota = orgaData.subscriptionInfo.subscriptionQuotaFeatureList.find(
      (curQuota) => curQuota.name == configuration.selectedQuota
    );
  }

  if (!quota || !contentSize) {
    return null;
  }

  const percentageValue =
    quota.percentage == null ? 100 : quota.percentage * 100;
  const currentQuantityLabel = getNumberLocalText(quota.currentQuantity);
  let percentageLabel;
  let smallPercentage = false;
  if (quota.percentage && percentageValue < 0.01) {
    percentageLabel = getNumberLocalText(0.01);
    percentageLabel = '< ' + percentageLabel;
    smallPercentage = true;
  } else {
    percentageLabel = getNumberLocalText(percentageValue);
  }
  if (quota.quota === 0) {
    percentageLabel = null;
  }

  let quotaLabel;
  if (isNaN(quota.quota) || quota.quota >= 2000000000) {
    quotaLabel = t('dashboard.subscription.quotaUnlimited');
  } else {
    quotaLabel = getNumberLocalText(quota.quota);
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Box position="relative" display="inline-flex">
          <div className={classes.circular}>
            <CircularProgress
              variant="determinate"
              className={classes.circularBottom}
              size={circularSize}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageValue}
              color="secondary"
              className={classes.circularTop}
              classes={{
                circle: classes.circle
              }}
              size={circularSize}
              thickness={4}
            />
          </div>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.percentage}
          >
            {percentageLabel != null && (
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                style={{
                  fontSize:
                    (smallPercentage ? textSize * 0.9 : textSize) + 'rem'
                }}
              >{`${percentageLabel}%`}</Typography>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box alignItems="center" justifyContent="center">
          <Typography variant="body1" component="div" color="textSecondary">
            {currentQuantityLabel} / {quotaLabel}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
