import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Select, MenuItem, ListItemText, Grid } from '@material-ui/core';

import theme from '../../PimTogoTheme';
import { DataManagementProvider } from './DataManagementContext';
import { InstanceTabEditorReader } from '../tabeditor/reader/InstanceTabEditorReader';
import { TabEditorTypeSelector } from './TabEditorTypeSelector';
import { ProductControl } from './ProductControl.jsx';
import ajaxGet from 'src/services/ajaxGet';

export const LOCAL_STORAGE_CLASSIFICATION_GROUP = 'TOGO.CLASSIFICATION_GROUP';
export const CLASSIFICATION_GROUP_ALL = 'all';

const useStyles = makeStyles(() => ({
  classification: {
    marginLeft: '8.5rem',
    '& .MuiInputBase-input': {
      fontSize: '1.5rem !important',
      maxWidth: '40rem !important',
      fontFamily: '"Roboto", sans-serif !important',
      minWidth: '20rem !important'
    }
  },
  classificationTitle: {
    color: '#212529',
    fontSize: '1.5rem',
    fontWeight: 600
  }
}));

export default function InstanceEditor() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [targetEditorConfig, setTargetEditorConfig] = useState(null);
  const [classificationGroups, setClassificationGroups] = useState([]);
  const [targetClassificationGroup, setTargetClassificationGroup] =
    useState('');
  const [selectedClassName, setSelectedClassName] = useState('');

  //TODO:
  // TO DO SOLTAN
  const ptdId = $('#productPtdSelectButton').data('ptd-id') || null;

  useEffect(() => {
    ajaxGet('dataManagement/classificationGroupModels', {
      contentLanguage: $('#contentLangSelectElement').val() || null,
      ptdId: ptdId
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setClassificationGroups(response.classificationGroupModels);
        if (response.classificationGroupModels.length == 1) {
          const onlySelectionGroup = response.classificationGroupModels[0];
          setTargetClassificationGroup(
            onlySelectionGroup.classificationIdentifier
          );
          setSelectedClassName(onlySelectionGroup.className);
          return;
        }

        ajaxGet('dataManagement/getClassificationGroup', {}).then(
          (ajaxData) => {
            if (!ajaxData.error && ajaxData.response) {
              const storedClassificationIdentifier =
                ajaxData.response.resultInfo;
              const lastClassificationGroup =
                response.classificationGroupModels.find(
                  (c) =>
                    c.classificationIdentifier ===
                    storedClassificationIdentifier
                );
              if (lastClassificationGroup) {
                setTargetClassificationGroup(storedClassificationIdentifier);
                showSelectedClassificationClass(
                  storedClassificationIdentifier,
                  response.classificationGroupModels
                );
              } else {
                const allClassificationGroup =
                  response.classificationGroupModels.find(
                    (c) => c.classificationIdentifier === 'all'
                  );
                if (allClassificationGroup) {
                  setTargetClassificationGroup(
                    allClassificationGroup.classificationIdentifier
                  );
                }
              }
            }
          }
        );
      } else {
        TOGO.Util.notifyResponse(ajaxData.error, true);
      }
    });
  }, [ptdId]);

  function selectClassificationGroup(event) {
    const val = event.target.value;
    if (val) {
      setTargetClassificationGroup(val);
      var pojo = new Object();
      pojo.classificationGroupId = val;
      ajaxGet('listusers/setClassificationGroup', pojo).then((ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          console.log(ajaxData.response);
        }
      });
      showSelectedClassificationClass(val, classificationGroups);
    }
  }

  function showSelectedClassificationClass(
    classificationIdentifier,
    classificationGroupModels
  ) {
    const selectedClassificationGroup = classificationGroupModels.find(
      (curGroup) =>
        curGroup.classificationIdentifier === classificationIdentifier
    );
    if (selectedClassificationGroup?.className) {
      setSelectedClassName(selectedClassificationGroup.className);
    } else {
      setSelectedClassName('');
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductControl />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Grid container spacing={3}>
              <Grid item xs="auto">
                <span className={classes.classificationTitle}>
                  {t('productType.classification')}:
                </span>

                <Select
                  id="data-management-classification-group-select"
                  value={targetClassificationGroup}
                  onChange={selectClassificationGroup}
                  className={classes.classification}
                  renderValue={(selectedClassificationIdentifier) => {
                    if (
                      selectedClassificationIdentifier ===
                      CLASSIFICATION_GROUP_ALL
                    ) {
                      return t('common.all');
                    }

                    const selectedClassificationGroup =
                      classificationGroups.find(
                        (curGroup) =>
                          curGroup.classificationIdentifier ===
                          selectedClassificationIdentifier
                      );
                    if (selectedClassificationGroup) {
                      return (
                        selectedClassificationGroup.groupName ||
                        selectedClassificationGroup.classificationIdentifier
                      );
                    } else {
                      return '';
                    }
                  }}
                >
                  {classificationGroups.map((classificationGroup) => (
                    <MenuItem
                      key={classificationGroup.classificationIdentifier}
                      value={classificationGroup.classificationIdentifier}
                    >
                      <ListItemText
                        primary={
                          classificationGroup.groupName ||
                          classificationGroup.classificationIdentifier
                        }
                        secondary={classificationGroup.className || null}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.classification}>
                  {selectedClassName}
                </span>
              </Grid>
            </Grid>
          </div>
          <DataManagementProvider
            //TODO: read only
            readOnlyProvided={false}
            ptdId={ptdId}
            classificationGroup={targetClassificationGroup}
          >
            <TabEditorTypeSelector
              setTargetEditorConfig={setTargetEditorConfig}
            />
            <InstanceTabEditorReader
              readOnly={false}
              selectedNodesContent={targetEditorConfig}
              firstComponent={true}
            />
          </DataManagementProvider>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
