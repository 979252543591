import React from 'react';
import Dashboard from './Dashboard';

export default function HomeDashboard() {
  return (
    <Dashboard
      dashboardType="HOME"
      editable={true}
      defaultOrgaGridJson={orgaGridJson}
      defaultOrgaPanelsJson={orgaPanelsJson}
      defaultUserGridJson={userGridJson}
      defaultUserPanelsJson={userPanelsJson}
    />
  );
}

const orgaGridJson = {
  lg: [
    {
      w: 2,
      h: 3,
      x: 0,
      y: 0,
      i: 'SubscriptionQuota_SQklLWGUgg',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 6,
      y: 0,
      i: 'SubscriptionQuota_h5R_m7LlP8',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 7,
      x: 0,
      y: 3,
      i: 'SubscriptionFeatureList_XY7NfL0JL-',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 7,
      x: 3,
      y: 3,
      i: 'SubscriptionFeatureList__f5WPddLhi',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 0,
      i: 'SubscriptionQuota_CNtqWMQJ0-',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 4,
      y: 0,
      i: 'SubscriptionQuota_lSTPw0vcnm',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 7,
      x: 6,
      y: 3,
      i: 'SubscriptionFeatureList_7jHc73XUcD',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 8,
      y: 0,
      i: 'SubscriptionQuota_ZIlbW9nySR',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 10,
      y: 0,
      i: 'SubscriptionQuota_h-pzODAFod',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 7,
      x: 9,
      y: 3,
      i: 'SubscriptionFeatureList_zJotq3syV0',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    }
  ],
  md: [
    {
      w: 2,
      h: 4,
      x: 0,
      y: 0,
      i: 'SubscriptionQuota_SQklLWGUgg',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 4,
      x: 2,
      y: 4,
      i: 'SubscriptionQuota_h5R_m7LlP8',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 5,
      h: 8,
      x: 0,
      y: 8,
      i: 'SubscriptionFeatureList_XY7NfL0JL-',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 5,
      h: 8,
      x: 5,
      y: 8,
      i: 'SubscriptionFeatureList__f5WPddLhi',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 4,
      x: 2,
      y: 0,
      i: 'SubscriptionQuota_CNtqWMQJ0-',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 4,
      i: 'SubscriptionQuota_lSTPw0vcnm',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 8,
      x: 6,
      y: 0,
      i: 'SubscriptionFeatureList_7jHc73XUcD',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 4,
      x: 4,
      y: 4,
      i: 'SubscriptionQuota_ZIlbW9nySR',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 4,
      x: 4,
      y: 0,
      i: 'SubscriptionQuota_h-pzODAFod',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 8,
      x: 8,
      y: 0,
      i: 'SubscriptionFeatureList_zJotq3syV0',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    }
  ],
  sm: [
    {
      w: 2,
      h: 3,
      x: 0,
      y: 0,
      i: 'SubscriptionQuota_SQklLWGUgg',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 0,
      y: 3,
      i: 'SubscriptionQuota_h5R_m7LlP8',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 8,
      x: 0,
      y: 6,
      i: 'SubscriptionFeatureList_XY7NfL0JL-',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 8,
      x: 3,
      y: 6,
      i: 'SubscriptionFeatureList__f5WPddLhi',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 0,
      i: 'SubscriptionQuota_CNtqWMQJ0-',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 4,
      y: 0,
      i: 'SubscriptionQuota_lSTPw0vcnm',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 5,
      x: 0,
      y: 14,
      i: 'SubscriptionFeatureList_7jHc73XUcD',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 3,
      i: 'SubscriptionQuota_ZIlbW9nySR',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 2,
      h: 3,
      x: 4,
      y: 3,
      i: 'SubscriptionQuota_h-pzODAFod',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 3,
      h: 5,
      x: 3,
      y: 14,
      i: 'SubscriptionFeatureList_zJotq3syV0',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    }
  ]
};

const orgaPanelsJson = {
  SubscriptionQuota_SQklLWGUgg: {
    i: 'SubscriptionQuota_SQklLWGUgg',
    panelType: 'SubscriptionQuota',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedQuota: 'SubscriptionFeature.Users'
    }
  },
  SubscriptionQuota_h5R_m7LlP8: {
    i: 'SubscriptionQuota_h5R_m7LlP8',
    panelType: 'SubscriptionQuota',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedQuota: 'SubscriptionFeature.PIMIndividualCLC'
    }
  },
  'SubscriptionFeatureList_XY7NfL0JL-': {
    i: 'SubscriptionFeatureList_XY7NfL0JL-',
    panelType: 'SubscriptionFeatureList',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedListType: 'subscribed'
    }
  },
  SubscriptionFeatureList__f5WPddLhi: {
    i: 'SubscriptionFeatureList__f5WPddLhi',
    panelType: 'SubscriptionFeatureList',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedListType: 'not_subscribed'
    }
  },
  'SubscriptionQuota_CNtqWMQJ0-': {
    i: 'SubscriptionQuota_CNtqWMQJ0-',
    panelType: 'SubscriptionQuota',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedQuota: 'SubscriptionFeature.StorageSpaceMedia'
    }
  },
  SubscriptionQuota_lSTPw0vcnm: {
    i: 'SubscriptionQuota_lSTPw0vcnm',
    panelType: 'SubscriptionQuota',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedQuota: 'SubscriptionFeature.PIMArticles'
    }
  },
  SubscriptionFeatureList_7jHc73XUcD: {
    i: 'SubscriptionFeatureList_7jHc73XUcD',
    panelType: 'SubscriptionFeatureList',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedListType: 'contentLanguages'
    }
  },
  SubscriptionQuota_ZIlbW9nySR: {
    i: 'SubscriptionQuota_ZIlbW9nySR',
    panelType: 'SubscriptionQuota',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedQuota: 'SubscriptionFeature.CustomerAddresses'
    }
  },
  'SubscriptionQuota_h-pzODAFod': {
    i: 'SubscriptionQuota_h-pzODAFod',
    panelType: 'SubscriptionQuota',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedQuota: 'SubscriptionFeature.userIndesign'
    }
  },
  SubscriptionFeatureList_zJotq3syV0: {
    i: 'SubscriptionFeatureList_zJotq3syV0',
    panelType: 'SubscriptionFeatureList',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedListType: 'classifications'
    }
  }
};

const userGridJson = {
  lg: [
    {
      w: 6,
      h: 7,
      x: 0,
      y: 0,
      i: 'LinkList_7f_rWq6J08',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 6,
      h: 7,
      x: 6,
      y: 0,
      i: 'LinkList_gkIYMwCxkz',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    }
  ],
  md: [
    {
      w: 10,
      h: 7,
      x: 0,
      y: 0,
      i: 'LinkList_7f_rWq6J08',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 10,
      h: 7,
      x: 0,
      y: 7,
      i: 'LinkList_gkIYMwCxkz',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    }
  ],
  sm: [
    {
      w: 6,
      h: 7,
      x: 0,
      y: 0,
      i: 'LinkList_7f_rWq6J08',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    },
    {
      w: 6,
      h: 7,
      x: 0,
      y: 7,
      i: 'LinkList_gkIYMwCxkz',
      minH: 1,
      moved: false,
      static: false,
      isDraggable: true,
      isResizable: true
    }
  ]
};

const userPanelsJson = {
  LinkList_7f_rWq6J08: {
    i: 'LinkList_7f_rWq6J08',
    panelType: 'LinkList',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedListType: 'favorites'
    }
  },
  LinkList_gkIYMwCxkz: {
    i: 'LinkList_gkIYMwCxkz',
    panelType: 'LinkList',
    configuration: {
      titleVisible: true,
      closable: true,
      movable: true,
      resizableX: true,
      resizableY: true,
      selectedListType: 'recents'
    }
  }
};
