import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Link, MenuItem, TextField } from '@material-ui/core';

import ajaxGet from 'src/services/ajaxGet.js';

import ExportTable from './ExportTable';



export const PANELTYPE = 'OrganizationExports';
export const ORGA_EXPORTS_LIST_CONFIG_I18KEY = 'dashboard.orgaExports.orgaExportsConfigTitle';

const LIST_TYPES = {
  FAVORITES: 'favorites',
  RECENTS: 'recents'
};

export function getOrgaExportsListLabel({ panelItem, listType }) {
  return "OrganizationExports";

  if (!listType) {
    listType = panelItem.configuration.selectedListType;
    if (!listType) {
      listType = LIST_TYPES.FAVORITES;
    }
  }
// Title 
  if (listType == LIST_TYPES.FAVORITES) {
    return i18next.t('dashboard.linkList.favorites');
  }
  if (listType == LIST_TYPES.RECENTS) {
    return i18next.t('dashboard.linkList.recents');
  }

  return listType;
}

export function OrgaExportsListPanelConfig({
  triggerConfigSave,
  saveConfig,
  panelItem,
  setPanelTitle
}) {
  const { t } = useTranslation();

  const [selectedListType, setSelectedListType] = useState('');

  useEffect(() => {
    setSelectedListType(panelItem.configuration.selectedListType || '');
  }, []);

  useEffect(() => {
    if (triggerConfigSave) {
      saveListType();
    }
  }, [triggerConfigSave]);

  function saveListType() {
    const changedPanel = {
      ...panelItem,
      configuration: {
        ...panelItem.configuration,
        selectedListType: selectedListType
      }
    };
    saveConfig({ changedPanel });

    const title = getOrgaExportsListLabel({ panelItem, listType: selectedListType });
    setPanelTitle(title);
  }

  function changeListType(event) {
    const newListType = event.target.value;
    setSelectedListType(newListType);
  }

  return (
    <TextField
      id="linkListConfigSelect"
      select
      variant="outlined"
      fullWidth={true}
      label={t('common.type')}
      value={selectedListType}
      onChange={changeListType}
    >
      {Object.values(LIST_TYPES).map((listType) => (
        <MenuItem key={listType} value={listType}>
          {getOrgaExportsListLabel({ panelItem, listType })}
        </MenuItem>
      ))}
    </TextField>
  );
}

const TYPE_LABEL_CODE = {
  Article: 'data.article',
  Product: 'data.product',
  ProductTypeDefinition: 'data.ptd',
  ProductStructureNode: 'data.productStructure',
  Catalog: 'data.catalog',
  Shop: 'data.shop'
};

export function OrgaExportsListPanelContent({
  panelItem,
  contentSize,
  saveConfigAll
}) {
  const { t } = useTranslation();

  const columns = [
    {
      field: 'instanceClassName',
      headerName: t('dashboard.linkList.type'),
      minwidth: 100,
      editable: false,
      getCellDisplay: ClassNameDisplay
    },
    {
      field: 'link',
      headerName: 'Link',
      sortingOrder: 'label',
      flex: 1,
      minWidth: 350,
      editable: false,
      getCellDisplay: LinkDisplay
    },
    {
      field: 'timeStamp',
      headerName: 'Timestamp',
      sortingOrder: 'timeStamp',
      defaultSortingColumnOrder: 'desc',
      type: 'dateTime',
      minWidth: 150,
      editable: false,
      getCellDisplay: TimeStampDisplay
    }
  ];

  const [rows, setRows] = useState([]);

  const configuration = panelItem.configuration;

  let timeStampLocale = 'en-GB';
  const userLang = document.documentElement.lang || 'en';
  switch (userLang) {
    case 'de':
      timeStampLocale = 'de-DE';
      break;
    case 'fr':
      timeStampLocale = 'fr-FR';
      break;
    case 'it':
      timeStampLocale = 'it-IT';
      break;
  }

  useEffect(() => {
    let initListType = configuration.selectedListType;

    if (!initListType) {
      initListType = LIST_TYPES.FAVORITES;
      const changedPanel = {
        ...panelItem,
        configuration: {
          ...panelItem.configuration,
          selectedListType: initListType
        }
      };

      saveConfigAll({ changedPanel });
    }

    let contentLanguage = $('#contentLangSelectElement').val() || null;

    ajaxGet('linkList', {
      targetListType: initListType,
      contentLanguage: contentLanguage
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        const listRows = ajaxData.response.linkList.map((item) => {
          const obj = Object.assign({}, item);
          obj.timeStamp = new Date(obj.timeStamp);
          return obj;
        });

        setRows(listRows);
      }
    });
  }, [configuration.selectedListType]);

  function getTypeLabel(type) {
    const typeLabelCode = TYPE_LABEL_CODE[type];
    if (typeLabelCode) {
      return t(typeLabelCode);
    }

    return type;
  }

  function TimeStampDisplay(row) {
    return row.timeStamp.toLocaleString(timeStampLocale, {
      dateStyle: 'medium',
      timeStyle: 'medium'
    });
  }

  function ClassNameDisplay(row) {
    return getTypeLabel(row.instanceClassName);
  }

  function LinkDisplay(row) {
    return (
      <Link href={row.link} underline="hover">
        {row.label ? row.label : t('common.anyTextMissing')}
      </Link>
    );
  }

  return (

    <ExportTable
      panelItem={panelItem}
      columns={columns}
      rows={rows}
      contentSize={contentSize}
      listEdit={{ select: false, create: false, delete: false, edit: false }}
    />
  );
}
