import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FormControlLabel,
  makeStyles,
  MenuItem,
  Switch,
  TextField
} from '@material-ui/core';

import { getUserCountryLang } from 'src/util/languageUtil';

export const PANELTYPE = 'Text';
export const TEXTCONFIG_I18KEY = 'dashboard.text.textConfig';

const useStylesConfig = makeStyles(() => ({
  outlinedComponent: { marginBottom: '2rem' },
  switch: {
    marginLeft: 0
  }
}));

export function getTextTitle({ panelItem }) {
  let title = PANELTYPE;

  const userLang = getUserCountryLang();
  const locales = panelItem.locales;
  const userLocales = locales ? locales[userLang] : null;

  if (userLocales?.title) {
    title = userLocales.title;
  }

  return title;
}

export function TextPanelConfig({
  triggerConfigSave,
  saveConfig,
  orgaData,
  panelItem,
  setPanelTitle
}) {
  const { t } = useTranslation();
  const classes = useStylesConfig();

  const togoInterfaceLangs = orgaData.interfaceLangs
    ? Object.keys(orgaData.interfaceLangs).map((interfaceLangKey) => ({
        value: interfaceLangKey,
        label: orgaData.interfaceLangs[interfaceLangKey]
      }))
    : null;

  const [configLang, setConfigLang] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [panelEditable, setPanelEditable] = useState(false);

  const userLang = getUserCountryLang();
  const locales = panelItem.locales;
  const userLocales = locales ? locales[userLang] : null;

  useEffect(() => {
    setConfigLang(userLang);

    if (userLocales?.title) {
      const initTitle = getTextTitle({ panelItem });
      setTitle(initTitle);
    }

    const textConfig = panelItem.configuration.text;
    setPanelEditable(textConfig?.panelEditable || false);
  }, []);

  useEffect(() => {
    if (userLocales?.textContent) {
      setContent(userLocales.textContent);
    }
  }, [userLocales]);

  useEffect(() => {
    if (triggerConfigSave) {
      saveTextPanel(false);
    }
  }, [triggerConfigSave]);

  function saveTextPanel(keepConfigDialogOpen) {
    if (configLang == userLang) {
      setPanelTitle(title);
    }

    const changedPanel = {
      ...panelItem,
      configuration: {
        ...panelItem.configuration,
        text: {
          panelEditable: panelEditable
        }
      },
      locales: {
        ...panelItem.locales,
        [configLang]: {
          title: title,
          textContent: content
        }
      }
    };

    saveConfig({ changedPanel, keepConfigDialogOpen });
  }

  function changeConfigLang(event) {
    saveTextPanel(true);

    const newConfigLang = event.target.value;
    setConfigLang(newConfigLang);

    if (!locales) return;

    let newTitle = locales[newConfigLang]?.title || '';
    setTitle(newTitle);

    let newContent = locales[newConfigLang]?.textContent || '';
    setContent(newContent);
  }

  function changeTitle(event) {
    setTitle(event.target.value);
  }

  function changeContent(event) {
    setContent(event.target.value);
  }

  function changePanelEditable(event) {
    setPanelEditable(event.target.checked);
  }

  return (
    <>
      {togoInterfaceLangs && (
        <TextField
          id="selectInterfaceLang"
          select
          variant="outlined"
          className={classes.outlinedComponent}
          label={t('common.language')}
          value={configLang}
          onChange={changeConfigLang}
        >
          {togoInterfaceLangs.map((togoInterfaceLang) => (
            <MenuItem
              key={togoInterfaceLang.value}
              value={togoInterfaceLang.value}
              onClick={changeConfigLang}
            >
              {togoInterfaceLang.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        variant="outlined"
        fullWidth
        label={t('dashboard.text.textTitle')}
        autoFocus
        className={classes.outlinedComponent}
        value={title}
        onChange={changeTitle}
      />
      <TextField
        variant="outlined"
        fullWidth
        label={t('dashboard.text.textContent')}
        multiline
        maxRows={7}
        className={classes.outlinedComponent}
        value={content}
        onChange={changeContent}
      />
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch checked={panelEditable} onChange={changePanelEditable} />
        }
        label={t('dashboard.text.textPanelEditable')}
      />
    </>
  );
}

const useTextPanelStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
}));

export function TextPanelContent({ panelItem, savePanel }) {
  const classes = useTextPanelStyles();

  const [content, setContent] = useState();

  const panelEditable = Boolean(panelItem.configuration.text?.panelEditable);
  const userLang = getUserCountryLang();
  const userLocales = panelItem.locales ? panelItem.locales[userLang] : null;

  useEffect(() => {
    if (userLocales?.textContent) {
      setContent(userLocales.textContent);
    }
  }, [userLocales]);

  function changeContent(event) {
    setContent(event.target.value);
  }

  function saveContent() {
    const changedPanel = {
      ...panelItem,
      locales: {
        ...panelItem.locales,
        [userLang]: {
          ...panelItem.locales[userLang],
          textContent: content
        }
      }
    };
    savePanel(panelItem.i, changedPanel);
  }

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        variant="outlined"
        multiline
        value={content}
        InputProps={{
          readOnly: !panelEditable
        }}
        onChange={changeContent}
        onBlur={saveContent}
      />
    </div>
  );
}
