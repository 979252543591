import React from 'react';

import Carousel from 'react-grid-carousel';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { Container, IconButton, Tooltip, Box } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import MediaContainerHeader from './MediaContainerHeader';
import Lightbox from 'react-image-lightbox';
import ajaxPost from 'src/services/ajaxPost';

window.dragId = '';

window.changePositionUri = '';
window.isOpen = false;
window.photoIndex = 0;

const Card = styled.div`
  margin: 2px !important;
  border-radius: 6px;
  border: 1px solid #eaeaea;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.25s;
  :hover {
    box-shadow: 0 0 2px 0 #00000063;
  }
`;

const Img = styled.div`
  height: 160px;
  margin-bottom: 4px;
  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
`;

const MediaEditorMain = ({
  mediaList,
  titleMediaEditor,
  searchUrl,
  onDelete,
  addImage,
  updateMediaList,
  isDefaultData,
  deleteDefaultData,
  defaultDataObject,
  setBlockDefaultData,
  resetBlockDefaultData,
  isBlockDefaultData
}) => {
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDrag = (ev) => {

    window.dragId = ev.currentTarget.id;

    window.changePositionUri = ev.currentTarget.dataset.url;
  };

  const swapArrayElements = (arr, indexA, indexB) => {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  };

  Array.prototype.swap = function (indexA, indexB) {
    swapArrayElements(this, indexA, indexB);
  };

  const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    const medienArray = JSON.parse(JSON.stringify(arr));
    swapArrayElements(medienArray, old_index, new_index);
    return medienArray;
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (ev) => {

    const dragImageOrder = mediaList.findIndex(
      (image) => image.id === window.dragId
    );
    const dropImageOrder = mediaList.findIndex(
      (image) => image.id === ev.currentTarget.id
    );

    let changePositionUri = TOGO.Util.url.replaceURLParameter("delete", "false", window.changePositionUri, true);
    changePositionUri = TOGO.Util.url.replaceURLParameter("dragged", dragImageOrder, changePositionUri, true);
    changePositionUri = TOGO.Util.url.replaceURLParameter("dropped", dropImageOrder, changePositionUri, true);
    changePositionUri = TOGO.Util.url.replaceURLParameter("shift", "0", changePositionUri, true);

    ajaxPost(
      changePositionUri,
      {},
      {}
    ).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
            window.changePositionUri = '';
      }
    });

    if (isDefaultData) {
      deleteDefaultData(defaultDataObject);
    }

    const newImageState = moveArrayItemToNewIndex(
      mediaList,
      dropImageOrder,
      dragImageOrder
    );

    updateMediaList(newImageState);
  };

  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: 'inline-block',
        height: isActive ? '8px' : '5px',
        width: isActive ? '8px' : '5px',
        background: '#FFFFFF'
      }}
    ></span>
  );
  const showlightBox = (bool, index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  return (
    <React.Fragment>
      {isOpen && (
        <Lightbox
          mainSrc={mediaList[photoIndex].previewUri}
          nextSrc={mediaList[(photoIndex + 1) % mediaList.length].previewUri}
          prevSrc={
            mediaList[(photoIndex + mediaList.length - 1) % mediaList.length]
              .previewUri
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + mediaList.length - 1) % mediaList.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + mediaList.length + 1) % mediaList.length
            )
          }
        />
      )}
      <Container className="mediaContainer" maxWidth={false}>
        <MediaContainerHeader
          titleMediaEditor={titleMediaEditor}
          searchUrl={searchUrl}
          addImage={addImage}
          isDefaultData={isDefaultData}
          setBlockDefaultData={setBlockDefaultData}
          resetBlockDefaultData={resetBlockDefaultData}
          isBlockDefaultData={isBlockDefaultData}
        />
        <Box>
          {mediaList.length > 0 && (
            <Carousel
              style={{ padding: '23px' }}
              // loop
              cols={5}
              rows={1}
              gap={11}
              showDots={true}
              spacing={0}
              scrollSnap={false}
              responsiveLayout={[
                {
                  breakpoint: 1540,
                  cols: 4
                },
                {
                  breakpoint: 1460,
                  cols: 3
                },
                {
                  breakpoint: 1228,
                  cols: 2
                },
                {
                  breakpoint: 991,
                  cols: 1
                },
                {
                  breakpoint: 700,
                  cols: 1
                }
              ]}
              mobileBreakpoint={670}
            >
              {mediaList.map((medium, index) => (
                <Carousel.Item key={medium.id}>
                  <Card
                    className="carouselItem"
                    draggable={true}
                    id={medium.id}
                    data-url={medium.changePositionUri}
                    onDragOver={onDragOver}
                    onDragStart={handleDrag}
                    onDrop={handleDrop}
                  >
                    <Tooltip title={medium.altText}>
                      <Img
                        draggable={false}
                        id={medium.id}
                        img={medium.thumbnailUri}
                      />
                    </Tooltip>
                    <Tooltip title={'löschen'}>
                      <IconButton
                        className="removeBtn"
                        onClick={() => {
                          onDelete(medium.id);
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Detailanzeige'}>
                      <IconButton onClick={() => showlightBox(true, index)}>
                        <ZoomInIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Card>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default MediaEditorMain;
