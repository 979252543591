import React from 'react';
import Typography from "@material-ui/core/Typography";

export default function ContentLangText({ product, contentText, classes }) {
  
  if (!contentText) return null;

  return (
    <>
      {!contentText.missingContentLang ? (
        // <span
        //   className={
        //     product?.description1 || product?.description2
        //       ? 'overviewItemTitle productOverviewTitle'
        //       : 'overviewItemTitle productOverviewTitle bigTitle'
        //   }
        // >
      
        // </span>


    <Typography gutterBottom className={classes.title} noWrap={true} component="div">
      {contentText.text}
    </Typography>

      ) : (

        <Typography gutterBottom className={classes.title} component="div">
                 &lt;+{contentText.typeName}:{contentText.text}
                &gt;
      </Typography>

        //TODO Tooltip title={missingText} ContantLangText.jsp
        // <span
        //   className={
        //     'missingContentText ' +
        //     (product?.description1 || product?.description2
        //       ? 'overviewItemTitle productOverviewTitle'
        //       : 'overviewItemTitle productOverviewTitle bigTitle')
        //   }

        // >
        //   &lt;+{contentText.typeName}:{contentText.text}
        //   &gt;
        // </span>
      )}

      {/* <spring:message code="contentLang.missingText" arguments="${contentText.missingContentLang}" var="missingText"/>
	<span class="${contentLangTextClass} missingContentText"
		  data-toggle="tooltip" data-placement="auto bottom" title="${missingText}">
		&lt;${contentText.typeName}: 
		<c:out value="${contentText.text}" escapeXml="${escaping}"/>
		&gt;
	</span> */}

      {contentText.textAddon && (
        <span className="contentLangTextAddon">{contentText.textAddon}</span>
      )}
      {/* <c:set var="escaping" value="true" />
      <c:if test="${contentText.editorTextContent}">
        <c:set var="escaping" value="false" />
      </c:if>
      <c:choose>
        <c:when test="${empty contentText.missingContentLang}">
          <span class="${contentLangTextClass}">
            <c:out value="${contentText.text}" escapeXml="${escaping}" />
          </span>
        </c:when>
        <c:otherwise></c:otherwise>
      </c:choose>
      <c:if test="${not empty contentText.textAddon}">
        <span class="contentLangTextAddon">
          <c:out value="${contentText.textAddon}" escapeXml="${escaping}" />
        </span>
      </c:if>
      <c:remove var="escaping" /> */}
    </>
  );
}
