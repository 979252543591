
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Button, Typography} from "@mui/material";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import EditableTable from './EditableTable';

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTitleStyles } from '../../../articledata/datamanagement/featurecomponents/FeatureComponent';


import { usePimStyles } from '../../../PimTogoTheme';
import ajaxCall from 'src/services/ajaxCall.js';
import { getUserCountryLang } from 'src/util/languageUtil';



function ContextTableDefiniton({
	handleCloseDialogTableDefinition,
	initFormTableDefinitionState,
	toCreateTableDefinition,
	// contentLangSelections,
    initContentTable,
	searchTableDefinitions,
    setTableContent,
    tableContent
}) {

    const { t } = useTranslation();

	const [language, setLanguage] = useState(t('language.code'));

	const userLang = getUserCountryLang();

	const classesTitle = useTitleStyles();

	const classesPim = usePimStyles();

/* 	const formikRef = useRef();


    const [alignment, setAlignment] = useState('vertical');
    const [size, setSize] = useState(10);
    const [unit, setUnit] = useState('pt');
 */

/*     const handleChange_alignment = (event) => {
        setAlignment(event.target.value);
    };
    
    const handleSizeChange = (event) => {
        const res = Number(event.target.value)
        if (unit ==='%' && res > 100 ) return;
        setSize(event.target.value);
    };

    const handleUnitChange = (event) => {
        setUnit(event.target.value);
        setSize(10);

    };
 */
    const  requiredFieldErrorMessage = t('manageOrganization.organization.requiredFieldErrorMessage');
    const validationSchema = yup.object().shape({
        id:'',
        name: yup.string().required(requiredFieldErrorMessage),
        size: yup
            .number()
            .required(requiredFieldErrorMessage)
            .max(yup.ref('unit') === '%' ? 100 : 2000, t('dashboard.tableDef.sizeErrorMessage')),
        unit: yup.string().required(requiredFieldErrorMessage),
        alignment: yup.string().required(requiredFieldErrorMessage),
        order: yup.number().required(requiredFieldErrorMessage).positive().min(1, t('dashboard.tableDef.orderErrorMessage')),
    });
    
    //tableDefinitionForm
    const formik = useFormik({
        initialValues: initFormTableDefinitionState,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Handle form submission
            console.log('Form values:', values);
            console.log('Form tableContent:', tableContent);
            let params = {};
            params.id = values?.id;
            params.tableDefinitionName = values?.name;
            params.fieldSize= values?.size;
            params.unit= values?.unit;
            params.alignment = values?.alignment;
            params.order = values?.order;
            params.language = language;

    
            if (toCreateTableDefinition) {
              ajaxCall({
                httpMethod: 'post',
                url: 'tableDefinition/create',
                params,
                json: {
                  tableContent: JSON.stringify(tableContent),
                }
              }).then((ajaxData) => {
                if (!ajaxData.error && ajaxData.response) {
                  TOGO.Util.notifyResponse(ajaxData.response.message, false);
                  handleCloseDialogTableDefinition();
                  searchTableDefinitions();
                }
              });
            } else {
            //  params.language = formikRef.current.values.language;           
                ajaxCall({
                httpMethod: 'post',
                url: 'tableDefinition/editDone',
                params,
                json: {
                    tableContent: JSON.stringify(tableContent),
                }
              }).then(
                (ajaxData) => {
                  if (!ajaxData.error && ajaxData.response) {
                    handleCloseDialogTableDefinition();
                    TOGO.Util.notifyResponse(ajaxData.response.message, false);
                    searchTableDefinitions();
                  }
                }
              );
            }  
        },
    });


  return (

    <Container sx={{ padding: '0px !important'}}>

    <form onSubmit={formik.handleSubmit}>
        <Box item sx={{ borderRadius: 'sm', border: 0, p: 3 }}>
            <Box
                item
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ borderRadius: 'md', border: 0, p: 0 }}
                gap={4}
            >
                <TextField
                    id="outlined-basic"
                    label={t('dashboard.tableDef.name')}
                    variant="outlined"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    inputProps={{
                        sx: {
                            fontSize: '1.5rem',  
                          }
                    }}

                    InputLabelProps={{
                        sx: {
                          fontSize: '1.2rem',  
                        },
                      }}
                      
                    FormHelperTextProps={{
                        sx: {
                          fontSize: '1rem', 
                        }
                    }}
                />
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 5 }}>
                    <TextField
                        variant="outlined"
                        label={t('dashboard.tableDef.width')}
                        name="size"
                        value={formik.values.size}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                        sx={{ width: '100px' , fontSize:'1.5rem' }}
                        inputProps={{
                            max: formik.values.unit === '%' ? 100 : 2000, min:10, 
                            sx: {
                                fontSize: '1.5rem',  
                              },
                        }}
                        error={formik.touched.size && Boolean(formik.errors.size)}
                        helperText={formik.touched.size && formik.errors.size}

                        InputLabelProps={{
                            sx: {
                              fontSize: '1.2rem',  
                            },
                          }}
                          
                        FormHelperTextProps={{
                            sx: {
                              fontSize: '1rem', 
                            }
                        }}
                    />
                    <FormControl>
                        <InputLabel sx={{ fontSize: '1.2rem' }} id="demo-simple-select-label_unit">Unit</InputLabel>
                        <Select
                            labelId="demo-simple-select-label_unit"
                            label="Unit"
                            name="unit"
                            value={formik.values.unit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{ width: '74px', fontSize:'1.5rem'  }}
                            error={formik.touched.unit && Boolean(formik.errors.unit)}
                        >
                            <MenuItem value="pt">pt</MenuItem>
                            <MenuItem value="%">%</MenuItem>
                        </Select>
                        {formik.touched.unit && formik.errors.unit &&
                            <FormHelperText sx={{ fontSize: '1rem', color: 'red' }}>{formik.errors.unit}</FormHelperText>
                        }
{/*                         {formik.touched.unit && formik.errors.unit && (
                            <div style={{ color: 'red', fontSize: '14px' }}>
                                {formik.errors.unit}
                            </div>
                        )} */}
                    </FormControl>
                </Box>
                <FormControl>
                    <InputLabel sx={{ fontSize: '1.2rem' }} id="demo-simple-select-label_alignment">Alignment</InputLabel>
                    <Select
                        labelId="demo-simple-select-label_alignment"
                        id="demo-simple-select_alignment"
                        name="alignment"
                        sx={{ width: '150px', fontSize:'1.5rem'  }}

                        value={formik.values.alignment}
                        label={t('dashboard.tableDef.alignment')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.alignment && Boolean(formik.errors.alignment)}
                    >
                        <MenuItem value={'horizontal'}>{t('dashboard.tableDef.horizontal')}</MenuItem>
                        <MenuItem value={'vertical'}>{t('dashboard.tableDef.vertical')}</MenuItem>
                    </Select>
                    {formik.touched.alignment && formik.errors.alignment &&
                            <FormHelperText sx={{ fontSize: '1rem', color: 'red' }}>{formik.errors.alignment}</FormHelperText>
                    }
{/*                     {formik.touched.alignment && formik.errors.alignment && (
                        <div style={{ color: 'red', fontSize: '8px' }}>
                            {formik.errors.alignment}
                        </div>
                    )} */}
                </FormControl>
                <TextField
                    sx={{ width: '90px'}}
                    id="outlined-order"
                    type="number"
                    inputProps={{ 
                       sx: {
                        fontSize: '1.5rem',  
                      },
                         min:1
                    }}  
                    label={t('dashboard.tableDef.order')}
                    name="order"
                    value={formik.values.order}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}

                    InputLabelProps={{
                        sx: {
                          fontSize: '1.2rem',  
                        },
                      }}
                      
                    FormHelperTextProps={{
                        sx: {
                          fontSize: '1rem', 
                        }}
                    }
                />
            </Box>
            {/* EditableTable Component goes here */} 
            <EditableTable tableContent={ tableContent}  setTableContent={setTableContent}/>
        </Box>
          <div
              style={{
                  display: 'inline-flex',
                  width: '100%',
                  position: 'sticky',
                  bottom: '0',
                  zIndex: '9'
              }}
          >
              <Button
                  type="submit"
                  className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
                  style={{ width: '50%' , backgroundColor:'#5cb85c ',color: 'white',fontSize: '14px',
                    borderColor: '#5cb85c'}}
              >
                  {toCreateTableDefinition
                      ? t('common.create')
                      : t('managUsers.register.saveUser')}
              </Button>

              <Button
                  onClick={handleCloseDialogTableDefinition}
                  className={classesPim.dialogBottom}
                  style={{ width: '50%' , backgroundColor:'#d9534f',color: 'white', fontSize: '14px',
                    borderColor: '#d43f3a'}}
              >
                  {t('managUsers.register.cancel')}
              </Button>
          </div>
            
        </form>
      </Container>
    );
}

export default ContextTableDefiniton;
