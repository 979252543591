import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tooltip from '@material-ui/core/Tooltip';

import {
  ButtonGroup,
  Button,
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox,
  IconButton,
  Icon,
  Collapse,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useDataManagement } from '../../datamanagement/DataManagementContext';
import { InstanceTabEditorReader } from './InstanceTabEditorReader';
import { useStylesTable, TableDataContext } from '../tools/Table';
import { CreateInstance } from '../../datamanagement/create-instances/CreateInstance';
import { CreateInstanceProvider } from '../../datamanagement/create-instances/CreateInstanceContext';
import { usePimStyles } from 'src/jsx/PimTogoTheme';

import ajaxPost from 'src/services/ajaxPost';

const useStyles = makeStyles(() => ({
  tableTop: {
    display: 'flex'
  },
  tableSelected: {
    position: 'relative',
    top: '1rem',
    left: '1rem'
  },
  tableActions: {
    marginLeft: 'auto',
    order: '2'
  },
  collapsibleViewPort: {
    width: 'calc(90vw - 54rem)'
  }
}));

export const TableDisplay = ({
  instanceType,
  parentInstanceEditor,
  columns,
  initialInstanceRows,
  createRow,
  nodesContent,
  nodeIdInCollapseRow
  //orientation
}) => {
  const { t } = useTranslation();
  const classes = useStylesTable();
  const classesPim = usePimStyles();
  const classesTableTop = useStyles();
  const {
    getMultipleInstances,
    setMultipleStances,
    getLoadedInstance,
    getVariableContentDefs,
    getFeatureDefs,
    getClassificationGroup
  } = useDataManagement();
  const multipleStancesState = getMultipleInstances(instanceType);
  const classificationGroup = getClassificationGroup();

  const [instanceRows, setInstanceRows] = useState(initialInstanceRows);
  const [selected, setSelected] = useState([]);
  const [isCreateInstanceOpen, setIsCreateInstanceOpen] = useState(false);
  const [isDuplicateInstanceOpen, setIsDuplicateInstanceOpen] = useState(false);
  const [duplicateInstance_, setDuplicateInstance_] = useState(false);

  useEffect(() => {
    const controlledInstancesState = initialInstanceRows.filter((instance) =>
      multipleStancesState.includes(instance.instanceId)
    );
    setInstanceRows(controlledInstancesState);
  }, [multipleStancesState.length, classificationGroup]);

  let createInstanceDeactivated = false;
  if (instanceType === 'ArticleGoldenRecordItem') {
    createInstanceDeactivated = true;
  }

  let deleteUrl;
  const isArticleTable = instanceType === 'Article';
  if (isArticleTable) {
    deleteUrl =
      'dataManagement/deleteArticles?productId=' +
      parentInstanceEditor.instanceId;
  } else {
    deleteUrl = 'dataManagement/deleteCustomInstances';
  }

  function selectAllRows() {
    if (selected.length != instanceRows.length) {
      const newSelectedRows = instanceRows.map((n) => n.instanceId);
      setSelected(newSelectedRows);
      return;
    }

    setSelected([]);
  }

  const selectRow = React.useCallback(
    (_, instanceId) => {
      const selectedIndex = selected.indexOf(instanceId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, instanceId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    [selected]
  );

  function checkRowSelected(instanceId) {
    return selected.indexOf(instanceId) !== -1;
  }

  function deleteSelected() {
    let msg;
    if (isArticleTable) {
      msg = t('instanceEditor.deleteArticles');
    } else {
      msg = t('instanceEditor.deleteInstances', instanceType);
    }

    TOGO.popupManager.confirmZebraDialog(msg, deleteConfirmed);

    function deleteConfirmed() {
      ajaxPost(deleteUrl, null, selected).then((ajaxData) => {
        let response = ajaxData.response;
        TOGO.Util.notifyResponse(response.message, !response.success);

        if (!ajaxData.error && response) {
          const reducedInstances = instanceRows.filter(
            (instance) => !selected.includes(instance.instanceId)
          );

          setInstanceRows(reducedInstances);

          setMultipleStances(
            instanceType,
            reducedInstances.map((instance) => instance.instanceId)
          );

          setSelected([]);
        }
      });
    }
  }

  function duplicateInstanceEvent() {
    setDuplicateInstance_(true);
    setIsDuplicateInstanceOpen(true);
  }

  function openCreateInstance() {
    TOGO.creationMandatory = {};
    setIsCreateInstanceOpen(true);
  }

  function closeCreateInstance(createdInstance) {
    if (createdInstance) {
      const createdInstanceRow = createRow(createdInstance);
      setInstanceRows(instanceRows.concat(createdInstanceRow));

      if (duplicateInstance_) {
        setSelected([]);
      }
    }

    setIsCreateInstanceOpen(false);
    setIsDuplicateInstanceOpen(false);
  }

  if (!columns) {
    return null;
  }
  return (
    <>
      <div className={classesTableTop.tableTop}>
        <div className={classesTableTop.tableSelected}>
          <span>
            {t('common.selected')}: {selected.length}
          </span>
        </div>
        {!createInstanceDeactivated && (
          <ButtonGroup
            className={classesTableTop.tableActions}
            variant="contained"
            color="secondary"
            aria-label="instance table actions"
          >
            {selected.length > 0 && (
              <Button
                onClick={deleteSelected}
                className={classesPim.deleteAction}
              >
                <Tooltip title={t('common.delete')} arrow>
                  <Icon className="fa fa-trash-o" />
                </Tooltip>
              </Button>
            )}
            {selected.length == 1 && (
              <Button onClick={duplicateInstanceEvent}>
                <Tooltip title={t('common.duplicate')} arrow>
                  <Icon className="fa fa-clone" />
                </Tooltip>
              </Button>
            )}
            {parentInstanceEditor && (
              <CreateInstanceProvider
                isInCreateInstanceContext={true}
                isDuplicate={true}
                duplicateInstanceState={getLoadedInstance(selected[0])}
                duplicateId={selected[0]}
                variableContentDefs={getVariableContentDefs(instanceType)}
                featureDefs={getFeatureDefs()}
              >
                <CreateInstance
                  isCreateInstanceOpen={isDuplicateInstanceOpen}
                  parentInstanceEditor={parentInstanceEditor}
                  instanceType={instanceType}
                  articleId={selected[0]}
                  closeCreateInstance={closeCreateInstance}
                />
              </CreateInstanceProvider>
            )}
            <Button
              onClick={openCreateInstance}
              className={classesPim.addAction}
            >
              <Tooltip title={t('common.create')} arrow>
                <Icon className="fa fa-plus" />
              </Tooltip>
            </Button>
            {parentInstanceEditor && (
              <CreateInstanceProvider
                isInCreateInstanceContext={true}
                isDuplicate={false}
              >
                <CreateInstance
                  isCreateInstanceOpen={isCreateInstanceOpen}
                  parentInstanceEditor={parentInstanceEditor}
                  instanceType={instanceType}
                  closeCreateInstance={closeCreateInstance}
                />
              </CreateInstanceProvider>
            )}
          </ButtonGroup>
        )}
      </div>
      <MuiTableContainer size="small" className={classes.tableWrap}>
        <MuiTable
          className="instanceEditorTable"
          aria-label="instance-editor-table"
        >
          <MuiTableHead className={classes.head}>
            <MuiTableRow>
              <MuiTableCell
                className="instance-checkbox instance-checkbox-all"
                padding="checkbox"
              >
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < instanceRows.length
                  }
                  checked={
                    instanceRows.length > 0 &&
                    selected.length === instanceRows.length
                  }
                  onClick={selectAllRows}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </MuiTableCell>
              <MuiTableCell
                className="instance-checkbox instance-checkbox-all head"
                padding="checkbox"
                style={{ fontSize: 'bold' }}
              ></MuiTableCell>
              {/* {nodeIdInCollapseRow && <MuiTableCell />} */}
              {columns.map((column) => (
                <MuiTableCell key={column.dataId}>
                  {column.header && column.header.missingContentLang
                    ? '<' +
                      column.header.typeName +
                      ': ' +
                      column.header.text +
                      '>'
                    : column.header.text}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {instanceRows.map((instanceRow, index) => {
              const isRowSelected = checkRowSelected(instanceRow.instanceId);

              return (
                <InstanceRow
                  key={instanceRow.instanceData.id}
                  instanceRows={instanceRows}
                  selectRow={selectRow}
                  instanceIndex={index}
                  isRowSelected={isRowSelected}
                  parentInstanceEditor={parentInstanceEditor}
                  instanceId={instanceRow.instanceId}
                  instanceType={instanceType}
                  setInstanceRows={setInstanceRows}
                  instanceData={instanceRow.instanceData}
                  nodesContent={nodesContent}
                  cells={instanceRow.cells}
                  nodeIdInCollapseRow={nodeIdInCollapseRow}
                ></InstanceRow>
              );
            })}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
    </>
  );
};
TableDisplay.propTypes = {
  columns: PropTypes.array,
  orientation: PropTypes.any,
  isMultipleData: PropTypes.bool
};

const InstanceRow = React.memo(
  ({
    instanceRows,
    setInstanceRows,
    instanceIndex,
    selectRow,
    isRowSelected,
    parentInstanceEditor,
    instanceId,
    instanceType,
    instanceData,
    nodesContent,
    cells,
    nodeIdInCollapseRow
  }) => {
    //const classes = useStylesTable();
    const [openCollapseRow, setOpenCollapseRow] = useState(false);
    const isArticleTable = instanceType === 'Article';
    const classes = useStyles();
    let readOnly = false;
    if (
      parentInstanceEditor.readOnly ||
      instanceType === 'ArticleGoldenRecordItem'
    ) {
      readOnly = true;
    }

    const moveInstance = (articleId, shift, index) => {
      let url;
      const payload = {
        articleId: articleId
      };
      if (isArticleTable) {
        if (shift == -1) {
          url = 'dataManagement/getArticleUpButton';
        } else {
          url = 'dataManagement/getArticleDownButton';
        }
        payload.articleId = articleId;
      } else {
        url = 'dataManagement/swapCustomInstanceRanking';
        payload.customInstanceType = instanceType;
        payload.index = index;
        payload.shift = shift;
        payload.containerId = parentInstanceEditor.instanceId;
        payload.containerInstanceType = parentInstanceEditor.instanceType;
      }

      ajaxPost(url, payload, {}).then((ajaxData) => {
        let response = ajaxData.response;
        TOGO.Util.notifyResponse(response.message, !response.success);

        if (!ajaxData.error && response) {
          const newSortingInstanceRows = moveArrayItemToNewIndex(
            instanceRows,
            index,
            index + shift
          );
          setInstanceRows(newSortingInstanceRows);
        }
      });
    };

    const swapArrayElements = (arr, indexA, indexB) => {
      var temp = arr[indexA];
      arr[indexA] = arr[indexB];
      arr[indexB] = temp;
    };

    const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
      const tabsArray = JSON.parse(JSON.stringify(arr));
      swapArrayElements(tabsArray, old_index, new_index);

      return tabsArray;
    };

    return (
      <>
        <TableDataContext.Provider value={[true, null]}>
          <MuiTableRow hover={false}>
            <MuiTableCell
              // className={classes.controlCollapse}
              className="instance-checkbox instance-checkbox-all"
              padding="checkbox"
            >
              <Checkbox
                checked={isRowSelected}
                onClick={(event) => selectRow(event, instanceId)}
              />
              {nodeIdInCollapseRow && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenCollapseRow(!openCollapseRow)}
                >
                  {openCollapseRow ? (
                    <Icon className="fa fa-caret-up" />
                  ) : (
                    <Icon className="fa fa-caret-down" />
                  )}
                </IconButton>
              )}
            </MuiTableCell>
            <MuiTableCell
              // className={classes.controlCollapse}
              className="instance-checkbox instance-checkbox-all"
              padding="checkbox"
            >
              <IconButton
                disabled={instanceIndex === 0}
                onClick={() => moveInstance(instanceData.id, -1, instanceIndex)}
              >
                <ArrowUpwardIcon> </ArrowUpwardIcon>
              </IconButton>
              <IconButton
                disabled={instanceIndex === instanceRows.length - 1}
                onClick={() => moveInstance(instanceData.id, 1, instanceIndex)}
              >
                <ArrowDownwardIcon> </ArrowDownwardIcon>
              </IconButton>
            </MuiTableCell>
            <InstanceTabEditorReader
              readOnly={readOnly}
              instanceType={instanceType}
              instanceId={instanceData.id}
              loadedInstanceData={instanceData}
              startingRootNodeId={null}
              loadedNodesContent={nodesContent}
              loadedContentLang={parentInstanceEditor.contentLang}
              flatNodesListToWrap={cells}
              Wrapper={InstanceRowCell}
            ></InstanceTabEditorReader>
          </MuiTableRow>
        </TableDataContext.Provider>
        {nodeIdInCollapseRow && (
          <MuiTableRow hover={false}>
            <MuiTableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={cells.length + 2}
            >
              <Collapse in={openCollapseRow} timeout="auto" unmountOnExit>
                <Box margin={1} className={classes.collapsibleViewPort}>
                  <InstanceTabEditorReader
                    readOnly={readOnly}
                    instanceType={instanceType}
                    instanceId={instanceData.id}
                    loadedInstanceData={instanceData}
                    startingRootNodeId={nodeIdInCollapseRow}
                    loadedNodesContent={nodesContent}
                    loadedContentLang={parentInstanceEditor.contentLang}
                  ></InstanceTabEditorReader>
                </Box>
              </Collapse>
            </MuiTableCell>
          </MuiTableRow>
        )}
      </>
    );
  }
);
InstanceRow.displayName = 'InstanceRow';

const InstanceRowCell = ({ children }) => {
  return <MuiTableCell>{children}</MuiTableCell>;
};
