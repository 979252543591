import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Tooltip, Icon } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddMedien from './AddMedien';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

import { gradientPimColor, usePimStyles } from 'src/jsx/PimTogoTheme';
import { ToggleStandardValues } from '../featurecomponents/ToggleStandardValues';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    fontSize: '15px !Important',
    paddingLeft: '2px'
  },
  barHeader: {
    textTransform: 'capitalize !important',
    margin: '0px 0 -1px',


    backgroundImage: gradientPimColor,
    height: '35px',
    minHeight: '35px !important',
    '@media (min-width: 600px)': {
      minHeight: '35px'
    }
  }
}));

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '2.5rem !important'
      }
    },
    MuiToolbar: {
      regular: {
        height: '32px',
        minHeight: '32px',
        '@media(min-width:600px)': {
          minHeight: '26px'
        },
        padding: 0
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '10px'
      }
    }
  }
});

const StyleAddPhotoAlternateIcon = withStyles({
  root: {
    '& .MuiSvgIcon-root': { fontSize: '2.5rem !important' }
  }
})(AddPhotoAlternateIcon);

export default function MediaContainerHeader({
  titleMediaEditor,
  searchUrl,
  addImage,
  isDefaultData,
  setBlockDefaultData,
  resetBlockDefaultData,
  isBlockDefaultData
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const classesPim = usePimStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AddMedien
        open={open}
        handleClose={handleClose}
        searchUrl={searchUrl}
        addImage={addImage}
        blockDefault={isBlockDefaultData}
      />
      <MuiThemeProvider theme={theme}>
        <AppBar position="static" className={classes.barHeader}>
          <Toolbar>
            <Typography className={classes.title}>
              {titleMediaEditor}
            </Typography>
            {(isDefaultData || isBlockDefaultData) && (
              <ToggleStandardValues
                isCustomField={false}
                setBlockDefaultData={setBlockDefaultData}
                resetBlockDefaultData={resetBlockDefaultData}
                isBlockDefaultData={isBlockDefaultData}
              />
            )}
            <Tooltip title="Bilder hinzufügen">
              <Button
                onClick={handleClickOpen}
                className={classesPim.addAction}
                style={{ width: '30px !important', minWidth: '0px' }}
              >
                <Icon className="fa fa-plus color-white" aria-hidden="true" />
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    </div>
  );
}
