import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTitleStyles } from '../articledata/datamanagement/featurecomponents/FeatureComponent';

import {
  Grid,
  Box,
 Button
} from '@material-ui/core';

import { countries } from './ressources/contries/countries';

import { usePimStyles } from '../PimTogoTheme';

import Textfield from '../user/FormsUI/TextField';

import Select from '../user/FormsUI/Select';

import ajaxPost from 'src/services/ajaxPost';

import FocusError from '../user/FocusError';
import MultipleSelect__ from '../user/FormsUI/MultipeSelect__';
import UserDataZonesSelect from './FormsUI/UserDataZonesSelect';

export const UserForm = ({
  handleCloseDialogUser,
  initFormState,
  internalEdit,
  toCreateOrga,
  roleInfos,
  articleDataZones,
  orgList,
  searchUsers
}) => {


  const { t } = useTranslation();
  var userLocales_ = {
    de_DE: t('managUsers.register.userLocales_.de_DE') ,
    en_GB: t('managUsers.register.userLocales_.en_GB'),
    fr_FR: t('managUsers.register.userLocales_.fr_FR'),
    it_IT: t('managUsers.register.userLocales_.it_IT') 
  };
  var userTimezone_ = {
    'Europe/Berlin': 'Berlin',
    UTC: 'Koordinierte Universalzeit',
    'Europe/Rome': 'Rom'
  };
  
  var salutations_ = { MALE: t('managUsers.register.salutations_.MALE'), FEMALE: t('managUsers.register.salutations_.FEMALE') };

  const classesTitle = useTitleStyles();

  // const userLocales = {'ACTIVE':'ACTIVE','SILENT':'SILENT' }
  // const userTimezone = {'ACTIVE':'ACTIVE','SILENT':'SILENT' }

  
  const classesPim = usePimStyles();

  const phoneRegExp =
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}\s*[(]{0,1}[0-9]{1,7}[)]{0,1}[-\s./0-9]*$/;

  const FORM_VALIDATION_USER_ = Yup.object().shape({
    id: '',
    username: Yup.string(),
    password: Yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen'
    ),
    //	password: Yup.string().required('Please Enter your password'),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // ),
    passwordRepeat: Yup.string().oneOf(
      [Yup.ref('password')],
      'Passwords does not match'
    ),
    userLocale: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    userTimezone: '',
    salutation: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    title: '',
    company: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    department: '',
    firstName: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    lastName: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    street: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    streetNumber: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    postalCode: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    city: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    country: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    phone: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputPhoneNumberRegExpErrorMessage')
    ),
    mobile: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputPhoneNumberRegExpErrorMessage')
    ),
    fax: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputPhoneNumberRegExpErrorMessage')
    ),
    email: Yup.string()
      .required(t('manageOrganization.organization.requiredFieldErrorMessage'))
      .email(t('manageOrganization.organization.inputMailRegExpErrorMessage')),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
       t('manageOrganization.organization.inputWebSiteRegExpErrorMessage')
    ),
    organization: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    permissions: '',
    userDataZones: Yup.array()
      .when('userDataZonesDefault', {
        is: false,
        then: (schema) => schema.min(1),
        otherwise: (schema) => schema.min(0)
      }),    
  });

  const FORM_VALIDATION_USER = Yup.object().shape({
    id: '',
    username: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    password: Yup.string()
      .required('Bitte geben Sie Ihr Passwort ein')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen'
      ),
    passwordRepeat: Yup.string()
      .required('Passwort bestätigen')
      .oneOf([Yup.ref('password')], 'Passworteingaben stimmen nicht überein'),
    userLocale: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    userTimezone: '',
    salutation: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    title: '',
    company: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    department: '',
    firstName: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    lastName: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    street: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    streetNumber: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    postalCode: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    city: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    country: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    phone: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputNumberRegExpErrorMessage')
    ),
    mobile: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputNumberRegExpErrorMessage')
    ),
    fax: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputNumberRegExpErrorMessage')
    ),
    email: Yup.string()
      .required(t('manageOrganization.organization.requiredFieldErrorMessage'))
      .email(t('manageOrganization.organization.inputMailRegExpErrorMessage')),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
       t('manageOrganization.organization.inputWebSiteRegExpErrorMessage')
    ),
    organization: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    permissions: '',
    userDataZones: Yup.array()
      .when('userDataZonesDefault', {
        is: false,
        then: (schema) => schema.min(1),
        otherwise: (schema) => schema.min(0)
      }),
    
  });

  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={{
        ...initFormState
      }}
      enableReinitialize={true}
      validationSchema={
        toCreateOrga ? FORM_VALIDATION_USER : FORM_VALIDATION_USER_
      }
      onSubmit={(values) => {
        if (toCreateOrga) {
          ajaxPost('listusers/create_', values).then((ajaxData) => {
            if (!ajaxData.error && ajaxData.response) {
              handleCloseDialogUser();
              TOGO.Util.notifyResponse(ajaxData.response.message, false);
              searchUsers(true);
            }
          });
        } else {
          ajaxPost('listusers/editDone_', values).then((ajaxData) => {
            if (!ajaxData.error && ajaxData.response) {
              handleCloseDialogUser();
              TOGO.Util.notifyResponse(ajaxData.response.message, false);
              searchUsers(false);
            }
          });
        }
      }}
    >
      <Form>
        <FocusError />
        <Box className={classesPim.gridItem}>
          <Grid container spacing={2}>
            {toCreateOrga ? (
              <>
                <Grid item xs={12}>
                  <Textfield
                    name="username"
                    label= {t('managUsers.register.username')+ "*"}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
                <Grid item xs={12}>
                  <Textfield
                    type="password"
                    name="password"
                    label={t('managUsers.register.password')+ "*"}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
                <Grid item xs={12}>
                  <Textfield
                    type="password"
                    name="passwordRepeat"
                    label={t('managUsers.register.passwordRepeat')+ "*"}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <input
                    type="password"
                    id="prevent_autofill1"
                    autoComplete="off"
                    style={{
                      opacity: '0',
                      position: 'absolute',
                      height: '0',
                      width: '0',
                      padding: '0',
                      margin: '0'
                    }}
                    tabIndex="-2"
                  />
                  <input
                    type="password"
                    id="prevent_autofill2"
                    autoComplete="off"
                    style={{
                      opacity: '0',
                      position: 'absolute',
                      height: '0',
                      width: '0',
                      padding: '0',
                      margin: '0'
                    }}
                    tabIndex="-2"
                  />
                  <Textfield
                    type="password"
                    name="password"
                    label={t('managUsers.register.password')}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
                <Grid item xs={12}>
                  <Textfield
                    type="password"
                    name="passwordRepeat"
                    label={t('managUsers.register.passwordRepeat')}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Select
                name="userLocale"
                label={t('managUsers.register.userLocale')+ "*"}
                options={userLocales_}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="userTimezone"
                label={t('managUsers.register.userTimezone')}
                options={userTimezone_}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="salutation"
                label={t('managUsers.register.salutation')+ "*"}
                options={salutations_}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="title"
                label={t('managUsers.register.title')}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>

            <Grid item xs={12}>
              <Textfield
                name="company"
                label={t('managUsers.register.company')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="department"
                label={t('managUsers.register.department')}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="firstName"
                label={t('managUsers.register.firstName')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="lastName"
                label={t('managUsers.register.lastName')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="street"
                label={t('managUsers.register.street')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="streetNumber"
                label={t('managUsers.register.streetNumber')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            
            <Grid item xs={12}>
              <Textfield
                name="postalCode"
                label={t('managUsers.register.postalCode')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="city"
                label={t('managUsers.register.city')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Select
                name="country"
                label={t('managUsers.register.country')+ "*"}
                options={t('manageOrganization.organization.countries') == "de_countries" ? countries.de: countries.en}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="phone"
                label={t('managUsers.register.phone')}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
          
            <Grid item xs={12}>
              <Textfield
                name="fax"
                label="Fax"
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="mobile"
                label={t('managUsers.register.mobile')}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
             
            <Grid item xs={12}>
              <Textfield
                type="email"
                name="email"
                label={t('managUsers.register.email')+ "*"}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
                         
            <Grid item xs={12}>
              <Textfield
                name="website"
                label={t('managUsers.register.webSite')}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
            {!internalEdit && <Grid item xs={12}>
              <Select
                name="organization"
                label={t('managUsers.register.organization')+ "*"}
                options={orgList}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              />
            </Grid>}
            <Grid item xs={12}>
              <MultipleSelect__
                name="permissions"
                roleInfos={roleInfos}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.datepickerStyle}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <UserDataZonesSelect
                dataZones={articleDataZones}
              />
            </Grid>            
          </Grid>
        </Box>
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            position: 'sticky',
            bottom: '0',
            zIndex: '9'
          }}
        >
          {/* <Grid container spacing={3}>
						<Button toCreateOrga={toCreateOrga} handleCloseDialogUser={handleCloseDialogUser} isOrganisation={false}/>
					</Grid>	 */}

          <Button
            // startIcon={<Icon className="fa fa-plus" />}
            type="submit"
            className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
            style={{ width: '50%' }}
          >
           {toCreateOrga
            ? t('common.create')
            : t('managUsers.register.saveUser') }
          </Button>

          <Button
            onClick={handleCloseDialogUser}
            className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
            style={{ width: '50%', marginLeft: '1rem' }}
          >
             {t('managUsers.register.cancel')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default UserForm;
