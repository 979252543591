import React, { useState, useEffect} from 'react';
import {
  IconButton,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PaginationComponent from './PaginationComponent';
import ajaxGet from 'src/services/ajaxGet';
import EnhancedTable from './EnhancedTable';
import theme, { usePimStyles } from '../PimTogoTheme';
// import PersonAddAltIcon from '@material-ui/icons/PersonAddAlt';
import PersonAddAltIcon from '@material-ui/icons/PersonAdd';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CreateUser from '../user/CreateUser';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));

const INITIAL_FORM_STATE_USER = {
  id: '',
  username: '',
  password: '',
  passwordRepeat: '',
  userLocale: '',
  userTimezone: '',
  salutation: '',
  title: '',
  company: '',
  department: '',
  firstName: '',
  lastName: '',
  street: '',
  streetNumber: '',
  postalCode: '',
  city: '',
  country: '',
  phone: '',
  mobile: '',
  fax: '',
  email: '',
  website: '',
  organization: '',
  permissions: []
};

export default function Users() {
  const classes = useStyles();
  const classesPim = usePimStyles();
  const { t } = useTranslation();

  const [searchExpression, setSearchExpression] = useState('');
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(5);
  const [paginationstate, setPaginationstate] = useState({});
  const [openDialogUser, setOpenDialogUser] = useState(false);
  const [toCreateUser, setToCreateUser] = useState(true);
  const [orgList, setOrgaList] = useState({});
  const [roleInfos, setRoleInfos] = useState({});
  const [articleDataZones, setArticleDataZones] = useState([]);
  const [internalEdit, setInternalEdit] = useState(true);
  const [initFormUserState, setInitFormUserState] = useState(
    INITIAL_FORM_STATE_USER
  );
  
  const handelEventSearchUsers = () => {
    setCurrentPage(1);
    setElementsPerPage(5);
    searchUsers();
  };

  const searchUsers = (showFirstUserPage) => {

    let targetPage = currentPage;
    if (showFirstUserPage) {
      targetPage = 1;
    }

    ajaxGet('listusers/searchUser', {
      searchExpression: searchExpression,
      currentPage: targetPage,
      elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        let usersList = response.users;
        setUsers(usersList);
        setPaginationstate(response.pagination);
        setInternalEdit(!response.manageGlobalUsers);
      }
    });
  };

  useEffect(() => {
    searchUsers();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickOpenDialogUser = () => {
    setOpenDialogUser(true);
  };

  const addUserhandel = () => {
    ajaxGet('listusers/create_', {}).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setOpenDialogUser(true);
        const userform = {
          id: '',
          username: '',
          password: '',
          passwordRepeat: '',
          userLocale: '',
          userTimezone: '',
          salutation: '',
          title: '',
          company: '',
          department: '',
          firstName: '',
          lastName: '',
          street: '',
          streetNumber: '',
          postalCode: '',
          city: '',
          country: '',
          phone: '',
          mobile: '',
          fax: '',
          email: '',
          website: '',
          organization: '',
          permissions: '',
          userDataZones: '',
          userDataZonesDefault: true
        };
        var listOrg = ajaxData.response.organizationList;
        if (listOrg != null && listOrg.length != 0) {
          setOrgaList(listOrg);
        }
        var listRoles = ajaxData.response.roleInfos;
        if (listRoles != null && listRoles.length != 0) {
          setRoleInfos(listRoles);
        }

        const dataZones  = ajaxData.response.articleDataZones;
        if (dataZones != null && dataZones.length != 0) {
          dataZones.sort((a, b) =>
            a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
          );          
          setArticleDataZones(dataZones);
        }

        setInternalEdit(ajaxData.response.internalEdit);

        setInitFormUserState(userform);
      }
    });
  };

  const handleCloseDialogUser = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
    setOpenDialogUser(false);
      setToCreateUser(true);
      setInitFormUserState(INITIAL_FORM_STATE_USER);
    
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCurrentPage(1);
      setElementsPerPage(5);
      searchUsers();
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid className={classes.root}>
          <form>
            <TextField
              label={t('manageOrganization.organization.search')}
              margin="normal"
              id="user-name-search"
              fullWidth
              value={searchExpression}
              onChange={handleChange}
              onKeyDown={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handelEventSearchUsers()}
                    position="start"
                  >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Grid>
        <Grid className={classes.root}>
          {true && (
            <PaginationComponent
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              isOrganization={false}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
            />
          )}
        </Grid>
        {'   '}
        <Grid className={classes.root}>
          <Button
            variant="outlined"
            onClick={() => {
              setToCreateUser(true);
              addUserhandel();
            }}
            startIcon={<PersonAddAltIcon />}
            className={`${classesPim.addAction} ${classes.submitButton}`}
          >
            <Typography fontSize={5}>{t('managUsers.register.createTitle')} </Typography>
          </Button>

          <CreateUser
            openDialogUser={openDialogUser}
            handleCloseDialogUser={handleCloseDialogUser}
            roleInfos={roleInfos}
            articleDataZones={articleDataZones}
            orgList={orgList}
            initFormState={initFormUserState}
            isLocked={false}
            toCreateOrga={toCreateUser}
            internalEdit={internalEdit}
            searchUsers={searchUsers}
          />

          <EnhancedTable
            rows={users}
            isOrganization={false}
            setOrgaList={setOrgaList}
            setInitFormUserState={setInitFormUserState}
            setRoleInfos={setRoleInfos}
            setArticleDataZones={setArticleDataZones}
            setUsers={setUsers}
            setToCreateUser={setToCreateUser}
            handleClickOpenDialogUser={handleClickOpenDialogUser}
            searchUsers={searchUsers}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
}
