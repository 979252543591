import React, { useState, useRef, useEffect } from 'react';

import JoditEditor from 'jodit-react';

import { useTranslation } from 'react-i18next';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { ToggleStandardValues } from '../featurecomponents/ToggleStandardValues';
import { useDataManagement } from '../DataManagementContext';

import ajaxPost from '../../../../services/ajaxPost';
import ajaxGet from 'src/services/ajaxGet';

import { JODIT_CONFIG, useStyles } from './TextEditorConfig';
import {
  configPlaceholderForVariables,
  textVariablesCloseEditor,
  textVariablesOnChangeText,
  textVariablesShowEditor,
  textVariablesShowEditorEventCommand,
  useConfigJoditForVariables,
  useResolveTextVariables
} from './TextEditorVariables';
import {
  MediaSelector,
  useConfigJoditForAnchoredMedia
} from './TextEditorAnchoredMedia';

export const TextEditor = ({ textType, instanceEditor }) => {
  const { t } = useTranslation();
  const {
    getTextState,
    setTextState,
    isDefaultData,
    deleteDefaultData,
    setDefaultData,
    setBlockDefaultData,
    deleteBlockDefaultData,
    isBlockDefaultData
  } = useDataManagement();
  const classes = useStyles();
  const editor = useRef(null);

  const textEditorData = instanceEditor.textEditor[textType];

  const [textId, setTextId] = useState(textEditorData?.id);
  const [isOpen, setIsOpen] = useState(false);
  const [wrapperId, setWrapperId] = useState(null);

  const defaultDataObject = {
    id: textType,
    type: 'textEditor',
    instanceId: instanceEditor.instanceId
  };
  const blockDefault = isBlockDefaultData(defaultDataObject);
  const required = textEditorData?.required;
  const contentAutomation = Boolean(
    textEditorData?.defaultDataAwareInfo?.subscribedContentAutomation
  );

  let joditConfig = { ...JODIT_CONFIG };
  joditConfig = useConfigJoditForVariables({
    joditConfig,
    textType,
    instanceEditor
  });

  let text = getTextState(textId);

  if (text) {
    text = text.replace('<root>', '').replace('</root>', '');
  } else {
    text = '';
  }

  let { $textResolvedVariables, textHasVariables } = useResolveTextVariables({
    textType,
    instanceEditor,
    text
  });

  if (text) {
    let $text;
    if (textHasVariables) {
      $text = $textResolvedVariables;
    } else {
      $text = $(text);
    }

    let $textWrap = $('<div></div>');
    $textWrap.append($text);
    text = $textWrap.html();
  }

  joditConfig = configPlaceholderForVariables({ joditConfig, text });

  const [config, setConfig] = useState(joditConfig);

  const isDefaultDataPTD = isDefaultData(defaultDataObject);
  const hasDefaultData = Boolean(
    textEditorData?.defaultDataAwareInfo?.defaultDataAvailable
  );
  const [isError, setIsError] = useState(
    required && !text && !hasDefaultData ? true : false
  );
  const [errorMsg, setErrorMsg] = useState(
    required && !text && !hasDefaultData ? t('common.errorMandatory') : ''
  );

  var title = '';
  if (textEditorData.contentLangTitle) {
    if (textEditorData.contentLangTitle.missingContentLang) {
      title =
        '<' +
        textEditorData.contentLangTitle.typeName +
        ': ' +
        textEditorData.contentLangTitle.text +
        '>';
    } else {
      title = textEditorData.contentLangTitle.text;
    }
  }

  useEffect(() => {
    TOGO.popupManager.joditDialogOpen = false;

    let random = (Math.random() + 1).toString(36).substring(7);
    setWrapperId('textEditorWrapper' + random);
  }, []);

  if (!textEditorData) {
    return null;
  }

  function showTextEditor(selectedWrapperId) {
    if (!isOpen) {
      setConfig((prevState) => ({
        ...prevState,
        showCharsCounter: true,
        showWordsCounter: true,
        toolbarAdaptive: false,
        toolbar: true,
        showXPathInStatusbar: true,
        height: 270,
        minHeight: 100,
        readonly: false,
        autofocus: true,
        events: {
          beforeCommand: function () {
            if (!selectedWrapperId) return;

            if (contentAutomation) {
              textVariablesShowEditorEventCommand(selectedWrapperId, true);
            }
          },
          afterCommand: function () {
            if (!selectedWrapperId) return;

            if (contentAutomation) {
              textVariablesShowEditorEventCommand(selectedWrapperId, true);
            }
          }
        }
      }));
      setIsOpen(true);
    }

    if (contentAutomation) {
      textVariablesShowEditor(selectedWrapperId);
    }
  }

  function closeTextEditor() {
    setConfig((prevState) => ({
      ...prevState,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
      toolbar: false,
      showXPathInStatusbar: false,
      height: '6.25rem',
      minHeight: 0,
      readonly: true,
      autofocus: false
    }));
    setIsOpen(false);
  }

  function onChangeText(newTextValue) {
    if (TOGO.popupManager.joditDialogOpen || instanceEditor.readOnly) {
      return;
    }

    if (newTextValue) {
      let $newTextValue = $(newTextValue);
      textVariablesOnChangeText($newTextValue);

      let $textWrap = $('<div></div>');
      $textWrap.append($newTextValue);
      newTextValue = $textWrap.html();

      let parser = new DOMParser();
      const doc = parser.parseFromString(newTextValue, 'text/html');

      const xmlPreparer = new XMLSerializer();
      newTextValue = xmlPreparer.serializeToString(doc.body.firstElementChild);
    } else {
      newTextValue = null;
    }

    if (newTextValue) {
      newTextValue = newTextValue
        .replaceAll('<br>', '<br />')
        .replaceAll('</br>', '')
        .replaceAll('<br /></', '</');

      newTextValue = newTextValue.replace(/<div><br \/><\/div>/g, '');
      newTextValue = newTextValue.replace(/(&nbsp;){1}/g, ' ');
      newTextValue = newTextValue.replace(/(\u00a0){1}/g, ' ');
    }

    if (
      newTextValue === '<div><br /></div>' ||
      newTextValue === '<div></div>'
    ) {
      newTextValue = null;
    }

    if (required && !newTextValue && !hasDefaultData) {
      setIsError(true);
      setErrorMsg(t('common.errorMandatory'));
      TOGO.Util.notifyResponse(t('common.errorMandatory'), true);
      return;
    }

    closeTextEditor();

    setErrorMsg('');
    setIsError(false);

    if (text === newTextValue || (!text && !newTextValue)) {
      return;
    }

    const payload = {
      id: textId,
      language: textEditorData.language,
      parentId: textEditorData.parentId,
      parentObjectClass: textEditorData.parentObjectClass,
      defaultDataForObjectClass: textEditorData.defaultDataForObjectClass,
      text: newTextValue,
      textType: textEditorData.textType,
      textHasVariables: textHasVariables
    };

    if (isDefaultDataPTD || blockDefault) {
      payload.id = null;
    }

    ajaxPost('saveText', {}, payload).then((ajaxData) => {
      let response = ajaxData.response;
      if (response)
        TOGO.Util.notifyResponse(response.message, !response.success);

      let targetTextId;

      if (!ajaxData.error && response) {
        if (newTextValue === null) {
          // text instance was deleted:
          let textInstanceId = response.textInstanceId;
          let defaultText = response.defaultText;
          if (textInstanceId && defaultText) {
            setDefaultData(defaultDataObject);

            targetTextId = textInstanceId;
            setTextId(textInstanceId);
            setTextState(targetTextId, defaultText);
          } else {
            setTextId(null);
            setTextState(textId, null);
          }
        } else {
          targetTextId = textId;
          if (textId === null || isDefaultDataPTD) {
            setTextId(response.textInstanceId);
            targetTextId = response.textInstanceId;
          }
          resetBlockDefaultDataText(true);
          deleteDefaultData(defaultDataObject);
          setTextState(targetTextId, newTextValue);
        }
      }
    });
  }

  function setBlockDefaultDataText() {
    let contentIdentifier = defaultDataObject.id;
    let type = defaultDataObject.type;
    let parentId = textEditorData.parentId;
    let parentObjectClass = textEditorData.parentObjectClass;

    ajaxPost('dataManagement/postSetBlockDefaultData', {
      contentIdentifier: contentIdentifier,
      type: type,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    setBlockDefaultData(defaultDataObject);
    deleteDefaultData(defaultDataObject);
    setTextState(textId, null);
  }

  function resetBlockDefaultDataText(withOutReset) {
    let contentIdentifier = defaultDataObject.id;
    let type = defaultDataObject.type;
    let parentId = textEditorData.parentId;
    let parentObjectClass = textEditorData.parentObjectClass;

    ajaxPost('dataManagement/postResetBlockDefaultData', {
      contentIdentifier: contentIdentifier,
      type: type,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    deleteBlockDefaultData(defaultDataObject);
    if (!withOutReset) {
      setDefaultData(defaultDataObject);
      resetDefaultText();
    }
  }

  function resetDefaultText() {
    let contentIdentifier = defaultDataObject.id;
    let contentLanguage = textEditorData.language;
    let parentId = textEditorData.parentId;
    let parentObjectClass = textEditorData.parentObjectClass;

    ajaxGet('getDefaultText', {
      contentIdentifier: contentIdentifier,
      contentLanguage: contentLanguage,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
      let textInstanceId = ajaxData.response.textInstanceId;
      let defaultText = ajaxData.response.defaultText;
      if (textInstanceId && defaultText) {
        setTextId(textInstanceId);

        let targetTextId = textInstanceId;
        setTextState(targetTextId, defaultText);
      }
    });
  }

  if (!textEditorData) {
    return null;
  }
  return (
    <>
      <FormControl
        error={isError}
        className="instanceEditorComponent"
        fullWidth={true}
      >
        <div style={{ maxWidth: 'calc(100vw - 53rem)' }}>
          <div className={`textEditor ${isOpen ? 'editMode' : 'readingMode'}`}>
            <div
              className="textEditorTitle editorTitle"
              style={{
                height: '35px',
                minHeight: '35px !important'
              }}
            >
              <h2>
                <span>{title}</span>
              </h2>
              {(isDefaultDataPTD || blockDefault) && (
                <ToggleStandardValues
                  switchMediaTextEditor={true}
                  setBlockDefaultData={setBlockDefaultDataText}
                  resetBlockDefaultData={resetBlockDefaultDataText}
                  isBlockDefaultData={blockDefault}
                />
              )}
            </div>
            <div
              id={wrapperId}
              className={`${classes.borderWrap} ${
                isDefaultDataPTD ? classes.defaultData : ''
              }`}
              onClick={() => {
                if (!instanceEditor.readOnly) {
                  showTextEditor(wrapperId);
                }
              }}
            >
              <JoditEditor
                className={classes.dontShow}
                ref={editor}
                value={text}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent, e) => onChangeText(newContent, e)}
              />
            </div>
            <FormHelperText>{errorMsg}</FormHelperText>
          </div>
        </div>
      </FormControl>
      {
        <MediaSelector
          joditConfig={joditConfig}
          setConfig={setConfig}
          textType={textType}
          instanceEditor={instanceEditor}
        />
      }
    </>
  );
};
