import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import ajaxGet from '../../services/ajaxGet.js';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useExport } from './ExportContext';
import { useBMEcatStyles } from './BMEcatExportOverview.jsx';
import { useTranslation } from 'react-i18next';
import { Checkbox, ListItemText } from '@material-ui/core';

export const ExportConfiguration = () => {
  const classes = useBMEcatStyles();
  const { t } = useTranslation();

  const {
    getInternUsers,
    setInternUsers,
    getCustomerAdresses,
    setCustomerAdresses,
    getMediaExportOption,
    setMediaExportOption,
    getExportCycle,
    setExportCycle,
    getWeekday,
    setWeekday,
    getStartDateExport,
    setStartDateExport,
    setConnectionType,
    getConnectionType,
    setSftpConnectionConfigurationId,
    getSftpConnectionConfigurationId,
    getSelectServerError,
    setSelectServerError
  } = useExport();

  const [exportConfigInfo, setExportConfigInfo] = useState({});
  const [sftpConnectionConfigurations, setSftpConnectionConfigurations] =
    useState([]);

  let internUsers = getInternUsers();
  let customerAdresses = getCustomerAdresses();
  let mediaExportOption = getMediaExportOption();
  let exportCycle = getExportCycle();
  let weekday = getWeekday();
  let startDateExport = getStartDateExport();
  let sftpConnectionConfigurationId = getSftpConnectionConfigurationId();
  let connectionType = getConnectionType();
  useEffect(() => {
    let contentLang = $('#contentLangSelectElement').val() || null;
    ajaxGet('getExportConfigurations', { contentLanguage: contentLang }).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          let exportConfigInfo = {};
          exportConfigInfo.userAdresses = ajaxData.response.userAdresses;
          exportConfigInfo.customerAdresses =
            ajaxData.response.customerAdresses;
          exportConfigInfo.exportCycles = ajaxData.response.exportCycles;
          exportConfigInfo.weekdays = ajaxData.response.weekdays;
          exportConfigInfo.earliestDate = ajaxData.response.earliestDate;
          exportConfigInfo.startDate = ajaxData.response.startDate;
          let dateString = null;
          let dateExport = getStartDateExport();
          if (dateExport === '') {
            if (exportConfigInfo.startDate) {
              dateString =
                exportConfigInfo.startDate.year +
                '-' +
                exportConfigInfo.startDate.month +
                '-' +
                exportConfigInfo.startDate.dayOfMonth;
            } else {
              dateString = '';
            }
          }
          setExportConfigInfo(exportConfigInfo);

          if (dateString != null) {
            setStartDateExport(dateString);
          }
        }
      }
    );
    ajaxGet('sftpConnectionConfigManagement/search', {
      searchExpression: '',
      currentPage: 1,
      elementsPerPage: 100
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        let sftpConnectionConfigViewList =
          response.sftpConnectionConfigurations;
        setSftpConnectionConfigurations(sftpConnectionConfigViewList);
      }
    });
  }, []);

  function handleChangeServer(event) {
    setSftpConnectionConfigurationId(event.target.value);
    if (event.target.value != '') {
      setSelectServerError(false);
    }
  }

  function handleChangeExportType(event) {
    setConnectionType(event.target.value);
    if (event.target.value != 'SFTP') {
      setSftpConnectionConfigurationId('');
    }
  }

  function handleChangeInternUsers(event) {
    setInternUsers(event.target.value);
  }

  function handleChangecustomerAdresses(event) {
    setCustomerAdresses(event.target.value);
  }

  function handleChangeMediaExportOption(event) {
    setMediaExportOption(event.target.value);
  }

  function handleChangeExportCircle(event) {
    setExportCycle(event.target.value);
  }

  function handleChangeStartDate(value) {
    setStartDateExport(value);
  }

  function handleChangeWeekday(event) {
    setWeekday(event.target.value);
  }

  return (
    <div>
      <Grid container spaceing={3}>
        <Grid item xs={6}>
          <div style={{ margin: '2rem' }}>
            <InputLabel id="exportAttachmentLabel">Export Anhang</InputLabel>
            <Select
              labelId="exportAttachmentLabel"
              id="exportAttachmentSelect"
              value={mediaExportOption}
              onChange={handleChangeMediaExportOption}
              label="Export Anhang"
              className={classes.bmeCatSelect}
            >
              <MenuItem value="noMedia">ohne Medien</MenuItem>
              <MenuItem value="mappedMedia">
                mit den ausgewählten Medien
              </MenuItem>
              <MenuItem value="allMedia">mit allen Medien</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid item xs={6}>
          {exportConfigInfo.exportCycles && (
            <div style={{ margin: '2rem' }}>
              <InputLabel id="exportCircleLabel">Export Rhythmus</InputLabel>
              <Select
                labelId="exportCircleLabel"
                id="exportCircleSelect"
                value={exportCycle}
                onChange={handleChangeExportCircle}
                label="Export Rhythmus"
                className={classes.bmeCatSelect}
              >
                {exportConfigInfo.exportCycles &&
                  exportConfigInfo.exportCycles.map((content) => (
                    <MenuItem
                      key={content.value + content.label}
                      value={content.value}
                    >
                      {content.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}
          {exportCycle == 'WEEKLY' && (
            <div style={{ margin: '2rem' }}>
              <InputLabel id="exportWeekdayLabel">Wochentag</InputLabel>
              <Select
                labelId="exportWeekdayLabel"
                id="exportWeekdaySelect"
                value={weekday}
                onChange={handleChangeWeekday}
                label="Wochentag"
                className={classes.bmeCatSelect}
              >
                {exportConfigInfo.weekdays &&
                  exportConfigInfo.weekdays.map((content) => (
                    <MenuItem
                      key={content.value + content.label}
                      value={content.value}
                    >
                      {content.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}
          {(exportCycle == 'MONTHLY' || exportCycle == 'YEARLY') && (
            <div style={{ margin: '2rem' }}>
              <TextField
                label="Startdatum / Nächstes Exportdatum"
                InputLabelProps={{
                  disableAnimation: true,
                  shrink: false,
                  className: classes.title
                }}
                value={startDateExport}
                type="date"
                onChange={(event) => handleChangeStartDate(event.target.value)}
                className={classes.bmeCatInput}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <div style={{ margin: '2rem 2rem 4rem 2rem' }}>
            <InputLabel id="exportTypeLabel">
              {t('managUsersSFTP.connectionType')}
            </InputLabel>
            <Select
              labelId="exportTypeLabel"
              id="exportTypeSelect"
              value={connectionType}
              onChange={handleChangeExportType}
              label="Export-Art"
              className={classes.bmeCatSelect}
            >
              {sftpConnectionConfigurations.length > 0 && (
                <MenuItem value="SFTP">SFTP</MenuItem>
              )}
              <MenuItem value="fileDownload">
                {t('managUsersSFTP.fileDownload')}
              </MenuItem>
            </Select>
          </div>
          {connectionType == 'SFTP' &&
            sftpConnectionConfigurations.length > 0 && (
              <div style={{ margin: '2rem 2rem 4rem 2rem' }}>
                <InputLabel id="serverLabel">Server</InputLabel>
                <Select
                  error={getSelectServerError()}
                  labelId="serverLabel"
                  id="serverSelect"
                  value={sftpConnectionConfigurationId}
                  onChange={handleChangeServer}
                  label="sftp-server"
                  displayEmpty
                  className={classes.bmeCatSelect}
                >
                  <MenuItem value="">
                    <em>{t('managUsersSFTP.defaultSelect')}</em>
                  </MenuItem>
                  {sftpConnectionConfigurations.length > 0 &&
                    sftpConnectionConfigurations.map((content) => (
                      <MenuItem key={content.id} value={content.id}>
                        {content.serverName}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            )}
          <div style={{ margin: '2rem' }}>
            <h4>
              Empfänger hinzufügen{' '}
              <Tooltip
                arrow
                title="Wird keine E-Mail angegeben, sind Sie der Empfänger."
              >
                <Icon
                  className="fa fa-question-circle-o"
                  style={{ color: green[500] }}
                  aria-hidden="true"
                  fontSize="small"
                />
              </Tooltip>
            </h4>
          </div>
          <div style={{ margin: '2rem' }}>
            <InputLabel id="internUsersLabel">Interne Benutzer</InputLabel>
            <Select
              labelId="internUsersLabel"
              id="internUsersSelect"
              value={internUsers}
              onChange={handleChangeInternUsers}
              label="Interne Benutzer"
              multiple
              renderValue={(selected) => {
                let ret = '';
                if (exportConfigInfo.userAdresses) {
                  const selectedAdresses = exportConfigInfo.userAdresses.filter(
                    (adress) => selected.includes(adress.value)
                  );
                  ret = selectedAdresses
                    .map((adress) => adress.label)
                    .join(', ');
                }

                return ret;
              }}
              className={classes.bmeCatSelect}
            >
              {exportConfigInfo.userAdresses &&
                exportConfigInfo.userAdresses.map((content) => (
                  <MenuItem
                    key={content.value + content.label}
                    value={content.value}
                  >
                    <Checkbox checked={internUsers.includes(content.value)} />
                    <ListItemText primary={content.label} />
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ margin: '2rem' }}>
            <InputLabel id="customerAdressesLabel">
              Kundenadressen (automatischer Versand)
            </InputLabel>
            <Select
              labelId="customerAdressesLabel"
              id="customerAdressesSelect"
              value={customerAdresses}
              onChange={handleChangecustomerAdresses}
              label="Kundenadressen (automatischer Versand)"
              multiple
              renderValue={(selected) => {
                let ret = '';
                if (exportConfigInfo.customerAdresses) {
                  const selectedAdresses =
                    exportConfigInfo.customerAdresses.filter((adress) =>
                      selected.includes(adress.value)
                    );
                  ret = selectedAdresses
                    .map((adress) => adress.label)
                    .join(', ');
                }

                return ret;
              }}
              className={classes.bmeCatSelect}
            >
              {exportConfigInfo.customerAdresses &&
                exportConfigInfo.customerAdresses.map((content) => (
                  <MenuItem
                    key={content.value + content.label}
                    value={content.value}
                  >
                    <Checkbox
                      checked={customerAdresses.includes(content.value)}
                    />
                    <ListItemText primary={content.label} />
                  </MenuItem>
                ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
};
