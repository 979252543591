import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTitleStyles } from '../articledata/datamanagement/featurecomponents/FeatureComponent';
import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';

import {
	Grid,
	Box,
	Button,
	Select,
	MenuItem,
	InputLabel
} from '@material-ui/core';

import { usePimStyles } from '../PimTogoTheme';

import Textfield from '../user/FormsUI/TextField';

import Select_ from '../classificationGroup/FormsUI/Select';

import FocusError from '../classificationGroup/FocusError';

export const ClassificationGroupForm = ({
	handleCloseDialogClassificationGroup,
	initFormClassificationGroupState,
	classificationList,
	toCreateClassificationGroup,
	searchClassificationGroups,
	contentLangSelections,
	productTypeDefinition
}) => {
	const { t } = useTranslation();
	const [classificationType, setClassificationType] = useState(true);
	const [language, setLanguage] = useState(t('language.code'));

	const classesTitle = useTitleStyles();

	const classesPim = usePimStyles();

	const formikRef = useRef();


	const FORM_VALIDATION_CLASSIFICATION_GROUP____ = Yup.object().shape({
		id: '',
		classification:'',// Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		classificationIdentifier: Yup.string().max(64, 'Muss nicht mehr als 64 Zeichen enthalten'),
		language:'',
		// Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		classificationGroupName: Yup.string().max(255, 'Muss nicht mehr als 255 Zeichen enthalten')
	});

	useEffect(() => {}, []);

	function handleChangeClassificationType(event) {
		const val = event.target.value;
		setClassificationType(val);
	}


	const getClassificationGroup = (id, val) => {
		ajaxGet('classificationGroupsManagement/edit', {
			classificationGroupId: id,
			contentLang:val
		}).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				var res = ajaxData.response.model.classificationGroupForm;
				if (res != null && res != undefined) {
					formikRef.current.setFieldValue(
						"classificationIdentifier",
						res?.classificationIdentifier || ''
					);

					formikRef.current.setFieldValue(
						"classificationGroupName",
						res?.classificationGroupName || ''
					);

				}
			}
		});
	};
	function handleChangeLanguage(event) {
		const val = event.target.value;
		var contentLang = formikRef.current.values.language;

		formikRef.current.setFieldValue(
			"language",
			val
		);
		setLanguage(val);
		let params = {};
		params.id = formikRef.current.values.id;

		params.classificationGroupName = formikRef.current.values.classificationGroupName;
		params.language = contentLang;
		ajaxPost('classificationGroupsManagement/editDone', params).then(
			(ajaxData) => {
				if (!ajaxData.error && ajaxData.response) {
					// handleCloseDialogClassificationGroup();
					TOGO.Util.notifyResponse(ajaxData.response.message, false);
					// searchClassificationGroups();
				}
				getClassificationGroup(formikRef.current.values.id, val);
			}
		);
	}

	return (
	<Formik
	initialValues={{
		...initFormClassificationGroupState
	}}
		innerRef={formikRef}
		enableReinitialize={true}
		validationSchema={FORM_VALIDATION_CLASSIFICATION_GROUP____}
		onSubmit={(values) => {
		console.log(values);
		let params = {};
		params.id = values?.id;
		if (toCreateClassificationGroup){
			if (classificationType) {
				params.classificationIdentifier =
					values?.classification?.split('|')[0];
				params.classificationGroupName =
					values?.classification?.split('|')[1];
				params.localClassification = false;
			} else {
				params.classificationIdentifier = values?.classificationIdentifier;
				params.classificationGroupName = values?.classificationGroupName;
				params.localClassification = true;
			}
		}else{
			params.classificationGroupName = values?.classificationGroupName;
			params.language = values?.language;
		}
		if (toCreateClassificationGroup == true) {
			params.language=language;
			ajaxPost('classificationGroupsManagement/create', params).then(
				(ajaxData) => {
					if (!ajaxData.error && ajaxData.response) {
						TOGO.Util.notifyResponse(ajaxData.response.message, false);
						handleCloseDialogClassificationGroup();
						searchClassificationGroups();
					}
				}
			);
		} else {
			ajaxPost('classificationGroupsManagement/editDone', params).then(
				(ajaxData) => {
					if (!ajaxData.error && ajaxData.response) {
						handleCloseDialogClassificationGroup();
						TOGO.Util.notifyResponse(ajaxData.response.message, false);
						searchClassificationGroups();
					}
				}
			);
		}
	}}
>

      <Form>
        <FocusError />
        <Box className={classesPim.gridItem}>
          <Grid container spacing={2}>
            {toCreateClassificationGroup ? (
              <>
                <Grid item xs={12}>
                  <InputLabel id="classificationTypeLabel">
                    Klassifierung
                  </InputLabel>
                  <Select
                    labelId="classificationTypeLabel"
                    id="selectClassificationType"
                    value={classificationType}
                    onChange={handleChangeClassificationType}
                    label="classificationType"
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  >
                    <MenuItem selected value={true}>
                      {t('classificationGroup.externClassification')}{' '}
                    </MenuItem>
                    {productTypeDefinition && (
                      <MenuItem value={false}>
                        {t('classificationGroup.internClassification')}
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
                {classificationType ? (
                  <Grid item xs={12}>
                    {/* <InputLabel  id="externClassificationLabel">{t('classificationGroup.externClassification')}</InputLabel>
							<Select
								labelId="externClassificationLabel"
								id="selectExternClassification"
								value={classificationType}
								onChange={handleChangeClassificationType}
								className={classesTitle.datepickerStyle}>
								{
								Object.entries(classificationList).map((item, pos) => {
									return (
									<MenuItem key={pos} value={item[0]}>
										{item[1]}
									</MenuItem>
									)
								})}
							</Select> */}
                    <Select_
                      name="classification"
                      label={
                        t('classificationGroup.externClassification') + '*'
                      }
                      options={classificationList}
                      InputLabelProps={{
                        shrink: false,
                        disableAnimation: true,
                        className: classesTitle.title
                      }}
                      className={classesTitle.datepickerStyle}
                      variant={'outlined'}
                    ></Select_>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Textfield
                        name="classificationIdentifier"
                        label={t('classificationGroup.id')}
                        InputLabelProps={{
                          shrink: false,
                          disableAnimation: true,
                          className: classesTitle.title
                        }}
                        className={classesTitle.datepickerStyle}
                        variant={'outlined'}
                      ></Textfield>
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield
                        name="classificationGroupName"
                        label={t('classificationGroup.designation')}
                        InputLabelProps={{
                          shrink: false,
                          disableAnimation: true,
                          className: classesTitle.title
                        }}
                        className={classesTitle.datepickerStyle}
                        variant={'outlined'}
                      ></Textfield>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <InputLabel id="classificationTypeLabel">Sprache</InputLabel>
                  <Select
                    labelId="classificationTypeLabel"
                    variant={'outlined'}
                    name="language"
                    className={classesTitle.datepickerStyle}
                    onChange={handleChangeLanguage}
                    value={language}
                  >
                    {
                      (contentLangSelections =
                        !undefined &&
                        contentLangSelections.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        }))
                    }
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Textfield
                    name="classificationIdentifier"
                    label={t('classificationGroup.id')}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    InputProps={{ readOnly: true }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
                <Grid item xs={12}>
                  <Textfield
                    name="classificationGroupName"
                    label={t('classificationGroup.designation')}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true,
                      className: classesTitle.title
                    }}
                    className={classesTitle.datepickerStyle}
                    variant={'outlined'}
                  ></Textfield>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            position: 'sticky',
            bottom: '0',
            zIndex: '9'
          }}
        >
          <Button
            type="submit"
            className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
            style={{ width: '50%' }}
          >
            {toCreateClassificationGroup
              ? t('common.create')
              : t('managUsers.register.saveUser')}
          </Button>

          <Button
            onClick={handleCloseDialogClassificationGroup}
            className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
            style={{ width: '50%', marginLeft: '1rem' }}
          >
            {t('managUsers.register.cancel')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default ClassificationGroupForm;
