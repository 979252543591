import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper
} from '@material-ui/core';
import ajaxGet from 'src/services/ajaxGet';
import ajaxPut from 'src/services/ajaxPut';

const useStyles = makeStyles(() => ({
  toggleAllButton: {
    marginLeft: '2rem',
    marginTop: '2rem'
  }
}));

export default function SubscribableClassifications({
  orga,
  setForceResizing
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [classifications, setClassifications] = useState([]);

  if (orga == undefined) {
    orga = null;
  }

  useEffect(() => {
    ajaxGet('organizationManagement/subscribableClassifications', {
      orgaId: orga
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setClassifications(ajaxData.response.selectableClassifications);
        setSelected(ajaxData.response.selected);
      }

      if (setForceResizing) {
        setForceResizing((prevCount) => prevCount + 1);
      }
    });
  }, []);

  const changeSubscribables = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newSubscribables = [...selected];

    if (currentIndex === -1) {
      newSubscribables.push(value);
    } else {
      newSubscribables.splice(currentIndex, 1);
    }

    ajaxPut('organizationManagement/subscribableClassifications', {
      newClassificationIdentifiers: newSubscribables,
      orgaId: orga
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setSelected(newSubscribables);
      }
    });
  };

  const toggleAllSubscribables = () => {
    let newSubscribables = [];
    if (selected.length == 0) {
      newSubscribables = classifications.map((item) => item.value);
    }

    ajaxPut('organizationManagement/subscribableClassifications', {
      newClassificationIdentifiers: newSubscribables,
      orgaId: orga
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setSelected(newSubscribables);
      }
    });
  };

  return (
    <Paper>
      <Button
        variant="contained"
        color="secondary"
        className={classes.toggleAllButton}
        onClick={toggleAllSubscribables}
      >
        {t('common.selectDeselectAll')}
      </Button>
      <List dense component="div" role="list">
        {classifications.map((classification) => {
          const labelId = `classification-list-item-${classification.value}-label`;

          return (
            <ListItem
              key={classification.value}
              role="listitem"
              button
              onClick={changeSubscribables(classification.value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={selected.indexOf(classification.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={classification.label} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
}
