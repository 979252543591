import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    Typography, Switch, FormControlLabel, FormGroup, Slider, Input, Select, MenuItem, Grid, Radio, RadioGroup
} from '@mui/material';
import AddBoxIcon from "@mui/icons-material/AddBox";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from '@mui/material/TableCell';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {KeyboardDoubleArrowDown} from "@mui/icons-material";


const EditableTable = ({tableContent ,setTableContent}) => {

    
    const [columns, setColumns] = useState(tableContent?.columns);
    const [rows, setRows] = useState(tableContent?.rows); 
    const [selectedCell, setSelectedCell] = useState(null);
    const [cellProps, setCellProps] = useState( {
        content: "F1",
        width: 100,
        height: 50,
        paraStyle: "P2",
        charStyle: "C1",
        colour: "none",
        borderStyle: "B3",
        verticalAlignment: "middle",
        horizontalAlignment: "left",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        colspan: 1,
        rowspan: 1,
        row:1,
        column:1
    });

    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [paddingType, setPaddingType] = React.useState('paddingLeft');
    const [alignmentType, setAlignmentType] = React.useState('verticalAlignment');
    const [selectedOption, setSelectedOption] = useState('cell');

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        if (selectedCell !== null && event.target.value ==='column'){
            handleColumnClick(selectedCell.colIndex)
        }
        if (selectedCell !== null && event.target.value ==='row'){
            handleRowClick(selectedCell.rowId)
        }
        if ( event.target.value ==='cell' || selectedCell === null ){
            setSelectedColumn(null);
            setSelectedRow(null);
        }

    };

    const handleColumnClick = (index) => {
        setSelectedColumn(index);
        setSelectedOption('column');
        setSelectedRow(null);
        setSelectedCell(null)
        const newData = rows.filter((row, colIndex) =>
            row.data.length >0
        );

        if (newData.length >0 && newData[0].data.length >0){
            setSelectedWidth(newData[0].data[0].width)
        }
    };

    const handleRowClick = (index) => {
            setSelectedRow(index);
            setSelectedOption('row');
            setSelectedColumn(null);
            setSelectedCell(null);
        const newData = rows.filter((row, rowIndex) =>
                rowIndex === index
            );

        if (newData.length >0 && newData[0].data.length >0 ){
            setSelectedHeight(newData[0].data[0].height);
        }
    };

    const addElementAfter = () => {
        if (selectedOption ==='row'){
            if (checkIfRowIsEmpty(rows,selectedRow )) addRow(selectedRow + 1 )
        }else{
            addColumn(selectedColumn + 1)
        }
    };

    const addElementBefore = () => {
        if (selectedOption ==='row'){
            if (checkIfRowIsEmpty(rows,selectedRow )) addRow_before(selectedRow)
        }else{
            if (checkColspan(rows,selectedColumn) ){
                addColumn(selectedColumn + 1)
            }else{
                addColumn(selectedColumn)

            }
        }
    };

    const addColumn = (selectedIndex) => {
        const newColumnName =`Column ${selectedIndex}`;
        const totalCols=[...columns.slice(0, selectedIndex), newColumnName, ...columns.slice(selectedIndex)]
        setColumns(totalCols)
        setRows(rows.map(row => {
            if (row.data[selectedIndex-1] && row.data[selectedIndex-1].colspan > 1) {
                row.data[selectedIndex-1].colspan += 1;
            } else {
                row.data = [
                    ...row.data.slice(0, selectedIndex),
                    {
                        content: "",
                        width: 100,
                        height: 50,
                        paraStyle: "P2",
                        charStyle: "C1",
                        colour: "none",
                        borderStyle: "B3",
                        verticalAlignment: "middle",
                        horizontalAlignment: "left",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        colspan: 1,
                        rowspan: 1,
                        row: row.id,
                        column: columns.length
                    },
                    ...row.data.slice(selectedIndex)
                ];
            }
            return row;
        }))
        setTableContent({columns:totalCols, rows:rows})
    };

    function checkRowspan(array, id) {
        let item = array.find(el => el.id === id);

        if (!item) {
            return false;
        }

        return item.data.some(el => el.rowspan > 1);
    }
    
    function checkIfRowIsEmpty(array, index_) {
        let item = array.find((el,i )=> i === index_);
        if (!item) {
            return false;
        }
        if (item.data.length === 0){
            return false;
        }else {
            return true;
        }
    }

    function checkColspan(rows, index_) {
        if (!rows || rows.length === 0) {
            return false;
        }

        for (let obj of rows) {
            let item = obj.data[index_];
            if (!item) {
                return false;
            }
            if (item.colspan > 1) {
                return true;
            }
        }

        return false;
    }
    const addRow = (selectedIndex) => {
       let hasRowspan =  checkRowspan(rows, selectedIndex -1);
        if (hasRowspan) {
            const newRow = {
                id: rows.length + 1,
                data: []
            };
            rows.push(newRow);
            const updatedRows = rows.map((row,rowIndex) => {

                if (rowIndex === selectedIndex -1) {
                    const newData = [...row.data];
                    //2
                    const newData_ = newData.map(cell => {
                        return { ...cell, rowspan: cell.rowspan +1  };
                    });
                    return { ...row, data: newData_ };
                }


                return row;
            });

            for (let i = updatedRows.length - 1; i > 0; i--) {
                if (i > selectedIndex  ){
                    updatedRows[i].data = updatedRows[i - 1].data;
                }
            }

            updatedRows[selectedIndex].data = [];
            setRows(updatedRows);
            setTableContent({columns:columns,rows:updatedRows})

        }else {
            let result = tabInit();
            console.log(result);
            let cp = 0
            if (result.hasOwnProperty(selectedIndex) ){
                cp = result[selectedIndex].cp;
            }

            let cp_ = result.findIndex((obj, i) => i > selectedIndex && obj.cp === 0);
            if (cp_ < 0) cp_ = 0

            const newRow = {
                id: rows.length + 1,
                data: Array.from({ length: columns.length - cp}, (_, i) => (
                    {
                        content:"",
                        width: 100,
                        height: 50,
                        paraStyle: "P2",
                        charStyle: "C1",
                        colour: "none",
                        borderStyle: "B3",
                        verticalAlignment: "middle",
                        horizontalAlignment: "left",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        colspan: 1,
                        rowspan: 1,
                        row:rows.length + 1,
                        column:i
                    }
                ))
            };

            let rows__ =  [...rows.slice(0, selectedIndex), newRow, ...rows.slice(selectedIndex)]

            const updatedRows = rows__.map((row,rowIndex) => {

                if (rowIndex === selectedIndex) {
                    const newData = [...row.data];

                    return { ...row, data: newData };
                }

                if (cp_   === rowIndex ){

                    let res = Array.from({ length: cp }, (_, i) => (
                        {
                            content: rows.length + 1 + '_' + (i),
                            width: 100,
                            height: 50,
                            paraStyle: "P2",
                            charStyle: "C1",
                            colour: "none",
                            borderStyle: "B3",
                            verticalAlignment: "middle",
                            horizontalAlignment: "left",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            colspan: 1,
                            rowspan: 1,
                            row:rows.length + 1,
                            column:i
                        }
                    ))
                    const newData = [...row.data];

                    newData.push(...res);

                    return { ...row, data: newData };
                }
                return row;
            });
            setRows(updatedRows);
            setTableContent({columns:columns,rows:updatedRows})
        }
    };


    const addRow_before = (selectedIndex) => {
        let hasRowspan =  checkRowspan(rows, selectedIndex);
        if (hasRowspan) {
            const newRow = {
                id: rows.length + 1,
                data: []
            };
            rows.push(newRow);
            const updatedRows = rows.map((row,rowIndex) => {

                if (rowIndex === selectedIndex ) {
                    const newData = [...row.data];
                    //2
                    const newData_ = newData.map(cell => {
                        return { ...cell, rowspan: cell.rowspan +1  };
                    });
                    return { ...row, data: newData_ };
                }


                return row;
            });

            for (let i = updatedRows.length - 1; i > 0; i--) {
                if (i > selectedIndex + 1 ){
                    updatedRows[i].data = updatedRows[i - 1].data;
                }
            }

            updatedRows[selectedIndex + 1 ].data = [];
            setRows(updatedRows);
            setTableContent({columns:columns,rows:updatedRows})

        }else {
            let result = tabInit();
            console.log(result);
            let cp = 0
            if (result.hasOwnProperty(selectedIndex) ){
                cp = result[selectedIndex].cp;
            }

            let cp_ = result.findIndex((obj, i) => i > selectedIndex && obj.cp === 0);
            if (cp_ < 0) cp_ = 0

            const newRow = {
                id: rows.length + 1,
                data: Array.from({ length: columns.length - cp}, (_, i) => (
                    {
                        content:"",
                        width: 100,
                        height: 50,
                        paraStyle: "P2",
                        charStyle: "C1",
                        colour: "none",
                        borderStyle: "B3",
                        verticalAlignment: "middle",
                        horizontalAlignment: "left",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        colspan: 1,
                        rowspan: 1,
                        row:rows.length + 1,
                        column:i
                    }
                ))
            };

            let rows__ =  [...rows.slice(0, selectedIndex), newRow, ...rows.slice(selectedIndex)]

            const updatedRows = rows__.map((row,rowIndex) => {

                if (rowIndex === selectedIndex) {
                    const newData = [...row.data];

                    return { ...row, data: newData };
                }

                if (cp_   === rowIndex ){

                    let res = Array.from({ length: cp }, (_, i) => (
                        {
                            content: rows.length + 1 + '_' + (i),
                            width: 100,
                            height: 50,
                            paraStyle: "P2",
                            charStyle: "C1",
                            colour: "none",
                            borderStyle: "B3",
                            verticalAlignment: "middle",
                            horizontalAlignment: "left",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            colspan: 1,
                            rowspan: 1,
                            row:rows.length + 1,
                            column:i
                        }
                    ))
                    const newData = [...row.data];

                    newData.push(...res);

                    return { ...row, data: newData };
                }
                return row;
            });
            setRows(updatedRows);
            setTableContent({columns:columns,rows:updatedRows})

        }
    };
    const deleteElement = () => {
        if (selectedOption ==='row'){
            deleteRow()
        }else{
            deleteColumn()
        }
    };

    const deleteRow = () => {

        if (rows.length > 0) {
            let hasRowspan =  checkRowspan(rows, selectedRow);
            let rows_ = rows.filter(function(row,index) {
                return selectedRow !== index  ;
            })
            if (hasRowspan){
                let result_ = tabInit();
                let result = result_.filter(function(line,index) {
                    return selectedRow !== index  ;
                })

                for (let obj of rows_) {
                    let totalColspan = obj.data.reduce((sum, item) => sum + item.colspan, 0);
                    if (totalColspan < columns.length) {
                        let index__ = rows_.indexOf(obj);
                        let cp = 0
                        if (result.hasOwnProperty(index__) ){
                            cp = result[index__].cp;
                        }
                        let dif = columns.length - totalColspan;
                        let res = Array.from({ length: dif }, (_, i) => (
                            {
                                content: "",
                                width: 100,
                                height: 50,
                                paraStyle: "P2",
                                charStyle: "C1",
                                colour: "none",
                                borderStyle: "B3",
                                verticalAlignment: "middle",
                                horizontalAlignment: "left",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                                colspan: 1,
                                rowspan: 1,
                                row:rows.length + 1,
                                column:i
                            }
                        ))

                        obj.data.push(...res);
                    }
                }
            }

            setRows(rows_);
            setTableContent({columns:columns,rows:rows_})

        }
    };
    function deleteLastNElements(arr, n) {
        // Ensure n is a valid number
        if (n > 0 && n <= arr.length) {
            arr.splice(-n, n);
        }
        return arr;
    }
    const deleteColumn = () => {
        if (columns.length > 0) {

            let tatalCols = columns.filter(function(col,index) {
                return selectedColumn !== index
            })
            setColumns(tatalCols);
            const updatedRows = rows.map((row,index) => {
                if (row ){
                    const newData = [...row.data];
                    const newData_ = newData.filter(function(cell, index ) {
                        return selectedColumn !== index
                    })

                    return { ...row, data: newData_ };
                }
                return row;
            });


            for (let obj of updatedRows) {
                let totalColspan = obj.data.reduce((sum, item) => sum + item.colspan, 0);
                if (totalColspan < tatalCols.length ) {
                    let dif = (tatalCols.length) - totalColspan ;
                    let res = Array.from({ length: dif }, (_, i) => (
                        {
                            content: "",
                            width: 100,
                            height: 50,
                            paraStyle: "P2",
                            charStyle: "C1",
                            colour: "none",
                            borderStyle: "B3",
                            verticalAlignment: "middle",
                            horizontalAlignment: "left",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            colspan: 1,
                            rowspan: 1,
                            row:rows.length + 1,
                            column:i
                        }
                    ))

                    obj.data.push(...res);
                }
                if (totalColspan > tatalCols.length){
                    let dif = totalColspan - tatalCols.length   ;
                    if (obj.data.length === 1){
                        obj.data[0].colspan -= 1;
                    }else if (obj.data.length > 1 ){
                        let lastElement = obj.data.filter((item, index, arr) => index === arr.length - 1);
                        if (lastElement.colspan  > 1){
                            obj.data  = obj.data.map((item, index, array) => {
                                if (index === array.length - 1 ) {
                                    return { ...item, colspan: item.colspan - dif };
                                }
                                return item;
                            });
                        } else {
                            obj.data.pop();

                        }
                    }

                }
            }
            setRows(updatedRows);
            setTableContent({columns:tatalCols,rows:updatedRows})

        }
    };

    const mergeCells = () => {
        if (selectedCell) {
            const { rowId, colIndex, mergedCell } = selectedCell;
            let level =  mergedCell.rowspan + rowId ;
            var colspanMerge = 0
            if (columns.length >= 2 &&  colIndex < columns.length-1) {
                const updatedRows = rows.map((row, rowIndex) => {
                    if (rowIndex === rowId) {
                        const newData = [...row.data];
                        if (colIndex < newData.length-1){
                            newData[colIndex ]["colspan"] += newData[colIndex + 1 ]["colspan"];
                            colspanMerge = newData[colIndex]["colspan"]
                            newData.splice(colIndex + 1, 1);
                        }
                        return { ...row, data: newData };
                    }

                    if (rowIndex > rowId && rowIndex  < level){
                        const newData = [...row.data];
                        const newData_ = newData.filter(function(cell) {
                            return cell.column !== colIndex+1
                        })
                        newData.splice(colIndex , 1);
/*                        if(selectedRowLenght > nextRowLenght){
                            newData.splice(-1 , 1);
                            nextRowLenght=0;
                        }*/
                        return { ...row, data: newData };
                    }

                    return row;
                });
                setRows(updatedRows);
                setTableContent({columns:columns,rows:updatedRows})
                tabInit()
            }
        }
    };

const tabInit = () =>{

    let arrayOfObjects = []
    rows.forEach((obj,index) => {
        arrayOfObjects.push({
            id: obj.id, cp: 0
        })
    })

    rows.forEach((obj,index) => {
    //    console.log(`ID: ${obj.id}`);
        obj.data.forEach((item) => {
            const { colspan, rowspan, row, column } = item;
            if (rowspan > 1){
                editResult (arrayOfObjects , index , index + 1 , colspan-1)
                editResult (arrayOfObjects , index + 1, index + rowspan , colspan)
            }
            if (colspan > 1 && rowspan === 1) {
                editResult (arrayOfObjects , index , index + rowspan , colspan-1)
            }

          //  console.log(`Colspan: ${colspan}, Rowspan: ${rowspan}, Row: ${row}, Column: ${column}`);
        });
    });
    return arrayOfObjects

/*    const updatedRows__ = rows.map((row ,index) => {

            const newData = [...row.data];
            if(newData.length > 0){
                const newData_ = deleteLastNElements (newData,arrayOfObjects[index].cp)
                return { ...row, data: newData_ };
            }
        return row;
    });

    setRows(updatedRows__);*/
}
    function deleteLastNElements(array, n) {
        if (n > array.length) {
            // If n is greater than the array length, clear the entire array
            array.length = 0;
        } else if (n > 0) {
            // Calculate the starting index and remove the last n elements
            let startIndex = array.length - n;
            array.splice(startIndex, n);
        }
        // If n is 0 or negative, do nothing
        return array;
    }
    const editResult  = (arrayOfObjects, startIndex, endIndex, colspan) => {

        const subArray = arrayOfObjects.slice(startIndex, endIndex);

        const modifiedSubArray = subArray.map(obj => {
            return { ...obj, cp: obj.cp + colspan };
        });

        arrayOfObjects.splice(startIndex, endIndex - startIndex, ...modifiedSubArray);

    }

    const mergeCellsVerticaly = () => {
        if (selectedCell) {
            const { rowId, colIndex } = selectedCell;
            var level = 0;
            var selectedRowLenght = 0;
            var nextRowLenght = 0;
            var rowspanMerge = 0 ;
            var colspanMerge = 0 ;

            if (columns.length > 1 ) {
                const updatedRows = rows.map((row, rowIndex) => {
                    if (rowIndex === rowId) {
                        const newData = [...row.data];
                        level = newData[colIndex ]["rowspan"];
                        colspanMerge = newData[colIndex ]["colspan"];
                        selectedRowLenght = newData.length;
                        return { ...row, data: newData };
                    }
                    if (rowIndex === rowId + level) {
                        const newData = [...row.data];

                        nextRowLenght = newData.length;

                        let colIndexMerge = colIndex - (selectedRowLenght - nextRowLenght) - 1 ;

                        if (nextRowLenght === columns.length ){
                            colIndexMerge = colIndex ;
                        }

                        if (colIndexMerge < 0 ){
                            colIndexMerge = 0;
                        }

                        rowspanMerge = newData[colIndexMerge] ["rowspan"]

                        newData.splice(colIndexMerge , colspanMerge);
                        return { ...row, data: newData };
                    }
                    return row;
                });

                const updatedRows_ = updatedRows.map((row, rowIndex) => {
                    if (rowIndex=== rowId) {
                        const newData = [...row.data];

                        newData[colIndex ]["rowspan"] += rowspanMerge;
                        return { ...row, data: newData };
                    }
                    return row;
                });
                setRows(updatedRows_);
                setTableContent({columns:columns,rows:updatedRows_})

            }
        }
    };
    const unmergeCells = () => {
        if (selectedCell) {
            const { rowId, colIndex ,mergedCell} = selectedCell;
            let level =  mergedCell.rowspan + rowId ;
            if ( colIndex < columns.length -1 && mergedCell.colspan >1) {
                const updatedRows = rows.map((row, rowIndex) => {
                    if (rowIndex === rowId) {
                        const newData = [...row.data];
                        newData[colIndex ]["colspan"] -= 1;
                        newData.splice(colIndex+1, 0,  {
                            content: "",
                            width: 100,
                            height: 50,
                            paraStyle: "P2",
                            charStyle: "C1",
                            colour: "none",
                            borderStyle: "B3",
                            verticalAlignment: "middle",
                            horizontalAlignment: "left",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            colspan: 1,
                            rowspan: 1
                        });
                        return { ...row, data: newData };
                    }

                    if (rowIndex > rowId && rowIndex < level){
                        const newData = [...row.data];
                        newData.splice(colIndex, 0,  {
                            content: "",
                            width: 100,
                            height: 50,
                            paraStyle: "P2",
                            charStyle: "C1",
                            colour: "none",
                            borderStyle: "B3",
                            verticalAlignment: "middle",
                            horizontalAlignment: "left",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            colspan: 1,
                            rowspan: 1
                        });

                        return { ...row, data: newData };
                    }
                    return row;
                });
                setRows(updatedRows);
                setTableContent({columns:columns,rows:updatedRows})

            }
        }
    };

    const unMergeCellsVerticaly = () => {
        if (selectedCell) {
            const { rowId, colIndex, mergedCell } = selectedCell;
            var level = 0;
            var colspanMerge = 0;
            if ( mergedCell.rowspan > 1 ) {
                const updatedRows = rows.map((row,rowIdenx) => {
                    if (rowIdenx=== rowId) {
                        const newData = [...row.data];
                        level = newData[colIndex ]["rowspan"];
                        newData[colIndex ]["rowspan"] -= 1;
                        colspanMerge = newData[colIndex ]["colspan"];
                        return { ...row, data: newData };
                    }

                    if (rowIdenx === rowId + level-1) {
                        const newData = [...row.data];
                        const res = createArrayOfObjects(colspanMerge)
                        newData.splice(colIndex, 0, ...res);
                        return { ...row, data: newData };
                    }
                    return row;
                })
                setRows(updatedRows);
                setTableContent({columns:columns,rows:updatedRows})

            }
        }
    };

    function createArrayOfObjects(n) {
        let array = [];
        for (let i = 0; i < n; i++) {
            array.push({
                content:"",
                width: 100,
                height: 50,
                paraStyle: "P2",
                charStyle: "C1",
                colour: "none",
                borderStyle: "B3",
                verticalAlignment: "middle",
                horizontalAlignment: "left",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                colspan: 1,
                rowspan: 1
            })
        }
        return array;
    }
    const handleCellClick = (rowId, colIndex,mergedCell) => {
        console.log(mergedCell)
        setSelectedOption('cell');
        setSelectedColumn(null);
        setSelectedRow(null);
        setSelectedCell({ rowId, colIndex , mergedCell});
        setCellProps( mergedCell);
    };

    const handleCellChange = (rowId, colIndex, value, prop) => {
        const updatedRows = rows.map(row => {
            if (row.id === rowId) {
                const updatedData = [...row.data];
                updatedData[colIndex][prop]= value;
                return { ...row, data: updatedData };
            }
            return row;
        });
        setRows(updatedRows);
        setTableContent({columns:columns,rows:updatedRows})

    };

    const handlePropChange = (prop, value) => {
        setCellProps({ ...cellProps, [prop]: value });
        if (selectedCell) {
            const { rowId, colIndex } = selectedCell;
            handleCellChange(rowId, colIndex, value, prop);
        }
    };

    const handlePropChangePadding = (prop, value) => {
        if (/^\d*\.?\d*$/.test(value) && Number(value) <=100) {
            setCellProps({ ...cellProps, [prop]: Number(value) });
            if (selectedCell) {
                const { rowId, colIndex } = selectedCell;
                handleCellChange(rowId, colIndex, Number(value), prop);
            }
        }
    };

    const handleChange_paddingType = (event) => {
        setPaddingType(event.target.value);
    };
    const handleChange_alignmentType = (event) => {
        setAlignmentType(event.target.value);
    };

    const [selectedWidth, setSelectedWidth] = React.useState(100);

    const [selectedHeight, setSelectedHeight] = React.useState(50);
    const handleSliderChangeWidth = (event, newValue) => {
        if (/^\d*\.?\d*$/.test(newValue)) {
            const res = Number(newValue)
            if (res <= 1000) {
                setSelectedWidth(newValue);
                if (selectedColumn !== undefined ) {
                    const updatedRows = rows.map(row => {
                        const newData = [...row.data];
                        newData[selectedColumn ]["width"] = Number(newValue) ;
                        return { ...row, data: newData };
                    });
                    setRows(updatedRows);
                    setTableContent({columns:columns,rows:updatedRows})

                }
            }
        }

    };

    const handleInputChangeWidth = (event) => {
        const newValue = event.target.value;
        if (/^\d*\.?\d*$/.test(newValue) ) {
            const res = Number(newValue)
            if (res <= 1000 ) {
                setSelectedWidth(res);
                if (selectedColumn !== undefined) {
                    const updatedRows = rows.map(row => {
                        const newData = [...row.data];
                        newData[selectedColumn ]["width"] = res ;
                        return { ...row, data: newData };
                    });
                    setRows(updatedRows);
                    setTableContent({columns:columns,rows:updatedRows})

                }
            }
        }
    };

    const handleBlurWidth = () => {
        if (selectedWidth < 0) {
            setSelectedWidth(0);
        } else if (selectedWidth > 1000) {
            setSelectedWidth(1000);
        }
        if (selectedColumn !== undefined) {
            const updatedRows = rows.map(row => {
                const newData = [...row.data];
                newData[selectedColumn ]["width"] = selectedWidth ;
                return { ...row, data: newData };

            });
            setRows(updatedRows);
            setTableContent({columns:columns,rows:updatedRows})
        }
    };
    const handleSliderChangeHeight = (event, newValue) => {
        if (/^\d*\.?\d*$/.test(newValue)) {
            var res = Number(newValue)
            if (res <= 1000) {
                setSelectedHeight(res);
                if (selectedRow !== undefined) {
                    const updatedRows = rows.map((row,index) => {
                        if (index === selectedRow) {
                            const newData = [...row.data];
                            const newData_ = newData.map(cell => {
                                return { ...cell, height: res };
                            });
                            return { ...row, data: newData_ };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    setTableContent({columns:columns,rows:updatedRows})

                }
            }

        }

    };

    const handleInputChangeHeight = (event) => {
        const newValue = event.target.value;
        if (/^\d*\.?\d*$/.test(newValue) ) {
            var res = Number(newValue)
            if (res <= 200 ) {
                setSelectedHeight(res);
                if (selectedRow !== undefined ) {
                    const updatedRows = rows.map(row => {
                        if (row.id === selectedRow) {
                            const newData = [...row.data];
                            const newData_ =  newData.map(cell => {
                                return { ...cell, height: res};
                            });
                            return { ...row, data: newData_ };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    setTableContent({columns:columns,rows:updatedRows})

                }
            }
        }
    };

    const handleBlurHeight = () => {
        if (selectedHeight < 0) {
            setSelectedHeight(0);
        } else if (selectedHeight > 1000) {
            setSelectedHeight(1000);
        }
        if (selectedRow !== undefined ) {
            const updatedRows = rows.map(row => {
                if (row.id === selectedRow) {
                    const newData = [...row.data];
                    const newData_ = newData.map(cell => {
                        return { ...cell, height: Number(selectedHeight) };
                    });
                    return { ...row, data: newData_ };
                }
                return row;
            });
            setRows(updatedRows);
            setTableContent({columns:columns,rows:updatedRows})
        }
    };

    const CustomSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#e8933b',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#e8933b',
        },
        '& .MuiSwitch-switchBase': {
            color: '#504e4c',
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: '#504e4c',
        },
    }));


    return (
        <Box>
            <Box mb={1}>
                <AppBar position="static" sx={{
                    backgroundColor: '#e79d29', width: '76rem', borderRadius: '16px',
                    height: '44px', margin: '7px 0px 10px 0px', overflow: 'hidden', padding: '0px'
                }}>
                    <Toolbar sx={{ minHeight: '48px !important' }}>
                        <FormControl sx={{ flexGrow: 1 }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedOption}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value="row"
                                    control={<Radio sx={{ transform: "scale(1.2)" }} />}
                                    label={<span style={{ fontSize: '1.2rem' }}>Zeile</span>}
                                />
                                <FormControlLabel
                                    value="column"
                                    control={<Radio sx={{ transform: "scale(1.2)" }} />}
                                    label={<span style={{ fontSize: '1.2rem' }}>Spalte</span>}
                                />
                                <FormControlLabel
                                    value="cell"
                                    control={<Radio sx={{ transform: "scale(1.2)" }} />}
                                    label={<span style={{ fontSize: '1.2rem' }}>Zellen</span>}
                                />
                            </RadioGroup>
                        </FormControl>

                        {(selectedColumn != null || selectedRow != null) && (
                            <IconButton
                                title="DeleteIcon"
                                color="inherit"
                                disabled={selectedOption === 'cell' || (selectedOption === 'column' && columns.length <= 1) || (selectedOption === 'row' && rows.length <= 1)}
                                onClick={deleteElement}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                        <IconButton
                            title="ArrowBackIosNewIcon"
                            color="inherit"
                            disabled={selectedOption === 'cell'}
                            onClick={addElementBefore}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                        >
                            {(selectedColumn != null || selectedRow != null) && <AddBoxIcon />}
                            {selectedColumn != null && <KeyboardDoubleArrowLeftIcon />}
                            {selectedRow != null && <KeyboardDoubleArrowUpIcon />}
                        </IconButton>
                        <IconButton
                            title="ArrowForwardIosIcon"
                            color="inherit"
                            disabled={selectedOption === 'cell'}
                            onClick={addElementAfter}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                        >
                            {(selectedColumn != null || selectedRow != null) && <AddBoxIcon />}
                            {selectedColumn != null && <KeyboardDoubleArrowRightIcon />}
                            {selectedRow != null && <KeyboardDoubleArrowDown />}
                        </IconButton>
                        <IconButton
                            title="VerticalAlignBottomIcon"
                            color="inherit"
                            disabled={selectedOption !== 'cell'}
                            onClick={mergeCellsVerticaly}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <InsertLinkIcon />
                                <VerticalAlignBottomIcon />
                            </Box>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            disabled={selectedOption !== 'cell'}
                            onClick={unMergeCellsVerticaly}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LinkOffIcon />
                                <VerticalAlignBottomIcon />
                            </Box>
                        </IconButton>
                        <IconButton
                            title="KeyboardTabIcon"
                            color="inherit"
                            disabled={selectedOption !== 'cell'}
                            onClick={mergeCells}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <InsertLinkIcon />
                                <KeyboardTabIcon />
                            </Box>
                        </IconButton>
                        <IconButton
                            title="LinkOffIcon"
                            color="inherit"
                            disabled={selectedOption !== 'cell'}
                            onClick={unmergeCells}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}  // Change the size here
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LinkOffIcon />
                                <KeyboardTabIcon />
                            </Box>
                        </IconButton>
                    </Toolbar>
                </AppBar>


            </Box>
            <Box display="flex">
                <Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ borderBottom: '1px solid black' }}></TableCell>
                                    {columns && columns.length > 0 && columns.map((column, index) => (
                                        <TableCell onClick={() => handleColumnClick(index)} key={index}
                                                   style={{ border: '1px solid black', cursor: 'pointer',
                                                       backgroundColor: selectedColumn === index ? 'rgb(227 222 215)' : 'inherit' }}> {`column ${index}`} </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows && rows.length > 0  && rows.map((row,rowIndex) => {
                                    return (
                                    <TableRow key={rowIndex}
                                              style={{
                                                  backgroundColor: selectedRow === rowIndex  ? 'rgb(227 222 215)' : 'inherit'
                                              }}>
                                        <TableCell align="center" onClick={() => handleRowClick(rowIndex)}
                                                   style={{
                                                       border: '1px solid black',cursor: 'pointer',
                                                   }}>
                                            {`Row ${rowIndex}`}
                                        </TableCell>
                                        {row.data.map((cell, colIndex) => {
                                                return (
                                                    <TableCell
                                                        colSpan={cell.colspan || 1}
                                                        rowSpan={cell.rowspan || 1}
                                                        align={cell.horizontalAlignment || 'center'}
                                                        key={colIndex.toString()}
                                                        onClick={() => handleCellClick(rowIndex, colIndex,cell)}
                                                        style={{
                                                            border: '1px solid black',
                                                            cursor: 'pointer',
                                                            width: cell.width,
                                                            height: cell.height,
                                                            verticalAlign: cell.verticalAlignment,
                                                            paddingLeft: cell.paddingLeft,
                                                            paddingRight: cell.paddingRight,
                                                            paddingTop: cell.paddingTop,
                                                            paddingBottom: cell.paddingBottom,
                                                            backgroundColor: (selectedCell &&
                                                                selectedCell.rowId === rowIndex &&
                                                                selectedCell.colIndex === colIndex) || (selectedColumn === colIndex) ? 'rgb(227 222 215)' : 'inherit'
                                                        }}
                                                    >
                                                        {cell.content}
                                                    </TableCell>
                                                )
                                            }
                                        )}
                                    </TableRow>
                                )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box ml={2}>
                    {selectedCell != null && <TableContainer component={Paper}>
                        <Table  aria-labelledby="tableTitle" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold" > Eigenschaften</Typography>
                                    </TableCell>
                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold"  > Wert</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black'}}>
                                        <Typography fontWeight="fontWeightBold"  >Inhalt</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        <TextField
                                            value={cellProps.content}
                                            onChange={e => handlePropChange('content', e.target.value)}
                                            fullWidth
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold"  >ParaStyle</Typography>
                                    </TableCell>
                                    <TableCell align="right" style={{ border: '1px solid black' }}>
                                        <Select
                                            variant="standard"
                                            labelId="paraStyle-customized-select-label"
                                            id="paraStyle-customized-select"
                                            value={cellProps.paraStyle}
                                            onChange={e => handlePropChange('paraStyle', e.target.value)}>
                                            <MenuItem value={'P1'} >P1</MenuItem>
                                            <MenuItem value={'P2'} >P2</MenuItem>
                                            <MenuItem value={'P3'} >P3</MenuItem>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold"  >CharStyle</Typography>
                                    </TableCell>
                                    <TableCell align="right" style={{ border: '1px solid black' }}>
                                        <Select
                                            labelId="charStyle-customized-select-label___"
                                            id="charStyle-customized-select___"
                                            value={cellProps.charStyle}
                                            variant="standard"
                                            onChange={e => handlePropChange('charStyle', e.target.value)}>
                                            <MenuItem value={'C1'} >C1</MenuItem>
                                            <MenuItem value={'C2'} >C2</MenuItem>
                                            <MenuItem value={'C3'} >C3</MenuItem>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold"  >Colour</Typography>
                                    </TableCell>
                                    <TableCell align="right" style={{ border: '1px solid black' }}>
                                        <Select
                                            variant="standard"
                                            labelId="color-customized-select-label___"
                                            id="color-customized-select___"
                                            value={cellProps.colour}
                                            onChange={e => handlePropChange('colour', e.target.value)}>
                                            <MenuItem value={'red'} >Red</MenuItem>
                                            <MenuItem value={'green'} >Green</MenuItem>
                                            <MenuItem value={'blue'} >Blue</MenuItem>
                                            <MenuItem value={'none'}>none</MenuItem>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold"  >BorderStyle </Typography>
                                    </TableCell>
                                    <TableCell align="right" style={{ border: '1px solid black' }}>
                                        <Select
                                            variant="standard"
                                            labelId="borderStyle-customized-select-label"
                                            id="borderStyle-customized-select"
                                            value={cellProps.borderStyle}
                                            onChange={e => handlePropChange('borderStyle', e.target.value)}>
                                            <MenuItem value={'B1'} >B1</MenuItem>
                                            <MenuItem value={'B2'} >B2</MenuItem>
                                            <MenuItem value={'B3'} >B3</MenuItem>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold"  >Alignment</Typography>
                                    </TableCell>
                                    <TableCell align="left" style={{ border: '1px solid black' }}>
                                        <Select
                                            labelId="demo-customized-select-label___"
                                            id="demo-customized-select___"
                                            variant="standard"
                                            value={alignmentType}
                                            onChange={handleChange_alignmentType}
                                        >
                                            <MenuItem value={'verticalAlignment'} >Vertical</MenuItem>
                                            <MenuItem value={'horizontalAlignment'}>Horizontal</MenuItem>
                                        </Select>

                                        { alignmentType === 'verticalAlignment'  ? ( <Select
                                                labelId="vertical-customized-select-label"
                                                id="vertical-customized-select"
                                                value={cellProps[alignmentType]}
                                                variant="standard"
                                                onChange={e => handlePropChange(alignmentType, e.target.value)}
                                                sx={{ width: '120px',ml:1 }}
                                            >
                                                <MenuItem value={'top'} >TOP</MenuItem>
                                                <MenuItem value={'middle'}>CENTER</MenuItem>
                                                <MenuItem value={'bottom'}>BOTTOM</MenuItem>
                                            </Select>
                                        ):( <Select
                                            variant="standard"
                                            labelId="horizontal-customized-select-label"
                                            id="horizontal-customized-select"
                                            sx={{ width: '90px',ml:1 }}

                                            value={cellProps[alignmentType]}
                                            onChange={e => handlePropChange(alignmentType, e.target.value)}
                                        >
                                            <MenuItem value={'left'} >LEFT</MenuItem>
                                            <MenuItem value={'center'}>CENTER</MenuItem>
                                            <MenuItem value={'right'}>RIGHT</MenuItem>
                                        </Select>)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold">Padding</Typography>
                                    </TableCell>
                                    <TableCell align="left" style={{ border: '1px solid black' }}>
                                        <Select
                                            variant="standard"
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={paddingType}
                                            onChange={handleChange_paddingType}
                                        >
                                            <MenuItem value={'paddingLeft'} >Left</MenuItem>
                                            <MenuItem value={'paddingRight'}>Right</MenuItem>
                                            <MenuItem value={'paddingTop'}>Top</MenuItem>
                                            <MenuItem value={'paddingBottom'}>Bottom</MenuItem>
                                        </Select>
                                        <TextField
                                            id="outlined-number-padding-value"
                                            variant="standard"
                                            type="number"
                                            value={cellProps[paddingType]}
                                            onChange={e => handlePropChangePadding(paddingType, e.target.value )}
                                            sx={{ width: '90px',ml:1 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            inputProps={{ inputMode: 'decimal', step: 0.5, min: 0, max: 200}}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> }
                    {selectedColumn != null && <TableContainer component={Paper}>
                        <Table              aria-labelledby="tableTitle" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold"  >Eigenschaften</Typography>
                                    </TableCell>
                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold"  >Wert</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black'}}>
                                        <Typography fontWeight="fontWeightBold"  >Inhalt</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        <TextField
                                            variant="standard"
                                            value={cellProps.content}
                                            onChange={e => handlePropChange('content', e.target.value)}
                                            fullWidth

                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold"  >Breite</Typography>
                                    </TableCell>

                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <Slider
                                                    value={typeof selectedWidth === 'number' ? selectedWidth : 0}
                                                    onChange={handleSliderChangeWidth}
                                                    aria-labelledby="input-slider_"
                                                    sx={{
                                                        width: '80%',
                                                        color: '#dc7f13',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '1px',
                                                        },
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    step={20}
                                                    min={0}
                                                    max={1000}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    variant="standard"
                                                    value={selectedWidth}
                                                    onChange={handleInputChangeWidth}
                                                    onBlur={handleBlurWidth}
                                                    sx={{width:'90%'}}
                                                    inputProps={{ inputMode: 'decimal', step: 0.5, min: 0, max: 1000,                                                        type: 'number',
                                                        'aria-labelledby': 'input-slider_', }}

                                                />
                                            </Grid>
                                        </Grid>
                                        <FormGroup>
                                            <FormControlLabel  control={<CustomSwitch defaultChecked  />} label="Dynamic" />
                                        </FormGroup>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> }
                    {selectedRow != null && <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold"  >Eigenschaften</Typography>
                                    </TableCell>
                                    <TableCell  align="center" style={{ border: '1px solid black' }}>
                                        <Typography fontWeight="fontWeightBold"  >Wert</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black'}}>
                                        <Typography fontWeight="fontWeightBold"  >Inhalt</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        <TextField
                                            variant="standard"
                                            value={cellProps.content}
                                            onChange={e => handlePropChange('content', e.target.value)}
                                            fullWidth

                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" style={{ border: '1px solid black' }} >
                                        <Typography fontWeight="fontWeightBold"  >Höhe</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <Slider
                                                    value={typeof selectedHeight === 'number' ? selectedHeight : 0}
                                                    onChange={handleSliderChangeHeight}
                                                    aria-labelledby="input-slider"
                                                    sx={{
                                                        width: '80%',
                                                        color: '#dc7f13',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '1px',
                                                        },
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    step={20}
                                                    min={0}
                                                    max={1000}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    variant="standard"
                                                    value={selectedHeight}
                                                    onChange={handleInputChangeHeight}
                                                   onBlur={handleBlurHeight}
                                                    sx={{width:'90%'}}
                                                    inputProps={{ inputMode: 'decimal', step: 0.5, min: 0, max: 2000,                                                        type: 'number',
                                                        'aria-labelledby': 'input-slider', }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormGroup>
                                            <FormControlLabel  control={<CustomSwitch defaultChecked  />} label="Dynamic" />
                                        </FormGroup>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> }
                </Box>
            </Box>
        </Box>

    );
};

export default EditableTable;
