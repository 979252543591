import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import ContentEditable from 'react-contenteditable';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import theme from '../PimTogoTheme';
import PimSpinner from '../components/common/PimSpinner';
import DashboardGrid from './DashboardGrid';
import Tool from './Tool';
import EditGridList from './EditGridList';

import sanitizeFromXML from 'src/util/sanitizeFromXML';
import { getUserCountryLang } from 'src/util/languageUtil';
import ajaxCall from '../../services/ajaxCall.js';

const ORGA_URL_PART = 'Organisation';

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 25,
    maxHeight: 25,
    border: '#b5b5b5 1px solid',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    borderBottom: 'none',
    '&.Mui-expanded': {
      minHeight: 25,
      maxHeight: 25,
      border: '#b5b5b5 1px solid',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      borderBottom: 'none'
    },
    '& > .MuiAccordionSummary-content': {
      justifyContent: 'center'
    }
  }
})(AccordionSummary);

const useTabStyles = makeStyles(() => ({
  tabLabelEdit: {
    cursor: 'text',
    textTransform: 'none'
  }
}));

function GridTab({
  grid,
  gridOrder,
  grids,
  setGrids,
  selectGrid,
  urlDashboardGrid
}) {
  const classes = useTabStyles();
  const { t } = useTranslation();

  const [disabledEditLabel, setDisabledEditLabel] = useState(true);
  const changedLabelRef = useRef(false);
  const userLang = getUserCountryLang();

  const inputText = useRef(grid.label[userLang] || '---');

  function selectTab(event) {
    event.stopPropagation();
    selectGrid(gridOrder, true);
  }

  function activateEditLabel() {
    setDisabledEditLabel(false);
  }

  function editGridLabel(event) {
    inputText.current = sanitizeFromXML(event.target.value);

    setGrids(
      grids.map((grid, curIndex) =>
        curIndex === gridOrder
          ? { ...grid, label: { ...grid.label, [userLang]: inputText.current } }
          : grid
      )
    );

    changedLabelRef.current = true;
  }

  function saveGridLabel() {
    setDisabledEditLabel(true);

    if (!changedLabelRef.current) {
      return;
    }

    if (!inputText.current) {
      inputText.current = t('common.inputName');
      return;
    }

    ajaxCall({
      httpMethod: 'put',
      url: urlDashboardGrid,
      params: {
        gridOrder: gridOrder,
        label: inputText.current,
        lang: userLang
      },
      json: {}
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        TOGO.Util.notify('', t('common.saved'), null, false);
      } else {
        TOGO.Util.notify('', t('common.saveError'), null, true);
      }

      changedLabelRef.current = false;
    });
  }

  return (
    <Tab
      onClick={selectTab}
      label={
        <ContentEditable
          onClick={activateEditLabel}
          className={classes.tabLabelEdit}
          disabled={disabledEditLabel}
          html={inputText.current}
          onChange={(e) => editGridLabel(e)}
          onBlur={saveGridLabel}
          tagName="p"
        />
      }
      {...a11yProps(gridOrder)}
    />
  );
}

function GridTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`grid-tabpanel-${index}`}
      aria-labelledby={`grid-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

GridTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
function a11yProps(index) {
  return {
    id: `grid-tab-${index}`,
    'aria-controls': `grid-tabpanel-${index}`
  };
}

const useDashboardStyles = makeStyles(() => ({
  dashboardRoot: {
    marginTop: '1.5rem'
  },
  errorTitle: {
    fontSize: 14
  },
  accordion: {
    maxWidth: 'calc(100vw - 36rem)',
    marginLeft: '1rem !important'
  },
  accordionCollapse: {
    border: '#b5b5b5 1px solid',
    borderTop: 'none',
    paddingBottom: 0
  },
  accordionTitle: {
    color: '#aeaeae',
    fontSize: '1.3rem'
  },
  saveButton: {
    marginRight: '1rem',
    position: 'relative',
    top: '1.8rem'
  },
  editGridsButtons: { marginTop: '0.2rem' },
  toolbar: {
    marginLeft: '1rem',
    paddingLeft: '0.5rem',
    overflow: 'auto',
    height: '8rem',
    width: 'calc(100% - 15rem)',
    padding: '0.7rem 1rem'
  },
  toolbarGrid: {
    backgroundColor: '#f5f5f5',
    padding: '0.1rem 1rem'
  },
  tabs: {
    background: 'white',
    color: 'black',
    marginLeft: '1.15rem',
    maxWidth: 'calc(100vw - 36.2rem)',
    '& > .MuiTabs-indicator': {
      bottom: 3,
      height: 4
    }
  },
  tabsNotVisible: {
    display: 'none'
  }
}));

const URL_DASHBOARD_MAPPING = {
  HOME: 'homeDashboard',
  ADJUST_PERMISSIONS: 'adjustPermissionsDashboard',
  TABLE_DEFINITION:'tableDefinition'
};

const OWNER_TYPES = {
  ORGA: 'orga',
  USER: 'user'
};

const EMPTY_GRID = {
  clientId: 'INITGRID',
  gridJson: '{}',
  panelsJson: '{}'
};

export default function Dashboard({
  dashboardType,
  editable,
  defaultOrgaGridJson,
  defaultOrgaPanelsJson,
  defaultUserGridJson,
  defaultUserPanelsJson
}) {
  const { t } = useTranslation();
  const classes = useDashboardStyles();

  const [editMode, setEditMode] = useState(editable);
  const [activeGridIndex, setActiveGridIndex] = useState(0);
  const [grids, setGrids] = useState([]);
  const [activeGridLayouts, setActiveGridLayouts] = useState({
    lg: [],
    md: [],
    sm: []
  });
  const [activeGridPanels, setActiveGridPanels] = useState({});
  const [toolsConfig, setToolsConfig] = useState([]);
  const [orgaData, setOrgaData] = useState({});
  const [error, setError] = useState(null);
  const [noSubscription, setNoSubscriptionWarning] = useState(false);
  const [loading, setLoading] = useState(true);

  const $dashboardWrapper = $('.dashboardWrapper');
  const owner = $dashboardWrapper.data('dashboardowner') || OWNER_TYPES.USER;
  const dashboardOwnerPath = owner === OWNER_TYPES.ORGA ? ORGA_URL_PART : '';

  let pathDashboard = URL_DASHBOARD_MAPPING[dashboardType];
  pathDashboard = pathDashboard + dashboardOwnerPath;
  const urlDashboard = '' + pathDashboard;
  const urlDashboardGrid = urlDashboard + '/grid';
  const userLang = getUserCountryLang();

  useEffect(() => {
    if (editable) {
      ajaxCall({
        httpMethod: 'get',
        url: urlDashboard
      }).then((ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          if (!ajaxData.response.orgaData?.subscriptionInfo) {
            setError(t('common.noSubscription'));
            setNoSubscriptionWarning(true);
            return;
          }

          const loadedEditMode =
            owner == OWNER_TYPES.USER || ajaxData.response.orgaDashboardRole;
          setEditMode(loadedEditMode);

          let loadedGrids = ajaxData.response.grids;
          if (!loadedGrids || loadedGrids.length == 0) {
            let defaultGrid = EMPTY_GRID;
            if (
              owner == OWNER_TYPES.USER &&
              defaultUserGridJson &&
              defaultUserPanelsJson
            ) {
              defaultGrid.gridJson = defaultUserGridJson;
              defaultGrid.panelsJson = defaultUserPanelsJson;
            } else if (defaultOrgaGridJson && defaultOrgaPanelsJson) {
              defaultGrid.gridJson = defaultOrgaGridJson;
              defaultGrid.panelsJson = defaultOrgaPanelsJson;
            }

            loadedGrids = [defaultGrid];

            ajaxCall({
              httpMethod: 'post',
              url: urlDashboardGrid,
              params: {
                label: t('dashboard.firstGridLabel'),
                lang: userLang
              },
              json: {
                gridJson: JSON.stringify(defaultGrid.gridJson),
                panelsJson: JSON.stringify(defaultGrid.panelsJson)
              }
            }).then((ajaxData) => {
              if (!ajaxData.error && ajaxData.response) {
                const newGrid = ajaxData.response.grids[0];
                newGrid.gridJson = defaultGrid.gridJson;
                newGrid.panelsJson = defaultGrid.panelsJson;
                setGrids([newGrid]);
                selectGrid(0, false, [newGrid], loadedEditMode);
              }
            });
          } else {
            setGrids(loadedGrids);
            selectGrid(0, false, loadedGrids, loadedEditMode);
          }

          setOrgaData((prevOrgaData) => {
            const newOrgaData = {
              ...prevOrgaData
            };
            const interfaceLangs = {};
            for (const interfaceLang in ajaxData.response.interfaceLangs) {
              let langLabel = ajaxData.response.interfaceLangs[interfaceLang];
              langLabel = langLabel.split(' (')[0];
              interfaceLangs[interfaceLang] = langLabel;
            }
            newOrgaData.interfaceLangs = interfaceLangs;

            for (const orgaDataItemKey in ajaxData.response.orgaData) {
              newOrgaData[orgaDataItemKey] =
                ajaxData.response.orgaData[orgaDataItemKey];
            }

            return newOrgaData;
          });

          if (ajaxData.response.tools) {
            const tools = [];
            ajaxData.response.tools.map((tool) => {
              tools.push({ toolType: tool });
            });
            setToolsConfig(tools);
          }
        }
        setError(ajaxData.error);
        setLoading(false);
      });
    } else {
      setLoading(false);

      const defaultNonEditGrid = {
        clientId: 'FIXED_GRID',
        gridJson: defaultOrgaGridJson,
        panelsJson: defaultOrgaPanelsJson,
        label: {
          de: '-',
          de_DE: '-'
        }
      };

      setGrids([defaultNonEditGrid]);
      selectGrid(0, false, [defaultNonEditGrid], false);
    }
  }, [owner]);

  function getActiveGridDataJsons() {
    const payloadGridJson = JSON.stringify(activeGridLayouts);
    const payloadPanelsJson = JSON.stringify(activeGridPanels);
    return { payloadGridJson, payloadPanelsJson };
  }

  function cacheActiveGrid() {
    const { payloadGridJson, payloadPanelsJson } = getActiveGridDataJsons();
    const cachedGrids = grids.map((grid, index) =>
      index === activeGridIndex
        ? {
            ...grid,
            gridJson: payloadGridJson,
            panelsJson: payloadPanelsJson
          }
        : grid
    );
    setGrids(cachedGrids);

    return cachedGrids;
  }

  function selectGrid(
    gridOrder,
    isCacheActiveGrid,
    loadedGrids,
    loadedEditMode
  ) {
    if (isCacheActiveGrid) {
      cacheActiveGrid();
    }

    setActiveGridIndex(gridOrder);

    let gridsSourceForNewActiveGrid = null;
    if (loadedGrids) {
      gridsSourceForNewActiveGrid = loadedGrids;
    } else {
      gridsSourceForNewActiveGrid = grids;
    }
    const newActiveGrid = gridsSourceForNewActiveGrid.find(
      (_, index) => index === gridOrder
    );
    if (!newActiveGrid) {
      return;
    }

    let initGridLayouts = {
      lg: [],
      md: [],
      sm: []
    };
    if (newActiveGrid.gridJson) {
      let loadedGridLayout = {};

      if (typeof newActiveGrid.gridJson != 'object') {
        loadedGridLayout = JSON.parse(newActiveGrid.gridJson);
      } else if (newActiveGrid.gridJson !== null) {
        loadedGridLayout = newActiveGrid.gridJson;
      }

      if (loadedGridLayout.lg && loadedGridLayout.md && loadedGridLayout.sm) {
        initGridLayouts = loadedGridLayout;

        if (
          (editMode == false && loadedEditMode == undefined) ||
          loadedEditMode == false
        ) {
          initGridLayouts = switchToReadOnlyMode(initGridLayouts);
        }
      }
    }
    setActiveGridLayouts(initGridLayouts);

    let initPanels = {};
    if (newActiveGrid.panelsJson) {
      if (typeof newActiveGrid.panelsJson != 'object') {
        initPanels = JSON.parse(newActiveGrid.panelsJson);
      } else {
        initPanels = newActiveGrid.panelsJson;
      }
    }
    setActiveGridPanels(initPanels);

    function switchToReadOnlyMode(layoutsToSwitch) {
      for (const size in layoutsToSwitch) {
        layoutsToSwitch[size] = layoutsToSwitch[size].map((gridItem) => {
          gridItem.isDraggable = false;
          gridItem.isResizable = false;
          return gridItem;
        });
      }

      return layoutsToSwitch;
    }
  }

  function saveAllGrids() {
    const gridsToSave = [];
    const cachedGrids = cacheActiveGrid();
    cachedGrids.forEach((grid) => {
      let gridJsonToSave = grid.gridJson;
      let panelsJsonToSave = grid.panelsJson;
      if (typeof gridJsonToSave != 'string') {
        gridJsonToSave = JSON.stringify(gridJsonToSave);
      }
      if (typeof panelsJsonToSave != 'string') {
        panelsJsonToSave = JSON.stringify(panelsJsonToSave);
      }

      gridsToSave.push({
        gridJson: gridJsonToSave,
        panelsJson: panelsJsonToSave
      });
    });

    ajaxCall({
      httpMethod: 'put',
      url: urlDashboard,
      params: {},
      json: {
        grids: gridsToSave
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        TOGO.Util.notify('', t('common.saved'), null, false);
      }
    });
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            maxWidth: '40rem'
          }}
        >
          <Card className={classes.dashboardRoot}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {(noSubscription ? t('common.hint') : t('common.error')) + ':'}
              </Typography>
              <Typography variant="body2" component="p">
                {error}
              </Typography>
            </CardContent>
            {noSubscription && (
              <CardActions>
                <Button component={Link} href="subscription" variant="outlined">
                  {t('common.linkToSubscription')}
                </Button>
              </CardActions>
            )}
          </Card>
        </div>
      </ThemeProvider>
    );
  } else if (loading) {
    return <PimSpinner firstElementOnPage={true} />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.dashboardRoot}>
          {editMode && (
            <Accordion className={classes.accordion}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="dashboard-toolbar-content"
                id="dashboard-toolbar-header"
              >
                <Typography className={classes.accordionTitle}>
                  {t('dashboard.editTitle')}
                </Typography>
              </StyledAccordionSummary>
              <AccordionDetails className={classes.accordionCollapse}>
                <Box>
                  <Button
                    onClick={saveAllGrids}
                    size="small"
                    variant="outlined"
                    className={classes.saveButton}
                  >
                    {t('common.save')}
                  </Button>
                </Box>
                <Box className={classes.editGridsButtons}>
                  <EditGridList
                    urlDashboardGrid={urlDashboardGrid}
                    activeGridIndex={activeGridIndex}
                    setActiveGridIndex={setActiveGridIndex}
                    grids={grids}
                    setGrids={setGrids}
                    selectGrid={selectGrid}
                  />
                </Box>
                <Paper elevation={0} className={classes.toolbar}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    wrap="nowrap"
                    spacing={2}
                    className={classes.toolbarGrid}
                  >
                    {toolsConfig.map((tool, index) => (
                      <Grid key={tool.toolType + index} item>
                        <Tool toolConfig={tool} />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
          )}
          <Tabs
            className={
              grids.length == 1 ? classes.tabsNotVisible : classes.tabs
            }
            value={activeGridIndex}
            aria-label="grid tabs"
          >
            {grids.map((grid, gridOrder) => (
              <GridTab
                key={grid.clientId}
                grid={grid}
                gridOrder={gridOrder}
                grids={grids}
                setGrids={setGrids}
                selectGrid={selectGrid}
                urlDashboardGrid={urlDashboardGrid}
              />
            ))}
          </Tabs>
          {grids.map((grid, index) => (
            <GridTabPanel
              key={grid.clientId}
              value={activeGridIndex}
              index={index}
            >
              <DashboardGrid
                editMode={editMode}
                gridLayouts={activeGridLayouts}
                setGridLayouts={setActiveGridLayouts}
                panels={activeGridPanels}
                setPanels={setActiveGridPanels}
                orgaData={orgaData}
              />
            </GridTabPanel>
          ))}
        </div>
      </ThemeProvider>
    );
  }
}
