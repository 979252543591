import React, { useEffect, useState } from 'react';
//df
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core';

export const PANELTYPE = 'SubscriptionFeatureList';
export const SUB_FEATURELIST_I18KEY = 'dashboard.subscription.subFeatureList';

const LIST_TYPES = {
  SUBSCRIBED: 'subscribed',
  NOT_SUBSCRIBED: 'not_subscribed',
  CONTENT_LANGUAGES: 'contentLanguages',
  CLASSIFICATIONS: 'classifications'
};

export function getSubFeatureListLabel({ panelItem, orgaData, listType }) {
  if (!listType) {
    listType = panelItem.configuration.selectedListType;
    if (!listType) return PANELTYPE;
  }

  if (listType == LIST_TYPES.SUBSCRIBED) {
    return i18next.t('dashboard.subscription.subscribed');
  }
  if (listType == LIST_TYPES.NOT_SUBSCRIBED) {
    return i18next.t('dashboard.subscription.notSubscribed');
  }

  if (orgaData.subscriptionInfo) {
    if (listType == LIST_TYPES.CONTENT_LANGUAGES) {
      return orgaData.subscriptionInfo.subscriptionFeatureList.find(
        (feature) => feature.name == 'SubscriptionFeature.Languages'
      ).label;
    }
    if (listType == LIST_TYPES.CLASSIFICATIONS) {
      return orgaData.subscriptionInfo.subscriptionFeatureList.find(
        (feature) => feature.name == 'SubscriptionFeature.PIMClassifications'
      ).label;
    }
  }

  return listType;
}

export function SubFeatureListPanelConfig({
  triggerConfigSave,
  saveConfig,
  orgaData,
  panelItem,
  setPanelTitle
}) {
  const { t } = useTranslation();

  const [selectedListType, setSelectedListType] = useState('');

  useEffect(() => {
    setSelectedListType(panelItem.configuration.selectedListType || '');
  }, []);

  useEffect(() => {
    if (triggerConfigSave) {
      saveListType();
    }
  }, [triggerConfigSave]);

  function saveListType() {
    const changedPanel = {
      ...panelItem,
      configuration: {
        ...panelItem.configuration,
        selectedListType: selectedListType
      }
    };
    saveConfig({ changedPanel });

    const title = getSubFeatureListLabel({
      panelItem,
      orgaData,
      listType: selectedListType
    });
    setPanelTitle(title);
  }

  function changeListType(event) {
    const newListType = event.target.value;
    setSelectedListType(newListType);
  }

  return (
    <TextField
      id="subFeatureListConfigSelect"
      select
      variant="outlined"
      fullWidth={true}
      label={t('common.type')}
      value={selectedListType}
      onChange={changeListType}
    >
      {Object.values(LIST_TYPES).map((listType) => (
        <MenuItem key={listType} value={listType}>
          {getSubFeatureListLabel({ panelItem, orgaData, listType })}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function SubFeatureListPanelContent({ orgaData, panelItem }) {
  const configuration = panelItem.configuration;
  let list = [];

  if (orgaData.subscriptionInfo && configuration) {
    if (
      [LIST_TYPES.SUBSCRIBED, LIST_TYPES.NOT_SUBSCRIBED].includes(
        configuration.selectedListType
      )
    ) {
      const subscribed =
        configuration.selectedListType == LIST_TYPES.SUBSCRIBED;

      list = orgaData.subscriptionInfo.subscriptionBasicFeatureList.reduce(
        (acc, { label, value }) => {
          subscribed === value && acc.push(label);
          return acc;
        },
        []
      );
    } else if (configuration.selectedListType == LIST_TYPES.CONTENT_LANGUAGES) {
      list = orgaData.subscriptionInfo.subscriptionFeatureList.find(
        (feature) => feature.name == 'SubscriptionFeature.Languages'
      ).value;
    } else if (configuration.selectedListType == LIST_TYPES.CLASSIFICATIONS) {
      list = orgaData.subscriptionInfo.subscriptionFeatureList.find(
        (feature) => feature.name == 'SubscriptionFeature.PIMClassifications'
      ).value;
    }
  }

  if (!list) {
    return null;
  }

  return (
    <Table size="small" aria-label="simple table">
      <TableBody>
        {list.map((itemText) => (
          <TableRow key={itemText} hover={true}>
            <TableCell component="th" scope="row">
              {itemText}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
