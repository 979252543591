import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  TEXTCONFIG_I18KEY,
  getTextTitle,
  TextPanelConfig,
  TextPanelContent
} from './panels/Text';
import {
  SUB_QUOTA_I18KEY,
  getSubQuotaTitle,
  SubQuotaPanelConfig,
  SubQuotaPanelContent
} from './panels/SubscriptionQuota';
import {
  SUB_FEATURELIST_I18KEY,
  getSubFeatureListLabel,
  SubFeatureListPanelConfig,
  SubFeatureListPanelContent
} from './panels/SubscriptionFeatureList';
import {
  LINK_LIST_CONFIG_I18KEY,
  getLinkListLabel,
  LinkListPanelConfig,
  LinkListPanelContent
} from './panels/LinkList';

import {
  TABLE_DEF_I18KEY,
  getTableDefinitionLabel,
  TableDefinitionPanelConfig,
  TableDefinitionPanelContent
} from './panels/tableDefPackage/TableDefinition';

import {
  ARTICLE_DATA_ZONES_CONFIG_I18KEY,
  getArticleDataZonesTitle,
  ArticleDataZonesPanelConfig,
  ArticleDataZonesPanelContent
} from './panels/ArticleDataZoneList';

import {
  ORGA_EXPORTS_LIST_CONFIG_I18KEY,
  getOrgaExportsListLabel,
  OrgaExportsListPanelConfig,
  OrgaExportsListPanelContent
} from './panels/organizationExports';

import { PanelBaseConfig } from './PanelBaseConfig';
import { lightPimColor } from '../PimTogoTheme';

const useStyles = makeStyles(() => ({
  panelHeader: {
    backgroundColor: lightPimColor,
    padding: '5px 11px'
  },
  panelHeaderHide: {
    display: 'none'
  },
  panelHeaderTitleGrab: {
    cursor: 'grab'
  },
  panelHeaderTitleGrabbing: {
    cursor: 'grabbing'
  },
  panelHeaderContent: {
    maxWidth: 'calc(100% + 0.5rem)'
  },
  panelHeaderContentEdit: {
    maxWidth: 'calc(100% - 2rem)',
    overflow: 'hidden'
  },
  panelHeaderAction: {
    position: 'relative',
    top: '0.3rem',
    '& .material-icons': {
      width: '1.2em'
    },
    '& .material-icons.fa-cogs': {
      fontSize: '2rem'
    }
  },
  panelContent: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: 0
  },
  panelContentWithHeader: {
    height: 'calc(100% - 32px)'
  }
}));

const panelComponents = {
  Text: {
    configI18Key: TEXTCONFIG_I18KEY,
    getTitle: getTextTitle,
    Config: TextPanelConfig,
    Content: TextPanelContent
  },
  SubscriptionQuota: {
    configI18Key: SUB_QUOTA_I18KEY,
    getTitle: getSubQuotaTitle,
    Config: SubQuotaPanelConfig,
    Content: SubQuotaPanelContent
  },
  SubscriptionFeatureList: {
    configI18Key: SUB_FEATURELIST_I18KEY,
    getTitle: getSubFeatureListLabel,
    Config: SubFeatureListPanelConfig,
    Content: SubFeatureListPanelContent
  },
  LinkList: {
    configI18Key: LINK_LIST_CONFIG_I18KEY,
    getTitle: getLinkListLabel,
    Config: LinkListPanelConfig,
    Content: LinkListPanelContent
  },
  TableDefinition: {
    configI18Key: TABLE_DEF_I18KEY,
    getTitle: getTableDefinitionLabel,
    Config: TableDefinitionPanelConfig,
    Content: TableDefinitionPanelContent
  },
  ArticleDataZones: {
    configI18Key: ARTICLE_DATA_ZONES_CONFIG_I18KEY,
    getTitle: getArticleDataZonesTitle,
    Config: ArticleDataZonesPanelConfig,
    Content: ArticleDataZonesPanelContent
  },
  OrganizationExports: {
    configI18Key: ORGA_EXPORTS_LIST_CONFIG_I18KEY,
    getTitle: getOrgaExportsListLabel,
    Config: OrgaExportsListPanelConfig,
    Content: OrgaExportsListPanelContent
  }
};

export default function DashboardPanel({
  editMode,
  panelItem,
  savePanel,
  isDraggingPanel,
  hoverPanel,
  removePanel,
  orgaData
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [configDialogAnchor, setConfigDialogAnchor] = useState();
  const [triggerConfigSave, setTriggerConfigSave] = useState(false);

  const [panelTitle, setPanelTitle] = useState(panelItem?.panelType);
  const [contentSize, setContentSize] = useState(null);
  const panelType = panelItem?.panelType;
  const targetComponents = panelComponents[panelType];
  const getTitle = targetComponents?.getTitle;
  const configI18Key = targetComponents?.configI18Key || 'common.config';
  const PanelConfig = targetComponents?.Config || null;
  const PanelContent = targetComponents?.Content || null;

  const movable = editMode && panelItem?.configuration.movable;
  let panelHeaderGrabClass = '';
  if (movable) {
    panelHeaderGrabClass = isDraggingPanel
      ? classes.panelHeaderTitleGrabbing
      : classes.panelHeaderTitleGrab;
  }

  let headerVisible =
    panelItem?.configuration.titleVisible ||
    (editMode && hoverPanel === panelItem?.i);

  useEffect(() => {
    if (getTitle && panelItem) {
      const initTitle = getTitle({ orgaData, panelItem });
      setPanelTitle(initTitle);
    }
  }, [getTitle, orgaData]);

  if (!panelItem) {
    return null;
  }

  const contentWrapRef = (node) => {
    if (node !== null) {
      const nodeRect = node.getBoundingClientRect();
      setContentSize(Math.min(nodeRect.width - 20, nodeRect.height - 20));
    }
  };

  function openConfig(event) {
    setConfigDialogAnchor(event.currentTarget);
  }

  function closeConfig() {
    setConfigDialogAnchor(null);
    setTriggerConfigSave(false);
  }

  function triggerSavingConfig() {
    setTriggerConfigSave(true);
  }

  function saveConfigAll({ changedPanel, keepConfigDialogOpen = false }) {
    savePanel(panelItem.i, changedPanel);

    if (!keepConfigDialogOpen) {
      setConfigDialogAnchor(null);
    }

    setTriggerConfigSave(false);
  }

  if (!PanelConfig || !PanelContent) {
    return null;
  }

  return (
    <>
      <CardHeader
        className={
          headerVisible ? classes.panelHeader : classes.panelHeaderHide
        }
        disableTypography={true}
        title={
          <Tooltip
            title={!isDraggingPanel && panelTitle ? panelTitle : ''}
            placement="top-start"
            arrow
          >
            <Typography
              className={`panelDragHandle ${panelHeaderGrabClass}`}
              variant="body2"
              color="textPrimary"
              noWrap={true}
            >
              {panelTitle}
            </Typography>
          </Tooltip>
        }
        classes={
          editMode
            ? {
                content: classes.panelHeaderContentEdit
              }
            : {
                content: classes.panelHeaderContent
              }
        }
        action={
          editMode && (
            <>
              <IconButton
                aria-label="configure panel"
                size="small"
                onClick={openConfig}
                className={classes.panelHeaderAction}
              >
                <Icon className="fa fa-cogs" />
              </IconButton>
              <Dialog
                open={Boolean(configDialogAnchor)}
                onClose={closeConfig}
                aria-labelledby="dashboardTextConfigTitle"
              >
                <DialogTitle id="dashboardTextConfigTitle">
                  {t('common.config')}
                </DialogTitle>
                <DialogContent>
                  <PanelBaseConfig
                    PanelConfig={PanelConfig}
                    configI18Key={configI18Key}
                    triggerConfigSave={triggerConfigSave}
                    saveConfigAll={saveConfigAll}
                    panelItem={panelItem}
                    orgaData={orgaData}
                    setPanelTitle={setPanelTitle}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={triggerSavingConfig} color="primary">
                    {t('common.save')}
                  </Button>
                  <Button onClick={closeConfig} color="primary">
                    {t('common.cancel')}
                  </Button>
                </DialogActions>
              </Dialog>
              {panelItem.configuration.closable && (
                <IconButton
                  aria-label="remove panel"
                  size="small"
                  onClick={() => removePanel(panelItem.i)}
                  className={classes.panelHeaderAction}
                >
                  <Icon className="fa fa-window-close" />
                </IconButton>
              )}
            </>
          )
        }
      />
      <CardContent
        ref={contentWrapRef}
        className={`panelNonDragArea ${classes.panelContent} ${
          headerVisible ? classes.panelContentWithHeader : null
        }`}
      >
        {targetComponents && (
          <PanelContent
            orgaData={orgaData}
            panelItem={panelItem}
            contentSize={contentSize}
            savePanel={savePanel}
            saveConfigAll={saveConfigAll}
          />
        )}
      </CardContent>
    </>
  );
}
