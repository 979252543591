import React, { useState, useRef, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from 'react-i18next';

import { useDataManagement } from '../DataManagementContext';
import { useCreateInstance } from '../create-instances/CreateInstanceContext';

import ajaxPost from '../../../../services/ajaxPost';
import { makeStyles } from '@material-ui/core/styles';
import ajaxGet from 'src/services/ajaxGet';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
  contentEdit: {
    border: '1px solid #dadada',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    padding: '10px'
  },
  contentEditError: {
    border: '1px solid #f44336 !important'
  },
  helperWrap: {
    width: '20rem'
  },
  helperText: {
    position: 'relative',
    bottom: '5px',
    left: '12px'
  },
  defaultData: {
    backgroundColor: '#fafad2'
  }
}));

export const TextEditorSimple = ({ textType, instanceEditor }) => {
  const { t } = useTranslation();
  const {
    getTextState,
    setTextState,
    isDefaultData,
    deleteDefaultData,
    setDefaultData
  } = useDataManagement();
  const {
    isCreatingInstance,
    isDuplicateInstance,
    setCreationDataText,
    setCreationComponentRef
  } = useCreateInstance();
  const classes = useStyles();

  const defaultDataObject = {
    id: textType,
    type: 'textEditor',
    instanceId: instanceEditor.instanceId
  };

  const textEditorData = instanceEditor.textEditor[textType];
  const creatingInstance = isCreatingInstance();
  const duplicateInstance = isDuplicateInstance();
  const isDefaultDataPTD = isDefaultData(defaultDataObject);
  const hasDefaultData = Boolean(
    textEditorData?.defaultDataAwareInfo?.defaultDataAvailable
  );
  let textId = textEditorData.id;
  let text = getTextState(textId) || '';
  const [inputState, setInputState] = useState(text);
  const refText = useRef('');
  const [blurState, setBlurState] = useState(text);

  const required = instanceEditor.textEditor[textType].required;

  const componentRef = useRef(null);

  const [isMandatoryError, setIsMandatoryError] = useState(required && !text);
  const [errorMsg, setErrorMsg] = useState(
    required && !text ? t('common.errorMandatory') : ''
  );

  useEffect(() => {
    if (creatingInstance && !duplicateInstance) {
      setCreationComponentRef(textType, componentRef, isMandatoryError);
    }

    if (duplicateInstance && text) {
      setCreationDataText(textEditorData.textType, text);
    }
  }, [isMandatoryError]);

  function changeText(event) {
    const changedText = event.target.value;
    refText.current = changedText;
    setInputState(changedText);
  }

  function saveText() {
    let newTextValue = refText.current;
    if (newTextValue) {
      newTextValue = newTextValue
        .replaceAll('<br>', '<br />')
        .replaceAll('</br>', '')
        .replaceAll('<br /></', '</');

      newTextValue = newTextValue.replace(/<div><br \/><\/div>/g, '');
      newTextValue = newTextValue.replace(/(&nbsp;){1}/g, ' ');
      newTextValue = newTextValue.replace(/(\u00a0){1}/g, ' ');
    }
    
    if (blurState === newTextValue) {
      return;
    }
    setBlurState(newTextValue);

    if (required && !newTextValue) {
      setIsMandatoryError(true);
      setErrorMsg(t('common.errorMandatory'));
      return;
    }
    setIsMandatoryError(false);
    setErrorMsg('');

    if (creatingInstance) {
      let newTextValueTest = $(newTextValue).text();

      if (newTextValueTest === '' && hasDefaultData) {
        resetDefaultText();
      } else {
        deleteDefaultData(defaultDataObject);
        setCreationDataText(textEditorData.textType, newTextValue);
      }

      return;
    }
    setTextState(textId, newTextValue);

    const payload = {
      id: textId,
      language: textEditorData.language,
      parentId: textEditorData.parentId,
      parentObjectClass: textEditorData.parentObjectClass,
      text: newTextValue,
      textType: textEditorData.textType
    };

    ajaxPost('saveText', {}, payload).then((ajaxData) => {
      let response = ajaxData.response;
      TOGO.Util.notifyResponse(response.message, !response.success);

      if (!ajaxData.error && response) {
        setTextState(textId, newTextValue);
      }
    });
  }

  function resetDefaultText() {
    let contentIdentifier = defaultDataObject.id;
    let contentLanguage = textEditorData.language;
    let ptdId = textEditorData.defaultDataAwareInfo.defaultDataId;
    let ptdObjectClass =
      textEditorData.defaultDataAwareInfo.defaultDataObjectClass;

    ajaxGet('getDefaultTextPtd', {
      contentIdentifier: contentIdentifier,
      contentLanguage: contentLanguage,
      ptdId: ptdId,
      ptdObjectClass: ptdObjectClass
    }).then((ajaxData) => {
      if (ajaxData.error) return;

      let textInstanceId = ajaxData.response.textInstanceId;
      let defaultText = ajaxData.response.defaultText;
      if (textInstanceId && defaultText) {
        setDefaultData(defaultDataObject);
        setTextState(textEditorData.id, defaultText);
        setInputState(defaultText);
      }
      setCreationDataText(textEditorData.textType, null);
    });
  }

  if (!textEditorData) {
    return null;
  }
  return (
    <FormControl
      error={isMandatoryError}
      className="instanceEditorComponent"
      style={{ width: '90%' }}
    >
      <div ref={componentRef}>
        <div className="textEditorTitle editorTitle">
          <h2>
            <span>{textEditorData.contentLangTitle.text}</span>
          </h2>
        </div>
        <ContentEditable
          html={inputState}
          onChange={changeText}
          onBlur={saveText}
          tagName="p"
          className={`${classes.contentEdit} ${
            isMandatoryError ? classes.contentEditError : null
          } ${isDefaultDataPTD ? classes.defaultData : null}`}
        />
        <div className={classes.helperWrap}>
          <FormHelperText className={classes.helperText}>
            {errorMsg}
          </FormHelperText>
        </div>
      </div>
    </FormControl>
  );
};
