import React, { useState , useEffect, useRef  } from 'react';

import {MdStarOutline,MdOutlineStar} from "react-icons/md";
import Typography from "@material-ui/core/Typography";


import { useTranslation } from 'react-i18next';
import Tooltip from "@material-ui/core/Tooltip";
import ajaxCall from 'src/services/ajaxCall.js';

import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "@mui/material/styles";


const FavoriteButton = ({instanceId, objectClassName}) => {
  const theme = useTheme();

  const [isFavoriteInstance, setIsFavoriteInstance] = useState(false);
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    root: {
      ' &.Mui-selected': {
        backgroundColor: ' rgb(0 0 0 / 6%) !important'
      }
    } 
  }));

  const classes = useStyles();

  useEffect(() => {
    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    // let objectClassName = $instanceWrap.data('classname');
    // let instanceId = $instanceWrap.data('id');

    ajaxCall({
      httpMethod: 'get',
      url: 'favoriteInstance',
      params: {
        toggle: false,
        instanceId: instanceId,
        instanceClassName: objectClassName
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setIsFavoriteInstance("favorite" == ajaxData.response.resultInfo);
      }
    });
  }, []);



  function toggleFavoriteInstance(event) {
    event.stopPropagation();
    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    // let objectClassName = $instanceWrap.data('classname');
    // let instanceId = $instanceWrap.data('id');

    ajaxCall({
      httpMethod: 'post',
      url: 'favoriteInstance',
      params: {
        toggle: true,
        instanceId: instanceId,
        instanceClassName: objectClassName
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        if ("favorite" == ajaxData.response.resultInfo) {
          setIsFavoriteInstance(true);
        }
        else {
          setIsFavoriteInstance(false);
        }

        TOGO.Util.notify('', ajaxData.response.message, null, false);
      }
    });
  }
  return (
    <>
      {' '}
      <Tooltip
        title={
          <Typography variant="h6">
            {isFavoriteInstance
              ? t('data.removeFavorite')
              : t('data.addFavorite')}
          </Typography>
        }
      >
        <ToggleButton
          onChange={(event) =>  toggleFavoriteInstance(event)}
          selected={isFavoriteInstance}
          value='isFavoriteInstance'
          className={
            classes.root  + ' iconButton  overviewItemDeleteButton'
          }
        >
          {isFavoriteInstance ? (
            <MdOutlineStar size={50} style={{ color: '#f29528' }} />
          ) : (
            <MdStarOutline size={50} style={{ color: '#f29528' }} />
          )}
        </ToggleButton>
      </Tooltip>
    </>
  );
};

export default FavoriteButton;
