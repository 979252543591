import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

import {
  useStyles,
  
} from '../../user/ressources/contries/utils';
import { useField, useFormikContext } from 'formik';
import { makeStyles, OutlinedInput } from '@material-ui/core';

export const useTitleStyles = makeStyles(() => ({
  titleSelect: {
    top: '-2rem',
    left: '-0.6rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  }
}));

function MultipeSelect__({ name, roleInfos }) {
  const getResultTab = (roleInfos, value, bool) => {
    var res = [];
    Object.values(roleInfos).forEach((element) => {
      if (bool) {
        if (value.includes(element.roleName)) {
          res.push(element.roleMessageCode);
        }
      } else {
        if (value.includes(element.roleMessageCode)) {
          res.push(element.roleName);
        }
      }
    });

    return res;
  };
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const classes = useStyles();
  const classesTitle = useTitleStyles();
  const result = getResultTab(roleInfos, field.value, true);
  const [selected, setSelected] = useState(result);

  const isAllSelected =
    roleInfos.length > 0 && selected.length === roleInfos.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      var codeMessagesTab = [];
      var roleNamesTab = [];

      Object.values(roleInfos).forEach((element) => {
        codeMessagesTab.push(element.roleMessageCode);
        roleNamesTab.push(element.roleName);
      });
      setSelected(selected.length === roleInfos.length ? [] : codeMessagesTab);
      setFieldValue(
        name,
        selected.length === roleInfos.length ? [] : roleNamesTab
      );
      return;
    }
    setSelected(value);
    const postResult = getResultTab(roleInfos, value, false);
    setFieldValue(name, postResult);
  };

  return (
    <FormControl variant="filled">
      <InputLabel id="mutiple-select-label" disableAnimation shrink={false}
        className={classesTitle.titleSelect} >{t('managUsers.register.permissions')}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        displayEmpty={true}
        // autoWidth={true}
        input={<OutlinedInput notched={false} label={t('managUsers.register.permissions')} />}
        renderValue={(selected) => {
          let selectedLength = selected != null ? selected.length : 0;
          if (selectedLength == 0) {         
            return t('managUsers.register.noPermissions');
          }
          
          let rolesTotal = roleInfos != null ? roleInfos.length : 0;
          return t('managUsers.register.permissionsSelection', { selectedLength: selectedLength, rolesTotal: rolesTotal });
        }}
        // MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ''
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < roleInfos.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary={t('managUsers.register.toggleAllNone')}
          />
        </MenuItem>
        {roleInfos.length != 0 &&
          roleInfos.length != undefined &&
          roleInfos.map((role, pos) => (
            <MenuItem key={pos} value={role['roleMessageCode']}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.indexOf(role['roleMessageCode']) > -1}
                />
              </ListItemIcon>
              <ListItemText primary={role['roleMessageCode']} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default MultipeSelect__;
