import React, { useState } from 'react';
import {
  IconButton,
  MenuItem,
  Select,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '0px ​16px 0px 1px',
      overflow: 'initial !important',
        fontSize: '1.5rem',
      '& .MuiSelect-select.MuiSelect-select': {
        padding: 0,
        paddingLeft: 25
      },
      '& .MuiInputBase-input': {
        padding: 0,
        paddingLeft: 1
      },
      '& .MuiSelect-selectMenu': {
        overflow: 'initial !important'
      }
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  btn: {
    padding: '5px',
    margin: '12px'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  labelPagination: {
    fontSize: '1.6rem',
    padding: '10px'
  }
}));



function PaginationProduct({
  paginationstate,
  gotoPage,
  previousPage,
  searchUserName,
  nextPage,
  handleChangeElementsPerPage,
  handleChangeCurrentPage,
  currentPage,
  elementsPerPage
}) {



  const [page, setPage] = useState();

  const [pageIndex, setPageIndex] = useState(1);
  
  const [pageSize, setPageSize] = useState(10);

  const { t } = useTranslation();

  const classes = useStyles();


  return (
    <>
     {paginationstate.countElements > 0 && ( <div className="pagination">
        
          <div>
            <Tooltip
          title={
            <Typography variant="h5">{t('manageOrganization.organization.firstPage')}</Typography>
          }
        >
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => gotoPage(1)}
                  disabled={paginationstate.currentPage == 1}
                >
                  <FirstPageIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
          title={
            <Typography variant="h5">{t('manageOrganization.organization.previousPage')}</Typography>
          }
        >
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => previousPage()}
                  disabled={paginationstate.currentPage == 1}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>{' '}
              </span>
            </Tooltip>
            <span>
            <span className={classes.labelPagination}>{t('manageOrganization.organization.page')} {'   '}</span>{' '}
              <Select
                style={{ padding: '0px 5px 0px 5px' }}
                className={classes.root}
                value={paginationstate.currentPage}
                onChange={(e) => {
                  const page = Number(e.target.value);
                  handleChangeCurrentPage(page);
                }}
              >
                {Array.from(
                  { length: paginationstate.countPages },
                  (_, i) => i + 1
                ).map((pageIndex) => (
                  <MenuItem key={pageIndex} value={pageIndex}>
                    {pageIndex}
                  </MenuItem>
                ))}
              </Select>
              <span className={classes.labelPagination}>
              &#160;{t('manageOrganization.organization.pageOf')} &#160; {paginationstate.countPages}
              </span>{' '}
            </span>

            <Tooltip
          title={
            <Typography variant="h5">{t('manageOrganization.organization.nextPage')}</Typography>
          }
             >
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => nextPage()}
                  disabled={paginationstate.currentPage == paginationstate?.countPages}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>{' '}
              </span>
            </Tooltip>

            <Tooltip
          title={
            <Typography variant="h5">{t('manageOrganization.organization.lastPage')}</Typography>
          }
             >
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => gotoPage(paginationstate?.countPages)}
                  disabled={paginationstate.currentPage == paginationstate?.countPages}
                >
                  <LastPageIcon />
                </IconButton>{' '}
              </span>
            </Tooltip>
          </div>

        <span>{t('manageOrganization.organization.elementsPerPage')}</span>{' '}
        <Select
          style={{ padding: '0px 5px 0px 5px' }}
          className={classes.root}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={elementsPerPage}
          onChange={(e) => {
            handleChangeElementsPerPage(Number(e.target.value));}}
        >
                  {/* { paginationstate.elementsPerPageOptions?.map((pageSize) => ( */}
          { [10, 50, 100].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </div>
       )}
    </>
  );
}

export default PaginationProduct;
