import React ,{useRef} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { TableHead, TableRow, TableCell } from '@mui/material';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { getUserCountryLang } from 'src/util/languageUtil';

function EnhancedTableHead(props) {

	const { t } = useTranslation();

const headCells_CLASSIFICATION_GROUP = [
	{
		id: 'Aktionen',
		numeric: false,
		disablePadding: false,
		label: t('manageOrganization.organization.actions')
	},
	{ 
		id: 'TableDefinitionID',
		numeric: false,
		disablePadding: false,
		label:  'ID'
	},
	{ id: 'TableDefinition Name',
		numeric: false,
		disablePadding: false,
		label:  t('dashboard.tableDef.name')
	},
	{
		id: 'fieldSize',
		numeric: false,
		disablePadding: false,
		label: t('dashboard.tableDef.width')
	},
	{
		id: 'columns',
		numeric: false,
		disablePadding: false,
		label:  t('dashboard.tableDef.unit')
	},
	{
		id: 'alignment',
		numeric: false,
		disablePadding: false,
		label: t('dashboard.tableDef.alignment')
	},
	{
		id: 'order',
		numeric: false,
		disablePadding: false,
		label: t('dashboard.tableDef.order')
	}
];
	return (
		<>
			<TableHead>
				<TableRow>
					{headCells_CLASSIFICATION_GROUP.map((headCell, index) => (
						<TableCell
							key={index}
							align={headCell.numeric ? 'right' : 'left'}
							sx={{ fontWeight: 'bold' }} 
						>
							{headCell.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		</>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	onRequestSort: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({

	// table: {
    // '& tbody>.MuiTableRow-root:hover': {
    //   background: 'red',
    // }}
	// ,
	root: {
		'& .MuiTableCell-head': {
			backgroundColor: '#a9a9a975 !important',
			fontSize: '1.5rem !important',
			fontWeight: 'bold !important'
		},
		'& .MuiTableCell-body': {
			fontSize: '1.5rem'
		},
		width: '100%',
		'& .MuiSvgIcon-root': { fontSize: '3rem !important' }
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750,
		'& .MuiTableCell-root': {
			borderLeft: '1px solid rgba(224, 224, 224, 1)'
		}
	},
	deleteBtn: {
		color: 'rgb(237 14 14 / 89%) !important'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
	
}));


export default function EnhancedTableDefinition({
	tableDefinitions,
	setInitFormTableDefinitionState,
	setTableDefinitions,
	searchTableDefinitions,
	setToCreateTableDefinition,
	// setContentLangSelections,
	setTableContent,
	tableContent,
	handleClickOpenDialogTableDefinition
}) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('company');
	
	const { t } = useTranslation();

	const formikRef = useRef();

	const userLang = getUserCountryLang();
	
	const [contentLang, setContentLang] = React.useState(t('language.code'));

	const confirmDeleteTableDefinition = (id) => {
		TOGO.popupManager.confirmZebraDialog(
			t('dashboard.tableDef.deleteButtonConfirm'),
			deleteTableDefinition,
			id
		);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const deleteTableDefinition = (id) => {
		var pojo = new Object();
		pojo.tableDefinitionId = id;
		ajaxPost('tableDefinition/delete', pojo).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				setTableDefinitions(tableDefinitions.filter((org) => org.id !== id));
				TOGO.Util.notifyResponse(ajaxData.response.message, false);
				searchTableDefinitions();
			}
		});
	};


	const editTableDefinition= (id) => {
		ajaxGet('tableDefinition/edit', {
			tableDefinitionId: id,
			contentLang:userLang
		}).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				var res = ajaxData.response;
				// var contentLangSelections = ajaxData.response.model.contentLangSelections;
				if (res != null && res != undefined) {

					var tableDefinitionForm = {
						id: id,
						language: userLang,
						name: res?.tableDefinitionName || '',
						size: res?.fieldSize || '',
						unit: res?.unit || '',
						alignment: res?.alignment || '',
						order: res?.order || '',
					};

					setTableContent(JSON.parse(ajaxData.response.tableContent))
					setInitFormTableDefinitionState(tableDefinitionForm);
					setToCreateTableDefinition(false);
					handleClickOpenDialogTableDefinition();
				}
			}
		});
	};
	return (
		<div className={classes.root}>
			{ tableDefinitions?.length > 0 ? (
				<Paper className={classes.paper}>
					<TableContainer>
						<Table
							id="tabDef"
							className={classes.table}
							aria-labelledby="tableTitle"
							aria-label="simple table"
						>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
							/>
								<TableBody>
									{tableDefinitions.map((row, index) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={index}>
												<TableCell align="center">
													<Grid container >
													<Grid item xs={6}>
															<Tooltip
																title={
																	<Typography fontSize={5}>{t('common.edit')}</Typography>}>
																<IconButton
																	onClick={() => {
																		editTableDefinition(row.tableDefinitionID);
																	}}
																>
																	<EditIcon />
																</IconButton>
															</Tooltip> 
														</Grid>
														<Grid item>
														<Tooltip
														title={
															<Typography fontSize={5}>{t('common.delete')}</Typography>
														}
													>
														<IconButton
															onClick={() => {
																confirmDeleteTableDefinition(row.tableDefinitionID);
															}}
														>
															<DeleteIcon className={classes.deleteBtn} />
														</IconButton>
													</Tooltip>
														</Grid>
													</Grid>
												</TableCell>
												<TableCell align="center">
												{row?.tableDefinitionID}
												</TableCell>
												<TableCell align="center">
												{row?.tableDefinitionName}
												</TableCell>
												<TableCell align="center">
												{row?.fieldSize}
												</TableCell>
												<TableCell align="center">
												{row?.unit}
												</TableCell>
												<TableCell align="center">
												{row?.alignment}
												</TableCell>
												<TableCell align="center">
												{row?.order}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
						</Table>
					</TableContainer>{' '}
				</Paper>
			) : (
				<Typography
					paragraph={true}
					noWrap={false}
					fontWeight={'bold'}
					align="center"
					fontSize={20}
				>
					{t('common.noSearchResults')}
				</Typography>
			)}
		</div>
	);
}

