import React, { createContext, useContext, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { getFeatureKey } from '../ArticleFeatureEditor';

import { getAlphanumericPayloadData } from '../featurecomponents/Alphanumeric';
import { getBoolPayloadData } from '../featurecomponents/Bool';
import { getDatePayloadData } from '../featurecomponents/Date';
import { getNumericPayloadData } from '../featurecomponents/Numeric';
import { getRangePayloadData } from '../featurecomponents/Range';
import { getSelectionPayloadData } from '../featurecomponents/Selection';

const CreateInstanceContext = createContext([]);

const CreateInstanceProvider = ({
  isInCreateInstanceContext,
  isDuplicate,
  duplicateInstanceState,
  duplicateId,
  variableContentDefs,
  featureDefs,
  children
}) => {
  const [creationParameters, setCreationParameters] = useImmer({});

  const [mandatoryRefs, setMandatoryRefs] = useState({});
  const [regExErrorList, setRegExErrorList] = useState([]);

  const creationDataStart = {
    textValues: {},
    featureValues: {}
  };
  const [creationData, setCreationData] = useImmer(creationDataStart);

  useEffect(() => {
    if (duplicateInstanceState && variableContentDefs) {
      for (let customFieldType in duplicateInstanceState.customFields) {
        const customFieldDef = variableContentDefs.customFieldDefs.find(
          (def) => customFieldType === def.value
        );
        let fieldState = duplicateInstanceState.customFields[customFieldType];
        let fieldCreationData = getCreationDataForFeature(
          customFieldDef,
          fieldState
        );
        if (fieldCreationData) {
          creationDataStart.featureValues[customFieldType] = fieldCreationData;
        }
      }

      for (let featureKey in duplicateInstanceState.features) {
        const featureDef = featureDefs.find(
          (def) => featureKey === getFeatureKey(def)
        );
        let featureState = duplicateInstanceState.features[featureKey];
        let featureCreationData = getCreationDataForFeature(
          featureDef,
          featureState
        );
        if (featureCreationData) {
          creationDataStart.featureValues[featureKey] = featureCreationData;
        }
      }

      creationDataStart.duplicateFromInstanceId = duplicateId;

      setCreationData(creationDataStart);
    }
  }, [duplicateInstanceState, duplicateId]);

  function getCreationDataForFeature(featureDef, featureState) {
    if (!featureDef || featureState == null) return null;
    switch (featureDef.featureType) {
      case 'FEATURE_TYPE_ALPHANUMERIC':
      case 'FEATURE_TYPE_ALPHANUMERIC_NON_LOCALIZED':
        return getAlphanumericPayloadData(featureState);
      case 'FEATURE_TYPE_NUMERIC':
        return getNumericPayloadData(featureState);
      case 'FEATURE_TYPE_BOOLEAN':
        return getBoolPayloadData(featureState);
      case 'FEATURE_TYPE_LOCAL_DATE':
        return getDatePayloadData(featureState);
      case 'FEATURE_TYPE_ALPHANUMERIC_SELECTION':
        return getSelectionPayloadData(false, featureState);
      case 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION':
        return getSelectionPayloadData(true, featureState);
      case 'FEATURE_TYPE_NUMERIC_RANGE':
        return getRangePayloadData(featureDef.featureType, featureState);
    }
  }

  return (
    <CreateInstanceContext.Provider
      value={[
        isInCreateInstanceContext,
        isDuplicate,
        creationParameters,
        setCreationParameters,
        creationData,
        setCreationData,
        regExErrorList,
        setRegExErrorList,
        mandatoryRefs,
        setMandatoryRefs
      ]}
    >
      {children}
    </CreateInstanceContext.Provider>
  );
};

const useCreateInstance = () => {
  const [
    isCreatingInstance,
    isDuplicate,
    creationParameters,
    setCreationParameters,
    creationData,
    setCreationData,
    regExErrorList,
    setRegExErrorList
  ] = useContext(CreateInstanceContext);

  function handleIsCreatingInstance() {
    return isCreatingInstance;
  }

  function handleIsDuplicateInstance() {
    return isDuplicate;
  }

  function handleGetCreationParameters() {
    return creationParameters;
  }
  function handleSetCreationParameter(creationParameter, value) {
    if (value) {
      setCreationParameters((draft) => {
        draft[creationParameter] = value;
      });
    } else {
      setCreationParameters((draft) => {
        delete draft[creationParameter];
      });
    }
  }

  function handleGetCreationData() {
    return creationData;
  }
  function handleSetCreationDataText(textType, textValue) {
    if (textValue) {
      setCreationData((draft) => {
        draft.textValues[textType] = textValue;
      });
    } else {
      setCreationData((draft) => {
        delete draft.textValues[textType];
      });
    }
  }
  function handleSetCreationDataFeature(featureIdentifier, valuePayload) {
    if (valuePayload) {
      setCreationData((draft) => {
        draft.featureValues[featureIdentifier] = valuePayload;
      });
    } else {
      setCreationData((draft) => {
        delete draft.featureValues[featureIdentifier];
      });
    }
  }

  function handleResetCreation() {
    setCreationParameters((draft) => {
      for (let paramKey in draft) delete draft[paramKey];
    });
    setCreationData((draft) => {
      draft.textValues = {};
      draft.featureValues = {};
    });
  }

  function handleSetComponentRef(refKey, ref, add) {
    if (add) {
      TOGO.creationMandatory[refKey] = ref;
    } else {
      if (refKey in TOGO.creationMandatory) {
        delete TOGO.creationMandatory[refKey];
      }
    }
  }

  function handleGetRegExErrorList() {
    return regExErrorList;
  }

  function handleSetRegExErrorList(id) {
    let pos = regExErrorList.indexOf(id);
    if (pos == -1) {
      regExErrorList.push(id);
    }
  }

  function handleDeleteFromRegExErrorList(id) {
    let pos = regExErrorList.indexOf(id);
    if (pos != -1) {
      regExErrorList.splice(pos, 1);
    }
  }

  function handleResetRegExErrorList() {
    setRegExErrorList([]);
  }

  return {
    isCreatingInstance: handleIsCreatingInstance,
    isDuplicateInstance: handleIsDuplicateInstance,
    getCreationParameters: handleGetCreationParameters,
    setCreationParameter: handleSetCreationParameter,
    getCreationData: handleGetCreationData,
    setCreationDataText: handleSetCreationDataText,
    setCreationDataFeature: handleSetCreationDataFeature,
    resetCreation: handleResetCreation,
    setCreationComponentRef: handleSetComponentRef,
    getRegExErrorList: handleGetRegExErrorList,
    setRegExErrorList: handleSetRegExErrorList,
    deleteFromRegExErrorList: handleDeleteFromRegExErrorList,
    resetRegExErrorList: handleResetRegExErrorList
  };
};

export { CreateInstanceProvider, useCreateInstance };
