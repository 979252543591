const COUNTRYLANG_TO_LANG = {
  de_DE: 'de',
  en_GB: 'en',
  fr_FR: 'fr',
  it_IT: 'it'
};
const LANG_TO_COUNTRYLANG = {
  de: 'de_DE',
  en: 'en_GB',
  fr: 'fr_FR',
  it: 'it_IT'
};

function getUserCountryLang() {
  const userLang = document.documentElement.lang || 'en';
  const userCountryLang = LANG_TO_COUNTRYLANG[userLang];
  return userCountryLang;
}

function getLangFromCountryLang(countryLang) {
  const lang = COUNTRYLANG_TO_LANG[countryLang];
  return lang;
}

export { getUserCountryLang, getLangFromCountryLang };
