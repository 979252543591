import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonGroup,
  Button,
  Dialog,
  DialogActions,
  Icon,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  DialogContent,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { usePimStyles } from 'src/jsx/PimTogoTheme';

export const PANELTYPE = 'LinkList';
export const LINK_LIST_I18KEY = 'dashboard.linkList.name';

const useStyles = makeStyles(() => ({
  blankCell: {
    borderColor: 'white'
  },
  iconText: { color: 'white' },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  selectedRow: {
    backgroundColor: '#0066ff3b',
    '&:hover': {
      backgroundColor: '#0066ff3b !important'
    }
  },
  noEditCell: {
    height: '4.6rem'
  }
}));

const useStylesTop = makeStyles(() => ({
  tableTop: {
    display: 'flex',
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '2rem',
    marginBottom: '0.7rem'
  },
  tableActions: {
    marginLeft: 'auto',
    order: '2'
  },
  collapsibleViewPort: {
    width: 'calc(90vw - 54rem)'
  }
}));

const ROWS_PER_PAGE_LOCAL_STORAGE_KEY_PREFIX = 'ListPanel_ElementsPerPage_';

export function ListPanelContent({
  panelItem,
  columns,
  rows,
  contentSize,
  listEdit,
  CreateRowDialog,
  saveCreateRow,
  EditRowDialog,
  deleteRow
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesTableTop = useStylesTop();
  const classesPim = usePimStyles();

  let initOrder = 'asc';
  let initSortingColumn = columns.find(
    (column) => 'defaultSortingColumnOrder' in column
  );
  if (initSortingColumn) {
    initOrder = initSortingColumn.defaultSortingColumnOrder;
  } else {
    initSortingColumn = columns[0];
  }
  const [order, setOrder] = useState(initOrder);
  const [orderBy, setOrderBy] = useState(
    initSortingColumn.sortingOrder || initSortingColumn.field
  );

  localStorage.getItem('lastname');

  let initRowsPerPage = null;
  let rowsPerPageLsKey = null;
  if (panelItem?.i) {
    rowsPerPageLsKey = ROWS_PER_PAGE_LOCAL_STORAGE_KEY_PREFIX + panelItem.i;
    initRowsPerPage = localStorage.getItem(rowsPerPageLsKey);
    initRowsPerPage = parseInt(initRowsPerPage);
  }
  if (!initRowsPerPage) {
    initRowsPerPage = 5;
    localStorage.setItem(rowsPerPageLsKey, initRowsPerPage);
  }
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);
  const [page, setPage] = useState(0);

  const [isCreateRowOpen, setIsCreateRowOpen] = useState(false);

  const sortedRows = stableSort(rows, getComparator(order, orderBy));

  const lastRowIndex = (page + 1) * rowsPerPage;
  const firstRowIndex = page * rowsPerPage;
  const pageRows = sortedRows.slice(firstRowIndex, lastRowIndex);
  const blankRowCount =
    page == 0 ? 0 : Math.max(0, rowsPerPage - pageRows.length);

  let size = null;
  if (contentSize) {
    size = contentSize - 50;
  }

  function changePage(_, newPage) {
    setPage(newPage);
  }

  function changeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    if (rowsPerPageLsKey) {
      localStorage.setItem(rowsPerPageLsKey, newRowsPerPage);
    }

    setPage(0);
  }

  function descendingComparator(a, b, orderBy) {
    let a_ob = a[orderBy];
    let b_ob = b[orderBy];

    if (typeof a_ob === 'string' && typeof b_ob === 'string') {
      return b_ob.localeCompare(a_ob, undefined, { sensitivity: 'base' });
    } else if (orderBy === 'timeStamp') {
      a_ob = a_ob.valueOf();
      b_ob = b_ob.valueOf();
    }

    if (b_ob < a_ob) {
      return -1;
    }
    if (b_ob > a_ob) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    let stabilizedThis = array.map((el, index) => [el, index]);

    let firstElements = [];
    const indexFirstElementsAlways = stabilizedThis.findIndex((element) =>
      Boolean(element[0].firstElementAlways)
    );
    if (indexFirstElementsAlways != -1) {
      firstElements = stabilizedThis.splice(indexFirstElementsAlways, 1);
    }

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return firstElements
      .map((el) => el[0])
      .concat(stabilizedThis.map((el) => el[0]));
  }

  function requestSort(columnSortingOrder) {
    const isAsc = orderBy === columnSortingOrder && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnSortingOrder);
  }

  function openCreateRow() {
    setIsCreateRowOpen(true);
  }

  function closeCreateRowDialog() {
    setIsCreateRowOpen(false);
  }

  if (!rows) {
    return null;
  }

  return (
    <div>
      <div className={classesTableTop.tableTop}>
        <>
          <ButtonGroup
            className={classesTableTop.tableActions}
            variant="contained"
            color="secondary"
            aria-label="instance table actions"
          >
            {listEdit.create && (
              <Button onClick={openCreateRow} className={classesPim.addAction}>
                <Tooltip title={t('common.create')} arrow>
                  <Icon className="fa fa-plus" />
                </Tooltip>
              </Button>
            )}
          </ButtonGroup>
          {listEdit.create && (
            <Dialog open={isCreateRowOpen} onClose={closeCreateRowDialog}>
              <CreateRowDialog />
              <DialogActions>
                <Button
                  onClick={() => {
                    saveCreateRow();
                    closeCreateRowDialog();
                  }}
                  color="primary"
                >
                  {t('common.save')}
                </Button>
                <Button onClick={closeCreateRowDialog} color="primary">
                  {t('common.cancel')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      </div>
      <TableContainer style={size ? { maxHeight: size } : null}>
        <Table stickyHeader size="small" aria-label="dashboard-table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sortDirection={
                    orderBy === (column.sortingOrder || column.field)
                      ? order
                      : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === (column.sortingOrder || column.field)}
                    direction={
                      orderBy === (column.sortingOrder || column.field)
                        ? order
                        : 'asc'
                    }
                    onClick={() =>
                      requestSort(column.sortingOrder || column.field)
                    }
                  >
                    {column.headerName}
                    {orderBy === column.field ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              {(listEdit.edit || listEdit.delete) && (
                <TableCell sortDirection={false}>
                  {t('common.actions')}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {pageRows.map((row) => (
              <ListRow
                key={row.id}
                columns={columns}
                row={row}
                listEdit={listEdit}
                EditRowDialog={EditRowDialog}
                deleteRow={deleteRow}
              />
            ))}
            {Array(blankRowCount)
              .fill()
              .map((_, index) => (
                <TableRow key={`blankRow-${index}`}>
                  <TableCell colSpan={3} className={classes.blankCell}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
      />
    </div>
  );
}

function ListRow({ columns, row, listEdit, EditRowDialog, deleteRow }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesPim = usePimStyles();

  const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
  const actionsMenuAnchorRef = useRef(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [savingEdit, setSavingEdit] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const toggleActionsMenu = () => {
    setActionsMenuOpen((prevOpen) => !prevOpen);
  };

  const closeActionsMenu = (event) => {
    if (actionsMenuAnchorRef.current) {
      if (event) {
        if (actionsMenuAnchorRef.current.contains(event.target)) return;
      } else {
        return;
      }
    }

    setActionsMenuOpen(false);
  };

  const showEditDialog = () => {
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    closeActionsMenu();
  };

  const confirmedEditAction = () => {
    setSavingEdit(true);
    setEditDialogOpen(false);
    closeActionsMenu();
  };

  const showDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const confirmedDeleteAction = () => {
    deleteRow(row);
    setDeleteDialogOpen(false);
    closeActionsMenu();
  };

  const cancelDeleteDialog = () => {
    setDeleteDialogOpen(false);
    closeActionsMenu();
  };

  return (
    <>
      <TableRow
        hover={true}
        className={actionsMenuOpen ? classes.selectedRow : null}
      >
        {columns.map((column) => (
          <TableCell key={column.field}>{column.getCellDisplay(row)}</TableCell>
        ))}
        {(listEdit.edit || listEdit.delete) && (
          <TableCell className={row.nolistEdit ? classes.noEditCell : null}>
            {!row.nolistEdit && (
              <ButtonGroup
                color="primary"
                ref={actionsMenuAnchorRef}
                aria-label="split button"
              >
                <Button
                  color="primary"
                  size="small"
                  aria-controls={
                    actionsMenuOpen ? 'split-button-menu' : undefined
                  }
                  aria-expanded={actionsMenuOpen ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={toggleActionsMenu}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
            )}
          </TableCell>
        )}
      </TableRow>
      {(listEdit.edit || listEdit.delete) && !row.nolistEdit && (
        <>
          <Popper
            open={actionsMenuOpen}
            anchorEl={actionsMenuAnchorRef.current}
            role={undefined}
            transition
            placement="right"
            modifiers={{
              flip: {
                enabled: true
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport'
              }
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={closeActionsMenu}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        onClick={showEditDialog}
                        className={classesPim.editActionMenuButton}
                      >
                        <ListItemIcon>
                          <Icon
                            className={`fa fa-pencil-square-o ${classesPim.editActionMenuButton}`}
                          />
                        </ListItemIcon>
                        <ListItemText primary={t('common.edit')} />
                      </MenuItem>
                      <MenuItem
                        onClick={showDeleteDialog}
                        className={classesPim.deleteActionMenuButton}
                      >
                        <ListItemIcon>
                          <Icon
                            className={`fa fa-trash-o ${classesPim.deleteActionMenuButton}`}
                          />
                        </ListItemIcon>
                        <ListItemText primary={t('common.delete')} />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Dialog
            open={deleteDialogOpen}
            onClose={cancelDeleteDialog}
            aria-labelledby="delete-dashboard-dialog"
          >
            <DialogTitle id="delete-dashboard-dialog">
              {t('common.deleteWithParameter', { label: row.label })}
            </DialogTitle>
            <DialogContent>{t('common.confirm')}</DialogContent>
            <DialogActions>
              <Button onClick={confirmedDeleteAction} color="primary">
                {t('common.delete')}
              </Button>
              <Button onClick={cancelDeleteDialog} color="primary">
                {t('common.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={editDialogOpen}
            onClose={closeEditDialog}
            aria-labelledby="delete-dashboard-dialog"
          >
            <EditRowDialog
              row={row}
              saving={savingEdit}
              setSaving={setSavingEdit}
            />
            <DialogActions>
              <Button onClick={confirmedEditAction} color="primary">
                {t('common.edit')}
              </Button>
              <Button onClick={closeEditDialog} color="primary">
                {t('common.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
