import React, { useMemo, useState } from 'react';

import { nanoid } from 'nanoid';

import { Responsive, WidthProvider } from 'react-grid-layout';
import { Card, makeStyles } from '@material-ui/core';

import DashboardPanel from './DashboardPanel';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 170
  }
}));

const PANEL_MIN_H = 1;

export default function DashboardGrid({
  editMode,
  gridLayouts,
  setGridLayouts,
  panels,
  setPanels,
  orgaData
}) {
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const classes = useStyles();

  const [hoverPanel, setHoverPanel] = useState();
  const [isDraggingPanel, setIsDraggingPanel] = useState(false);

  let breakpointsIndependentGridItems = [];
  if (gridLayouts.lg.length > 0) {
    breakpointsIndependentGridItems = gridLayouts.lg;
  }
  if (breakpointsIndependentGridItems.length && gridLayouts.md.length > 0) {
    breakpointsIndependentGridItems = gridLayouts.md;
  }
  if (breakpointsIndependentGridItems.length && gridLayouts.sm.length > 0) {
    breakpointsIndependentGridItems = gridLayouts.sm;
  }

  function layoutChange(newLayout, newLayouts) {
    if (newLayout) {
      const droppedItem = newLayout.find(
        (gridItem) => gridItem.i == 'newDroppingItem'
      );
      if (droppedItem) {
        return;
      }
    }

    setGridLayouts(newLayouts);
  }

  function savePanel(panelId, changedPanel) {
    setPanels((prevPanels) => ({
      ...prevPanels,
      [panelId]: changedPanel
    }));

    const movable = changedPanel.configuration.movable;
    const resizableX = changedPanel.configuration.resizableX;
    const resizableY = changedPanel.configuration.resizableY;

    setGridLayouts((prevLayouts) => {
      const ret = { lg: [], md: [], sm: [] };
      for (const size in ret) {
        ret[size] = prevLayouts[size].map((gridItem) => {
          if (gridItem.i === panelId) {
            const changeGridItem = { ...gridItem };

            changeGridItem.isDraggable = movable;

            changeGridItem.isResizable = false;
            if (resizableX) {
              changeGridItem.isResizable = true;
              delete changeGridItem.minW;
              delete changeGridItem.maxW;
            } else {
              changeGridItem.minW = gridItem.w;
              changeGridItem.maxW = gridItem.w;
            }
            if (resizableY) {
              changeGridItem.isResizable = true;
              changeGridItem.minH = PANEL_MIN_H;
              delete changeGridItem.maxH;
            } else {
              changeGridItem.minH = gridItem.h;
              changeGridItem.maxH = gridItem.h;
            }

            return changeGridItem;
          }

          return gridItem;
        });
      }
      return ret;
    });

    setHoverPanel(null);
  }

  function removePanel(id) {
    if (!panels[id].configuration.closable) {
      return;
    }

    const panelsChanged = { ...panels };
    delete panelsChanged[id];
    setPanels(panelsChanged);

    const gridLayoutChanged = {
      lg: gridLayouts.lg.filter((gridItem) => gridItem.i !== id),
      md: gridLayouts.md.filter((gridItem) => gridItem.i !== id),
      sm: gridLayouts.sm.filter((gridItem) => gridItem.i !== id)
    };
    setGridLayouts(gridLayoutChanged);
  }

  function dropFromToolbox(newLayoutArray, newItem, event) {
    const toolTypeForPanel = event.dataTransfer.getData('toolTypeForPanel');

    if (!newLayoutArray || !newItem || !toolTypeForPanel) {
      return;
    }

    const newPanelId = toolTypeForPanel + '_' + nanoid(10);
    newItem.i = newPanelId;

    const newGridLayout = {
      lg: newLayoutArray,
      md: newLayoutArray,
      sm: newLayoutArray
    };
    setGridLayouts(newGridLayout);

    const newPanel = {
      i: newPanelId,
      panelType: toolTypeForPanel,
      configuration: {
        titleVisible: true,
        closable: true,
        movable: true,
        resizableX: true,
        resizableY: true
      }
    };
    setPanels((prevPanels) => ({
      ...prevPanels,
      [newPanelId]: newPanel
    }));

    setIsDraggingPanel(false);
  }

  function dragPanelStart() {
    setIsDraggingPanel(true);
  }

  function dragPanelStop() {
    setIsDraggingPanel(false);
  }

  function getPanelItem(gridItem) {
    let panelItem = panels[gridItem.i];
    if (panelItem) {
      return panelItem;
    }

    return null;
  }

  function hoverPanelStart(panelId) {
    setHoverPanel(panelId);
  }

  function hoverPanelEnd() {
    setHoverPanel(null);
  }

  return (
    <div>
      <ResponsiveGridLayout
        className={classes.root}
        layouts={{
          lg: gridLayouts.lg,
          md: gridLayouts.md,
          sm: gridLayouts.sm
        }}
        rowHeight={50}
        onLayoutChange={layoutChange}
        measureBeforeMount={true}
        useCSSTransforms={false}
        onDrop={dropFromToolbox}
        isDroppable={true}
        onDragStart={dragPanelStart}
        onDragStop={dragPanelStop}
        draggableHandle=".panelDragHandle"
        draggableCancel=".panelNonDragArea"
        droppingItem={{ i: 'newDroppingItem', w: 2, h: 2, minH: PANEL_MIN_H }}
        breakpoints={{ lg: 960, md: 720, sm: 540 }}
        cols={{ lg: 12, md: 10, sm: 6 }}
      >
        {breakpointsIndependentGridItems.map((gridItem) => (
          <Card
            key={gridItem.i}
            onMouseEnter={() => {
              hoverPanelStart(gridItem.i);
            }}
            onMouseLeave={hoverPanelEnd}
          >
            <DashboardPanel
              editMode={editMode}
              panelItem={getPanelItem(gridItem)}
              savePanel={savePanel}
              isDraggingPanel={isDraggingPanel}
              hoverPanel={hoverPanel}
              removePanel={removePanel}
              orgaData={orgaData}
            />
          </Card>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
}
