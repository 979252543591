import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
  Tooltip
} from '@material-ui/core';

import ajaxCall from '../../services/ajaxCall.js';
import { getUserCountryLang } from 'src/util/languageUtil.js';

export default function EditGridList({
  urlDashboardGrid,
  activeGridIndex,
  grids,
  setGrids,
  selectGrid
}) {
  const { t } = useTranslation();

  const [openCreateGrid, setOpenCreateGrid] = useState(false);
  const [createGridLabel, setCreateGridLabel] = useState('');
  const [openDeleteGrid, setOpenDeleteGrid] = useState(false);

  const userLang = getUserCountryLang();
  const activeGrid = grids.find((_, index) => index == activeGridIndex);
  const activeGridLabel = activeGrid ? activeGrid.label[userLang] : '';

  const openCreateGridDialog = () => {
    setCreateGridLabel('');
    setOpenCreateGrid(true);
  };

  const cancelCreateGrid = () => {
    setOpenCreateGrid(false);
  };

  const confirmCreateGrid = () => {
    const payloadGridJson = JSON.stringify({});
    const payloadPanelsJson = JSON.stringify({});

    ajaxCall({
      httpMethod: 'post',
      url: urlDashboardGrid,
      params: {
        label: createGridLabel,
        lang: userLang
      },
      json: {
        gridJson: payloadGridJson,
        panelsJson: payloadPanelsJson
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        const newGrid = ajaxData.response.grids[0];
        newGrid.gridJson = payloadGridJson;
        const oldGridLength = grids.length;

        setGrids((prevGrids) => [...prevGrids, newGrid]);

        let loadedGrids = grids.concat(newGrid);

        setOpenCreateGrid(false);
        setCreateGridLabel('');

        selectGrid(oldGridLength, false, loadedGrids, true);
      }
    });
  };

  const openDeleteGridDialog = () => {
    if (grids.length == 1) {
      return;
    }
    setOpenDeleteGrid(true);
  };

  const cancelDeleteGrid = () => {
    setOpenDeleteGrid(false);
  };

  const confirmDeleteGrid = () => {
    ajaxCall({
      httpMethod: 'delete',
      url: urlDashboardGrid,
      params: {
        gridOrder: activeGridIndex
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        const reducedGrids = grids.filter(
          (_, index) => index != activeGridIndex
        );
        setGrids(reducedGrids);
        selectGrid(0, false, reducedGrids, true);

        setOpenDeleteGrid(false);
      }
    });
  };

  const editCreateGridLabel = (event) => {
    setCreateGridLabel(event.target.value);
  };

  return (
    <>
      <ButtonGroup
        orientation="vertical"
        color="default"
        aria-label="vertical outlined primary button group"
      >
        <Tooltip title={t('common.create')} placement="top" arrow>
          <Button
            onClick={openCreateGridDialog}
            size="small"
            style={
              grids.length > 1
                ? null
                : {
                    position: 'relative',
                    top: '1.55rem'
                  }
            }
          >
            <Icon className="fa fa-plus" />
          </Button>
        </Tooltip>
        {grids.length > 1 && (
          <Tooltip title={t('common.delete')} placement="bottom" arrow>
            <Button onClick={openDeleteGridDialog} size="small">
              <Icon className="fa fa-minus" />
            </Button>
          </Tooltip>
        )}
      </ButtonGroup>
      <Dialog
        open={openCreateGrid}
        onClose={cancelCreateGrid}
        aria-labelledby="create-dashboard-dialog"
      >
        <DialogTitle id="create-dashboard-dialog">
          {t('dashboard.createGrid')}
        </DialogTitle>
        <DialogContent>
          <TextField
            onChange={editCreateGridLabel}
            variant="outlined"
            autoFocus
            margin="dense"
            label="Name"
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmCreateGrid} color="primary">
            {t('common.create')}
          </Button>
          <Button onClick={cancelCreateGrid} color="primary">
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteGrid}
        onClose={cancelDeleteGrid}
        aria-labelledby="delete-dashboard-dialog"
      >
        <DialogTitle id="delete-dashboard-dialog">
          {t('dashboard.deleteGrid', { gridLabel: activeGridLabel })}
        </DialogTitle>
        <DialogContent>{t('common.confirm')}</DialogContent>
        <DialogActions>
          <Button onClick={confirmDeleteGrid} color="primary">
            {t('common.delete')}
          </Button>
          <Button onClick={cancelDeleteGrid} color="primary">
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
