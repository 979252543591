import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTitleStyles } from '../articledata/datamanagement/featurecomponents/FeatureComponent';


import {
	Grid,
	Box,
	Button,
	Select,
	MenuItem,
	InputLabel
} from '@material-ui/core';

import { usePimStyles } from '../PimTogoTheme';
import Textfield from '../user/FormsUI/TextField';
import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';
import FocusError from '../classificationGroup/FocusError';


export const OutputChannelForm = ({
	handleCloseDialogOutputChannel,
	initFormOutputChannelState,
	toCreateOutputChannel,
	contentLangSelections,
	searchOutputChannels
}) => {

	const { t } = useTranslation();

	const [language, setLanguage] = useState(t('language.code'));

	const classesTitle = useTitleStyles();

	const classesPim = usePimStyles();

	const formikRef = useRef();

	const FORM_VALIDATION_OUTPUT_CHANNEL_ = Yup.object().shape({
		id: '',
		language:'',
		outputChannelName: Yup.string()
	///	.required(t('manageOrganization.organization.requiredFieldErrorMessage'))
		.max(64, 'Muss nicht mehr als 64 Zeichen enthalten')
	});

	useEffect(() => {}, []);


	const getClassificationGroup_ = (id, val) => {
		ajaxGet('outputchannelsManagement/edit', {
			outputChannelId: id,
			contentLang:val
		}).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				var res = ajaxData.response.articleDataOutputChannelPojo;
				if (res != null && res != undefined) {
					formikRef.current.setFieldValue(
						"outputChannelName",
						res?.outputChannelName || ''
					);
				}
			}
		});
	};


	function handleChangeLanguage_(event) {
		const val = event.target.value;
		var contentLang = formikRef.current.values.language;

		formikRef.current.setFieldValue(
			"language",
			val
		);
		setLanguage(val);
		let params = {};
		params.id = formikRef.current.values.id;

		params.outputChannelName = formikRef.current.values.outputChannelName;
		params.language = contentLang;
		ajaxPost('outputchannelsManagement/editDone', params).then(
			(ajaxData) => {
				if (!ajaxData.error && ajaxData.response) {
					// handleCloseDialogClassificationGroup();
					TOGO.Util.notifyResponse(ajaxData.response.message, false);
					// searchClassificationGroups();
				}
				getClassificationGroup_(formikRef.current.values.id, val);
			}
		);

		

	}
	return (
		<Formik
			initialValues={{
				...initFormOutputChannelState
			}}
			innerRef={formikRef}
			enableReinitialize={true}
			validationSchema={
				FORM_VALIDATION_OUTPUT_CHANNEL_
			}
			onSubmit={(values) => {
				console.log(values);
				let params = {};
				params.id = values?.id;
				params.outputChannelName = values?.outputChannelName;


				if (toCreateOutputChannel){
					ajaxPost('outputchannelsManagement/create', params).then(
						(ajaxData) => {
							if (!ajaxData.error && ajaxData.response) {
								TOGO.Util.notifyResponse(ajaxData.response.message, false);
								handleCloseDialogOutputChannel();
								searchOutputChannels();
							}
						}
					);

				}else{
					params.language = formikRef.current.values.language;
					ajaxPost('outputchannelsManagement/editDone', params).then(
						(ajaxData) => {
							if (!ajaxData.error && ajaxData.response) {
								handleCloseDialogOutputChannel();
								TOGO.Util.notifyResponse(ajaxData.response.message, false);
								searchOutputChannels();
							}
						}
					);
				}

				
			}}
		>
			<Form>
				<FocusError />
				<Box className={classesPim.gridItem}>
					<Grid container spacing={2}>
					{!toCreateOutputChannel && <Grid item xs={12}>
                  <InputLabel id="languageTypeLabel">Sprache</InputLabel>
                  <Select
                    labelId="languageTypeLabel"
                    variant={'outlined'}
					name="language"
                    className={classesTitle.datepickerStyle}
                    onChange={handleChangeLanguage_}
					value={language}
                  >
                    {
                      (contentLangSelections =
                        !undefined &&
                        contentLangSelections.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        }))
                    }
                  </Select>
                </Grid> }
						<Grid item xs={12}>
							<Textfield
								name="outputChannelName"
								label={t('outputChannel.outputChannelName')}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.datepickerStyle}
								variant={'outlined'}
							></Textfield>
						</Grid>
					</Grid>
				</Box>
				<div
					style={{
						display: 'inline-flex',
						width: '100%',
						position: 'sticky',
						bottom: '0',
						zIndex: '9'
					}}
				>
					<Button
						type="submit"
						className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
						style={{ width: '50%' }}
					>
						{toCreateOutputChannel
							? t('common.create')
							: t('managUsers.register.saveUser')}
					</Button>

					<Button
						onClick={handleCloseDialogOutputChannel}
						className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
						style={{ width: '50%', marginLeft: '1rem' }}
					>
						{t('managUsers.register.cancel')}
					</Button>
				</div>
			</Form>
		</Formik>
	);
};

export default OutputChannelForm;
