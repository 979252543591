import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  numericEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  }
}));

export const getNumericPayloadData = (value) => ({
  numericValue: value
});

export const Numeric = ({
  featureInfo,
  number,
  setValueState,
  saveChangedValue,
  loading,
  markIrrelevantParent,
  error,
  title,
  mandatoryErrorMsg
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();

  const classes = useStyles();

  if (!number) {
    number = '';
  }
  const [inputState, setInputState] = useState(number);
  const [blurState, setBlurState] = useState(number);

  const irrelevantValue = Boolean(
    featureInfo.featureComponent?.irrelevantFeatureValue
  );
  const [markIrrelevant, setMarkIrrelevant] = useState(
    markIrrelevantParent || irrelevantValue
  );

  const mandatory = featureInfo.mandatory;
  let isMandatoryError = !irrelevantValue && mandatory && !inputState;

  const irrelevantFeaturePossible = Boolean(
    featureInfo.featureComponent?.irrelevantFeatureValuePossible
  );

  let regExError = featureInfo.isRegExError;
  let numericMinRestriction = featureInfo.numericMinRestriction;
  let numericMaxRestriction = featureInfo.numericMaxRestriction;
  let numericStepRestriction = featureInfo.numericStepRestriction;
  const regExErrorMessage = featureInfo.regexRestrictionMsg;
  const hasDefaultData = featureInfo.hasDefaultData;

  const isRange =
    featureInfo.featureComponent.featureType == 'FEATURE_TYPE_NUMERIC_RANGE';

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (!markIrrelevant && isMandatoryError && hasDefaultData) {
    helper = mandatoryErrorMsg;
  } else if (!markIrrelevant && isMandatoryError && !hasDefaultData) {
    helper = mandatoryErrorMsg || t('common.errorMandatory');
  } else if (!markIrrelevant && regExError) {
    helper =
      t('common.invalidInput') +
      ':\n' +
      (regExErrorMessage ? regExErrorMessage : '');
  }

  let shrinkTitle = false;
  if (!title) {
    title = isInTableContext ? null : featureInfo.title;
  } else {
    shrinkTitle = true;
  }

  useEffect(() => {
    setInputState(number);
  }, [number]);

  function saveValue(newValue) {
    if (markIrrelevant && (!newValue || newValue == '-')) {
      setBlurState('');
      setValueState('');
      const payload = getNumericPayloadData(null);
      payload.irrelevantFeatureValue = true;
      saveChangedValue('-', payload);
      return;
    }

    if (!newValue) {
      newValue = null;
    }

    if (blurState == newValue || (!blurState && !newValue) || loading) return;
    setBlurState(newValue);

    setValueState(newValue);

    if (!newValue && mandatory && !hasDefaultData && !mandatoryErrorMsg) return;
    saveChangedValue(newValue, getNumericPayloadData(newValue));
    setMarkIrrelevant(false);
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <>
      <TextField
        label={title}
        InputLabelProps={
          shrinkTitle
            ? null
            : {
                shrink: false,
                disableAnimation: true,
                className: classesTitle.title
              }
        }
        className={
          isInTableContext
            ? classes.numericEditorInTable
            : classesTitle.datepickerStyle
        }
        variant={isInTableContext ? 'standard' : 'outlined'}
        value={inputState}
        type="number"
        inputProps={
          numericMaxRestriction &&
          numericMinRestriction &&
          numericStepRestriction
            ? {
                max: numericMaxRestriction,
                min: numericMinRestriction,
                step: numericStepRestriction
              }
            : {}
        }
        error={
          Boolean(error) ||
          (isMandatoryError && (!hasDefaultData || mandatoryErrorMsg)) ||
          regExError
        }
        FormHelperTextProps={{
          component: 'div',
          style: { whiteSpace: 'pre-wrap', wordWrap: 'break-word' }
        }}
        helperText={helper}
        onChange={(event) => {
          setInputState(event.target.value);
        }}
        onKeyDown={(event) => {
          if (irrelevantFeaturePossible) {
            const key = event.key;
            if (key == '-') {
              setMarkIrrelevant(true);
            } else if (key != 'Tab' && key != 'Shift') {
              setMarkIrrelevant(false);
            }
          }
        }}
        onBlur={(event) => saveValue(event.target.value)}
        onMouseEnter={showError}
      />
      {markIrrelevant && !isRange && <p>{t('instanceEditor.irrelevant')}</p>}
    </>
  );
};
