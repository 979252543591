import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: false,
  lng: document.documentElement.lang,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: {
      translation: {
        common: {
          ok: 'Ok',
          yes: 'Yes',
          no: 'No',
          save: 'Save',
          saved: 'Saved',
          saveError: 'Error occured while saving',
          load: 'Load',
          edit: 'Edit',
          add: 'Add',
          delete: 'Delete',
          deleteWithParameter: 'Delete "{{label}}"',
          forward: 'Forward',
          back: 'Back',
          create: 'Create',
          confirm: 'Are you sure?',
          duplicate: 'Duplicate',
          selected: 'Selected',
          upload: 'Upload',
          download: 'Download',
          noResults: 'No Results',
          inputName: 'Input name',
          error: 'Error',
          hint: 'Hint',
          errorHappened: 'Something went wrong:',
          errorTryAgain: 'Try again',
          errorMandatory: 'Mandatory value cannot be empty.',
          abort: 'Abort',
          german: 'German',
          english: 'English',
          french: 'French',
          italian: 'Italian',
          noSearchResults: 'No Results found.',
          category: 'Category',
          cancel: 'Cancel',
          all: 'all',
          invalidInput:
            'The entered data does not correspond to the specifications',
          config: 'Configuration',
          type: 'Type',
          language: 'Language',
          name: 'Name',
          actions: 'Actions',
          anyTextMissing: '--- (Name missing)',
          selectDeselectAll: 'Select / Unselect All',
          noSubscription: 'No subscription has been booked yet.',
          linkToSubscription: 'Go to subscription'
        },
        dashboard: {
          user: 'User',
          orga: 'Organisation',
          createGrid: 'Create dashboard',
          deleteGrid: 'Delete dashboard "{{gridLabel}}"',
          editTitle: 'Edit dashboard',
          firstGridLabel: 'Overview',
          panel: {
            panelBaseConfig: 'Base Configuration',
            titleVisible: 'Title visible',
            closable: 'Closable',
            movable: 'Movable',
            resizableX: 'Resizable Horizontal',
            resizableY: 'Resizable Vertical'
          },
          text: {
            textConfig: 'Configure text',
            textTitle: 'Title',
            textContent: 'Text content',
            textPanelEditable: 'Edit text content in display'
          },
          subscription: {
            subQuota: 'Subscription quota',
            quotaUnlimited: 'unlimited',
            subFeatureList: 'Current Subscription View',
            subscribed: 'Subscribed features',
            notSubscribed: 'Features not subscribed'
          },
          list: {},
          linkList: {
            name: 'List of links',
            favorites: 'Favorites',
            recents: 'Recent edits',
            linkListConfigTitle: 'Set the type of list',
            type: 'Type'
          },
          articleDataZones: {
            name: 'Data zones',
            config: 'Configure data zone list',
            create: 'Create data zone',
            edit: 'Edit data zone'
          },
          orgaExports: {
            orgaExportsConfigTitle: 'organization Exports'
          },
          tableDef: {
            name: 'Name',
            favorites: 'Favorites',
            recents: 'Recent edits',
            createTableDef: 'Create table definition',
            createTableDefTitle: 'create table definition',
            editTableDef: 'edit table definition ',
            deleteTableDef: 'delete table definition',
            rows: 'rows',
            columns: 'Columns',
            deleteButtonConfirm:
              'Are you sure, you want to delete this table definition?',
            width: 'Field size',
            unit: 'unit',
            alignment: 'Alignment',
            order: 'Order',
            orderErrorMessage: 'must be at least 1',
            size: 'size',
            sizeErrorMessage: 'Max value exceeded',
            features: 'Features',
            horizontal: 'Horizontal',
            vertical: 'Vertikal',
            namePanel: 'table definition'
          }
        },
        data: {
          article: 'Article',
          articleNumber: 'Article number',
          artNr: 'Art. No.',
          customField: 'Field',
          customFields: 'Fields',
          feature: 'Feature',
          features: 'Features',
          product: 'Product',
          customInstance: 'Container',
          ptd: 'Product type',
          productStructure: 'Product Structure',
          catalog: 'Catalog',
          shop: 'Shop',
          addFavorite: 'Add favorite',
          removeFavorite: 'Remove favorite'
        },
        instanceEditor: {
          createInstance: 'Create {{instanceTitle}}',
          deleteInstances:
            'Are you sure you want to delete all selected objects of type {{instanceTitle}}?',
          deleteArticles:
            'Are you sure you want to delete all selected articles of this product?',
          textEditor: {
            placeholder: 'Text can be typed here...'
          },
          textVariable: {
            title: 'Text variable',
            dataCategory: 'Element',
            buttonAdd: 'Add',
            buttonEdit: 'Edit',
            buttonDelete: 'Delete',
            dataCategoryNotPossible:
              'There are no fields to choose for a text variable. No custom fields, features etc. for this data type defined.',
            tagName: 'Type',
            value: 'WERT',
            label: 'BEZEICHNUNG',
            unit: 'EINHEIT'
          },
          anchoredMedia: {
            title: 'Image',
            buttonAdd: 'Add',
            buttonEdit: 'Edit',
            buttonDelete: 'Delete',
            mediumType: 'Medium type',
            searchPlaceholder: 'Search filename'
          },
          articleDataZone: {
            name: 'Data zone',
            missingZoneError: 'Saved value no longer exists in the system'
          },
          keywords: {
            globalKeywords: 'All Keywords',
            customKeywordsGroup: 'Custom keywords',
            addOption:
              'Should the value be added to all keywords or only here to the custom keywords?'
          },
          selection: {
            commonOptions: 'Value list of definition',
            customOptions: 'Custom Values',
            addOption:
              'Should the value be added to the value list of the definition or only here to the custom values?'
          },
          range: {
            from: 'from',
            to: 'to',
            incomplete: 'Incomplete'
          },
          regExErrorDefault:
            'The entered data does not correspond to the specifications',
          defaultToggleOn: 'PTD default value on',
          defaultToggleOff: 'PTD default value off',
          irrelevant: '- (irrelevant value)'
        },
        tabeditor: {
          source: 'Source',
          lang: 'Language',
          versions: {
            version: 'Version',
            versions: 'Versions',
            name: 'Version name',
            applied: 'Global',
            overwrite: 'Do you want to overwrite this version?',
            delete: 'Do you want to delete this version??'
          },
          tools: {
            structure: 'Structure',
            title: 'Headline',
            container: 'Container',
            tabs: {
              name: 'Tabs',
              add: 'Add tab',
              orientation: 'Orientation',
              horizontal: 'Horizontal',
              vertical: 'Vertical',
              deleteSelected: 'Delete active tab'
            },
            table: {
              name: 'Table',
              firstColumn: 'First Column',
              newColumn: 'New Column',
              emptyColumn: 'Empty Spalte',
              add: 'Add column',
              deleteSelected: 'Delete active column'
            },
            accordion: 'Accordion',
            editors: 'Editors',
            textEditor: {
              name: 'Text editor',
              exampleText: 'Example text..',
              labelPlaceholder: 'Select text type...',
              type: 'Text type',
              variant: 'Variant',
              formatted: 'Formatted Text',
              simple: 'Only Text',
              allTexts: 'All texts',
              bulkData: 'all texts',
              bulkDataTextGroup: 'all texts "{{groupId}}"'
            },
            mediaEditor: {
              name: 'Media editor',
              labelPlaceholder: 'Select media type...',
              type: 'Media type',
              allMedia: 'All media',
              bulkData: 'all media',
              bulkDataMediaGroup: 'all media "{{groupId}}"'
            },
            fieldEditor: {
              name: 'Fields',
              exampleValue: 'Example value...',
              labelPlaceholder: 'Select field type...',
              type: 'Field type',
              generalFields: 'General fields',
              customFields: 'Custom fields',
              customFieldsNode: 'All custom fields',
              bulkDataGeneralFields: 'all general fields',
              bulkDataFeatures: 'all features',
              bulkDataCustomFields: 'all custom fields',
              bulkDataFieldGroup: 'all fields "{{groupId}}"',
              keywords: 'Keywords'
            },
            missingDef: 'Definition "{{defTitle}}" does not exist!'
          },
          settings: {
            background: 'Colour',
            padding: 'Padding',
            fontSize: 'Font size',
            dataSource: 'Data Source',
            multipleDataSourceField: 'Select data field(s)',
            columns: 'Columns'
          }
        },
        productType: {
          name: 'Product type name',
          classification: 'Classification',
          clear: 'Clear',
          select: 'please select ',
          open: 'Open',
          datasheetWithoutDefaultData:
            'Show product datasheetn (without PTD default values)',
          datasheet: 'Show product datasheet',
          Producttypdefinition: 'Producttypdefinition',
          ptdSelectHelpNoSMLSubscription:
            'The product category definition defines the classification of this product. You can apply product type definitions from the standard classifications into your system. To do this, select the required product type definition under Template and apply it.',
          ptdSelectHelp:
            'The product category definition defines the classification of this product. You can apply product type definitions from the standard classifications into your system. To do this, select the required product type definition under Template and apply it. You can create your own product type definitions using the product type editor.'
        },
        export: {
          overwrite: 'Do you want to overwrite the export "{{exportName}}"?',
          chooseArticle: 'Article selection',
          exportSettings: 'Export settings'
        },
        bmeCatExport: {
          chooseArticle: {
            title: 'Article selection',
            allArticles: 'All articles',
            excelList: 'Excel list',
            catalog: 'catalog',
            productStructure: 'Product structure',
            shop: 'Shop'
          },
          generalSettings: 'General settings',
          header: 'Catalog header',
          hierarchySystem: 'Structure data'
        },
        bmeCatDataModel: {
          addMandatoryFields: 'Add Mandatory Fields',
          mandatoryFieldsAdded: 'Required fields was added, please save.',
          specification: 'Specification',
          saveDataModel: 'Save data model',
          selectAll: 'Select all',
          currentDataModel: 'Current data model',
          automaticUpdate: 'Update data model',
          fieldError1: 'These fields could not be updated: ',
          fieldError2: 'because the field type has changed.',
          errorObjectClass: 'because the object type has changed.',
          textError1: 'These texts could not be updated: ',
          mediaError1: 'These media could not be updated: '
        },
        customerAdresses: {
          noValidEmail: 'Not a valid email adress.'
        },
        managUsers: {
          register: {
            addressData: 'Address data',
            registrationTitle: 'Registration',
            position: 'Position',
            taxnumber: 'Taxnumber',
            provisionReceiver: 'Provision receiver',
            provisionAmount: 'Provisionsanteil',
            companyAffix: 'Company suffix',
            department: 'Department',
            postalCode: 'Postal code',
            webSite: 'Website',
            title: 'Titel',
            lastName: 'Last name',
            streetNumber: 'Street number',
            street: 'Street',
            state: 'State',
            salutation: 'Title',
            phone: 'Phone',
            mobile: 'Mobile phone',
            city: 'City',
            firstName: 'First name',
            fax: 'Fax',
            email: 'E-mail',
            country: 'Country',
            company: 'Company',
            next: 'Next',
            username: 'Username',
            password: 'Password',
            passwordRepeat: 'Repeat password',
            userLocale: 'Interface language',
            userTimezone: 'Time zone',
            previous: 'Back',
            user: 'User',
            organization: 'Organization',
            subscription: 'Abonnement',
            summary: 'Summary',
            success: 'Success',
            deleteButtonConfirm: 'Are you sure, you want to delete this user?',
            permissions: 'Permissions',
            noPermissions: 'No permissions selected.',
            permissionsSelection:
              '{{selectedLength}} of {{rolesTotal}} permissions selected.',
            allDataZones: 'All data zones',
            dataZonesSelection: '{{count}} data zones selected',
            dataZoneRequired: 'At least 1 data zone required',
            toggleAllNone: 'Select All/Unselect All',
            createTitle: 'Create new user',
            editTitle: 'Edit user',
            deleteButton: 'Delete user',
            copieUser: 'Copie user',
            saveUser: 'SAVE',
            cancel: 'CANCEL',
            userLocales_: {
              de_DE: 'Germany',
              en_GB: 'English',
              fr_FR: 'French',
              it_IT: 'Italian'
            },
            userTimezone_: {
              'Europe/Berlin': 'Berlin',
              UTC: 'Coordinated Universal Time',
              'Europe/Rome': 'Rom'
            },
            salutations_: { MALE: 'Mr.', FEMALE: 'Mrs.' }
          }
        },
        manageOrganization: {
          overview: {
            organizations: 'Organizations',
            subscribableClassifications: 'Subscribable classifications (global)'
          },
          organization: {
            name: 'Organization name',
            nameAffix: 'Name suffix',
            taxnumber: 'Tax number*',
            provisionReceiver: 'Provision receiver',
            provisionAmount: 'Provision amount (in %)',
            companyPolicy: 'Company Policy',
            additionalImprint: 'Further informations for the imprint',
            street: 'Street*',
            streetNumber: 'Street number*',
            postalCode: 'Postal code*',
            city: 'City',
            phone: 'Telephone*',
            department: 'Department',
            mobile: 'Mobile phone',
            fax: 'Fax',
            email: 'E-mail',
            state: 'State*',
            country: 'Country',
            webSite: 'Website',
            organisationState: 'Status',
            subscription: 'Abonnement',
            supplierId: 'Supplier ID',
            masterOrgaId: 'Trunk-Organization-ID',
            actions: 'Actions',
            createTitle: 'Create new organization',
            createButtonConfirm:
              'Are you sure, you want to create this organization?',
            createButton: 'Create organization',
            editTitle: 'Edit organization',
            saveButton: 'Save organization',
            changeOrganisation: 'Change Organisation',
            saveButtonConfirm: 'Are you sure, you want to save all changes',
            deleteButton: 'Delete organization',
            deleteButtonConfirm:
              'Are you sure, you want to delete this organization?',
            editButton: 'Edit organization',
            pdfNameBuilder: 'PDFNameBuilder*',
            search: 'search',
            active: 'Active',
            silent: 'Silent',
            locked: 'Locked',
            requiredFieldErrorMessage: 'Mandatory value cannot be empty.',
            inputPhoneNumberRegExpErrorMessage:
              'Invalid value, only phone numbers are allowed',
            inputMailRegExpErrorMessage: 'Invalid  E-Mail ',
            inputWebSiteRegExpErrorMessage: 'Invalid URL-Format!',
            countries: 'en_countries',
            elementsPerPage: 'elements per page',
            page: 'page',
            pageOf: 'of',
            firstPage: 'First page',
            lastPage: 'Last page',
            nextPage: 'Next page',
            previousPage: 'previous page'
          },
          subscribableOrgaClassifications: 'Subscribable classifications'
        },
        managUsersSFTP: {
          name: 'Name',
          connection: 'Connection',
          serverUrl: 'Server-URL',
          createTitle: 'Create new SFTP-Connection',
          editTitle: 'Edit SFTP-Connection',
          deleteButton: 'Delete SFTP-Connection',
          copieConnection: 'Copie SFTP-Connection',
          downLoadFolder: 'Folder',
          user: 'User',
          password: 'Password',
          privateKey: 'privateKey file',
          deleteButtonConfirm:
            'Are you sure, you want to delete this Connection?',
          fileDownload: 'file-Download',
          connectionType: 'Connection type',
          defaultSelect: 'select server'
        },
        classificationGroup: {
          id: 'ID',
          designation: 'Bezeichnung',
          externClassification: 'Extern Classifications',
          internClassification: 'Intern Classification',
          deleteButtonConfirm:
            'Are you sure, you want to delete this Classification ?',
          createTitle: 'Create Classification',
          editTitle: 'Edit Classification',
          deleteButton: 'Delete Classification',
          copieClassification: 'Copie Classification ',
          classification: 'Classification'
        },
        manageClassificator: {
          classificator: {
            classificationName: 'Classification Name',
            createTitle: 'Export Classification',
            exportFinished: 'Export finished, open classificator ?'
          }
        },
        outputChannel: {
          id: 'ID',
          outputChannelName: 'OutputChannel',
          deleteButtonConfirm:
            'Are you sure, you want to delete this output channel ?',
          createTitle: 'Create new output channel',
          deleteButton: 'Delete output channel',
          classification: 'output channels',
          editTitle: 'Edit output channel'
        },
        products: {
          deleteButtonConfirm: 'Are you sure, you want to delete this product ?'
        },
        language: {
          code: 'en_GB'
        }
      }
    },
    de: {
      translation: {
        common: {
          ok: 'Ok',
          yes: 'Ja',
          no: 'Nein',
          save: 'Speichern',
          saved: 'Gespeichert',
          saveError: 'Fehler beim Speichern',
          load: 'Laden',
          edit: 'Bearbeiten',
          add: 'Hinzufügen',
          delete: 'Löschen',
          deleteWithParameter: '"{{label}}" löschen',
          forward: 'Vor',
          back: 'Zurück',
          create: 'Erstellen',
          confirm: 'Sind Sie sicher?',
          duplicate: 'Duplizieren',
          selected: 'Ausgewählt',
          upload: 'Hochladen',
          download: 'Herunterladen',
          noResults: 'Kein Ergebnis',
          inputName: 'Name eingeben',
          error: 'Fehler',
          hint: 'Hinweis',
          errorHappened: 'Etwas ist schief gelaufen:',
          errorTryAgain: 'Nochmal versuchen',
          errorMandatory: 'Pflichtwert darf nicht leer sein.',
          abort: 'Abbrechen',
          german: 'Deutsch',
          english: 'Englisch',
          french: 'Französisch',
          italian: 'Italienisch',
          noSearchResults: 'Keine Suchergebnisse gefunden .',
          category: 'Kategorie',
          cancel: 'Abbrechen',
          all: 'alle',
          invalidInput: 'Die eingegebenen Daten entsprechen nicht den Vorgaben',
          config: 'Konfiguration',
          type: 'Typ',
          language: 'Sprache',
          name: 'Name',
          actions: 'Aktionen',
          anyTextMissing: '--- (Bezeichnung fehlt)',
          selectDeselectAll: 'Alles auswählen/abwählen',
          noSubscription: 'Es wurde noch kein Abonnement gebucht.',
          linkToSubscription: 'Hier zum Abonnement'
        },
        dashboard: {
          user: 'Benutzer',
          orga: 'Organisation',
          createGrid: 'Dashboard erstellen',
          deleteGrid: 'Dashboard "{{gridLabel}}" löschen',
          editTitle: 'Dashboard bearbeiten',
          firstGridLabel: 'Übersicht',
          panel: {
            panelBaseConfig: 'Basis-Konfiguration',
            titleVisible: 'Titel sichtbar',
            closable: 'Schließbar',
            movable: 'Bewegbar',
            resizableX: 'Breite verstellbar',
            resizableY: 'Höhe verstellbar'
          },
          text: {
            textConfig: 'Text konfigurieren',
            textTitle: 'Titel',
            textContent: 'Textinhalt',
            textPanelEditable: 'Textinhalt in Anzeige bearbeiten'
          },
          subscription: {
            subQuota: 'Abonnement-Kontingent',
            quotaUnlimited: 'unbegrenzt',
            subFeatureList: 'Ansicht Aktuelles Abonnement',
            subscribed: 'Gebuchte Features',
            notSubscribed: 'Nicht gebuchte Features'
          },
          linkList: {
            name: 'Liste von Links',
            favorites: 'Favoriten',
            recents: 'Letzte Änderungen',
            linkListConfigTitle: 'Typ der Liste festlegen',
            type: 'Typ'
          },
          articleDataZones: {
            name: 'Datenbereiche',
            config: 'Datenbereichsliste konfigurieren',
            create: 'Datenbereich erstellen',
            edit: 'Datenbereich bearbeiten'
          },
          orgaExports: {
            orgaExportsConfigTitle: 'organisation Exporte'
          },
          tableDef: {
            name: 'Name',
            favorites: 'Favoriten',
            recents: 'Letzte Änderungen',
            createTableDef: 'Tabelle Definition anlegen',
            createTableDefTitle: 'Neue Tabelle Definition anlegen',
            editTableDef: 'Tabelle Definition bearbeiten ',
            deleteTableDef: 'Tabelle Definition löschen',
            rows: 'Zeilen',
            columns: 'Spalten',
            deleteButtonConfirm:
              'Sind sie sicher, dass Sie diese Tabelle Definition löschen möchten?',
            width: 'Feldgröße',
            unit: 'Einheit',
            alignment: 'Ausrichtung',
            orderErrorMessage: 'muss mindestens 1 sein',
            size: 'Größe',
            sizeErrorMessage: 'Maximalwert überschritten',
            order: 'Reihenfolge',
            features: 'Merkmale',
            horizontal: 'Horizontal',
            vertical: 'Vertikal',
            namePanel: 'Tabelle Definition'
          }
        },
        data: {
          article: 'Artikel',
          articleNumber: 'Artikelnummer',
          artNr: 'Art. Nr.',
          customField: 'Feld',
          customFields: 'Felder',
          feature: 'Merkmal',
          features: 'Merkmale',
          product: 'Produkt',
          customInstance: 'Container',
          ptd: 'Produkttyp',
          productStructure: 'Produktstruktur',
          catalog: 'Katalog',
          shop: 'Shop',
          addFavorite: 'Favorit hinzufügen',
          removeFavorite: 'Favorit entfernen'
        },
        instanceEditor: {
          createInstance: '{{instanceTitle}} erstellen',
          deleteInstances:
            'Sind Sie sicher, dass Sie alle ausgewählten Objekte des Typs {{instanceTitle}} löschen möchten?',
          deleteArticles:
            'Sind Sie sicher, dass Sie alle ausgewählten Artikel dieses Produkts löschen möchten?',
          textEditor: {
            placeholder: 'Text kann hier eingegebenen werden...'
          },
          textVariable: {
            title: 'Text-Variable',
            dataCategory: 'Element',
            buttonAdd: 'Hinzufügen',
            buttonEdit: 'Editieren',
            buttonDelete: 'Löschen',
            dataCategoryNotPossible:
              'Für eine Textvariable gibt es keine Felder zur Auswahl. Keine eigenen Felder, Merkmale etc. für diesen Datentyp definiert.',
            tagName: 'Typ',
            value: 'WERT',
            label: 'BEZEICHNUNG',
            unit: 'EINHEIT'
          },
          anchoredMedia: {
            title: 'Bild',
            buttonAdd: 'Hinzufügen',
            buttonEdit: 'Editieren',
            buttonDelete: 'Löschen',
            mediumType: 'Medientyp',
            searchPlaceholder: 'Dateinamen suchen'
          },
          articleDataZone: {
            name: 'Datenbereich',
            missingZoneError:
              'Gespeicherter Datenbereich ist nicht mehr im System vorhanden'
          },
          keywords: {
            globalKeywords: 'Alle Keywords',
            customKeywords: 'Eigene Keywords',
            addOption:
              'Soll der Wert zu allen Keywords oder nur hier zu den eigenen Keywords hinzugefügt werden?'
          },
          selection: {
            commonOptions: 'Werteliste der Definition',
            customOptions: 'Eigene Werte',
            addOption:
              'Soll der Wert der Werteliste der Definition oder nur hier zu den eigenen Werten hinzugefügt werden?'
          },
          range: {
            from: 'von',
            to: 'bis',
            incomplete: 'Unvollständig'
          },
          regExErrorDefault:
            'Die eingegebenen Daten entsprechen nicht den Vorgaben',
          defaultToggleOn: 'PTD Standardwert einschalten',
          defaultToggleOff: 'PTD Standardwert ausschalten',
          irrelevant: '- (Nicht relevant)'
        },
        tabeditor: {
          source: 'Quelle',
          lang: 'Sprache',
          versions: {
            version: 'Version',
            versions: 'Versionen',
            name: 'Versionsname',
            applied: 'Global',
            overwrite: 'Möchten Sie diese Version überschreiben?',
            delete: 'Möchten Sie diese Version löschen?'
          },
          tools: {
            structure: 'Struktur',
            title: 'Überschrift',
            container: 'Container',
            tabs: {
              name: 'Tabs',
              add: 'Tab hinzufügen',
              orientation: 'Ausrichtung',
              horizontal: 'Horizontal',
              vertical: 'Vertikal',
              deleteSelected: 'Aktiven Tab löschen'
            },
            table: {
              name: 'Tabelle',
              firstColumn: 'Erste Spalte',
              newColumn: 'Neue Spalte',
              emptyColumn: 'Leere Spalte',
              add: 'Spalte hinzufügen',
              deleteSelected: 'Aktive Spalte löschen'
            },
            accordion: 'Akkordeon',
            editors: 'Editoren',
            textEditor: {
              name: 'Texte',
              exampleText: 'Beispieltext...',
              labelPlaceholder: 'Texttyp auswählen...',
              type: 'Texttyp',
              variant: 'Variante',
              formatted: 'Formatierter Text',
              simple: 'Nur Text',
              allTexts: 'Alle Texte',
              bulkData: 'alle Texte',
              bulkDataTextGroup: 'alle Texte "{{groupId}}"'
            },
            mediaEditor: {
              name: 'Medien',
              labelPlaceholder: 'Medientyp auswählen...',
              type: 'Medientyp',
              allMedia: 'Alle Medien',
              bulkData: 'alle Medien',
              bulkDataMediaGroup: 'alle Medien "{{groupId}}"'
            },
            fieldEditor: {
              name: 'Felder',
              exampleValue: 'Beispielwert...',
              labelPlaceholder: 'Feldtyp auswählen...',
              type: 'Feldtyp',
              generalFields: 'Allgemeine Felder',
              customFields: 'Eigene Felder',
              customFieldsNode: 'Alle eigenen Felder',
              bulkDataGeneralFields: 'alle allgemeinen Felder',
              bulkDataFeatures: 'alle Merkmale',
              bulkDataCustomFields: 'alle eigenen Felder',
              bulkDataFieldGroup: 'alle Felder "{{groupId}}"',
              keywords: 'Keywords'
            },
            missingDef: 'Definition "{{defTitle}}" nicht vorhanden!'
          },
          settings: {
            background: 'Farbe',
            padding: 'Padding',
            fontSize: 'Textgröße',
            dataSource: 'Datenquelle',
            multipleDataSourceField: 'Datenfeld(er) auswählen',
            columns: 'Spaltenanzahl'
          }
        },
        productType: {
          name: 'Produkttyp-Name',
          classification: 'Klassifizierung',
          clear: 'Zurücksetzen',
          select: 'bitte wählen',
          open: 'Öffnen',
          datasheetWithoutDefaultData:
            'Produktdatenblatt anzeigen (Ohne PTD Standardwerte)',
          datasheet: 'Produktdatenblatt anzeigen',
          Producttypdefinition: 'Produkttypdefinition',
          ptdSelectHelpNoSMLSubscription:
            'Die Produkttypdefinition legt die Klassifizierung dieses Produktes fest. Sie können Produkttypdefinitionen aus den Standardklassifizierungen in Ihr System übernehmen. Wählen Sie dazu die gewünschte Produkttypdefinition unter Vorlage aus und übernehmen diese.',
          ptdSelectHelp:
            'Die Produkttypdefinition legt die Klassifizierung dieses Produktes fest.Sie können Produkttypdefinitionen aus den Standardklassifizierungen in Ihr System übernehmen. Wählen Sie dazu die gewünschte Produkttypdefinition unter Vorlage aus und übernehmen diese. Eigene Produkttypdefinitionen können Sie mit dem Produkttyp-Editor erstellen.'
        },
        export: {
          overwrite: 'Wollen Sie den Export "{{exportName}}" überschreiben?',
          chooseArticle: 'Artikel-Auswahl',
          exportSettings: 'Export-Einstellungen'
        },
        bmeCatExport: {
          chooseArticle: {
            title: 'Artikel-Auswahl',
            allArticles: 'Alle Artikel',
            excelList: 'Excel-Liste',
            catalog: 'Katalog',
            productStructure: 'Produkt-Struktur',
            shop: 'Shop'
          },
          generalSettings: 'Allgemeine-Einstellungen',
          header: 'Katalog-Header',
          hierarchySystem: 'Struktur-Daten'
        },
        bmeCatDataModel: {
          addMandatoryFields: 'Pflichtfelder hinzufügen',
          mandatoryFieldsAdded: 'Pflichtfelder hinzugefügt, bitte speichern.',
          specification: 'Spezifikation',
          saveDataModel: 'Datenmodell speichern',
          selectAll: 'Alles Auswählen',
          currentDataModel: 'Aktuelles Datenmodell',
          automaticUpdate: 'Datenmodell aktualisieren',
          fieldError1: 'Diese Felder konnten nicht aktualisiert werden: ',
          fieldError2: 'da sich der Feldtyp geändert hat.',
          errorObjectClass: 'da sich der Objekttyp geändert hat.',
          textError1: 'Diese Texte konnten nicht aktualisiert werden: ',
          mediaError1: 'Diese Medien konnten nicht aktualisiert werden: '
        },
        customerAdresses: {
          noValidEmail: 'Not a valid email adress.'
        },
        managUsers: {
          register: {
            addressData: 'Adressdaten',
            registrationTitle: 'Registrierung',
            position: 'Position',
            taxnumber: 'Steuernummer',
            provisionReceiver: 'Provisionsempfänger',
            provisionAmount: 'Provisionsanteil',
            companyAffix: 'Firmensuffix',
            department: 'Abteilung',
            postalCode: 'PLZ',
            webSite: 'Webseite',
            title: 'Titel',
            lastName: 'Nachname',
            streetNumber: 'Hausnummer',
            street: 'Straße',
            state: 'Staat',
            salutation: 'Anrede',
            phone: 'Telefon',
            mobile: 'Handy',
            city: 'Stadt',
            firstName: 'Vorname',
            fax: 'Fax',
            email: 'E-Mail',
            country: 'Land',
            company: 'Firma',
            next: 'Weiter',
            username: 'Benutzername',
            password: 'Passwort',
            passwordRepeat: 'Passwort wiederholen',
            userLocale: 'Oberflächensprache',
            userTimezone: 'Zeitzone',
            previous: 'Zurück',
            user: 'Benutzer',
            organization: 'Organisation',
            subscription: 'Abonnement',
            summary: 'Zusammenfassung',
            success: 'Erfolg',
            deleteButtonConfirm:
              'Sind sie sicher, dass Sie diesen Benuzter löschen möchten?',
            permissions: 'Berechtigungen',
            noPermissions: 'Keine Berechtigungen ausgewählt.',
            permissionsSelection:
              '{{selectedLength}} von {{rolesTotal}} Berechtigungen ausgewählt',
            allDataZones: 'Alle Datenbereiche',
            dataZonesSelection: '{{count}} Datenbereiche ausgewählt',
            dataZoneRequired: 'Mind. 1 Datenbereich erforderlich',
            toggleAllNone: 'Alles aus-/abwählen',
            createButton: 'Benuzter anlegen',
            createTitle: 'Neuen Benutzer anlegen',
            editTitle: 'Benutzer bearbeiten ',
            deleteButton: 'Benuzter löschen',
            copieUser: 'Benuzter kopieren',
            saveUser: 'ÜBERNEHMEN',
            cancel: 'ABBRECHEN',
            userLocales_: {
              de_DE: 'Deutsch',
              en_GB: 'Englisch',
              fr_FR: 'Französisch',
              it_IT: 'Italienisch'
            },
            userTimezone_: {
              'Europe/Berlin': 'Berlin',
              UTC: 'Koordinierte Universalzeit',
              'Europe/Rome': 'Rom'
            },
            salutations_: { MALE: 'Herr', FEMALE: 'Frau' }
          }
        },
        manageOrganization: {
          overview: {
            organizations: 'Organisationen',
            subscribableClassifications:
              'Klassifizierungen fürs Abonnement (Global)'
          },
          organization: {
            name: 'Name der Organisation',
            nameAffix: 'Namenszusatz',
            taxnumber: 'Steuernummer*',
            provisionReceiver: 'Provisionsempfänger',
            provisionAmount: 'Provisionsanteil (in %)',
            companyPolicy: 'Firmen Politik (AGB)',
            additionalImprint: 'Weitere Informationen für das Impressum',
            street: 'Straße*',
            streetNumber: 'Hausnummer*',
            postalCode: 'PLZ*',
            city: 'Stadt',
            phone: 'Telefon*',
            department: 'Abteilung',
            mobile: 'Handy',
            fax: 'Fax',
            email: 'E-Mail',
            state: 'Bundesland*',
            country: 'Land',
            webSite: 'Webseite',
            organisationState: 'Status',
            subscription: 'Abonnement',
            supplierId: 'Lieferanten-ID',
            masterOrgaId: 'Stamm-Orga-ID',
            actions: 'Aktionen',
            create: 'Organisation anlegen',
            createTitle: 'Neue Organisation anlegen',
            createButtonConfirm:
              'Sind Sie sicher, dass Sie diese Organisation anlegen möchten?',
            createButton: 'Organisation anlegen',
            editTitle: 'Organisation bearbeiten',
            saveButton: 'Organisation speichern',
            changeOrganisation: 'Organisation wechseln',
            saveButtonConfirm:
              'Sind sie sicher, dass Sie die Änderungen speichern möchten?',
            deleteButton: 'Organisation löschen',
            deleteButtonConfirm:
              'Sind sie sicher, dass Sie diese Organisation löschen möchten?',
            editButton: 'Organisation bearbeiten',
            pdfNameBuilder: 'PDFNameBuilder*',
            search: 'suchen',
            active: 'Aktiv',
            silent: 'Ruhezustand',
            locked: 'Gesperrt',
            requiredFieldErrorMessage: 'Pflichtwert darf nicht leer sein',
            inputPhoneNumberRegExpErrorMessage:
              'Ungültiger Wert, nur Telefonnummern sind erlaubt',
            inputMailRegExpErrorMessage: 'E-Mail ungültig',
            inputWebSiteRegExpErrorMessage: 'Ungültiges URL-Format!',
            countries: 'de_countries',
            elementsPerPage: 'Elemente pro Seite',
            page: 'Seite',
            pageOf: 'von',
            firstPage: 'Erste Seite',
            lastPage: 'Letzte Seite',
            nextPage: 'Nächste Seite',
            previousPage: 'Vorige Seite'
          },
          subscribableOrgaClassifications: 'Klassifizierungen fürs Abonnement'
        },
        managUsersSFTP: {
          name: 'Name',
          connection: 'Verbindung',
          serverUrl: 'Server-URL',
          downLoadFolder: 'Verzeichnis',
          createTitle: 'Neue SFTP-Verbindung anlegen',
          editTitle: 'SFTP-Verbindung bearbeiten',
          deleteButton: 'SFTP-Verbindung löschen',
          copieConnection: 'SFTP-Verbindung kopieren',
          user: 'Benutzer',
          password: 'Passwort',
          privateKey: 'Schlüsseldatei',
          deleteButtonConfirm:
            'Sind sie sicher, dass Sie diese Verbindung löschen möchten?',
          fileDownload: 'Datei-Download',
          connectionType: 'Verbindungsart',
          defaultSelect: 'bitte wählen'
        },
        classificationGroup: {
          id: 'ID',
          designation: 'Bezeichnung',
          externClassification: 'Gebuchte Klassifizierungen',
          internClassification: 'interne Klassifizierung',
          deleteButtonConfirm:
            'Sind sie sicher, dass Sie diese Klassifizierung löschen möchten?',
          createTitle: 'Neue Klassifizierung anlegen',
          editTitle: 'Klassifizierung bearbeiten',
          deleteButton: 'Klassifizierung löschen',
          copieClassification: 'Klassifizierung kopieren',
          classification: 'Klassifizierung'
        },
        manageClassificator: {
          classificator: {
            classificationName: 'Klassifizierung Name',
            createTitle: 'Klassifizierung exportieren',
            exportFinished: 'Export beendet, Classificator öffnen?'
          }
        },
        outputChannel: {
          id: 'ID',
          outputChannelName: 'Ausgabekanal',
          deleteButtonConfirm:
            'Sind sie sicher, dass Sie diesen Ausgabekanal löschen möchten?',
          createTitle: 'Neuen Ausgabekanal erstellen',
          deleteButton: 'Ausgabekanal löschen',
          classification: 'Ausgabekanäle',
          editTitle: 'Ausgabekanal bearbeiten'
        },
        products: {
          deleteButtonConfirm:
            'Sind sie sicher, dass Sie dieses Produkt löschen möchten?'
        },
        language: {
          code: 'de_DE'
        }
      }
    }
  }
});

export default i18n;
