import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle
} from '@material-ui/core';

import  { usePimStyles } from '../PimTogoTheme';
import ClassificationGroupForm from '../classificationGroup/ClassificationGroupForm';


export const CreateClassificationGroup = ({
  openDialogClassificationGroup,
  handleCloseDialogClassificationGroup,
  initFormClassificationGroupState,
  toCreateClassificationGroup,
  searchClassificationGroups,
  classificationList,
  contentLangSelections,
  productTypeDefinition
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();

  useEffect(() => {}, []);

  return (
    <Dialog
      aria-labelledby="create-instance-title"
      open={openDialogClassificationGroup}
      onClose={handleCloseDialogClassificationGroup}
      className={classesPim.dialog}
      transitionDuration={0}
    >
      <DialogTitle
        id="create-instance-title"
        className={classesPim.dialogTitle}
      >        {toCreateClassificationGroup ? t('classificationGroup.createTitle') : t('classificationGroup.editTitle') }

      </DialogTitle>
      <ClassificationGroupForm
        handleCloseDialogClassificationGroup={handleCloseDialogClassificationGroup}
        classificationList={classificationList}
        initFormClassificationGroupState={initFormClassificationGroupState}
        toCreateClassificationGroup={toCreateClassificationGroup}
        searchClassificationGroups={searchClassificationGroups}
        contentLangSelections={contentLangSelections}
        productTypeDefinition={productTypeDefinition}
      />
    </Dialog>
  );
};

export default CreateClassificationGroup;
